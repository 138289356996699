import { post } from '@/backend/client'
import type { BackendMeta } from '@/backend/types'
import type { SnakeToCamelCaseObj } from '@/shared/types'

export type StartConnectionPickerSessionMeta = BackendMeta<
  '/api/workspaces/{workspace_id}/integrations/{integration_id}/connections/{connection_id}/start_file_picker_session',
  'post'
>
type Body = StartConnectionPickerSessionMeta['body']
type Params = StartConnectionPickerSessionMeta['pathParams']
type Response = StartConnectionPickerSessionMeta['successResponse']

export type FilePickerSessionResponse = SnakeToCamelCaseObj<Response>

export async function startFilePickerSession(
  params: SnakeToCamelCaseObj<Params & Body>,
): Promise<FilePickerSessionResponse> {
  const { workspaceId, integrationId, connectionId } = params

  const response = await post<
    Params,
    StartConnectionPickerSessionMeta['successResponse'],
    StartConnectionPickerSessionMeta['path']
  >(
    `/api/workspaces/${workspaceId}/integrations/${integrationId}/connections/${connectionId}/start_file_picker_session`,
    {
      workspace_id: workspaceId,
      integration_id: integrationId,
      connection_id: connectionId,
    },
  )

  if (!response.ok) {
    throw new Error(response?.error?.message || 'Could not start file picker session')
  }

  const r = response.data
  return {
    accessToken: r.access_token,
    expiresAt: r.expires_at,
    type: r.type,
  }
}
