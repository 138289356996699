<script setup lang="ts">
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'

defineProps<{
  teleportTargetId: string
}>()

const emit = defineEmits<{
  (e: 'updateCover', file: File | undefined): void
  (e: 'generateCover'): void
}>()

const emitUpdate = (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0]
  emit('updateCover', file)
}
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    :teleport-to="`#${teleportTargetId}`"
    close-on-select
  >
    <IconButton
      icon="more-dots"
      size="sm"
      variant="transparent"
      rounded
      data-test="project-menu-trigger"
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      aria-label="Open cover menu"
      >Click me</IconButton
    >
    <Menu.Content class="min-w-56">
      <Menu.Item
        element="label"
        icon="picture"
        aria-label="Update project cover"
        @select="() => {}"
      >
        Update project cover
        <input
          ref="fileInput"
          class="hidden"
          type="file"
          data-test="project-cover-input"
          @input="emitUpdate"
        />
      </Menu.Item>

      <Menu.Item
        element="button"
        icon="switch"
        aria-label="Generate project cover"
        @select="emit('generateCover')"
      >
        Generate project cover
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
