<script setup lang="ts">
import { deleteCase } from '@/backend/deleteCase'
import { updateCase } from '@/backend/updateCase'
import AgentPicker from '@/modules/Cases/AgentPicker.vue'
import CaseMessageInput from '@/modules/Cases/CaseMessageInput.vue'
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import FileUploadMenu from '@/modules/Project/FileUploadMenu.vue'
import WorkspaceFixedToolbar from '@/modules/Workspaces/WorkspaceFixedToolbar.vue'
import WorkspaceHomepageGreetingV2 from '@/modules/Workspaces/WorkspaceHomepageGreetingV2.vue'
import { toast } from '@/shared/toast'
import { invariant } from '@/shared/utils/typeAssertions'
import { useDashboardFilterStore } from '@/sharedComposables/useDashboardFilterStore'
import IconButton from '@/uiKit/IconButton.vue'
import { useDropZone } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, useTemplateRef } from 'vue'
import CasesList from './CasesList.vue'
import NoCaseFound from './NoCaseFound.vue'
import { useCaseStore } from './useCaseStore'
import { useNewCase } from './useNewCase'

const props = defineProps<{ workspaceId: string }>()

const casesStore = useCaseStore()
const dashboardFilterStore = useDashboardFilterStore()
const { filteredCases } = storeToRefs(dashboardFilterStore)
const {
  addIntegrationFiles,
  addLibraryFiles,
  addPendingUploads,
  agentId,
  agents,
  attachments,
  status,
  messageText,
  removeUpload,
  startNewCase,
  supportedMimeTypes,
} = useNewCase()
const userStore = useUser()
const messageInputRef = useTemplateRef<HTMLDivElement>('messageInputRef')
const { isOverDropZone } = useDropZone(messageInputRef, {
  onDrop: (files) => {
    if (files && files.length > 0) {
      addPendingUploads(files)
    }
  },
})

const isLoading = computed(() => casesStore.loadingState === 'loading')

const shouldShowNoCases = computed(() => !isLoading.value && filteredCases.value?.length === 0)

const onRenameCase = async (id: string, name: string) => {
  const targetCase = casesStore.getCaseById(id)
  if (!targetCase) {
    invariant(targetCase, 'Case not found')
    return
  }

  const revert = casesStore.setCase({ ...targetCase, name })

  try {
    const res = await updateCase({ workspaceId: props.workspaceId, caseId: id, name })
    if (!res.ok) {
      revert()
      toast.error('Failed to rename case')
    }
  } catch {
    revert()
    toast.error('Failed to rename case')
  }
}

const onRemoveCase = async (id: string) => {
  const revert = casesStore.removeCase(id)

  try {
    const res = await deleteCase({ workspaceId: props.workspaceId, caseId: id })
    if (!res.ok && res.error.status !== 404) {
      revert()
      toast.error('Failed to delete case')
    }
  } catch {
    revert()
    toast.error('Failed to delete case')
  }
}
</script>

<template>
  <div
    class="go-scrollbar flex h-auto min-h-full flex-col gap-10 overflow-auto pb-4 [contain:strict]"
    data-test="cases-container"
  >
    <div class="mt-8 flex items-center justify-between">
      <WorkspaceHomepageGreetingV2 :user="userStore.user" />
    </div>

    <CaseMessageInput
      ref="messageInputRef"
      v-model="messageText"
      class="go-column px-2"
      :attachments="attachments"
      :placeholder="'Ask Go...'"
      :disabled="status !== 'initial'"
      :is-dropping-files="isOverDropZone"
      @submit="startNewCase({ workspaceId: props.workspaceId, initialMessage: messageText })"
      @delete-attachment="removeUpload($event)"
    >
      <template #actions>
        <FileUploadMenu
          v-slot="{ getTriggerProps, menu }"
          library-picker-confirm-label="Attach"
          :file-input-props="{ accept: supportedMimeTypes.join(', ') }"
          @import:library="addLibraryFiles($event)"
          @import:computer="addPendingUploads($event)"
          @import:google="addIntegrationFiles"
        >
          <IconButton
            aria-label="Add attachment"
            variant="transparent"
            rounded
            size="lg"
            icon="plus"
            :active="menu?.open"
            v-bind="getTriggerProps()"
          />
        </FileUploadMenu>
        <AgentPicker
          v-model="agentId"
          :agents="agents"
        />
        <span
          v-if="status === 'creating'"
          class="text-sm-12px-light text-text-subtlest"
        >
          Starting new case...
        </span>
        <span
          v-if="status === 'uploading'"
          class="text-sm-12px-light text-text-subtlest"
        >
          Uploading files...
        </span>
        <span
          v-if="status === 'waiting'"
          class="text-sm-12px-light text-text-subtlest"
        >
          Waiting for file preprocessing...
        </span>
        <span
          v-if="status === 'sending'"
          class="text-sm-12px-light text-text-subtlest"
        >
          Asking question...
        </span>
      </template>
    </CaseMessageInput>

    <div class="flex grow flex-col items-start justify-start gap-4 pt-2">
      <WorkspaceFixedToolbar />
      <NoCaseFound
        v-if="shouldShowNoCases"
        class="go-column px-2"
        @reset-filters="dashboardFilterStore.clear"
      />
      <div
        v-else
        class="go-column px-2"
      >
        <CasesList
          :loading="isLoading"
          :workspace-id="workspaceId"
          :cases="filteredCases"
          @remove="onRemoveCase"
          @rename="onRenameCase"
        />
      </div>
    </div>
  </div>
</template>
