import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import { useEntity } from '@/modules/Project/useEntity'
import { useProject, type View } from '@/modules/Project/useProject'
import { computed, type Ref } from 'vue'

type FieldItem = {
  field: Field
  property: Property
  view: View
}

/**
 * When displaying an entity in the case sidebar,
 * we want to display all fields that are relevant to the case.
 * They can be found in multiple different views, so we need to find them all.
 */
export function useAllStageFields() {
  const entityStore = useEntity()
  const projectStore = useProject()

  const fields: Ref<FieldItem[]> = computed(() => {
    const fields: FieldItem[] = []
    const entity = entityStore.entity
    if (!entity) return fields

    const allViews = projectStore.views
    const relevantViewIds = entity.activeViewIds || []

    /** Views that this entity appears in */
    const entityViews = relevantViewIds.map((id) => allViews.find((v) => v.id === id))

    for (const view of entityViews) {
      for (const propertyId of view?.propertyIds || []) {
        const field = entity.fields.get(propertyId)
        // Skip if we already have this field from another view
        if (fields.find((item) => item.field === field)) continue

        const property = projectStore.propertiesById[propertyId]
        if (!field || !property || !view) continue
        fields.push({ property, field, view })
      }
    }

    return fields
  })

  return { fields }
}
