<script setup lang="ts">
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import { getSubprojectId } from '@/shared/utils/property'
import { computed } from 'vue'
import FileCellEmpty from './FileCellEmpty.vue'
import FileCellPopulated from './FileCellPopulated.vue'
import { useFileField } from './useFileField'

const props = defineProps<{
  field: Field<'file' | 'file_collection'>
  property: Property<'file' | 'file_collection'>
  workspaceId: string
  projectId: string
  isFocused: boolean
  isSelected: boolean
  hasSelectedRange: boolean
}>()

defineEmits<{
  (e: 'uploaded', url: string): void
  (e: 'cleared' | 'blur'): void
}>()

const { displayUrl, downloadUrl, filename, dialogFiles } = useFileField(computed(() => props.field))

const subprojectId = computed<string | null>(() => {
  if (props.property.type === 'file') {
    return null
  }
  return getSubprojectId(props.property)
})
</script>

<template>
  <FileCellPopulated
    v-if="displayUrl && downloadUrl && filename"
    :filename="filename"
    :display-url="displayUrl"
    :download-url="downloadUrl"
    :field="field"
    :property="property"
    :is-focused="isFocused"
    :is-selected="isSelected"
    :project-id="projectId"
    :workspace-id="workspaceId"
    :has-selected-range="hasSelectedRange"
    v-bind="$attrs"
    @replace="dialogFiles = [$event]"
    @blur="$emit('blur')"
  />
  <FileCellEmpty
    v-else
    v-bind="{ ...props, ...$attrs, pdfProjectId: subprojectId }"
    :filename="filename"
    @uploaded="$emit('uploaded', $event)"
    @cleared="$emit('cleared')"
    @upload:files="dialogFiles = $event"
    @blur="$emit('blur')"
  />
</template>
