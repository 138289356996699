<script setup lang="ts">
import InlineFile from '@/sharedComponents/InlineFile.vue'
import { usePdfSearch } from '@/sharedComposables/usePdfSearch'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Select from '@/uiKit/Select/Select.vue'
import { computed, watch } from 'vue'
import type { Field } from '../Project/Fields/types'
import GroundingModalFile from '../Project/GroundingModalFile.vue'
import { resolveFilename } from '../Project/resolveFilename'
import { useFileField } from '../Project/useFileField'
import { useGroundingStore } from '../Project/useGroundingStore'
import CaseFileViewerSearchField from './CaseFileViewerSearchField.vue'

const props = defineProps<{
  field: Field<'file' | 'file_collection'>
  availableFileFields: Array<Field<'file' | 'file_collection'>>
}>()

const emit = defineEmits<{
  (e: 'close', event: Event): void
  (e: 'change:field', propertyId: string): void
}>()

const groundingStore = useGroundingStore()

const { fileType, displayUrl, filename } = useFileField(computed(() => props.field))

const {
  canSearch,
  search,
  onHighlight,
  matchingElements,
  activeMatchIndex,
  reset,
  onTextLayerLoaded,
} = usePdfSearch(computed(() => props.field))
watch(
  () => props.field,
  () => {
    search.value = null
  },
)

const onClear = (event: Event) => {
  if (search.value === null) {
    emit('close', event)
  } else {
    reset()
  }
}
</script>

<template>
  <div
    class="flex max-h-full min-h-0 w-full flex-col bg-surface-secondary"
    data-test="case-file-viewer"
  >
    <div class="flex min-h-[52px] items-center gap-2 border-b border-border-subtle px-2.5">
      <CaseFileViewerSearchField
        v-if="fileType === 'pdf' && search !== null"
        v-model="search"
        class="grow"
        :aria-label="filename ? `Search in ${filename}` : 'Search in file'"
        :total-matches="matchingElements.length"
        :current-match="activeMatchIndex"
        @next="activeMatchIndex++"
        @previous="activeMatchIndex--"
      />
      <template v-else>
        <Select
          class="max-w-52"
          constant-trigger-width
          inline
          aria-label="Active file"
          :value="field.propertyId"
          :items="
            availableFileFields.map((f) => ({
              label: resolveFilename(f) || f.propertyId,
              value: f.propertyId,
            }))
          "
          :positioning="{
            offset: {
              crossAxis: 0,
              mainAxis: 4,
            },
          }"
          @change="$emit('change:field', $event)"
        >
          <template #item:icon="{ selected }">
            <IconSprite
              class="text-icon-subtle"
              :class="!selected && 'opacity-0'"
              icon="check"
            />
          </template>
        </Select>
        <div class="flex grow items-center justify-end">
          <IconButton
            v-if="fileType === 'pdf' && canSearch"
            icon="search"
            aria-label="Search in file"
            size="xl"
            variant="transparent"
            rounded
            @click="search = ''"
          />
        </div>
      </template>
      <IconButton
        icon="close"
        :aria-label="search === null ? 'Close file' : 'Clear search'"
        size="xl"
        variant="transparent"
        rounded
        @click="onClear"
      />
    </div>
    <div class="go-scrollbar min-h-0 grow overflow-y-auto">
      <GroundingModalFile
        v-if="groundingStore.activeInputField"
        :highlight-text="search || undefined"
        @highlight="onHighlight"
        @text-loaded="onTextLayerLoaded"
      />
      <template v-else-if="displayUrl && fileType">
        <InlineFile
          :file-type="fileType"
          :url="displayUrl"
          :highlight-text="search || undefined"
          @pdf:highlight="onHighlight"
          @pdf:text-loaded="onTextLayerLoaded"
        />
      </template>
    </div>
  </div>
</template>
