<script setup lang="ts">
import { isCommandOrControlKey } from '@/shared/utils/event'
import { useTextareaAutosize } from '@vueuse/core'
import { computed, useTemplateRef, watch } from 'vue'

type Props = {
  modelValue: string
  /**
   * singleline: Submit on {Enter}, no line breaks allowed.
   * multiline: Submit on Meta+Enter, allow line breaks on {Enter}.
   * chat: Submit on {Enter}, allow line breaks on {Shift+Enter}.
   */
  mode?: 'singleline' | 'multiline' | 'chat'
  autofocus?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'multiline',
})

const emit = defineEmits<{
  'update:modelValue': [value: string]
  submit: [event: Event]
}>()

const textarea = useTemplateRef('textarea')
useTextareaAutosize({
  element: computed(() => textarea.value || undefined),
  input: computed(() => props.modelValue),
})

function handleInput(event: Event) {
  const target = event.target as HTMLTextAreaElement
  let value = target.value
  if (props.mode === 'singleline') {
    value = value.replace(/\n/g, '')
  }

  emit('update:modelValue', value)
}

function handleKeyDown(event: KeyboardEvent) {
  /**
   * A single-line textarea should submit on Enter, like ome would expect on an <input type="text">.
   * A multi-line textarea should submit on Meta+Enter.
   */
  const isSubmit =
    (props.mode === 'singleline' && event.key === 'Enter') ||
    (props.mode === 'chat' && event.key === 'Enter' && !event.shiftKey) ||
    (props.mode === 'multiline' && event.key === 'Enter' && isCommandOrControlKey(event))

  if (isSubmit) {
    event.preventDefault()
    emit('submit', event)
  }
}

watch(
  textarea,
  (el) => {
    if (props.autofocus && el) {
      el.focus()
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <textarea
    ref="textarea"
    :value="modelValue"
    rows="1"
    class="block min-h-6 w-full resize-none overflow-hidden whitespace-pre-wrap"
    @input="handleInput"
    @keydown="handleKeyDown"
  />
</template>
