/**
 * Takes a string, generates a deterministic random number between 1-16
 */
export const textToDetRandom = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0
  }
  hash = Math.abs(hash)
  hash = (hash % 16) + 1
  const formattedHash = ('0' + hash).slice(-2)
  return formattedHash
}

/**
 * Generates a deterministic rainbow color based on text input
 */
export const getRainbowColor = (str: string) => `var(--rainbow-${textToDetRandom(str)}-light)`

export const getTextRainbowColor = (str: string) =>
  `var(--color-text-rainbow-${textToDetRandom(str)})`

export const getBackgroundRainbowColor = (str: string) =>
  `var(--color-background-rainbow-${textToDetRandom(str)}-subtle)`
