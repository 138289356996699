<script setup lang="ts">
import LibraryCsvSvg from '@/illustrations/library-csv.svg'
import LibraryImageSvg from '@/illustrations/library-image.svg'
import LibraryPdfSvg from '@/illustrations/library-pdf.svg'
import { computed } from 'vue'

const props = defineProps<{ name: string }>()

const document = Symbol('libraryDocument')

const placeholderComponent = computed(() => {
  const type = !props.name ? document : props.name.split('.').pop()

  switch (type) {
    case 'doc':
    case 'docx':
    case 'md':
    case 'pdf':
    case 'txt':
    case document:
      return LibraryPdfSvg
    case 'csv':
      return LibraryCsvSvg
    default:
      return LibraryImageSvg
  }
})
</script>

<template>
  <component :is="placeholderComponent" />
</template>
