<script setup lang="ts">
import { toast } from '@/shared/toast'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { format as formatDate } from 'date-fns'
import { computed, ref } from 'vue'
import AgentCover from './AgentCover.vue'
import CaseMarkdown from './CaseMarkdown.vue'
import type {
  BubbleMessageAgentRun,
  BubbleMessagePlaceholder,
  BubbleMessageResponse,
  BubbleMessageToolRun,
  ToolName,
} from './types'

const props = defineProps<{
  messages: (
    | BubbleMessageResponse
    | BubbleMessageAgentRun
    | BubbleMessageToolRun
    | BubbleMessagePlaceholder
  )[]
  previousQueryId: string | null
  disabled: boolean
}>()

const emit = defineEmits<{
  'select-agent': [string]
  'rerun-query': [string]
}>()

const FRIENDLY_TOOL_NAMES: Record<ToolName, string> = {
  query_files: 'Reading source files',
  search_web: 'Searching the web',
}

const formattedCreatedDate = computed(() =>
  formatDate(props.messages.at(-1)?.updatedAt ?? '', 'EEE d MMM yyyy · HH:mm'),
)

const expanded = ref(true)

const isComplete = computed(() =>
  props.messages.every(
    (m) =>
      m.messageType !== 'response-placeholder' &&
      m.messageType !== 'partial_response' &&
      !('inProgress' in m && m.inProgress),
  ),
)

const copyMessage = () => {
  const text = props.messages
    .filter(
      (m): m is BubbleMessageResponse | BubbleMessagePlaceholder =>
        m.messageType === 'response' ||
        m.messageType === 'partial_response' ||
        m.messageType === 'response-placeholder',
    )
    .map((m) => m.text)
    .join('\n')

  navigator.clipboard.writeText(text)

  toast.info(
    isComplete.value
      ? 'Text copied to clipboard.'
      : 'Text copied to clipboard. The response is still in progress. You may want to wait for the full response.',
  )
}
</script>

<template>
  <div
    v-if="expanded"
    class="group flex flex-col gap-1"
  >
    <div
      class="flex max-w-full flex-row items-start gap-4 rounded-corner-20 bg-surface-primary p-4 shadow-sm"
    >
      <!-- eslint-disable tailwindcss/no-custom-classname -->
      <IconSprite
        icon="ask-go"
        size="xxl"
        class="overflow-visible"
        :class="isComplete ? 'text-icon-subtlest' : ['animated-icon', 'text-[hsla(23,100%,50%,1)]']"
      />
      <!-- eslint-enable tailwindcss/no-custom-classname -->
      <div class="flex min-w-0 shrink grow flex-col">
        <template
          v-for="message in messages"
          :key="message.id"
        >
          <CaseMarkdown
            v-if="message.messageType === 'partial_response' || message.messageType === 'response'"
            :markdown="message.text"
          />

          <div v-if="message.messageType === 'response-placeholder'">
            <span class="ellipsis-loading text-md-13px-light text-text-subtlest">{{
              message.text
            }}</span>
          </div>

          <div
            v-if="message.messageType === 'agent_run'"
            class="flex flex-row items-center justify-start gap-2"
          >
            <DarwinButton
              variant="outline"
              size="md"
              rounded
              @click="message.agent && emit('select-agent', message.agent.id)"
            >
              <div class="-ml-1.5 -mr-1 flex flex-row items-center justify-start gap-1.5">
                <AgentCover :url="message.agent?.coverLowRes" />

                {{ message.agent?.name ?? 'Agent data unavailable' }}

                <IconSprite
                  icon="chevron-right"
                  size="sm"
                />
              </div>
            </DarwinButton>
          </div>

          <div
            v-else-if="message.messageType === 'tool_run' && message.inProgress"
            class="text-text-subtlest"
          >
            <span class="ellipsis-loading text-md-13px-light text-text-subtlest">
              {{ FRIENDLY_TOOL_NAMES[message.toolName] }}
            </span>
          </div>
        </template>
      </div>
    </div>
    <div
      class="flex h-8 flex-row items-center justify-between gap-2 opacity-0 transition-opacity group-hover:opacity-100"
    >
      <div class="flex flex-row items-center gap-1 px-4 text-xs-11px-light text-text-subtlest">
        {{ formattedCreatedDate }}
      </div>
      <div class="flex grow flex-row items-center justify-end gap-2">
        <DarwinButton
          variant="transparent"
          size="sm"
          rounded
          aria-label="Collapse"
          @click="expanded = false"
        >
          <template #leading-icon>
            <IconSprite
              class="text-icon-subtlest"
              icon="chevron-select"
            />
          </template>
          Collapse
        </DarwinButton>
        <ToolTip title="Copy response">
          <IconButton
            class="text-icon-subtlest"
            icon="copy"
            variant="transparent"
            size="lg"
            rounded
            aria-label="Copy response"
            @click="copyMessage"
          />
        </ToolTip>
        <ToolTip title="Recompute answer">
          <IconButton
            v-if="previousQueryId"
            icon="switch"
            class="text-icon-subtlest"
            variant="transparent"
            size="lg"
            rounded
            :disabled="disabled"
            aria-label="Recompute answer"
            @click="emit('rerun-query', previousQueryId)"
          />
        </ToolTip>
      </div>
    </div>
  </div>
  <div v-else>
    <DarwinButton
      variant="transparent"
      size="md"
      rounded
      aria-label="Expand"
      @click="expanded = true"
    >
      <template #leading-icon>
        <IconSprite
          class="text-icon-subtlest"
          icon="chevron-select"
        />
      </template>
      Expand
    </DarwinButton>
  </div>
</template>

<style lang="scss" scoped>
@keyframes scale1 {
  0% {
    transform: scale(1);
    transform-origin: center;
  }

  50% {
    transform: scale(1.3);
    transform-origin: center;
  }

  100% {
    transform: scale(1);
    transform-origin: center;
  }
}

@keyframes rotateAndScaleUp2 {
  0% {
    transform: rotate(0deg) scale(1);
    transform-origin: center;
  }

  50% {
    transform: rotate(120deg) scale(1.25);
    transform-origin: center;
  }

  100% {
    transform: rotate(240deg) scale(1);
    transform-origin: center;
  }
}

@keyframes rotateAndScaleUp3 {
  0% {
    transform: rotate(0deg) scale(1);
    transform-origin: center;
  }

  50% {
    transform: rotate(180deg) scale(1.2);
    transform-origin: center;
  }

  100% {
    transform: rotate(360deg) scale(1);
    transform-origin: center;
  }
}

.animated-icon {
  --duration: 5s;

  :deep(svg) {
    overflow: visible;
    /* scale up and down */
    path {
      @apply transition-all;
    }

    path:first-child {
      animation: scale1 var(--duration) ease-in-out infinite;
      animation-delay: 0s;
    }

    path:nth-child(2) {
      animation: rotateAndScaleUp2 var(--duration) ease-in-out infinite;
      animation-delay: 0.25s;
    }

    path:last-child {
      animation: rotateAndScaleUp3 var(--duration) ease-in-out infinite;
      animation-delay: 0.5s;
    }
  }
}
</style>
