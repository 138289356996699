<script setup lang="ts">
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { ref } from 'vue'

defineProps<{ url?: string | null }>()

const isCoverError = ref(false)
</script>

<template>
  <ObjectURLImage
    v-if="url && !isCoverError"
    :loading="false"
    :url="url"
    class="size-5 rounded-full"
    @error="isCoverError = true"
  >
    <template #fallback>
      <CircularProgress
        size="sm"
        class="animate-spin rounded-full"
      />
    </template>
  </ObjectURLImage>
  <IconSprite
    v-else-if="isCoverError"
    icon="file"
    size="xxl"
    class="rounded-full"
  />
</template>
