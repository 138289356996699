<script setup lang="ts">
import { clamp } from '@vueuse/core'
import * as progress from '@zag-js/progress'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed, useId, watch } from 'vue'
/**
 * A progress bar. Renders a line with a fill that represents the progress.
 *
 * https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/V7-Go---Design-System?type=design&node-id=3410-5283&mode=design&t=I2fdFhr1rwGarB01-0
 */

export type LinearProgressProps = {
  min?: number
  max?: number
  value?: number | null
  /**
   * Indicates whether this is a progress bar or a meter. A progress bar is used to
   * show progress towards a long task, while a meter is used to show the usage towards
   * some defined limit.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/progressbar_role
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/meter_role
   */
  role?: 'progressbar' | 'meter'
  color?: 'neutral' | 'blue' | 'success' | 'warning' | 'critical'
  disabled?: boolean
}

const props = withDefaults(defineProps<LinearProgressProps>(), {
  min: 0,
  max: 100,
  value: null,
  role: 'progressbar',
  color: 'neutral',
  disabled: false,
})

const id = useId()
const service = useMachine(progress.machine, {
  id,
  min: props.min,
  max: props.max,
  defaultValue: clamp(props.value ?? 0, props.min, props.max),
  orientation: 'horizontal',
})

const api = computed(() => progress.connect(service, normalizeProps))

/**
 * Watch the full props object here because `setValue` will 'clamp' the value to the
 * min and max props. This means that if e.g. the max changes to below the current
 * value, the value will be set to the new max.
 */
watch(
  () => ({ ...props }),
  ({ value, max, min }) => {
    api.value.setValue(clamp(value ?? 0, min, max))
  },
)
</script>

<template>
  <div
    v-bind="api.getTrackProps()"
    :role="role"
    class="flex h-2 shrink-0 items-center rounded-full bg-background-gray-subtlest p-0.5"
  >
    <div
      v-bind="api.getRangeProps()"
      class="h-1 max-w-full shrink-0 rounded-full transition-all"
      :class="[
        color === 'neutral' && 'bg-background-black',
        color === 'blue' && 'bg-background-primary',
        color === 'success' && 'bg-background-success',
        color === 'warning' && 'bg-background-warning',
        color === 'critical' && 'bg-background-critical',
        disabled && '!bg-background-disabled',
      ]"
    />
  </div>
</template>
