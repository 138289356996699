<script lang="ts">
/**
 * HTML ID attribute for the container element of this component. Is
 * used to teleport popup menus into this element.
 */
export const FILTER_MENU_ID = 'model-input-menu-filters'
</script>

<script setup lang="ts">
import type { Property, PropertyInput } from '@/modules/Project/Properties/types'
import { isEmptyObject } from '@/shared/utils/typeGuards'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import SegmentedControl, { type SegmentedControlItem } from '@/uiKit/SegmentedControl.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed, ref, watch } from 'vue'
import { isFieldValueFilter, type FieldValueFilter, type FilterableProperty } from './Filters/types'
import { getFilterValue } from './Filters/utils'
import ModelInputMenuFiltersFilterDraft from './ModelInputMenuFiltersFilterDraft.vue'
import ModelInputMenuFiltersFilterItem from './ModelInputMenuFiltersFilterItem.vue'
import type { Conjunction } from './useFilters'

const props = defineProps<{
  property: Property
  filters?: PropertyInput['filters']
  subprojectProperties: FilterableProperty[]
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update', filters: PropertyInput['filters'] | null): void
}>()

const getInitialFilters = (inputFilters: PropertyInput['filters']): FieldValueFilter[] => {
  if (!inputFilters) return []

  if ('conjunction' in inputFilters) {
    return inputFilters.filters.filter(isFieldValueFilter)
  } else if (isEmptyObject(inputFilters)) {
    return []
  } else {
    return isFieldValueFilter(inputFilters) ? [inputFilters] : []
  }
}

type DraftFilter = {
  matcher: undefined
}
const localFilters = ref<(FieldValueFilter | DraftFilter)[]>(getInitialFilters(props.filters))

const addDraftFilter = () => {
  localFilters.value.push({
    matcher: undefined,
  })
}

const onUpdateFilter = (filter: FieldValueFilter, index: number) => {
  localFilters.value = localFilters.value.with(index, filter)
}

const getInitialConjunction = (inputFilters: PropertyInput['filters']): Conjunction => {
  if (!inputFilters) return 'and'

  if ('conjunction' in inputFilters) {
    return inputFilters.conjunction
  } else {
    return 'and'
  }
}
const conjunction = ref<Conjunction>(getInitialConjunction(props.filters))
const conjunctionOptions: SegmentedControlItem<Conjunction>[] = [
  { value: 'and', label: 'And' },
  { value: 'or', label: 'Or' },
]

const inputFilter = computed<PropertyInput['filters'] | null>(() => {
  const validFilters = localFilters.value.filter((f): f is FieldValueFilter => {
    const hasMatcher = !!f.matcher
    if (!hasMatcher) {
      return false
    }

    const filterValue = getFilterValue(f)
    return filterValue !== undefined && filterValue.length > 0
  })

  if (validFilters.length === 0) {
    return null
  }

  return {
    conjunction: conjunction.value,
    filters: validFilters,
  }
})

watch(inputFilter, (newFilters) => {
  emit('update', newFilters)
})
</script>

<template>
  <ListMenuContainer
    :id="FILTER_MENU_ID"
    role="menu"
    :aria-label="`${property.name} input filters`"
    class="w-max min-w-[400px] max-w-[520px] !cursor-default"
  >
    <div class="flex h-8 items-center justify-between gap-1 py-0.5 pl-2 pr-1">
      <DarwinButton
        variant="transparent-link"
        class="!pl-0.5"
        size="sm"
        aria-label="Close filter menu"
        @click="$emit('close')"
      >
        <template #leading-icon>
          <IconSprite icon="chevron-left" />
        </template>
        {{ property.name }}
      </DarwinButton>
      <ToolTip
        :placement="{ allowedPlacements: ['top'] }"
        :arrow="true"
      >
        <template #content>
          <h3 class="text-sm-12px-default text-text-inverted-irreversible">Filter collections</h3>
          <p class="text-sm-12px-light text-text-inverted-subtle-irreversible">
            Collections filtering allows to include only specific entities of the collection in the
            input of this property.
          </p>
        </template>
        <IconButton
          aria-label="Reasoning"
          icon="info-fill"
          :rounded="true"
          size="md"
          class="hover:cursor-default"
          variant="transparent"
        />
      </ToolTip>
    </div>
    <DividerLine color="subtle" />
    <ul
      class="grid grid-cols-[auto,minmax(0,1fr),110px,minmax(0,1fr),auto] gap-y-1"
      :class="localFilters.length > 0 ? 'p-1' : 'p-0.5'"
    >
      <li
        v-if="localFilters.length === 0"
        class="flex h-7 items-center px-2.5 text-sm-12px-default text-text-disabled"
      >
        No filter set
      </li>
      <li
        v-for="(filter, index) in localFilters"
        :key="index"
        class="contents"
      >
        <div class="mr-1 flex h-7 min-w-16 items-center">
          <div
            v-if="index === 0"
            class="px-2 text-sm-12px-default text-text"
          >
            Where
          </div>
          <SegmentedControl
            v-else-if="index === 1"
            :items="conjunctionOptions"
            :value="conjunction"
            name="And/Or"
            @change="conjunction = $event || conjunction"
          />
          <div
            v-else
            class="w-full px-2 text-sm-12px-default text-text"
            :class="conjunction === 'or' && 'pr-2.5 text-right'"
          >
            {{ conjunction === 'and' ? 'And' : 'Or' }}
          </div>
        </div>
        <ModelInputMenuFiltersFilterItem
          v-if="filter.matcher"
          :properties="subprojectProperties"
          :filter="filter"
          class="w-1/3"
          @update="onUpdateFilter($event, index)"
        />
        <ModelInputMenuFiltersFilterDraft
          v-else
          :properties="subprojectProperties"
          @create="onUpdateFilter($event, index)"
        />
        <IconButton
          class="ml-1"
          icon="close"
          size="lg"
          variant="transparent"
          :aria-label="`Delete filter ${index + 1}`"
          @click="localFilters.splice(index, 1)"
        />
      </li>
    </ul>
    <DividerLine color="subtle" />
    <div class="px-2 py-0.5">
      <DarwinButton
        variant="transparent-link"
        size="sm"
        class="!pl-0.5"
        @click="addDraftFilter"
      >
        <template #leading-icon>
          <IconSprite icon="plus" />
        </template>
        Add a filter
      </DarwinButton>
    </div>
  </ListMenuContainer>
</template>
