import { invariant } from '@/shared/utils/typeAssertions'
import type { Ref } from 'vue'
import { useRouter } from 'vue-router'
import type { Project } from '../Projects/useProjects'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useLocalFilter } from './Filters/useLocalFilter'
import { useResolveProjectRoute } from './useResolveProjectRoute'

/**
 * Reusable logic which infers the route for a reference field's table view,
 * and navigates to it.
 */
export const useOpenReferenceTableView = (
  relatedProject: Ref<Project | null>,
  savedEntityIds: Ref<string[]>,
) => {
  const router = useRouter()

  const resolveProjectLink = useResolveProjectRoute()

  const { setFilter } = useLocalFilter()
  const workspacesStore = useWorkspaces()
  /**
   * Go to the project table for the related project, showing only the entities
   * that are selected in this field.
   */
  const openReferenceTableView = () => {
    const projectId = relatedProject.value?.id
    const workspaceId = workspacesStore.currentWorkspace?.id
    invariant(workspaceId && projectId, 'No current workspace or project')

    const mainViewId = relatedProject.value?.views ? relatedProject.value.views[0].id : null
    if (!mainViewId) {
      return
    }

    if (savedEntityIds.value.length > 0) {
      /**
       * Set the filter to show only the matched entities when loading the
       * related project. Note that although `field.toolValue` might be
       * a filter that returns the same entities, we can't use it here because
       * it could return more entities than are matched in this field because
       * of:
       * 1. The property's entityLimit config param
       * 2. Entities that were created after the tool made its inference
       */
      setFilter(
        {
          conjunction: 'and',
          filters: [
            { subject: 'entity_id', matcher: { name: 'any_of', values: savedEntityIds.value } },
          ],
        },
        mainViewId,
      )
    }

    const route = resolveProjectLink({ projectId, workspaceId })
    router.push(route)
  }

  return { openReferenceTableView }
}
