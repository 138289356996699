<script setup lang="ts">
import AgentPicker from '@/modules/Cases/AgentPicker.vue'
import CaseMessageInput from '@/modules/Cases/CaseMessageInput.vue'
import { useNewCase } from '@/modules/Cases/useNewCase'
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import FileUploadMenu from '@/modules/Project/FileUploadMenu.vue'
import ProjectListV2 from '@/modules/Projects/ProjectListV2.vue'
import { useTemplateModal } from '@/modules/Workspaces/TemplateModal.vue'
import WorkspaceFixedToolbar from '@/modules/Workspaces/WorkspaceFixedToolbar.vue'
import WorkspaceHomepageGreetingV2 from '@/modules/Workspaces/WorkspaceHomepageGreetingV2.vue'
import { useDashboardFilterStore } from '@/sharedComposables/useDashboardFilterStore'
import IconButton from '@/uiKit/IconButton.vue'
import { useDropZone } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, useTemplateRef } from 'vue'
import NoProjectFound from './NoProjectFound.vue'
import { useProjectCreateModal } from './ProjectCreateModal.vue'
import { useProjects } from './useProjects'
import { useRecentProjects } from './useRecentProjects'
const props = defineProps<{ workspaceId: string }>()

const dashboardFilterStore = useDashboardFilterStore()
const { filteredProjects } = storeToRefs(dashboardFilterStore)

const recentProjectsStore = useRecentProjects()
const {
  addIntegrationFiles,
  addLibraryFiles,
  addPendingUploads,
  agentId,
  agents,
  attachments,
  status,
  messageText,
  removeUpload,
  startNewCase,
  supportedMimeTypes,
} = useNewCase()
const projectsStore = useProjects()
const userStore = useUser()
const projectCreateModal = useProjectCreateModal()
const templateModal = useTemplateModal()
const messageInputRef = useTemplateRef<HTMLDivElement>('messageInputRef')
const { isOverDropZone } = useDropZone(messageInputRef, {
  onDrop: (files) => {
    if (files && files.length > 0) {
      addPendingUploads(files)
    }
  },
})

/**
 * As we're loading all paginated projects, we need to show a loading state only
 * for the first page, as further pages will be loaded below the fold.
 */
const isLoading = computed(
  () => filteredProjects.value.length === 0 && projectsStore.loadingState === 'loading',
)

const shouldShowNoProjects = computed(
  () => projectsStore.loadingState === 'loaded' && filteredProjects.value?.length === 0,
)

const onSetCoverImageDownloadError = (id: string, value: boolean) => {
  projectsStore.setCoverImageDownloadError(id, value)
  recentProjectsStore.setCoverImageDownloadError(id, value)
}
</script>

<template>
  <div
    class="go-scrollbar flex h-auto grow flex-col gap-10 overflow-y-auto pb-4 [contain:strict]"
    data-test="projects-container"
  >
    <div class="mt-8 flex items-center justify-between">
      <WorkspaceHomepageGreetingV2 :user="userStore.user" />
    </div>

    <CaseMessageInput
      ref="messageInputRef"
      v-model="messageText"
      class="go-column px-2"
      :attachments="attachments"
      :placeholder="'Ask Go...'"
      :disabled="status !== 'initial'"
      :is-dropping-files="isOverDropZone"
      @submit="startNewCase({ workspaceId: props.workspaceId, initialMessage: messageText })"
      @delete-attachment="removeUpload($event)"
    >
      <template #actions>
        <FileUploadMenu
          v-slot="{ getTriggerProps, menu }"
          library-picker-confirm-label="Attach"
          :file-input-props="{ accept: supportedMimeTypes.join(', ') }"
          @import:library="addLibraryFiles($event)"
          @import:computer="addPendingUploads($event)"
          @import:google="addIntegrationFiles"
        >
          <IconButton
            aria-label="Add attachment"
            variant="transparent"
            rounded
            size="lg"
            icon="plus"
            :active="menu?.open"
            v-bind="getTriggerProps()"
          />
        </FileUploadMenu>
        <AgentPicker
          v-model="agentId"
          :agents="agents"
        />
        <span
          v-if="status === 'creating'"
          class="text-sm-12px-light text-text-subtlest"
        >
          Starting new case...
        </span>
        <span
          v-if="status === 'uploading'"
          class="text-sm-12px-light text-text-subtlest"
        >
          Uploading files...
        </span>
        <span
          v-if="status === 'waiting'"
          class="text-sm-12px-light text-text-subtlest"
        >
          Waiting for file preprocessing...
        </span>
        <span
          v-if="status === 'sending'"
          class="text-sm-12px-light text-text-subtlest"
        >
          Asking question...
        </span>
      </template>
    </CaseMessageInput>

    <div class="flex grow flex-col items-start justify-start gap-4 pt-2">
      <WorkspaceFixedToolbar />
      <NoProjectFound
        v-if="shouldShowNoProjects"
        class="go-column px-2"
        @reset-filters="dashboardFilterStore.clear"
        @browse-library="templateModal.open"
        @create-agent="projectCreateModal.open"
      />
      <div
        v-else
        class="go-column px-2"
      >
        <ProjectListV2
          class="w-full"
          :loading="isLoading"
          :workspace-id="workspaceId"
          :projects="filteredProjects"
          @set-cover-image-download-error="onSetCoverImageDownloadError"
        />
      </div>
    </div>
  </div>
</template>
