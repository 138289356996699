import type { LibraryItem } from '@/modules/Library/libraryStore'
import { invariant } from '@/shared/utils/typeAssertions'
import { inject, provide, type InjectionKey, type Ref } from 'vue'

type RowContext = {
  item: Ref<LibraryItem>
  isRenaming: Ref<boolean>
  isHovering: Ref<boolean>
}

export const rowContextToken = Symbol('rowContext') as InjectionKey<RowContext>

/**
 * Provides a context for a Library item table row
 */
export function provideRowContext(value: RowContext) {
  provide(rowContextToken, value)
}

/**
 * Injects the row context for a Library item table row
 */
export function useRowContext() {
  const context = inject(rowContextToken)
  invariant(context, 'Row context not provided')
  return context
}
