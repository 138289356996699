import type { LibraryItem } from '@/modules/Library/libraryStore'

export type SortableColumn = 'filename' | 'createdAt' | 'updatedBy' | 'fileType'
export type SortingDirection = 'asc' | 'desc'
export type TableSorting = [SortableColumn, SortingDirection]

export const documentFileType = Symbol('documentFileType')
export type ExtensionOrDocument = string | typeof documentFileType

/** Returns extension of the original file name (not name given by the user) */
export function getFileExtension(item: LibraryItem) {
  return item.type === 'file' ? item.filename.split('.').pop() : undefined
}

export function getFileTypeName(item: LibraryItem) {
  if (item.type === 'text') {
    return 'Document'
  }

  const ext = getFileExtension(item)
  if (ext) {
    return ext.toUpperCase()
  }

  return 'File'
}
