<script setup lang="ts">
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from 'posthog-js'
import { computed } from 'vue'
import { provideFieldContext } from './fieldContext'
const props = defineProps<{
  field: Field
  property: Property
  column: number
  row: number
}>()

provideFieldContext({
  field: computed(() => props.field),
  property: computed(() => props.property),
  column: computed(() => props.column),
  row: computed(() => props.row),
})
</script>

<template>
  <slot />
</template>
