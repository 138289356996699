<script lang="ts" setup>
import type { Project } from '@/modules/Projects/useProjects'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconSprite from '@/uiKit/IconSprite.vue'

import ObjectURLImage from './ObjectURLImage.vue'

defineProps<{ project: Project }>()

const emit = defineEmits<{
  (e: 'error'): void
}>()
</script>

<template>
  <div class="size-14 overflow-hidden rounded-full">
    <div
      v-if="project.coverImageDownloadError"
      class="flex size-full items-center justify-center text-clip text-text-subtle"
    >
      <IconSprite
        icon="file"
        size="xxxxl"
      />
    </div>
    <div
      v-else-if="project.coverImageUploading"
      class="flex size-full items-center justify-center text-text-subtle"
    >
      <CircularProgress
        size="lg"
        class="animate-spin"
      />
    </div>
    <div
      v-if="!project.coverImageDownloadError"
      class="size-full"
    >
      <ObjectURLImage
        class="size-full rounded-corner-10 object-cover"
        :url="project.coverImageUrls.high"
        :loading="!!project.coverImageUploading"
        @error="emit('error')"
      />
    </div>
  </div>
</template>
