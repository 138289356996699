<script lang="ts">
const isOpen = ref(false)

export function useProjectCreateModal() {
  return {
    open() {
      isOpen.value = true
    },
    close() {
      isOpen.value = false
    },
    isOpen,
  }
}
</script>

<script setup lang="ts">
import { useProjectCover } from '@/modules/Project/useProjectCover'
import { toast } from '@/shared/toast'
import AutosizedTextarea from '@/sharedComponents/AutosizedTextarea.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import TextField from '@/uiKit/TextField.vue'
import { onBeforeMount, ref, useTemplateRef, watch } from 'vue'
import { useRouter } from 'vue-router'
import ObjectURLImage from './ObjectURLImage.vue'
import { useCreateProject } from './useCreateProject'

const props = defineProps<{ workspaceId: string }>()

const name = ref('')
const nameError = ref<string>()
const description = ref('')
const descriptionHasError = ref(false)
const coverFile = ref<File>()

const router = useRouter()

const MODAL_ID = 'project-create-modal'

const { createNewProject } = useCreateProject()

const { uploadFileAsProjectCover, getRandomProjectCoverURL, fetchCoverFile } = useProjectCover()

const fileInput = useTemplateRef('fileInput')

const setCoverFileAndUrl = (e: Event) => {
  const file = (e.target as HTMLInputElement).files?.[0]
  coverFile.value = file
  const oldUrl = coverUrl.value

  coverUrl.value = file ? URL.createObjectURL(file) : undefined
  if (oldUrl?.startsWith('blob:')) {
    URL.revokeObjectURL(oldUrl)
  }
}

const coverUrl = ref<string>()

const isSaving = ref(false)

const saveProject = async () => {
  isSaving.value = true
  nameError.value = undefined

  const projectName = name.value.trim() == '' ? null : name.value.trim()
  const projectDescription = description.value.trim() == '' ? null : description.value.trim()

  if (projectName === null || projectName.length < 3) {
    nameError.value = 'Project name must be at least 3 characters long.'
  }

  if (projectDescription === null || projectDescription.length < 3) {
    descriptionHasError.value = true
  }

  if (nameError.value || descriptionHasError.value) {
    isSaving.value = false
    return
  }

  const result = await createNewProject(props.workspaceId, {
    name: projectName,
    description: projectDescription,
  })

  if (!result.ok) {
    toast.error('Failed to create project')
    isSaving.value = false
    return
  }

  if (coverUrl.value) {
    const file = await fetchCoverFile(coverUrl.value)
    if (file) {
      await uploadFileAsProjectCover(file, props.workspaceId, result.data.id)
    }
  } else if (coverFile.value) {
    await uploadFileAsProjectCover(coverFile.value, props.workspaceId, result.data.id)
  }

  isSaving.value = false
  isOpen.value = false
  coverFile.value = undefined
  coverUrl.value = undefined
  name.value = ''
  description.value = ''

  router.push({
    name: 'WorkspaceProject',
    params: { workspaceId: props.workspaceId, projectId: result.data.id },
  })
}

const pickRandomCover = async () => {
  coverUrl.value = getRandomProjectCoverURL()
  coverFile.value = undefined
}

watch(isOpen, async (isOpen, wasOpen) => {
  if (isOpen && !wasOpen) {
    name.value = ''
    description.value = ''
    pickRandomCover()
  }
})

onBeforeMount(pickRandomCover)
</script>

<template>
  <ModalDialog
    :id="MODAL_ID"
    :open="isOpen"
    :background-blur="true"
    @close="isOpen = false"
  >
    <div class="flex min-h-[560px] min-w-[960px] flex-row items-center">
      <div
        class="flex w-1/2 shrink-0 grow flex-col content-stretch items-start gap-6 self-stretch p-8"
      >
        <label
          class="flex flex-col gap-0"
          for="project-name"
        >
          <span class="text-display-sm-24px-default text-text">Create a new project</span>
          <span class="-mt-1 text-display-sm-24px-default text-text-subtlest">Project details</span>
        </label>

        <div class="flex grow flex-col gap-2 self-stretch">
          <TextField
            id="project-name"
            :value="name"
            size="lg"
            autofocus
            placeholder="Project name"
            aria-label="Name"
            class="flex min-h-8 flex-col items-start justify-center self-stretch text-sm-12px-light"
            @submit="saveProject"
            @input="
              (newValue) => {
                nameError = undefined
                name = newValue
              }
            "
          />

          <div
            v-if="nameError"
            class="text-sm-12px-light text-text-critical"
          >
            {{ nameError }}
          </div>

          <div class="flex flex-col items-stretch gap-2 self-stretch overflow-hidden">
            <div
              class="go-scrollbar flex max-h-96 shrink-0 grow overflow-auto rounded-corner-8 bg-background-gray-subtlest -outline-offset-2 transition-all focus-within:bg-background-gray-subtlest-hovered focus-within:outline focus-within:outline-2 focus-within:outline-border-focused hover:bg-background-gray-subtlest-hovered"
            >
              <AutosizedTextarea
                :model-value="description"
                mode="multiline"
                placeholder="Set a description"
                aria-label="Description"
                class="flex min-h-[96px] shrink-0 flex-col bg-background-transparent p-2 text-sm-12px-light text-text outline-none transition-all"
                @update:model-value="
                  (newValue) => {
                    description = newValue
                    descriptionHasError = false
                  }
                "
                @submit="saveProject"
              />
            </div>

            <span
              class="text-sm-12px-light"
              :class="descriptionHasError ? 'text-text-critical' : 'text-text-subtlest'"
              >Describe what this project should be used for</span
            >
          </div>
        </div>
        <!-- Frame 3 (footer) -->

        <DarwinButton
          variant="black"
          size="md"
          :disabled="isSaving"
          rounded
          @click="saveProject"
        >
          Create new project
        </DarwinButton>
      </div>

      <div
        class="relative flex w-1/2 shrink-0 grow flex-col items-center gap-6 self-stretch rounded-r-corner-16 bg-surface-tertiary-persist p-8"
      >
        <IconButton
          class="absolute right-2 top-2 flex items-center justify-center"
          icon="close"
          size="lg"
          variant="transparent"
          rounded
          @click="isOpen = false"
        />
        <h2 class="text-center text-sm-12px-default text-text-subtlest">Project cover</h2>
        <div class="flex grow flex-col items-center justify-center gap-4 self-center">
          <ObjectURLImage
            v-if="coverUrl"
            class="size-[200px] rounded-full object-cover"
            :url="coverUrl"
            :loading="false"
          >
            <template #fallback>
              <div
                class="flex size-[200px] items-center justify-center rounded-full bg-background-gray-subtlest"
              >
                <CircularProgress
                  size="lg"
                  class="animate-spin rounded-full"
                />
              </div>
            </template>
          </ObjectURLImage>

          <CircularProgress
            v-if="isSaving"
            size="lg"
            class="animate-spin rounded-full"
          />
          <IconButton
            v-else
            variant="neutral"
            size="xl"
            icon="switch"
            aria-label="Generate project cover"
            class="w-12"
            rounded
            @click="pickRandomCover"
          />
        </div>

        <div class="flex flex-col items-center">
          <DarwinButton
            variant="transparent"
            size="md"
            :disabled="isSaving"
            rounded
            class="text-sm-12px-default text-text-subtle"
            @click="fileInput?.click()"
          >
            <template #leading-icon>
              <IconButton
                icon="upload"
                size="sm"
                variant="transparent"
              />
            </template>
            Upload custom cover
            <input
              ref="fileInput"
              class="hidden"
              type="file"
              data-test="project-cover-input"
              @input="setCoverFileAndUrl"
            />
          </DarwinButton>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
