import type { Case, PartialCase } from '@/modules/Cases/types'
import { useCaseStore } from '@/modules/Cases/useCaseStore'
import { useProjects, type Project } from '@/modules/Projects/useProjects'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { formatDate } from 'date-fns'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useDashboardFilterStore = defineStore('dashboardFilter', () => {
  const projectStore = useProjects()
  const casesStore = useCaseStore()
  const workspaceMemberStore = useWorkspaceMembers()

  const searchValue = ref<string>('')
  const isSearchbarActive = ref(false)
  const caseOwnerIds = ref<string[]>([])
  const caseSortValue = ref<'createdAt' | 'updatedAt' | 'name'>('createdAt')
  const caseSortDirection = ref<'asc' | 'desc'>('desc')

  const filteredCases = computed(() => {
    const lowerCasedSearchValue = searchValue.value?.toLowerCase()
    const filtered = casesStore.cases.filter((c: PartialCase | Case) => {
      if (!c) {
        return false
      }

      const hasSelectedOwners = caseOwnerIds.value.length > 0
      const isUserInSelectedOwners = caseOwnerIds.value.includes(c.userId ?? '')
      if (hasSelectedOwners && !isUserInSelectedOwners) {
        return false
      }

      const matchName = c.name?.toLowerCase().includes(lowerCasedSearchValue)
      const formattedCreatedDate = formatDate(c.createdAt, 'EEE d MMM yyyy · HH:mm')
      const matchCreatedDate = formattedCreatedDate?.toLowerCase().includes(lowerCasedSearchValue)
      const creatorName = workspaceMemberStore.getMemberNameFromId(c.userId ?? '')
      const matchCreatorName = creatorName?.toLowerCase().includes(lowerCasedSearchValue)

      return matchName || matchCreatedDate || matchCreatorName
    })

    return filtered.sort((a, b) => {
      const sortField = caseSortValue.value
      const direction = caseSortDirection.value === 'asc' ? 1 : -1

      if (sortField === 'name') {
        return (a.name ?? '').localeCompare(b.name ?? '') * direction
      }

      return a[sortField].localeCompare(b[sortField]) * direction
    })
  })

  const filteredProjects = computed(() => {
    const lowerCasedSearchValue = searchValue.value?.toLowerCase()
    return projectStore.projects.filter((p: Project) => {
      if (!p) {
        return
      }
      const matchName = p.name?.toLowerCase().includes(lowerCasedSearchValue)
      const matchDescription = p.description?.toLowerCase().includes(lowerCasedSearchValue)
      const matchNoDescription = !p.description && searchValue.value?.trim() === 'No description'

      return matchName || matchDescription || matchNoDescription
    })
  })

  const setSearchValue = (value: string) => {
    searchValue.value = value
  }

  const setSearchbarActive = (value: boolean) => {
    isSearchbarActive.value = value
  }

  const setCaseOwnerIds = (value: string[]) => {
    caseOwnerIds.value = value
  }

  const setCaseSortValue = (value: 'createdAt' | 'updatedAt' | 'name') => {
    caseSortValue.value = value
  }

  const setCaseSortDirection = (value: 'asc' | 'desc') => {
    caseSortDirection.value = value
  }

  const clear = () => {
    setSearchValue('')
    setSearchbarActive(false)
  }

  return {
    filteredCases,
    filteredProjects,
    searchValue,
    isSearchbarActive,
    caseSortDirection,
    setSearchValue,
    setCaseOwnerIds,
    setSearchbarActive,
    setCaseSortValue,
    setCaseSortDirection,
    clear,
  }
})
