import { isEntityParams, type NavigationParams } from '@/modules/Project/Navigation/navigationTypes'
import { useEntity } from '@/modules/Project/useEntity'
import { ref, watch } from 'vue'

export function useProjectNavigator() {
  const entityStore = useEntity()

  const breadcrumbs = ref<NavigationParams[]>([])

  watch(breadcrumbs, (crumbs) => {
    const last = crumbs.at(-1)
    const entityId = last?.entityId
    entityStore.setEntityId(entityId)
  })

  function navigate(params: NavigationParams) {
    const nextStack: NavigationParams[] = []
    if (isEntityParams(params)) {
      const projectParams = { ...params, entityId: undefined }
      nextStack.push(projectParams, params)
    } else {
      nextStack.push(params)
    }

    entityStore.setEntityId(params.entityId)
    breadcrumbs.value = nextStack
  }

  function reset() {
    breadcrumbs.value = []
    entityStore.setEntityId(undefined)
  }

  return { breadcrumbs, navigate, reset }
}
