<script setup lang="ts">
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import ToolTip from '@/uiKit/ToolTip.vue'

defineProps<{
  loading?: boolean
}>()

const emit = defineEmits<{
  (e: 'copy'): void
}>()
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    close-on-select
  >
    <ToolTip
      arrow
      :placement="{ allowedPlacements: ['left'] }"
      title="Export entity"
    >
      <IconButton
        size="lg"
        icon="upload"
        rounded
        variant="transparent"
        name="Open export menu"
        aria-label="Open export menu"
        :loading="loading"
        v-bind="getTriggerProps()"
        :disabled="loading"
      >
        Export
      </IconButton>
    </ToolTip>
    <Menu.Content class="go-scrollbar max-h-[340px] w-80 overflow-y-auto overflow-x-hidden">
      <Menu.Item
        name="Copy into clipboard"
        class="flex w-full items-center justify-start gap-2 !p-2"
        role="menutem"
        aria-label="Copy into clipboard"
        @select="emit('copy')"
        @keydown.enter="emit('copy')"
      >
        <AvatarIcon
          icon-name="markdown"
          size="lg"
        />
        <div class="flex grow flex-col items-start justify-start">
          <p class="text-sm-12px-default leading-4 text-text">Copy into clipboard</p>
          <p class="text-sm-12px-light text-text-subtle">Content will be copied as markdown</p>
        </div>
      </Menu.Item>
      <Menu.Item
        class="flex w-full cursor-not-allowed items-center justify-start gap-2 !p-2 opacity-50"
        role="menuitem"
        name="Export as DOCX"
        aria-label="Export as DOCX"
        disabled
      >
        <AvatarIcon
          icon-name="word-logo"
          size="lg"
        />
        <div class="flex grow flex-col items-start justify-start">
          <p class="text-sm-12px-default leading-4 text-text">Export as DOCX</p>
          <p class="text-sm-12px-light text-text-subtle">Ready to use directly in Microsoft Word</p>
        </div>
        <div class="flex grow items-center justify-start">
          <BadgeItem
            label="Soon"
            size="sm"
            variant="neutral"
          />
        </div>
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
