<script
  setup
  lang="ts"
  generic="T extends LibraryItem['type'], FilteredItem extends LibraryItem & { type: T }"
>
import { type LibraryItem } from '@/modules/Library/libraryStore'
import FilePicker from '@/modules/Workspaces/KnowledgeHub/Files/FilePicker.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'

defineProps<{ only?: T; confirmLabel: string }>()

defineEmits<{ select: [libraryItems: Array<FilteredItem>] }>()

const open = defineModel<boolean>('open', { required: true })
</script>

<template>
  <ModalDialog
    :open="open"
    class="flex max-h-[90vh] w-[900px] flex-col overflow-hidden"
    aria-label="Library File Picker"
    @close="open = false"
  >
    <FilePicker
      :only="only"
      :confirm-label="confirmLabel"
      @select="$emit('select', $event as FilteredItem[])"
      @close="open = false"
    />
  </ModalDialog>
</template>
