import type { Field } from '@/modules/Project/Fields/types'
import type { HighlightEventPayload, TextLayerLoadedEventPayload } from '@tato30/vue-pdf'
import { computed, ref, watch, type Ref } from 'vue'

/**
 * This composable provides state and logic used when searching within a PDF. It
 * provides a function that can be passed to `<VuePDF>` to handle highlighting
 * matches, and keeps track of the currently matching elements and the active
 * match.
 */
export const usePdfSearch = (field: Ref<Field>) => {
  /**
   * Users are unable to search within a PDF until its text layer has been loaded.
   * This flag should be set to `true` when the text layer is ready.
   */
  const canSearch = ref(false)

  /**
   * The search term that the user has entered, or `null` if the search UI is
   * not visible.
   */
  const search = ref<string | null>(null)

  /** All elements that match the current search term */
  const matchingElementsByPage = ref<HTMLElement[][]>([])
  const matchingElements = computed(() => matchingElementsByPage.value.flat())

  const onHighlight = (payload: HighlightEventPayload) => {
    const elements = payload.matches.map((m) => {
      const elIndex = m.start.idx
      return payload.textDivs[elIndex]
    })
    matchingElementsByPage.value[payload.page] = elements
  }

  /**
   * Handle the event emitted by VuePDF when the text layer has been loaded. If the
   * text layer contains any text, we can enable the search functionality.
   */
  const onTextLayerLoaded = (payload: TextLayerLoadedEventPayload) => {
    if (payload.textDivs.length > 0) {
      canSearch.value = true
    }
  }

  const activeMatchIndex = ref(0)
  /**
   * When the active match changes we want to scroll the element into view and
   * reduce the prominence of all other matches.
   */
  watch(activeMatchIndex, (index) => {
    matchingElements.value.forEach((element, i) => {
      if (i === index) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        element.style.opacity = '1'
      } else {
        element.style.opacity = '0.3'
      }
    })
  })

  const reset = () => {
    matchingElementsByPage.value = []
    activeMatchIndex.value = 0
    search.value = null
  }
  watch(field, () => {
    reset()
    canSearch.value = false
  })

  watch(matchingElements, (elements) => {
    if (activeMatchIndex.value >= elements.length) {
      activeMatchIndex.value = elements.length - 1
    }
  })

  return {
    search,
    onHighlight,
    onTextLayerLoaded,
    matchingElements,
    activeMatchIndex,
    canSearch,
    reset,
  }
}
