<!-- Figma component: Sidebar/Entity -->
<script setup lang="ts">
import { isFileField, isFileProperty } from '@/shared/utils/typeGuards'
import { computed, ref } from 'vue'
import type { Field } from './Fields/types'
import { isGroundableField } from './Fields/utils/grounding'
import type { Property } from './Properties/types'
import { useEntitySidebar } from './useEntitySidebar'
import { useEntitySidebarWidth } from './useEntitySidebarWidth'
import { usePrevOrNextEntity } from './usePrevOrNextEntity'
import { useProject } from './useProject'
import { useSerializeFieldToText } from './useSerializeFieldToText'

import { useFieldSaving } from '@/modules/Project/useFieldSaving'
import EntitySidebarLayout from '@/sharedComponents/EntitySidebarLayout.vue'
import SidebarField from '@/sharedComponents/SidebarField.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ListHeader from '@/uiKit/ListHeader.vue'
import { useGroundingStore, type ClaimAndSource } from './useGroundingStore'

defineProps<{
  workspaceId: string
  projectId: string
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const entitySidebarStore = useEntitySidebar()
const projectStore = useProject()
const { saveFieldChange } = useFieldSaving()
const serialize = useSerializeFieldToText()
const { previousEntityId, nextEntityId, loadAndSetEntity } = usePrevOrNextEntity()

const container = ref<HTMLDivElement>()
const { sidebarWidth, startDrag } = useEntitySidebarWidth(container)

const fields = computed(() => {
  const entity = entitySidebarStore.selectedEntity
  if (!entity) return []

  return projectStore.visibleProperties
    .map((property) => {
      const field = entity.fields.get(property.id)
      if (!field) return null

      return {
        property,
        field,
        serializedValue: serialize(field),
      }
    })
    .filter(
      (item): item is { property: Property; field: Field; serializedValue: string } =>
        item !== null,
    )
})

const selectedField = ref<{ field: Field; groundingField: Field } | null>(null)

const onClose = () => {
  entitySidebarStore.reset()
  emit('close')
}

const scrollToProperty = (propertyId: string) => {
  const propertyElement = document.querySelector(`[data-property-id="${propertyId}"]`)
  if (propertyElement) {
    propertyElement.scrollIntoView({ behavior: 'smooth' })
  }
}

const groundingStore = useGroundingStore()
const onClickClaim = (field: Field, { claimId, sourceId }: ClaimAndSource) => {
  if (!isGroundableField(field) || !field.hasGrounding || !field.grounding) {
    return
  }

  // Find the file field that corresponds to this source
  const fileField = fields.value.find((f) => isFileField(f.field) && isFileProperty(f.property))

  if (fileField && fileField.field.type === 'file') {
    entitySidebarStore.setSelectedFileField(fileField.field, field)
    groundingStore.clickClaim({ claimId, sourceId })
  }
}

const onPrevEntity = async () => {
  if (!previousEntityId.value) return
  const prevId = previousEntityId.value
  entitySidebarStore.setSelectedEntityId(prevId)
  entitySidebarStore.sidebarIsOpen = true
  await loadAndSetEntity(prevId)
}

const onNextEntity = async () => {
  if (!nextEntityId.value) return
  const nextId = nextEntityId.value
  entitySidebarStore.setSelectedEntityId(nextId)
  entitySidebarStore.sidebarIsOpen = true
  await loadAndSetEntity(nextId)
}
</script>

<template>
  <div
    class="relative h-full"
    data-test="entity-sidebar"
  >
    <div
      ref="container"
      class="z-0 mb-2.5 mr-5 flex h-full min-w-[720px] max-w-[70vw] flex-col overflow-hidden rounded-corner-12 border border-border-subtle bg-surface-primary p-0"
      data-test="entity-sidebar"
      :style="{ width: `${sidebarWidth}px` }"
    >
      <!-- Resize handle -->
      <div
        class="group absolute -left-3 top-0 z-20 h-full w-3 cursor-col-resize"
        aria-label="Resize sidebar"
        @mousedown="startDrag"
      >
        <div
          class="absolute left-1/2 top-1/2 h-5 w-1 -translate-x-1/2 -translate-y-1/2 rounded-full bg-background-gray-subtle transition-all group-hover:h-full group-hover:delay-100 group-active:bg-background-gray-subtle-pressed group-active:delay-0 hover:bg-background-gray-subtle-hovered"
          aria-hidden
        />
      </div>

      <ListHeader>
        <div class="flex w-full items-center gap-2">
          <div class="truncate font-medium">Entity details</div>
          <div class="relative max-w-[200px] flex-1">
            <select
              class="w-full appearance-none rounded-md border border-border-subtle bg-surface-primary py-1 pl-3 pr-8 text-sm-12px-default text-text-subtle transition-colors hover:border-border-subtle focus:border-border-focused focus:outline-none focus:ring-1"
              @change="scrollToProperty(($event.target as HTMLSelectElement).value)"
            >
              <option
                value=""
                class="text-text-disabled"
              >
                Jump to property...
              </option>
              <option
                v-for="property in projectStore.visibleProperties"
                :key="property.id"
                :value="property.id"
                class="text-text"
              >
                {{ property.name }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-text-subtle"
            >
              <svg
                class="size-4"
                viewBox="0 0 20 20"
              >
                <path
                  d="M7 7l3 3 3-3"
                  stroke="currentColor"
                  stroke-width="1.5"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <template #icon>
          <div class="flex items-center gap-1">
            <IconButton
              size="md"
              variant="transparent"
              icon="chevron-top"
              aria-label="Previous entity"
              :disabled="!previousEntityId"
              @click="onPrevEntity"
            />
            <IconButton
              size="md"
              variant="transparent"
              icon="chevron-bottom"
              aria-label="Next entity"
              :disabled="!nextEntityId"
              @click="onNextEntity"
            />
            <IconButton
              size="md"
              variant="transparent"
              icon="close"
              aria-label="Close sidebar"
              @click="onClose"
            />
          </div>
        </template>
      </ListHeader>

      <div
        v-if="!selectedField"
        class="flex grow flex-col overflow-auto scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md [contain:strict]"
      >
        <EntitySidebarLayout>
          <SidebarField
            v-for="{ field, property } in fields"
            :key="property.id"
            :property="property"
            :field="field"
            @submit="saveFieldChange(field, $event)"
            @show-source="onClickClaim(field, $event)"
            @file:preview="entitySidebarStore.setSelectedFileField"
          />
        </EntitySidebarLayout>
      </div>
    </div>
  </div>
</template>
