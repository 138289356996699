<script setup lang="ts">
/**
 * Primary component for '/:workspaceId/:projectId'
 *
 * Renders all project details and should be composed of smaller components
 */
import { useStorage } from '@vueuse/core'
import { computed, onMounted, toRefs, watch } from 'vue'

import { useProjects } from '@/modules/Projects/useProjects'

import { getProject } from '@/backend/getProject'

import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'

import { LDEvents, useABMetrics } from '@/modules/App/useABMetrics'
import ErrorPage from '../App/ErrorPage.vue'
import EntitySidebar from './EntitySidebar.vue'
import EntitySidebarFileViewer from './EntitySidebarFileViewer.vue'
import FilterBar from './Filters/FilterBar.vue'
import GroundingModal from './GroundingModal.vue'
import ProjectTable from './ProjectTable.vue'
import ProjectTableViewsTabstrip from './ProjectTableViewsTabstrip.vue'
import ProjectTopBar from './ProjectTopBar.vue'
import PropertySidebar from './PropertySidebar.vue'
import { useEntitySidebar } from './useEntitySidebar'
import { serializeProperty, useProject } from './useProject'
import { useProjectSync } from './useProjectSync'
import { useProperty } from './useProperty'
import { usePropertySidebarIntegration } from './usePropertySidebarIntegration'
import { useSubProject } from './useSubProject'

const props = defineProps<{
  workspaceId: string
  projectId: string
  parentProjectId?: string
  parentEntityId?: string
  viewId?: string
}>()
const projectsStore = useProjects()
const project = computed(() => projectsStore.getProjectById(props.projectId))

const projectStore = useProject()
const subProjectStore = useSubProject()

const { loadingState } = useProjectSync(toRefs(props))
const { startTrackingTimeFor, trackElapsedTimeEvent } = useABMetrics()

watch(
  () => projectStore.subProjectIds,
  (newIds) => {
    const oldIds = Object.keys(subProjectStore.projects)
    const added = newIds.filter((id) => !oldIds.includes(id))
    const removed = oldIds.filter((id) => !newIds.includes(id))

    added.forEach(async (id: string) => {
      const result = await getProject(props.workspaceId, id)
      if (result.ok) {
        if (result.data.parent_property === null) {
          throw Error('Subproject must have a parent property')
        }
        subProjectStore.setProject(id, {
          id: result.data.id,
          name: result.data.name,
          workspaceId: result.data.workspace_id,
          properties: result.data.properties.map(serializeProperty),
          parentPropertyId: result.data.parent_property.id,
          updatedAt: result.data.updated_at,
        })
      }
    })
    removed.forEach((id: string) => {
      subProjectStore.removeProject(id)
    })
  },
  { immediate: true },
)

const widthsFromStorage = useStorage<typeof projectStore.widths>(
  `col-widths-${props.projectId}`,
  {},
)

onMounted(() => {
  if (widthsFromStorage.value && Object.keys(widthsFromStorage.value).length > 0) {
    projectStore.widths = widthsFromStorage.value
  }
})

watch(projectStore.widths, () => {
  widthsFromStorage.value = projectStore.widths
})

const {
  deleteProperty,
  deletePropertyConfirmationOpen,
  deselectProperty,
  isPropertyBusy,
  reprocessColumn,
  reprocessColumnConfirmationOpen,
  saveProperty,
} = usePropertySidebarIntegration()

const propertyStore = useProperty()
const onCloseSidebar = () => {
  propertyStore.sidebarIsOpen = false
  deselectProperty()
}
const onConfirmDelete = async () => {
  await deleteProperty()
  propertyStore.sidebarIsOpen = false
}

watch(
  () => propertyStore.savedProperty,
  (property) => {
    if (property) {
      // AB_PROPERTY_EDITOR EXPERIMENT - Metrics
      startTrackingTimeFor(LDEvents.TIME_SPENT_ON_EDITING)
    }
  },
)

const entitySidebarStore = useEntitySidebar()
</script>

<template>
  <div
    v-if="project || loadingState === 'loading'"
    v-bind="$attrs"
    class="flex h-full flex-1 flex-col"
  >
    <ProjectTopBar />
    <GroundingModal />
    <div
      class="flex h-full"
      data-test="project-table-container"
    >
      <ProjectTable
        :parent-entity-id="parentEntityId"
        :class="{ 'mr-3': entitySidebarStore.sidebarIsOpen }"
      />
      <div
        v-if="propertyStore.sidebarIsOpen"
        class="pb-3"
      >
        <PropertySidebar
          :workspace-id="workspaceId"
          :project-id="projectId"
          :disabled="isPropertyBusy"
          @close="onCloseSidebar"
          @delete="deletePropertyConfirmationOpen = true"
          @update="
            () => {
              trackElapsedTimeEvent(LDEvents.TIME_SPENT_ON_EDITING)
              saveProperty('sidebar')
            }
          "
          @reprocess="reprocessColumnConfirmationOpen = true"
        />
      </div>

      <!-- Entity sidebar -->
      <div
        v-if="entitySidebarStore.sidebarIsOpen && workspaceId && projectId"
        class="relative pb-3"
      >
        <!-- File viewer overlay (positioned to the left of sidebar) -->
        <div
          v-if="entitySidebarStore.selectedFileField"
          class="absolute right-full top-0 z-10 h-full min-w-[650px] max-w-[800px]"
        >
          <EntitySidebarFileViewer
            :field="entitySidebarStore.selectedFileField.field"
            :grounding-field="entitySidebarStore.selectedFileField.groundingField"
            @close="entitySidebarStore.clearSelectedFileField()"
          />
        </div>

        <EntitySidebar
          :workspace-id="workspaceId"
          :project-id="projectId"
          @close="entitySidebarStore.sidebarIsOpen = false"
        />
      </div>
    </div>

    <div class="flex flex-col">
      <FilterBar />
      <ProjectTableViewsTabstrip />
    </div>
  </div>
  <ErrorPage
    v-else
    title="Project not found"
    message="We could not find the page you're looking for."
  />

  <ConfirmationDialog
    id="delete-property-confirmation"
    :open="deletePropertyConfirmationOpen"
    title="Delete this property?"
    description="This property will be deleted immediately. You can't undo this action."
    @confirm="onConfirmDelete"
    @close="deletePropertyConfirmationOpen = false"
  />

  <ConfirmationDialog
    id="reprocess-property-confirmation"
    :open="reprocessColumnConfirmationOpen"
    title="Recompute all stale fields for this property?"
    description="This may take a while and become quite costly."
    variant="black"
    confirm-text="Confirm"
    @confirm="reprocessColumn"
    @close="reprocessColumnConfirmationOpen = false"
  />
</template>
