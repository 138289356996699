<script lang="ts">
/**
 * @name Menu
 * @url https://www.figma.com/design/1HfA941cU4A9RZxXHLmbpG/V7-Go---Design-System?node-id=224-10893
 */

type Menu = zagMenu.Api

// Item
export type MenuItemProps = {
  onSelect: () => void
  onHighlight: () => void
  id: string
}
type InitItem = (props: MenuItemProps) => void

// Root
type RootProps = {
  ariaLabel?: string
  positioning?: zagMenu.Props['positioning']
  disableTeleport?: boolean
  /**
   * Use this when you want the parent to control the open state. If not
   * provided, the menu will control its own open state.
   */
  open?: boolean
  teleportTo?: string
  closeOnSelect?: boolean
  disableCloseOnClickOutside?: boolean
  /**
   * When true, the menu will be rendered to
   * the DOM without any fixed positioning. Otherwise the menu will
   * be anchored to the trigger element.
   */
  static?: true
}

// Context
const CTX_KEY = Symbol('menu-context')
type MenuContext = {
  initItem: InitItem
  menu: ComputedRef<Menu>
  props: RootProps
}

export const MenuCtx = {
  get(): MenuContext {
    const ctx = inject(CTX_KEY) as MenuContext | undefined
    if (!ctx) throw new Error('Menu context not found')
    return ctx
  },
  set(value: MenuContext) {
    provide(CTX_KEY, value)
  },
}

type ParentMenu = {
  service: zagMenu.Service
  menu: ComputedRef<zagMenu.Api>
}
const PARENT_KEY = 'menu-parent'
export const ParentCtx = {
  get(): ParentMenu | undefined {
    return inject(PARENT_KEY)
  },
  set(value: ParentMenu) {
    provide(PARENT_KEY, value)
  },
  init() {
    provide(PARENT_KEY, undefined)
  },
}
</script>

<script setup lang="ts">
import {
  computed,
  inject,
  onMounted,
  onUnmounted,
  provide,
  ref,
  useId,
  type ComputedRef,
} from 'vue'

import * as zagMenu from '@zag-js/menu'
import { normalizeProps, useMachine } from '@zag-js/vue'

const props = withDefaults(defineProps<RootProps>(), {
  open: undefined,
  ariaLabel: undefined,
  positioning: undefined,
  disableTeleport: false,
  teleportTo: 'body',
  closeOnSelect: false,
})

const emit = defineEmits<{
  (e: 'change:open', open: boolean): void
}>()

defineOptions({
  name: 'MenuRoot',
})

const items = ref<MenuItemProps[]>([])
const initItem: InitItem = (props) => {
  items.value.push(props)
  const itemIndex = computed(() => items.value.indexOf(props))

  onUnmounted(() => {
    items.value.splice(itemIndex.value, 1)
  })
}

const id = useId()
const service = useMachine(
  zagMenu.machine,
  computed(() => ({
    id,
    onOpenChange(e) {
      emit('change:open', e.open)
    },
    onSelect({ value }) {
      const item = items.value.find((item) => item.id === value)
      item?.onSelect()
    },
    onHighlightChange({ highlightedValue }) {
      const item = items.value.find((item) => item.id === highlightedValue)
      if (item) {
        item.onHighlight()
      }
    },
    closeOnSelect: props.closeOnSelect,
    onEscapeKeyDown(e) {
      if (!menu.value.open) return
      e.stopPropagation()
    },
    onInteractOutside(e) {
      if (props.disableCloseOnClickOutside || e.type === 'focus.outside') {
        e.preventDefault()
        return
      }
    },
    defaultOpen: props.open,
    open: props.open,
    positioning: props.positioning,
  })),
)
const menu = computed(() => zagMenu.connect(service, normalizeProps))

MenuCtx.set({ initItem, menu, props })

ParentCtx.set({ service, menu })
const parentMenu = ParentCtx.get()
onMounted(() => {
  if (parentMenu) {
    menu.value.setParent(parentMenu.service)
    parentMenu.menu.value.setChild(service)
  }
})

function getTriggerProps() {
  const p = menu.value.getTriggerProps()
  return {
    ...p,
    disabled: p.disabled === 'true' || p.disabled === true,
  }
}

defineExpose({ menu })
</script>

<template>
  <slot
    :get-trigger-props="getTriggerProps"
    :get-trigger-item-props="menu.getTriggerItemProps"
    :menu="menu"
  />
</template>
