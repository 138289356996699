import { nextTick, ref, watch } from 'vue'

export function useSearchField(props?: { focusFn?: () => void }) {
  const query = ref('')
  const isActive = ref(false)

  watch(isActive, async () => {
    query.value = ''
    await nextTick()
    props?.focusFn?.()
  })

  function keydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      query.value = ''
      isActive.value = false
      event.stopPropagation()
    }
  }

  return {
    isActive,
    query,
    eventHandlers: {
      keydown,
    },
  }
}
