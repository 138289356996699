<script setup lang="ts">
import { getFieldClaims } from '@/modules/Project/Fields/utils/grounding'
import { useFieldStatusIndicator } from '@/modules/Project/useFieldStatusIndicator'
import { useFieldValue } from '@/modules/Project/useFieldValue'
import HighlightedTextarea from '@/uiKit/HighlightedTextarea.vue'
import { computed, ref, watch } from 'vue'
import type { SidebarEmits, SidebarProps } from '../../field-types'

const props = defineProps<SidebarProps<'json'>>()

defineEmits<SidebarEmits<'json'>>()

const computedField = computed(() => props.field)
const value = useFieldValue(computedField)
const { isModelOutput } = useFieldStatusIndicator(computedField)

const claims = computed(() => getFieldClaims(props.field))

const localValue = ref<string | null>(value.value)

watch(
  value,
  (newValue) => {
    localValue.value = newValue
  },
  { immediate: true },
)
</script>

<template>
  <HighlightedTextarea
    ref="textareaRef"
    variant="transparent"
    :data-test="`json-sidebar-${props.property.id}`"
    lang="json"
    :value="localValue || ''"
    :claims="isModelOutput ? claims : undefined"
    size="md"
    class="h-auto max-h-[640px] grow font-mono hover:bg-background-transparent-hovered"
    @input="localValue = $event"
    @show-source="$emit('show-source', $event)"
    @change="$emit('submit', $event)"
    @keydown.escape="localValue = value"
  />
</template>
