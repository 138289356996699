import type { operations } from '@/api'
import { put } from './client'

export type UpdateProjectPath = `/api/workspaces/${string}/projects/${string}`
export type UpdateProjectResponse =
  operations['project-update']['responses']['200']['content']['application/json']

export type UpdateProjectRequest = Exclude<
  operations['project-update']['requestBody'],
  undefined
>['content']['application/json']

export const updateProject = (
  workspaceId: string,
  projectId: string,
  payload: UpdateProjectRequest,
) =>
  put<UpdateProjectRequest, UpdateProjectResponse, UpdateProjectPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}`,
    payload,
  )
