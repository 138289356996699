<script setup lang="ts">
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import PopOver from '@/uiKit/PopOver.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { autoPlacement, autoUpdate, offset, size, useFloating } from '@floating-ui/vue'
import { useDebounce, useInfiniteScroll } from '@vueuse/core'
import { computed, ref, toRef, useTemplateRef, watch } from 'vue'
import { ENTITY_NAME_FALLBACK } from './constants'
import EntityDialog from './EntityDialog.vue'
import { isFilterableProperty } from './Filters/types'
import ReferenceEntityPreview from './ReferenceEntityPreview.vue'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import { useOpenReferenceTableView } from './useOpenReferenceTableView'
import { useReferenceField } from './useReferenceField'
import { useScrollOnSelected } from './useScrollOnSelected'
import { useTableCellFocus } from './useTableCellFocus'
import { POPOVER_Z_INDEX, SELECTED_CELL_Z_INDEX } from './useTableZIndices'

const props = defineProps<{
  isSelected: boolean
  isFocused: boolean
  field: Field<'reference'>
  property: Property<'reference'>
  projectId: string
}>()

const cell = useTemplateRef('cell')
useTableCellFocus({
  cell,
  isSelected: toRef(props, 'isSelected'),
  isFocused: toRef(props, 'isFocused'),
})

const {
  localEntityIds,
  searchText,
  resetAllData,
  loadMoreEntities,
  getRelatedEntityName,
  updateFieldValue,
  onPreviewAnchorMouseover,
  onPreviewAnchorMouseleave,
  onPreviewAnchorClick,
  onToggleEntity,
  previewEntities,
  nameProperty,
  listItems,
  entityPreviewPopover,
  relatedProject,
  entityLimit,
  hasReachedEntityLimit,
  savedEntityIds,
  allLoadedEntities,
  showSkeletonList,
  skeletonItems,
  noMatchFound,
  badgeEntities,
  unrenderedCount,
  entityCountLabel,
  getPreviewMeta,
} = useReferenceField({
  field: computed(() => props.field),
  property: computed(() => props.property),
})

const previewMeta = computed(() => getPreviewMeta(floatingMenu.value?.listRef))

const relatedProjectStore = useRelatedProjectsStore()

watch(
  () => props.isFocused,
  () => {
    searchText.value = ''
  },
)

const floatingTarget = useTemplateRef<HTMLDivElement>('floatingTarget')
useScrollOnSelected(floatingTarget, toRef(props, 'isSelected'))

const floatingMenu = useTemplateRef<HTMLDivElement & { listRef: HTMLElement }>('floatingMenu')
const { floatingStyles: floatingMenuStyles } = useFloating(floatingTarget, floatingMenu, {
  middleware: [
    autoPlacement({ allowedPlacements: ['bottom-start', 'bottom-end'] }),
    size({
      apply({ availableHeight, elements, rects }) {
        elements.floating.style.maxHeight = Math.min(availableHeight - 16, 500) + 'px'
        elements.floating.style.minWidth = rects.reference.width + 8 + 'px'
        elements.floating.style.maxWidth = '610px'
      },
    }),
    offset({ mainAxis: 4, alignmentAxis: -4 }),
  ],
  whileElementsMounted: autoUpdate,
})

watch(
  () => props.isSelected,
  async (newIsSelected) => {
    if (!newIsSelected) {
      resetAllData()
      return
    }

    await loadMoreEntities()
  },
)

useInfiniteScroll(() => floatingMenu.value?.listRef, loadMoreEntities, { distance: 200 })

/** Update the field value when the dropdown is closed */
watch(
  () => props.isFocused,
  async (newIsFocused) => {
    if (!newIsFocused) {
      await updateFieldValue()
    }
  },
)

const previewEntityId = ref<string | null>(null)

/** Index of the badge that is currently hovered */
const debouncedPreviewEntityId = useDebounce(previewEntityId, entityPreviewPopover.debounceMs)

const entityPreviewRef = useTemplateRef('entityPreview')

const { openReferenceTableView } = useOpenReferenceTableView(relatedProject, savedEntityIds)

const dialogEntityId = ref<string | null>(null)

const openEntityDialog = (entityId: string) => {
  onPreviewAnchorMouseleave()
  dialogEntityId.value = entityId
}
</script>

<template>
  <div
    ref="cell"
    class="relative size-full min-h-full outline-none"
    :class="!isSelected && 'overflow-hidden'"
    v-bind="$attrs"
  >
    <div
      ref="floatingTarget"
      class="absolute left-0 top-0 flex min-h-full w-[352px] min-w-full max-w-max flex-wrap items-start gap-1 p-1.5"
      :class="[
        isSelected
          ? 'overflow-x-hidden rounded-corner-4 bg-surface-primary outline outline-2 outline-border-focused'
          : '',
      ]"
      :style="{ zIndex: isSelected ? SELECTED_CELL_Z_INDEX : 0 }"
      data-test="badges-container"
    >
      <div
        v-if="noMatchFound"
        class="truncate whitespace-nowrap pl-1 text-text-subtlest"
      >
        No match found
      </div>
      <BadgeItem
        v-for="entity in badgeEntities"
        :key="entity.id"
        :label="getRelatedEntityName(entity)"
        size="sm"
        variant="neutral"
        :title="undefined"
        class="hover:bg-background-gray-subtlest-hovered"
        :class="[
          entity.id === debouncedPreviewEntityId &&
            (previewEntityId === null || previewEntityId === entity.id) &&
            'bg-background-gray-subtlest-hovered',
          ,
        ]"
        :trailing-icon="isFocused ? 'close' : undefined"
        @trailing-icon-click="previewEntities = previewEntities.filter((e) => e.id !== entity.id)"
        @mouseover="
          !isFocused && onPreviewAnchorMouseover($event.currentTarget, entity.id, 'badge')
        "
        @mouseleave="onPreviewAnchorMouseleave"
        @click="onPreviewAnchorClick($event.currentTarget, entity.id, 'badge')"
      />
      <ToolTip
        v-if="unrenderedCount > 0"
        body="Open related project"
        :placement="{ allowedPlacements: ['top'] }"
      >
        <DarwinButton
          variant="outline"
          size="xxs"
          @click="openReferenceTableView"
          >+{{ unrenderedCount }}</DarwinButton
        ></ToolTip
      >
    </div>
    <ListMenu
      v-if="isFocused && nameProperty && relatedProject"
      ref="floatingMenu"
      :style="floatingMenuStyles"
      class="go-scrollbar box-border flex h-auto max-h-[500px] min-h-[70px] shrink grow-0 select-none flex-col overflow-y-auto"
      :items="showSkeletonList ? skeletonItems : listItems"
      :external-search="isFilterableProperty(nameProperty)"
      :group-by-predicate="(item) => item.data.group"
      :group-order="['selected', 'unselected']"
      :aria-label="`Entities from ${relatedProject.name}`"
      :hide-no-results-text="showSkeletonList"
      @update:search="searchText = $event"
      @scroll="onPreviewAnchorMouseleave"
    >
      <template #group-title="{ group }">
        <div
          v-if="group.key === 'selected'"
          class="flex h-7 items-center justify-between pl-[9px] pr-2.5 text-xs-11px-default text-text-subtlest"
        >
          <div>Related Entities</div>
          <div>{{ localEntityIds.length }}/{{ entityLimit }}</div>
        </div>
      </template>
      <template #item="{ item, active, key, setActiveItem }">
        <ListMenuCheckboxItem
          :key="item.data.id"
          :data-entity-id="item.data.id"
          :active="active"
          :checked="item.data.group === 'selected'"
          :label="item.data.name || ENTITY_NAME_FALLBACK"
          :disabled="item.data.group === 'unselected' && hasReachedEntityLimit"
          @mousemove="setActiveItem(key)"
          @mouseover="onPreviewAnchorMouseover($event.currentTarget, item.data.id, 'listitem')"
          @mouseleave="onPreviewAnchorMouseleave"
          @select="onToggleEntity(item)"
        >
          <template #default>
            <LoadingSkeleton
              :status="showSkeletonList"
              class="w-full"
            >
              <div
                class="line-clamp-1 w-max"
                :class="showSkeletonList && 'opacity-0'"
              >
                {{ item.data.name || ENTITY_NAME_FALLBACK }}
                <span
                  v-if="!item.data.name"
                  class="text-text-subtle"
                >
                  {{ item.data.id }}
                </span>
              </div>
            </LoadingSkeleton>
          </template>
        </ListMenuCheckboxItem>
      </template>
      <template #footer>
        <ListMenuItem
          default-hover-disabled
          class="pl-7 text-sm-12px-default text-text-subtlest"
          :label="entityCountLabel"
        />
      </template>
    </ListMenu>
  </div>
  <PopOver
    v-if="previewMeta?.anchor && nameProperty && relatedProject"
    :open="entityPreviewPopover.isOpen.value"
    :target-selector="previewMeta && previewMeta.anchor"
    :placement="previewMeta.placement"
    :offset="previewMeta.offset"
    :aria-labelledby="entityPreviewRef?.labelId"
    :floating-z-index="POPOVER_Z_INDEX"
    teleport-to="#grid-container"
  >
    <template #content>
      <ReferenceEntityPreview
        v-if="previewMeta"
        ref="entityPreview"
        :can-open-entity="true"
        :data-colindex="$attrs['aria-colindex']"
        :data-rowindex="$attrs['aria-rowindex']"
        :entity="previewMeta.entity"
        :name-property-id="nameProperty.id"
        :project="relatedProject"
        :properties="relatedProjectStore.getPropertiesForReference(props.property.id)"
        @mouseover="previewMeta.mouseover"
        @mouseleave="onPreviewAnchorMouseleave"
        @open:entity="openEntityDialog(previewMeta.entity.id)"
      />
    </template>
  </PopOver>
  <EntityDialog
    v-if="dialogEntityId && relatedProject"
    :data-colindex="$attrs['aria-colindex']"
    :data-rowindex="$attrs['aria-rowindex']"
    :intial-entity-id="dialogEntityId"
    :initial-loaded-entities="allLoadedEntities"
    :entity-ids="localEntityIds"
    :properties="relatedProjectStore.getPropertiesForReference(props.property.id)"
    :project="relatedProject"
  />
</template>
