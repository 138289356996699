<script setup lang="ts">
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import PermissionsDialog from '@/modules/Project/Permissions/PermissionsDialog.vue'
import { useRootProjectId } from '@/modules/Project/Permissions/useRootProjectId'
import ProjectSidebarToggle from '@/modules/Projects/ProjectSidebarToggle.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'
import { useHasIntercomChat } from '@/sharedComposables/useHasIntercomChat'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import { computed } from 'vue'
import SupportButton from './SupportButton.vue'

defineProps<{
  workspaceId: string
  projectId: string
  parentProjectId?: string | null
}>()

const { isOpen } = useSidebar()

const hasIntercomChat = useHasIntercomChat()
const permissionsStore = usePermissionsStore()
const canManageProjectPermissions = computed(
  () =>
    permissionsStore.currentProjectPermissions.invite_members ||
    permissionsStore.currentProjectPermissions.add_members ||
    permissionsStore.currentProjectPermissions.remove_members ||
    permissionsStore.currentProjectPermissions.update_members,
)

const rootProjectId = useRootProjectId()
</script>

<template>
  <div class="relative flex w-full flex-row items-center justify-between gap-2 px-[10px] py-2">
    <div class="relative flex flex-1 items-center justify-between">
      <div
        class="absolute flex items-center transition duration-300 ease-in-out-quint"
        :class="
          isOpen ? 'pointer-events-none translate-x-[-8px] opacity-0' : 'translate-x-0 opacity-100'
        "
      >
        <ProjectSidebarToggle
          data-test="project-sidebar-toggle"
          :disable-tooltip="isOpen"
        />
        <div class="ml-[9.5px] h-[20px] w-[2px] rounded border border-border-subtle"></div>
      </div>
      <nav
        class="flex flex-row items-center justify-start overflow-hidden transition-all duration-300 ease-in-out-quint [&>*]:grow-0"
        :class="isOpen ? 'pl-0' : 'pl-[48px]'"
        aria-label="crumbs"
      >
        <slot />
      </nav>

      <div class="flex justify-end gap-2">
        <DarwinButton
          v-if="projectId && canManageProjectPermissions"
          size="sm"
          rounded
          variant="black"
          @click="permissionsStore.dialogOpen = true"
          >Share
        </DarwinButton>
        <SupportButton v-if="hasIntercomChat" />
      </div>
    </div>

    <PermissionsDialog
      :open="permissionsStore.dialogOpen"
      :root-project-id="rootProjectId"
      :workspace-id="workspaceId"
      @close="permissionsStore.dialogOpen = false"
    />
  </div>
</template>
