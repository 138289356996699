<script setup lang="ts">
import {
  VuePDF,
  usePDF,
  type HighlightEventPayload,
  type TextLayerLoadedEventPayload,
} from '@tato30/vue-pdf'
import '@tato30/vue-pdf/style.css'
import { useDebounceFn, useElementBounding, useElementSize, useEventListener } from '@vueuse/core'
import { computed, onUnmounted, ref, toRef, watch } from 'vue'
import { useRoute } from 'vue-router'

import type { components } from '@/api'
import { clamp } from '@/shared/utils/number'
import { invariant } from '@/shared/utils/typeAssertions'
import ClaimIndicator from '@/sharedComponents/ClaimIndicator.ce.vue'
import { useNaturalZoom } from '@/sharedComposables/useNaturalZoom'
import { useTemporaryToggle } from '@/sharedComposables/useTemporaryToggle'
import { PDF_SEARCH_HIGHLIGHT_OPTIONS } from './constants'
import ScaleButtons from './ScaleButtons.vue'
import { useGroundingPolygons } from './useGroundingPolygons'
import { useGroundingStore, type Source } from './useGroundingStore'

type BoundingBox = components['schemas']['Projects.Grounding.BoundingBox']
type OcrPage = components['schemas']['Projects.Entities.OCRPage']
type GroundingInfo = {
  sources: Source[]
  ocrPages: OcrPage[]
}

const props = withDefaults(
  defineProps<{
    filePath: string
    storagePrefix?: string
    groundingInfo?: GroundingInfo
    showSidebar?: boolean
    propertyId?: string
    /**
     * If set, and the PDF has a text layer, all instances of this text
     * will be highlighted.
     */
    highlightText?: string
  }>(),
  {
    storagePrefix: 'entityview-scale',
    groundingInfo: () => ({ sources: [], ocrPages: [] }),
    showPages: false,
    propertyId: undefined,
    highlightText: undefined,
  },
)

defineEmits<{
  highlight: [payload: HighlightEventPayload]
  textLoaded: [payload: TextLayerLoadedEventPayload]
}>()

/**
 * The gap between pages in the PDF viewer. Is stored as a constant because
 * as well as using it as an inline style, it is also used when calculating
 * the position of a bounding box when clicking the claim to scroll.
 */
const PDF_PAGE_GAP = 16

/**
 * Max scaling factor that we allow.
 *
 * We can't animate transform of the PDF renderer, so we do it on the parent container.
 * Zooming in via CSS transform ends up with a blurry, CSS-upscaled PDF.
 * PDF itself needs to be rendered at a larger scaling factor.
 *
 * We can always render the PDF at max allowed scale.
 * Otherwise, it's either rendered at lower scale and gets blurry when you zoom in,
 * or you adjust the pdf scale dynamically, but then you can't animate its sizing.
 */
const maxScale = 2

const route = useRoute()
const projectId = [route.params.projectId].flat()[0]
const { pdf, pages, download } = usePDF(computed(() => props.filePath))

defineExpose({
  download,
})

/**
 * We use css translate+scale to emulate scrolling so that we can smoothly animate zooms. This
 * ref holds the current translate values.
 */
const translate = ref<{ x: number; y: number }>({ x: 0, y: 0 })

/**
 * Translation shoud only be animated when we are translating on user's click,
 * but not during scrolling.
 */
const [isAnimated, withAnimation] = useTemporaryToggle({ initialValue: false, trailingMs: 200 })

const storageKey = computed(
  () => `${props.storagePrefix}-proj-${projectId}-prop-${props.propertyId}`,
)
const {
  onZoom,
  scrollContainer: pdfContainer,
  scrollContainerChild: pdfContainerChild,
  scale,
  setScale,
} = useNaturalZoom({ storageKey, translation: translate })

const { width: containerWidth, height: containerHeight } = useElementSize(pdfContainer)
const { height: childHeight } = useElementBounding(pdfContainerChild)

/** A numeric value for the 'fit' scale so that we can animate zooming in/out. */
const actualScale = computed(() => {
  if (scale.value === 'fit') return 1

  return scale.value
})

const pdfInfo = ref<
  {
    scale: number
    rawDims: {
      pageWidth: number
      pageHeight: number
    }
  }[]
>([])

const pagesContainer = ref<HTMLDivElement | null>(null)
const { width: pagesWidth } = useElementSize(pagesContainer)

type GetBoxCoordsArgs = {
  scale: number
  box: BoundingBox
  page: number
}

function getBoxCoords({ scale, box, page }: GetBoxCoordsArgs) {
  const ocrPage = props.groundingInfo.ocrPages.find((p) => p.number === page)
  if (!ocrPage) return

  // percentage based values
  const left = (box.xmin / ocrPage.width) * 100
  const top = (box.ymin / ocrPage.height) * 100
  const right = (box.xmax / ocrPage.width) * 100
  const bottom = (box.ymax / ocrPage.height) * 100
  const width = right - left
  const height = bottom - top

  const coords = {
    width: `${width}%`,
    height: `${height}%`,
    left: `${left}%`,
    top: `${top}%`,
    'border-width': `${scale * 1}px`,
    'border-radius': `${scale * 8}px`,
  }

  return coords
}

const sourcesWithCoords = computed(() => {
  return props.groundingInfo?.sources.map((source) => {
    const boxes = source.boundingBoxes.map((b) => {
      const s = scale.value === 'fit' ? (pdfInfo.value[b.page - 1]?.scale ?? 1) : scale.value

      return {
        ...b,
        coords: getBoxCoords({
          scale: s,
          box: b,
          page: b.page,
        }),
      }
    })

    const claimCoords = boxes[0]?.coords
      ? {
          left: boxes[0]?.coords?.left,
          top: boxes[0]?.coords?.top,
        }
      : {}

    return {
      ...source,
      boxes,
      claimCoords,
    }
  })
})

function getPageSources(page: number) {
  return sourcesWithCoords.value.filter((s) => s.boxes.some((b) => b.page === page))
}

const groundingStore = useGroundingStore()
const selectedSourceId = computed(() => groundingStore.selectedClaimsAndSource?.sourceId)

const scrollToSourceId = async (sourceId: number) => {
  if (!pdfContainer.value || !pdfContainerChild.value) return

  const source = sourcesWithCoords.value.find((s) => s.id === sourceId)
  const page = source?.boundingBoxes[0].page
  invariant(source && page !== undefined)

  const viewbox = getOcrPageViewbox(page)
  const [vh] = viewbox.split(' ').map(Number).slice(2)
  const points = getPolygonPointsFromSource(source, page).flatMap((polygons) => {
    return polygons
      .trim()
      .split(' ')
      .flatMap((points) => {
        const [x, y] = points.split(',').map(Number)
        return { x, y }
      })
  })

  // Get the smallest and largest y values of the source polygons
  const [minY, maxY] = points.reduce(
    ([minY, maxY], { y }) => [Math.min(minY, y), Math.max(maxY, y)],
    [Infinity, -Infinity],
  )

  // The percentage down the page of the first polygon point
  const topPercentage = minY / vh
  // The percentage down the page of the last polygon point
  const bottomPercentage = maxY / vh
  // The percentage down the page of the center of the source
  const centerYPercentage = (topPercentage + bottomPercentage) / 2

  // Size of a single PDF page
  const pageHeight = pdfContainerChild.value.querySelector('svg')?.clientHeight
  invariant(pageHeight !== undefined)

  const desiredYAtCenter =
    centerYPercentage * pageHeight + (page - 1) * (pageHeight + PDF_PAGE_GAP) + PDF_PAGE_GAP

  const pdfCenter = pdfContainer.value.getBoundingClientRect().height / 2

  withAnimation(() => {
    scale.value = 1
    translate.value = {
      x: 0,
      y: pdfCenter - desiredYAtCenter,
    }
  })
}

watch(
  () => groundingStore.selectedClaimsAndSource?.sourceId,
  async (firstSourceId) => {
    if (firstSourceId === undefined) return
    await scrollToSourceId(firstSourceId)
  },
  {
    immediate: true,
    flush: 'post',
  },
)

function goToPage(page: number) {
  if (!pdfContainer.value) return
  const box = pdfContainer.value.querySelector(`[data-page="${page}"]`)
  // scroll box and put it in center of viewport
  if (!box || !(box instanceof HTMLElement)) return

  const top = box.getBoundingClientRect().top - translate.value.y - 100

  translate.value.x = 0
  translate.value.y = -top
}

const activePage = ref(1)

function onScroll(e: WheelEvent) {
  if (!pdfContainer.value) return

  const isX = e.shiftKey
  /**
   * This is a limit to how much we should translate the pdf container. Without it,
   * the user can scroll off to infinity.
   */
  const minTranslate = Math.min(-(childHeight.value - containerHeight.value), 0)
  translate.value = {
    x: translate.value.x - (isX ? e.deltaY : 0),
    y: clamp(minTranslate, translate.value.y - (isX ? 0 : e.deltaY), 0),
  }
}

watch(
  () => translate.value.y,
  async () => {
    const container = pdfContainer.value
    if (!container) return

    await new Promise((resolve) => setTimeout(resolve, 200))

    // if at top, the page is 1
    if (translate.value.y > 100) {
      activePage.value = 1
      return
    }

    const pagesArray = Array.from(container.querySelectorAll('[data-page]')) as HTMLElement[]

    const containerRect = container.getBoundingClientRect()

    function getPagePercentage(page: HTMLElement) {
      const rect = page.getBoundingClientRect()
      const cutFromTop = Math.abs(Math.min(rect.top - containerRect.top, 0))
      const cutFromBottom = Math.abs(Math.min(containerRect.bottom - rect.bottom, 0))
      return (rect.height - cutFromTop - cutFromBottom) / rect.height
    }

    const mostVisiblePages = pagesArray.toSorted((a, b) => {
      return getPagePercentage(b) - getPagePercentage(a)
    })

    const closestPage = mostVisiblePages[0]

    const closestPageNum = pagesArray.findIndex((page) => page === closestPage) + 1

    if (activePage.value !== closestPageNum) {
      activePage.value = closestPageNum
    }
  },
)

const pagesScrollTo = useDebounceFn((top: number) => {
  if (!pagesContainer.value) return
  pagesContainer.value.scrollTo({ top, behavior: 'smooth' })
}, 200)

watch(activePage, () => {
  if (!pagesContainer.value) return
  const pageEl = pagesContainer.value.querySelector(`[data-page="${activePage.value}"]`)
  if (!(pageEl instanceof HTMLElement)) return

  const lowerBound = pagesContainer.value.scrollTop
  const upperBound = lowerBound + pagesContainer.value.clientHeight

  if (pageEl.offsetTop < lowerBound) {
    pagesScrollTo(pageEl.offsetTop - 10)
  }

  if (pageEl.offsetTop + pageEl.clientHeight > upperBound) {
    pagesScrollTo(pageEl.offsetTop + pageEl.clientHeight - pagesContainer.value.clientHeight + 10)
  }
})

onUnmounted(() => {
  pdf.value?.destroy()
  groundingStore.selectedClaimsAndSource = null
})

const { getPolygonPointsFromSource, strokeWidthPerPage, getOcrPageViewbox } = useGroundingPolygons(
  toRef(props.groundingInfo, 'ocrPages'),
)

/** Handle clearing the active claim when clicking outside of a claim indicator. */
useEventListener('click', (event) => {
  if (!(event.target instanceof Element)) {
    return
  }

  const isClickingClaimIndicator = !!event.target.closest('[data-source]')
  if (isClickingClaimIndicator) {
    return
  }

  groundingStore.selectedClaimsAndSource = null
})
</script>

<template v-if="filePath">
  <div
    class="relative flex size-full overflow-hidden pt-0"
    @wheel.ctrl.exact.prevent.stop="onZoom($event)"
    @wheel.meta.exact.prevent.stop="onZoom($event)"
  >
    <div
      v-if="showSidebar && pages > 1"
      ref="pagesContainer"
      class="go-scrollbar z-10 flex size-full w-[100px] flex-col gap-2 overflow-y-auto p-4 [contain:strict]"
    >
      <button
        v-for="page in pages"
        :key="page"
        class="relative flex scroll-my-2 flex-col items-center gap-1"
        :data-page="page"
        @click="withAnimation(() => goToPage(page))"
      >
        <VuePDF
          :page="page"
          :pdf="pdf"
          :width="pagesWidth"
          fit-parent
          :source="filePath"
          class="w-fit overscroll-none rounded-corner-2 outline outline-2 transition-all last:border-none"
          :class="page === activePage ? 'outline-border-focused' : 'outline-background-transparent'"
        />
        <span
          class="text-xxs-8px-default"
          :class="page === activePage ? 'text-text-selected' : 'text-text'"
        >
          {{ String(page).padStart(2, '0') }}
        </span>
        <div
          v-if="sourcesWithCoords?.find((s) => s.boxes.some((b) => b.page === page))"
          class="absolute right-1 top-1 size-1.5 rounded-full bg-background-stages-model-pressed"
          aria-label="Claimed"
        />
      </button>
    </div>

    <div class="size-full overflow-hidden rounded-corner-6">
      <div
        id="pdf-container"
        ref="pdfContainer"
        class="go-scrollbar relative flex size-full flex-col flex-wrap items-center px-8 pb-12 pt-4"
        @wheel.shift.exact.prevent.stop="onScroll"
        @wheel.exact.prevent.stop="onScroll"
      >
        <div
          id="pdf-container-child"
          ref="pdfContainerChild"
          class="inline-flex flex-col will-change-transform"
          :class="[isAnimated && 'transition-transform duration-200']"
          :style="{
            transform: `translate(${translate.x}px, ${translate.y}px) scale(${actualScale})`,
            transformOrigin: 'top center',
            gap: `${PDF_PAGE_GAP}px`,
          }"
        >
          <div
            v-for="page in pages"
            :key="page"
            class="relative scroll-my-10"
            :data-page="page"
          >
            <VuePDF
              :page="page"
              :pdf="pdf"
              text-layer
              :source="filePath"
              class="relative w-fit overscroll-none transition"
              :width="containerWidth"
              :highlight-text="highlightText"
              :highlight-options="PDF_SEARCH_HIGHLIGHT_OPTIONS"
              :scale="maxScale"
              @loaded="
                (e) => {
                  pdfInfo[page - 1] = e as any
                  pdfInfo = [...pdfInfo]
                  if (selectedSourceId !== undefined) {
                    scrollToSourceId(selectedSourceId)
                  }
                }
              "
              @highlight="$emit('highlight', $event)"
              @text-loaded="$emit('textLoaded', $event)"
            />
            <div
              v-for="source in getPageSources(page)"
              :key="source.id"
              class="pointer-events-none absolute inset-0 transition"
              :class="{
                'opacity-0': selectedSourceId !== undefined && selectedSourceId !== source.id,
              }"
            >
              <ClaimIndicator
                :data-source="source.id"
                class="pointer-events-auto absolute z-10 -translate-x-2 -translate-y-4 scroll-m-6"
                :source-id="source.id"
                :property-id="propertyId"
                :is-selected="selectedSourceId === source.id"
                :style="source.claimCoords"
              />
              <svg
                :viewBox="getOcrPageViewbox(page)"
                class="absolute left-0 top-0 size-full"
              >
                <polygon
                  v-for="(points, index) in getPolygonPointsFromSource(source, page)"
                  :key="index"
                  :points="points"
                  class="fill-background-stages-model-subtle stroke-background-stages-model"
                  :style="{
                    strokeWidth: String(strokeWidthPerPage[page - 1]),
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                  }"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="absolute bottom-2 flex w-full justify-center">
      <ScaleButtons
        class="shadow-sm"
        :scale="scale"
        :download-url="filePath"
        @update:scale="(scale) => withAnimation(() => setScale(scale))"
      />
    </div>
  </div>
</template>
