<script lang="ts" setup>
import AskGoIcon from '@/icons/put-icons-here/ask-go.svg'
import BingIcon from '@/icons/put-icons-here/bing-logo.svg'
import AzureIcon from '@/icons/put-icons-here/company-azure.svg'
import GoogleIcon from '@/icons/put-icons-here/company-google.svg'
import ZapierIcon from '@/icons/put-icons-here/company-zapier.svg'
import ExcelIcon from '@/icons/put-icons-here/excel-logo.svg'
import GmailLogo from '@/icons/put-icons-here/gmail-logo.svg'
import GoogleDriveLogo from '@/icons/put-icons-here/google-drive-logo.svg'
import HelpFillIcon from '@/icons/put-icons-here/help-fill.svg'
import MultiPageFileFill from '@/icons/put-icons-here/multi-page-file-fill.svg'
import MultiPageFile from '@/icons/put-icons-here/multi-page-file.svg'
import OneDriveLogo from '@/icons/put-icons-here/onedrive-logo.svg'
import OutlookLogo from '@/icons/put-icons-here/outlook-logo.svg'
import PythonLanguageIcon from '@/icons/put-icons-here/python-language.svg'
import SharepointLogo from '@/icons/put-icons-here/sharepoint-logo.svg'
import WordLogo from '@/icons/put-icons-here/word-logo.svg'
import { computed } from 'vue'
import type { IconName } from './IconName'

const props = withDefaults(
  defineProps<{
    icon: IconName
    size?: Size
  }>(),
  { size: 'md' },
)

const iconClass = computed(() => {
  // Our icon font solution doesn't support multicolour icons, or icons
  // that always need to be rendered in the same colours. Typically,
  // external company icons always need to be rendered in their own
  // colours, so we handle these cases separately.
  const multicolourIcons: IconName[] = [
    'company-google',
    'python-language',
    'bing-logo',
    'excel-logo',
    'word-logo',
    'company-zapier',
    'ask-go',
    'company-azure',
    'gmail-logo',
    'outlook-logo',
    'sharepoint-logo',
    'google-drive-logo',
    'onedrive-logo',
  ]

  // Some icons have issues being rendered with our iconfont, because the svg files use
  // fill-rule="evenodd" and the icon font uses fill-rule="nonzero". This causes
  // some icons to render incorrectly. We handle these cases separately.
  const issuesWithNonzeroFill: IconName[] = ['help-fill', 'multi-page-file', 'multi-page-file-fill']

  return [...multicolourIcons, ...issuesWithNonzeroFill].includes(props.icon)
    ? ''
    : `icon-${props.icon}`
})

const sizes = {
  xxxs: ['text-[8px] leading-[8px]', 8],
  xs: ['text-[12px] leading-3', 12],
  sm: ['text-[14px] leading-[14px]', 14],
  md: ['text-[14px] leading-[14px]', 14],
  lg: ['text-[16px] leading-4', 16],
  xl: ['text-[16px] leading-4', 16],
  xxl: ['text-[20px] leading-5', 20],
  xxxl: ['text-[24px] leading-10', 24],
  xxxxl: ['text-[40px] leading-10', 40],
  '32': ['text-[32px] leading-10', 32],
} as const

type Size = keyof typeof sizes
</script>

<template>
  <span
    class="flex items-center justify-center"
    :class="[iconClass, sizes[props.size][0]]"
  >
    <BingIcon
      v-if="icon === 'bing-logo'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <GoogleIcon
      v-else-if="icon === 'company-google'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <PythonLanguageIcon
      v-else-if="icon === 'python-language'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <ZapierIcon
      v-else-if="icon === 'company-zapier'"
      class="size-full"
    />
    <AskGoIcon
      v-else-if="icon === 'ask-go'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <AzureIcon
      v-else-if="icon === 'company-azure'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <ExcelIcon
      v-else-if="icon === 'excel-logo'"
      :width="'text-[12px] leading-[12px]'"
      :height="'text-[12px] leading-[12px]'"
    />
    <WordLogo
      v-else-if="icon === 'word-logo'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <HelpFillIcon
      v-else-if="icon === 'help-fill'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <MultiPageFile
      v-else-if="icon === 'multi-page-file'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <MultiPageFileFill
      v-else-if="icon === 'multi-page-file-fill'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <SharepointLogo
      v-else-if="icon === 'sharepoint-logo'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <GoogleDriveLogo
      v-else-if="icon === 'google-drive-logo'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <OutlookLogo
      v-else-if="icon === 'outlook-logo'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <GmailLogo
      v-else-if="icon === 'gmail-logo'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
    <OneDriveLogo
      v-else-if="icon === 'onedrive-logo'"
      :width="sizes[props.size][1]"
      :height="sizes[props.size][1]"
    />
  </span>
</template>
