<script setup lang="ts">
import { useReasoning } from '@/modules/Project/useReasoning'
import { useSelectField } from '@/modules/Project/useSelectField'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import { computed, ref } from 'vue'
import type { SidebarEmits } from './Fields/field-types'
import type { Field } from './Fields/types'
import type { Property } from './Properties/types'

/**
 * This component renders an element in the sidebar for user/single select fields.
 * Both fields behave the same way in the sidebar, with the only difference being
 * some very small UI details.
 */

type FieldType = 'user_select' | 'single_select'

const props = defineProps<{
  field: Field<FieldType>
  property: Property<FieldType>
}>()

const emit = defineEmits<SidebarEmits<FieldType>>()

const field = computed(() => props.field)
const { localValue, selectOptions, valueToColorMap, optionColours } = useSelectField(
  field,
  computed(() => props.property),
)

const { hasReasoning } = useReasoning(field)

const filteredOptions = ref<typeof selectOptions.value>([])

const badgeIcon = computed(() => (props.field.type === 'user_select' ? 'user-fill' : undefined))

/**
 * For user select fields, we internally store user IDs, but show the users'
 * names in the UI.
 */
const displayValue = computed<(typeof localValue)['value']>(() => {
  if (props.field.type === 'single_select') {
    return localValue.value
  }

  return (
    localValue.value?.map((v) => {
      const option = selectOptions.value.find((o) => o.id === v)
      return option?.data.label ?? v
    }) ?? null
  )
})

defineOptions({ inheritAttrs: false })
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps, menu }"
    :positioning="{ offset: { mainAxis: 2, crossAxis: 1 } }"
    close-on-select
  >
    <button
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      class="size-full min-h-7 rounded-corner-8 p-1 pr-0 hover:bg-background-transparent-hovered focus:outline-none focus-visible:bg-background-transparent-hovered"
      :class="menu.open && 'bg-background-transparent-hovered'"
    >
      <div class="size-full overflow-hidden rounded-r-corner-6">
        <div class="flex size-full cursor-pointer flex-row items-center gap-1 overflow-hidden">
          <div
            v-if="hasReasoning && localValue?.length === 0"
            class="h-5 max-w-full truncate whitespace-nowrap pl-2 text-text-subtlest"
          >
            No match found
          </div>
          <div
            v-if="!localValue"
            class="h-5 max-w-full truncate whitespace-nowrap pl-2 text-text-subtlest"
          >
            Select an option
          </div>

          <BadgeItem
            v-for="v in displayValue"
            :key="v"
            v-memo="[v, ...optionColours]"
            :label="v"
            :leading-icon="badgeIcon"
            size="sm"
            variant="blue"
            class="whitespace-nowrap"
            :rainbow-color="valueToColorMap[v]"
            @click="emit('focus', $event)"
          />
        </div>
      </div>
    </button>
    <Menu.Content>
      <Menu.Search
        :items="selectOptions"
        key-or-predicate="id"
        @change="filteredOptions = $event"
      />
      <Menu.Item
        v-for="option in filteredOptions"
        :key="option.id"
        @select="emit('submit', [option.id])"
      >
        <template #prefix>
          <IconSprite
            icon="check"
            class="text-icon-subtle"
            :class="!localValue?.includes(option.id) && 'opacity-0'"
          />
        </template>
        <BadgeItem
          :label="option.data.label"
          size="sm"
          :leading-icon="badgeIcon"
          variant="blue"
          :rainbow-color="valueToColorMap[option.id]"
        />
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
