import { updateView } from '@/backend/updateView'
import { ITEM_HEIGHT } from '@/modules/Project/ProjectTable.vue'
import { isHtmlElement } from '@/shared/utils'
import { useElementSize, useEventListener, useMouse } from '@vueuse/core'
import { computed, ref, type Ref } from 'vue'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useProject } from './useProject'

// Scroll speed in pixels per frame
const SCROLL_SPEED = 10
// Distance from edge to trigger scrolling (in pixels)
const EDGE_THRESHOLD = 50

export const useColumnReordering = (header: Ref<HTMLElement | null>) => {
  const propertyIdBeingDragged = ref<string | null>(null)
  const draggedElementHeader = ref<HTMLElement | null>(null)
  const mouseDragXStartPosition = ref<number | null>(null)
  const hasMoved = ref(false)
  const scrollAnimationFrame = ref<number | null>(null)

  const projectStore = useProject()
  const workspacesStore = useWorkspaces()
  const size = useElementSize(draggedElementHeader)
  const mousePosition = useMouse()

  const xDragOffset = computed(() => {
    if (!draggedElementHeader.value || !mouseDragXStartPosition.value) {
      return 0
    }
    return mouseDragXStartPosition.value - draggedElementHeader.value.getBoundingClientRect().left
  })

  const dragBoxStyles = computed(() => {
    const firstCell = header.value?.querySelector('[role="columnheader"]:first-of-type')
    const rect = firstCell?.getBoundingClientRect()

    return {
      width: `${size.width.value}px`,
      height: `${(rect?.height ?? 0) + (projectStore.activeView?.entities?.length ?? 0) * ITEM_HEIGHT}px`,
      left: `${mousePosition.x.value - xDragOffset.value}px`,
      top: `${rect?.top}px`,
    }
  })

  const getLinePosition = computed(() => {
    if (!header.value || potentialNewIndex.value === null) {
      return null
    }
    const headerItem =
      header.value.querySelectorAll('[role="columnheader"]')[potentialNewIndex.value]
    return headerItem
      ? headerItem.getBoundingClientRect().left
      : (header.value.querySelector('[role="columnheader"]:last-of-type')?.getBoundingClientRect()
          .right ?? null)
  })

  const lineStyles = computed(() => {
    const firstCell = header.value?.querySelector('[role="columnheader"]:first-of-type')
    const rect = firstCell?.getBoundingClientRect()
    return {
      left: `${getLinePosition.value ?? 0}px`,
      top: `${rect?.top}px`,
      height: `${(rect?.height ?? 0) + (projectStore.activeView?.entities?.length ?? 0) * ITEM_HEIGHT}px`,
    }
  })

  const onHeaderItemMouseDown = (propId: string, event: MouseEvent) => {
    propertyIdBeingDragged.value = propId
    draggedElementHeader.value = event.currentTarget as HTMLElement
    mouseDragXStartPosition.value = mousePosition.x.value
  }

  const shiftPropertiesAndSave = async () => {
    if (potentialNewIndex.value === null || !propertyIdBeingDragged.value) {
      return
    }

    // optimistically update the properties in the store for instant feedback
    const { ok, oldPropertyIds } = projectStore.shiftPropertyToIndex(
      propertyIdBeingDragged.value,
      potentialNewIndex.value,
    )
    if (
      projectStore.activeView &&
      workspacesStore.currentWorkspace &&
      projectStore.projectId &&
      ok
    ) {
      const result = await updateView({
        workspaceId: workspacesStore.currentWorkspace.id,
        projectId: projectStore.projectId,
        viewId: projectStore.activeView.id,
        name: projectStore.activeView.view.name,
        propertyIds: projectStore.activeView.view.propertyIds ?? [],
        propertyLayouts: projectStore.activeView.view.propertyLayouts,
        filters: projectStore.activeView.view.filters,
        propertyOptions: projectStore.activeView.view.propertyOptions ?? [],
        assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
        numPinnedProperties: projectStore.activeView.view.numPinnedProperties,
      })
      if (!result.ok && oldPropertyIds) {
        // rollback the change if the update failed
        projectStore.upsertView({
          id: projectStore.activeView.id,
          propertyIds: oldPropertyIds,
          propertyLayouts: projectStore.activeView.view.propertyLayouts,
          filters: projectStore.activeView.view.filters,
          name: projectStore.activeView.view.name,
          propertyOptions: projectStore.activeView.view.propertyOptions,
          assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
          numPinnedProperties: projectStore.activeView.view.numPinnedProperties,
        })
      }
    }
  }

  const potentialNewIndex = computed(() => {
    if (!draggedElementHeader.value) {
      return null
    }

    const columns = [...(header.value?.querySelectorAll('[role="columnheader"]') ?? [])]

    // Reversed so the first columns are evaluated last, to have preference
    // We want to put the pinned columns last, so they have preference when dragging
    const priorityColumns = columns.toReversed().toSorted((a, b) => {
      if (!isHtmlElement(a) || !isHtmlElement(b)) return 0
      const aIsPinned = a.dataset['pinned'] !== undefined
      const bIsPinned = b.dataset['pinned'] !== undefined
      if (!aIsPinned && bIsPinned) return -1
      return 0
    })
    const columnBounds = priorityColumns.map((col) => {
      return {
        rect: col.getBoundingClientRect(),
        el: col,
        index: columns.indexOf(col),
      }
    })

    const existingIndex = projectStore.visibleProperties.findIndex(
      (prop) => prop.id === propertyIdBeingDragged.value,
    )
    const existingBounds = columnBounds.find((col) => col.index === existingIndex)
    if (
      existingBounds &&
      mousePosition.x.value >= existingBounds.rect.left &&
      mousePosition.x.value <= existingBounds.rect.right
    ) {
      return null
    }

    const newIndex = columnBounds.reduce((acc, curr) => {
      return mousePosition.x.value < curr.rect.right ? curr.index : acc
    }, columnBounds.length)

    return newIndex === existingIndex ? null : newIndex
  })

  const handleAutoScroll = () => {
    if (!propertyIdBeingDragged.value) return

    const container = document.querySelector('[data-test="table-container"]')
    if (!container) return

    const containerRect = container.getBoundingClientRect()
    const mouseX = mousePosition.x.value
    const distanceFromLeft = mouseX - containerRect.left
    const distanceFromRight = containerRect.right - mouseX

    let scrollAmount = 0

    if (distanceFromLeft < EDGE_THRESHOLD) {
      // Scroll left - the closer to the edge, the faster the scroll
      scrollAmount = -SCROLL_SPEED * (1 - distanceFromLeft / EDGE_THRESHOLD)
    } else if (distanceFromRight < EDGE_THRESHOLD) {
      // Scroll right - the closer to the edge, the faster the scroll
      scrollAmount = SCROLL_SPEED * (1 - distanceFromRight / EDGE_THRESHOLD)
    }

    if (scrollAmount !== 0) {
      container.scrollLeft += scrollAmount
      scrollAnimationFrame.value = requestAnimationFrame(handleAutoScroll)
    } else {
      scrollAnimationFrame.value = null
    }
  }

  useEventListener(window, 'mousemove', () => {
    if (!propertyIdBeingDragged.value) return
    hasMoved.value = true

    // Start auto-scroll animation if not already running
    if (scrollAnimationFrame.value === null) {
      scrollAnimationFrame.value = requestAnimationFrame(handleAutoScroll)
    }
  })

  useEventListener(window, 'mouseup', () => {
    hasMoved.value = false
    if (!propertyIdBeingDragged.value) return
    shiftPropertiesAndSave()
    propertyIdBeingDragged.value = null

    // Cancel any ongoing auto-scroll animation
    if (scrollAnimationFrame.value !== null) {
      cancelAnimationFrame(scrollAnimationFrame.value)
      scrollAnimationFrame.value = null
    }
  })

  return {
    propertyIdBeingDragged,
    onHeaderItemMouseDown,
    hasMoved,
    dragBoxStyles,
    potentialNewIndex,
    lineStyles,
  }
}
