<script setup lang="ts">
/**
 * Primary component for '/:workspaceId/home'
 *
 * Renders workspace details and a list of projects in the workspace
 */
import EnterprisePaywallDialog from '@/modules/Billing/EnterprisePaywallDialog.vue'
import { usePaywallStore } from '@/modules/Billing/paywall'
import PaywallDialog from '@/modules/Billing/PaywallDialog.vue'
import { useBilling } from '@/modules/Billing/useBilling'
import LibraryDialog from '@/modules/Library/LibraryDialog.vue'
import ProjectCreateModal from '@/modules/Projects/ProjectCreateModal.vue'
import SidebarLayout from '@/sharedComponents/SidebarLayout.vue'
import { computed, toRef, watch } from 'vue'
import { RouterView } from 'vue-router'
import CsvImportModal from './CsvImportModal.vue'
import TemplateModal from './TemplateModal.vue'
import TutorialSidebar, { useTutorialSidebar } from './TutorialSidebar.vue'
import { useLaunchDarklyContext } from './useLaunchDarklyContext'
import { useLoadWorkspaceData } from './useLoadWorkspaceData'
import { useWorkspaces } from './useWorkspaces'

const props = defineProps<{ workspaceId: string }>()

const billingStore = useBilling()
const workspacesStore = useWorkspaces()
const paywallStore = usePaywallStore()

useLoadWorkspaceData(toRef(props, 'workspaceId'))

const { isOpen: isTutorialSidebarOpen } = useTutorialSidebar()

const workspace = computed(() => workspacesStore.workspaces.find((w) => w.id === props.workspaceId))
useLaunchDarklyContext(workspace)

watch(workspace, () => workspacesStore.setCurrentWorkspace(workspace.value), { immediate: true })
</script>

<template>
  <SidebarLayout>
    <template #sidebar>
      <RouterView name="Sidebar" />
    </template>
    <template #page-header>
      <RouterView name="BreadCrumbs" />
    </template>
    <template #page>
      <div class="relative grid h-full grid-cols-[1fr_auto]">
        <RouterView />
        <div
          class="overflow-hidden transition-all duration-300 ease-in-out-quint"
          :class="isTutorialSidebarOpen ? 'w-[280px]' : 'w-0'"
        >
          <Transition
            enter-active-class="transition-all duration-300 ease-in-out-quint"
            enter-from-class="scale-90 opacity-0"
            enter-to-class="scale-100 opacity-100"
            leave-active-class="transition-all duration-300 ease-in-out-quint"
            leave-from-class="scale-100 opacity-100"
            leave-to-class="scale-90 opacity-0"
          >
            <TutorialSidebar
              v-if="isTutorialSidebarOpen"
              class="size-full min-w-[280px] overflow-auto overflow-x-hidden border-l border-border-subtle"
            />
          </Transition>
        </div>
      </div>
      <component
        :is="
          billingStore.activePlan?.name === 'enterprise' ? EnterprisePaywallDialog : PaywallDialog
        "
        :open="paywallStore.isOpen"
        :workspace-id="workspaceId"
        @close="paywallStore.close()"
      />
    </template>
  </SidebarLayout>
  <LibraryDialog :workspace-id="workspaceId" />
  <TemplateModal :workspace-id="workspaceId" />
  <CsvImportModal :workspace-id="workspaceId" />
  <ProjectCreateModal :workspace-id="workspaceId" />
</template>
