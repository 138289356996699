import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import type { View } from '@/modules/Project/useProject'
import { useSerializeFieldToText } from '@/modules/Project/useSerializeFieldToText'
import { computed } from 'vue'
import { FALLBACK_CASE_NAME } from './serializers'
import { useAllStageFields } from './Sidebar/useAllStageFields'
import { useCaseStore } from './useCaseStore'

type FieldItem = {
  field: Field
  property: Property
  view: View
}

export const useEntitiesToMarkdown = () => {
  const caseStore = useCaseStore()
  const serializeField = useSerializeFieldToText()
  const { fields } = useAllStageFields()

  const caseName = computed(() => caseStore.activeCase?.name || FALLBACK_CASE_NAME)

  /**
   * Parses different field types into their markdown representation.
   */
  const parseFieldToMarkdown = (field: Field, property: Property): string | null => {
    const propertyValue = serializeField(field)
    if (!propertyValue) return null

    const header = `### ${property.name}\n\n`
    const separator = '\n<hr>\n\n'

    switch (field.type) {
      case 'file': {
        const propertyLink = `[${propertyValue}](${field.manualValue})`
        return propertyLink ? `${header}${propertyLink}${separator}` : null
      }

      case 'json': {
        return `${header}\`\`\`json\n${propertyValue}\n\`\`\`${separator}`
      }

      case 'multi_select': {
        const values = propertyValue.split(',').map((value) => `\`${value.trim()}\``)
        return `${header}${values.join(', ')}${separator}`
      }

      case 'single_select': {
        return `${header}\`${propertyValue}\`${separator}`
      }

      case 'collection': {
        if (!field.subprojectPreview?.entityPreviews?.length) {
          return `${header}No items in collection${separator}`
        }

        const previews = field.subprojectPreview.entityPreviews
        const firstPreview = previews[0]
        if (!firstPreview?.field_previews?.length) {
          return `${header}Empty collection preview${separator}`
        }

        const fieldPreviews = firstPreview.field_previews
        const headerRow = `| ${fieldPreviews.map(() => property.name).join(' | ')} |\n`
        const separatorRow = `| ${fieldPreviews.map(() => '---').join(' | ')} |\n`
        const dataRows = previews
          .map(
            (preview) =>
              `| ${preview.field_previews
                .map((fp) => String(fp.manual_value?.value ?? ''))
                .join(' | ')} |`,
          )
          .join('\n')

        return `${header}${headerRow}${separatorRow}${dataRows}${separator}`
      }

      default: {
        return `${header}${propertyValue}${separator}`
      }
    }
  }

  const getParsedFields = () => {
    const parsedFields = fields.value.reduce<string>((acc, { field, property }: FieldItem) => {
      if (!property) return acc
      const parsedField = parseFieldToMarkdown(field, property)
      return parsedField ? `${acc}${parsedField}\n` : acc
    }, '')

    return `# ${caseName.value} entities\n\n${parsedFields}`
  }

  return {
    getParsedFields,
  }
}
