import type { BackendClaim } from '@/backend/types'
import { invariant } from '@/shared/utils/typeAssertions'
import type { Claim } from '../../useGroundingStore'
import type { Field, GroundableField } from '../types'

export const isGroundableField = (field: Field): field is GroundableField => 'hasGrounding' in field

/**
 * This function is used to determine the start and end positions of a claim, within the
 * text to which the claim relates.
 *
 * @param claim - The grounding claim, as returned by the backend.
 * @param field - The field to which the claim relates.
 * @param displayValue - The serialized text value of the field
 * @param lastEnd - The end position of the last claim, to be used as the start position of
 *  this claim if the claim has no length.
 */
export const getClaimStartAndEnd = (
  claim: BackendClaim,
  field: GroundableField,
  displayValue: string,
  lastEnd: number,
): Pick<Claim, 'start' | 'end'> | null => {
  const start = claim.location.length ? claim.location.offset : lastEnd
  const end = claim.location.offset + claim.location.length
  if (field.type !== 'number') {
    return { start, end }
  }

  /**
   * Number fields are a special case, because the claims point to either the number,
   * the unit, or the tool's motivation. Because we format numbers on the frontend,
   * we can't rely on the backend-provided start/end positions.
   */

  switch (claim.object_path) {
    case '$.motivation':
      return null
    case '$.value.number': {
      const numberRegex = /\d(.*\d)?/
      const match = displayValue.match(numberRegex)
      if (!match || match.index === undefined) {
        return { start, end }
      }

      const numberString = match[0]
      return {
        start: match.index,
        end: match.index + numberString.length,
      }
    }
    case '$.value.unit': {
      invariant(field.toolValueUnit, 'Claim relates to unit, but the tool has returned no unit.')

      const adjustedStart = displayValue.indexOf(field.toolValueUnit) ?? -1
      if (adjustedStart === -1) {
        // If the unit is not found in the display value, it's best to return null to
        // skip the claim, as it probably won't make any sense.
        return null
      }

      const adjustedEnd = adjustedStart + field.toolValueUnit.length
      return { start: adjustedStart, end: adjustedEnd }
    }
    default: {
      /**
       * Confirmed with BE that this will only happen if the BE has done something
       * very stupid. In this case, we should skip the claim because it probably
       * won't make any sense and it might be incorrectly positioned.
       */
      return null
    }
  }
}

export const getFieldClaims = (field: Field): Claim[] => {
  if (!isGroundableField(field) || !field.hasGrounding || !field.grounding) {
    return []
  }

  const referencedSourceIds = field.grounding.claims.reduce((acc, claim) => {
    claim.source_indices.forEach(acc.add, acc)
    return acc
  }, new Set<number>())

  const backendSources = field.grounding.sources
    .filter((s) => s.bounding_boxes.length && referencedSourceIds.has(s.index))
    .toSorted((a, b) => a.property_id.localeCompare(b.property_id))

  return field.grounding.claims
    .toSorted((a, b) => a.location.offset - b.location.offset)
    .reduce<{ acc: Claim[]; lastEnd: number }>(
      ({ acc, lastEnd }, claim) => {
        const start = claim.location.length ? claim.location.offset : lastEnd
        const end = claim.location.offset + claim.location.length

        const sources = claim.source_indices
          .map((source_index) => backendSources.findIndex(({ index }) => index === source_index))
          .filter((idx) => idx !== -1)
          .toSorted()

        if (sources.length) {
          acc.push({ id: acc.length, start, end, sources: [sources[0], ...sources.slice(1)] })
        }

        return { acc, lastEnd: end }
      },
      { acc: [], lastEnd: 0 },
    ).acc
}
