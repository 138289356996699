<script setup lang="ts">
import type { PropertyType } from '@/backend/types'
import type { SidebarEmits, SidebarProps } from '@/modules/Project/Fields/field-types'
import { useDotIndicator } from '@/modules/Project/useDotIndicator'
import { useFieldStatusIndicator } from '@/modules/Project/useFieldStatusIndicator'
import DotIndicator from '@/uiKit/DotIndicator.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { computed, useId } from 'vue'
import { getSidebarComponent } from '../modules/Project/Fields/field-registry'
import { TYPE_ICON } from '../modules/Project/icons'

/**
 * This component renders a property name and its field value in the sidebar.
 */

const props = defineProps<SidebarProps<PropertyType>>()

defineEmits<SidebarEmits<PropertyType>>()

const { isModelOutput } = useFieldStatusIndicator(computed(() => props.field))

const labelId = useId()

const { staleness, showDotIndicator } = useDotIndicator(
  computed(() => props.field),
  computed(() => props.property),
)
</script>

<template>
  <div
    ref="propertyName"
    class="flex min-h-7 flex-col items-start gap-2 px-2 sm:flex-row"
    :data-property-id="property.id"
  >
    <div class="flex h-7 w-5 shrink-0 flex-row items-center justify-center">
      <IconSprite
        :icon="TYPE_ICON[property.type]"
        :class="
          isModelOutput
            ? 'fill-background-stages-model text-background-stages-model'
            : 'fill-icon-subtlest text-icon-subtlest'
        "
      />
    </div>
    <div
      :id="labelId"
      class="min-h-7"
      :title="property.name"
    >
      <span class="my-1.5 line-clamp-2 text-sm-12px-default text-text-subtle">
        {{ property.name }}
      </span>
    </div>
  </div>

  <div
    ref="fieldValue"
    class="flex min-h-7 min-w-0 grow flex-row items-center"
  >
    <component
      :is="getSidebarComponent(field)"
      :field="field"
      :property="property"
      :aria-labelledby="labelId"
      @submit="$emit('submit', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @show-source="$emit('show-source', $event)"
      @file:preview="$emit('file:preview', $event)"
    />
  </div>
  <div class="flex h-7 w-3 shrink-0 flex-row items-center justify-center">
    <DotIndicator
      v-if="showDotIndicator"
      class="-mr-2"
      :field="field"
      :staleness="staleness"
      size="xs"
      variant="transparent"
    />
  </div>
</template>
