<script setup lang="ts">
import LibraryDocument from '@/modules/Library/LibraryDocument.vue'
import { useLibraryStore } from '@/modules/Library/libraryStore'
import { useLibraryDocument } from '@/modules/Library/useLibraryDocument'
import { useUploadLibraryFile } from '@/modules/Library/useUploadLibraryFile'
import { useSearchField } from '@/modules/Workspaces/KnowledgeHub/Common/useSearchField'
import FileFilterMenu from '@/modules/Workspaces/KnowledgeHub/Files/FileFilterMenu.vue'
import PageTitle from '@/modules/Workspaces/KnowledgeHub/Layout/PageTitle.vue'
import PageTitleBar from '@/modules/Workspaces/KnowledgeHub/Layout/PageTitleBar.vue'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import { computed, useTemplateRef, watch } from 'vue'

const emit = defineEmits<{
  searchTerm: [term: string]
}>()

const libraryStore = useLibraryStore()
const workspace = useCurrentWorkspace()
const searchInput = useTemplateRef('searchInput')
const { onClickUpload } = useUploadLibraryFile()

const { onClickCreateDocument, isEditingLibraryDocument, onSaveDocument, activeItem, onClose } =
  useLibraryDocument()

const {
  isActive: isSearchActive,
  query,
  eventHandlers,
} = useSearchField({
  focusFn: () => searchInput.value?.focus(),
})

const fileCount = computed(() => libraryStore.libraryItems.length)

watch(query, (term) => emit('searchTerm', term))

function onNewDocumentSave({ content, title }: { content: string; title: string }) {
  onSaveDocument({ content, title, workspaceId: workspace.value.id })
}
</script>

<template>
  <PageTitleBar>
    <template #title>
      <div v-if="!isSearchActive">
        <PageTitle :count="fileCount">Library</PageTitle>
      </div>
      <div
        v-else
        class="flex w-full items-center border-b border-border-input"
      >
        <input
          ref="searchInput"
          v-model="query"
          type="text"
          placeholder="Search for a file…"
          spellcheck="false"
          class="w-full bg-background-transparent text-display-sm-24px-default outline-none"
          v-on="eventHandlers"
        />
        <IconButton
          icon="close"
          size="lg"
          wide
          rounded
          variant="outline"
          @click="isSearchActive = false"
        />
      </div>
    </template>
    <template #actions>
      <div
        v-if="!isSearchActive"
        class="flex gap-2"
      >
        <FileFilterMenu v-if="fileCount > 0" />

        <IconButton
          v-if="fileCount > 0"
          icon="search"
          size="lg"
          rounded
          variant="outline"
          wide
          aria-label="Search"
          @click="isSearchActive = true"
        />

        <Menu.Root
          v-slot="{ getTriggerProps }"
          :positioning="{ placement: 'bottom-end', offset: { mainAxis: 4 } }"
          close-on-select
        >
          <IconButton
            icon="plus"
            size="lg"
            rounded
            wide
            variant="black"
            v-bind="getTriggerProps()"
            aria-label="Add file or note"
          />
          <Menu.Content class="min-w-32">
            <Menu.Item
              icon="upload"
              label="Upload file"
              @click="onClickUpload"
            />
            <Menu.Divider />
            <Menu.Item
              icon="plus"
              label="Create note"
              @click="onClickCreateDocument"
            />
          </Menu.Content>
        </Menu.Root>
      </div>
      <LibraryDocument
        :aria-label="activeItem ? `Edit ${activeItem.name}` : 'Create new library document'"
        :open="isEditingLibraryDocument"
        :initial-title="activeItem?.name"
        :initial-content="activeItem?.content"
        @close="onClose"
        @save="onNewDocumentSave"
      />
    </template>
  </PageTitleBar>
</template>
