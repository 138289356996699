import { put } from './client'
import type { BackendMeta } from './types'

export type EditCaseQuery = BackendMeta<
  '/api/workspaces/{workspace_id}/cases/{case_id}/queries/{message_id}',
  'put'
>

export type EditCaseQueryParams = {
  workspaceId: string
  caseId: string
  messageId: string
  text: string
  agentId: string | null
  fileIds: string[]
}

export const editCaseQuery = async ({
  agentId,
  caseId,
  fileIds,
  messageId,
  text,
  workspaceId,
}: EditCaseQueryParams) =>
  put<EditCaseQuery['body'], EditCaseQuery['successResponse'], EditCaseQuery['path']>(
    `/api/workspaces/${workspaceId}/cases/${caseId}/queries/${messageId}`,
    {
      agent_id: agentId,
      file_ids: fileIds,
      text,
    },
  )
