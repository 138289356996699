<script setup lang="ts">
import { cloneProject } from '@/backend/cloneProject'
import { updateProject } from '@/backend/updateProject'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import { useProjectUpdateModal } from '@/modules/Projects/ProjectUpdateModal.vue'
import type { Project } from '@/modules/Projects/useProjects'
import { useProjects } from '@/modules/Projects/useProjects'
import { copyToClipboard } from '@/shared/clipboard'
import { toast } from '@/shared/toast'
import { omit } from '@/shared/utils'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import IconButton, { type IconButtonSize } from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed, ref } from 'vue'
import { RouterLink, useRouter } from 'vue-router'
import DeleteProjectDialog from './DeleteProjectDialog.vue'
import RenameProjectDialog from './RenameProjectDialog.vue'

const props = withDefaults(
  defineProps<{
    project: Project
    size?: IconButtonSize
  }>(),
  {
    size: 'xs',
  },
)

defineOptions({
  inheritAttrs: false,
})

const projectUpdateModal = useProjectUpdateModal()
const projectsStore = useProjects()
const permissionsStore = usePermissionsStore()
const { currentRoute, replace, push } = useRouter()

const isDeleting = ref(false)
const isRenaming = ref(false)
const newProjectName = ref('')
const menuRef = ref()

const canDelete = computed(() => {
  return !!permissionsStore.allProjectPermissions[props.project.id]?.delete_projects
})
const canUpdate = computed(() => {
  return !!permissionsStore.allProjectPermissions[props.project.id]?.update_projects
})

const onDeleteProject = () => {
  isDeleting.value = false
  // Handle the case where the user has deleted the project that
  // they are currently viewing
  if (currentRoute.value.params.projectId === props.project.id) {
    replace({ name: 'WorkspaceProjects', params: { workspaceId: props.project.workspaceId } })
  }
}

const handleRename = async (value: string) => {
  if (value.trim() === '') return

  const result = await updateProject(props.project.workspaceId, props.project.id, {
    name: value.trim(),
  })

  if (result.ok) {
    projectsStore.updateProject(props.project.id, { name: result.data.name })
  } else {
    toast.error('Failed to rename project. Please try again later.')
  }

  isRenaming.value = false
}

const onCloneProject = async () => {
  if (!props.project) {
    return
  }

  const yymmddhhmm = new Date().toLocaleString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })

  const clonedProjectName = `${props.project.name} (${yymmddhhmm})`

  // Show loading toast
  const loadingToast = toast.loading(`Cloning ${props.project.name}...`)

  const response = await cloneProject({
    name: clonedProjectName,
    workspaceId: props.project.workspaceId,
    templateId: props.project.id,
    cloneEntities: false,
  })

  toast.dismiss(loadingToast)

  if (response.ok) {
    push({
      name: 'WorkspaceProject',
      params: { workspaceId: props.project.workspaceId, projectId: response.data.id },
    })
  } else {
    toast.error('Failed to clone project. Please try again later.')
  }
}
</script>

<template>
  <Menu.Root
    ref="menuRef"
    v-slot="{ getTriggerProps }"
    close-on-select
  >
    <IconButton
      icon="more-dots"
      data-test="project-menu-trigger"
      :size="size"
      variant="transparent"
      v-bind="{ ...omit(getTriggerProps(), ['onSelect', 'disabled']), ...$attrs }"
      @click.prevent
    >
      Click me to open project menu
    </IconButton>
    <Menu.Content
      class="min-w-56"
      side="top"
      align="end"
      :side-offset="5"
      :align-offset="0"
    >
      <div class="flex items-center gap-2 py-1 pl-2 pr-1.5">
        <AvatarIcon
          :name="project.name"
          :url="project.coverImageDownloadError ? null : project.coverImageUrls.high"
          size="md"
          shape="circle"
        />
        <div class="flex max-w-[240px] flex-col items-start justify-start">
          <div class="w-full truncate text-left text-sm-12px-default text-text">
            {{ project.name || 'Untitled project' }}
          </div>
          <div class="w-full truncate text-left text-sm-12px-light text-text-subtle">
            {{ project.description || 'No description' }}
          </div>
        </div>
      </div>
      <Menu.Divider />
      <Menu.Item
        icon="info"
        @click="projectUpdateModal.open(project.id)"
      >
        Open agent details
      </Menu.Item>
      <ToolTip
        class="w-full"
        arrow
        :placement="{ allowedPlacements: ['left'] }"
        :title="project.id"
      >
        <Menu.Item
          icon="copy"
          @click="() => copyToClipboard('Project ID', project.id)"
        >
          Copy project ID
        </Menu.Item>
      </ToolTip>
      <Menu.Divider />
      <Menu.Item
        icon="case-bubble"
        class="relative"
        @click.prevent
      >
        <RouterLink
          :to="{
            name: 'New Case',
            params: { workspaceId: props.project.workspaceId },
            query: { agentId: project.id },
          }"
          class="w-full focus-visible:outline-none"
        >
          Start a new case
        </RouterLink>
      </Menu.Item>
      <Menu.Item icon="settings-sliders">
        <RouterLink
          :to="{
            name: 'WorkspaceProject',
            params: { workspaceId: props.project.workspaceId, projectId: project.id },
          }"
          class="w-full focus-visible:outline-none"
        >
          Configure agent
        </RouterLink>
      </Menu.Item>
      <Menu.Divider v-if="canUpdate || canDelete" />
      <Menu.Item
        v-if="canUpdate"
        icon="copy"
        @select="onCloneProject"
      >
        Clone project
      </Menu.Item>
      <Menu.Item
        v-if="canDelete"
        icon="trash"
        critical
        @select="isDeleting = true"
      >
        Delete project
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>

  <DeleteProjectDialog
    :project-id="project.id"
    :workspace-id="project.workspaceId"
    :open="isDeleting"
    @delete="onDeleteProject"
    @close="isDeleting = false"
  />

  <RenameProjectDialog
    v-model:value="newProjectName"
    :open="isRenaming"
    @rename="handleRename"
    @close="isRenaming = false"
  />
</template>
