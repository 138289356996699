<script setup lang="ts">
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import type { IconName } from '@/uiKit/IconName'
import SegmentedControl from '@/uiKit/SegmentedControl.vue'
import { useElementSize } from '@vueuse/core'
import { computed, ref, watch } from 'vue'

defineProps<{
  audioSrc: string
  arrivedState: { bottom: boolean }
}>()

const audioElement = ref<HTMLAudioElement | null>(null)
const progressBarRef = ref<HTMLDivElement | null>(null)

const isPlaying = ref(false)
const currentTime = ref(0)
const duration = ref(0)
const volume = ref(1)
const isDragging = ref(false)

const progress = computed(() => (currentTime.value / duration.value) * 100)

watch(progress, (newProgress) => {
  if (newProgress === 100) {
    isPlaying.value = false
  }
})

const togglePlay = () => {
  if (audioElement.value) {
    if (isPlaying.value) {
      audioElement.value.pause()
    } else {
      audioElement.value.play()
    }
    isPlaying.value = !isPlaying.value
  }
}

const onTimeUpdate = () => {
  if (audioElement.value && !isDragging.value) {
    currentTime.value = audioElement.value.currentTime
  }
}

const onLoadedMetadata = () => {
  if (audioElement.value) {
    duration.value = audioElement.value.duration
  }
}

const startDrag = (event: MouseEvent) => {
  isDragging.value = true
  document.addEventListener('mousemove', onDrag)
  document.addEventListener('mouseup', stopDrag)
  onDrag(event)
}

const onDrag = (event: MouseEvent) => {
  if (progressBarRef.value) {
    const rect = progressBarRef.value.getBoundingClientRect()
    const percent = Math.max(0, Math.min(1, (event.clientX - rect.left) / rect.width))
    currentTime.value = percent * duration.value
  }
}

const stopDrag = () => {
  if (audioElement.value) {
    audioElement.value.currentTime = currentTime.value
  }
  isDragging.value = false
  document.removeEventListener('mousemove', onDrag)
  document.removeEventListener('mouseup', stopDrag)
}

const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
}

const oldVolume = ref(0)
const tempVolume = ref(0)
const isSettingVolume = ref(false)

watch(volume, (newVolume) => {
  if (audioElement.value) {
    audioElement.value.volume = newVolume
  }
})

const volumeIcon = computed((): IconName => {
  if (volume.value < 0.01) {
    return 'mute-fill'
  } else if (volume.value < 0.25) {
    return 'volume-low-fill'
  } else if (volume.value < 0.75) {
    return 'volume-medium-fill'
  } else {
    return 'volume-high-fill'
  }
})

const isSettingPlaybackSpeed = ref(false)
const playbackSpeed = ref(1)

watch(playbackSpeed, (newPlaybackSpeed) => {
  if (audioElement.value) {
    audioElement.value.playbackRate = newPlaybackSpeed
  }
})

const playbackContainer = ref<HTMLDivElement | null>(null)
const { width: playbackContainerWidth } = useElementSize(playbackContainer)
</script>

<template>
  <div class="flex w-full p-1 pt-0">
    <audio
      ref="audioElement"
      :src="audioSrc"
      @timeupdate="onTimeUpdate"
      @loadedmetadata="onLoadedMetadata"
    ></audio>
    <div
      v-if="!isSettingVolume && !isSettingPlaybackSpeed"
      class="flex w-full items-center gap-1 rounded-corner-8 bg-background-gray-subtlest p-1"
      :class="arrivedState.bottom ? '' : 'shadow-arrived-b'"
    >
      <div>
        <IconButton
          :icon="isPlaying ? 'pause-fill' : 'play-fill'"
          size="md"
          variant="transparent"
          @click="togglePlay"
        />
      </div>
      <div class="p-1 text-xs-11px-default text-text-subtle">
        {{ formatTime(currentTime) }}
      </div>
      <div
        ref="progressBarRef"
        class="relative flex size-full cursor-pointer items-center gap-0.5"
        @mousedown="startDrag"
      >
        <div
          class="relative h-0.5 rounded-corner-4 bg-background-gray-subtle"
          :style="{ width: `${progress}%` }"
        />
        <div
          class="relative h-3 w-0.5 cursor-grab rounded-corner-4 bg-text-warning"
          :class="{ 'cursor-grabbing': isDragging }"
        />
        <div
          class="relative h-0.5 rounded-corner-4 bg-background-gray-subtle"
          :style="{ width: `${100 - progress}%` }"
        />
      </div>
      <div class="p-1 text-xs-11px-default text-text-subtle">
        {{ formatTime(duration) }}
      </div>
      <div>
        <IconButton
          icon="playback-fill"
          size="md"
          variant="transparent"
          @click="isSettingPlaybackSpeed = true"
        />
      </div>
      <div>
        <IconButton
          :icon="volumeIcon"
          size="md"
          variant="transparent"
          @click="isSettingVolume = true"
        />
      </div>
    </div>
    <div
      v-else-if="isSettingPlaybackSpeed"
      class="flex w-full items-center rounded-corner-8 bg-background-gray-subtlest"
    >
      <div
        ref="playbackContainer"
        class="flex w-full"
      >
        <SegmentedControl
          :items="[
            { label: '0.75x', value: '0.75', width: playbackContainerWidth / 5 - 2.5 },
            { label: '1x', value: '1', width: playbackContainerWidth / 5 - 2.5 },
            { label: '1.2x', value: '1.2', width: playbackContainerWidth / 5 - 2.5 },
            { label: '1.5x', value: '1.5', width: playbackContainerWidth / 5 - 2.5 },
            { label: '2x', value: '2', width: playbackContainerWidth / 5 - 2.5 },
          ]"
          transparent
          name="playback-speed"
          :value="`${playbackSpeed}`"
          @change="(event: string | null) => (playbackSpeed = parseFloat(event || ''))"
          @click.stop
        />
      </div>
      <DividerLine
        color="subtle"
        direction="vertical"
      />
      <div class="p-1">
        <IconButton
          icon="close"
          size="md"
          variant="transparent"
          @click="isSettingPlaybackSpeed = false"
        />
      </div>
    </div>
    <div
      v-else-if="isSettingVolume"
      class="flex w-full items-center rounded-corner-8 bg-background-gray-subtlest"
    >
      <div class="flex w-full items-center gap-1 p-1 pr-3">
        <div>
          <IconButton
            :icon="volumeIcon"
            size="md"
            variant="transparent"
            @click="((tempVolume = volume), (volume = oldVolume), (oldVolume = tempVolume))"
          />
        </div>
        <!-- Volume slider -->
        <div class="relative h-2 w-full">
          <input
            v-model="volume"
            class="absolute z-1 w-full cursor-pointer appearance-none rounded-full bg-background-gray-subtlest [&::-webkit-slider-runnable-track]:h-2 [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-thumb]:size-4 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:hover:cursor-ew-resize"
            type="range"
            min="0"
            max="1"
            step="0.01"
          />
          <div class="absolute flex h-2 w-full translate-y-[-1/2] rounded-full p-0.5">
            <div
              class="h-full rounded-full bg-background-black"
              :style="{ width: `${volume * 100}%` }"
            ></div>
          </div>
        </div>
      </div>
      <DividerLine
        color="subtle"
        direction="vertical"
      />
      <div class="p-1">
        <IconButton
          icon="close"
          size="md"
          variant="transparent"
          @click="isSettingVolume = false"
        />
      </div>
    </div>
  </div>
</template>
