import { listFilteredEntities } from '@/backend/listFilteredEntities'
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { useSetFieldValue } from '@/shared/useSetFieldValue'
import { pluralize } from '@/shared/utils/string'
import { assertIsNotNullOrUndefined, invariant } from '@/shared/utils/typeAssertions'
import { usePaginatedLoader } from '@/sharedComposables/usePaginatedLoader'
import { useTooltip } from '@/sharedComposables/useTooltip'
import type { AutoPlacementOptions, OffsetOptions } from '@floating-ui/vue'
import { useDebounce, useThrottleFn, watchDebounced } from '@vueuse/core'
import { computed, ref, watch, type Ref } from 'vue'
import { useBilling } from '../Billing/useBilling'
import { ENTITY_NAME_FALLBACK } from './constants'
import { isFilterableProperty, type SimpleFilter } from './Filters/types'
import { getSimpleFilterForProperty } from './Filters/utils'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import type { FieldPreview } from './types/fieldPreview'
import { useFieldPreviews } from './useFieldPreviews'
import { serializeEntity, useProject, type Entity } from './useProject'
import { useSerializeFieldToText } from './useSerializeFieldToText'

export type FullOrPreviewEntity = Entity | { id: string; fields: Map<Property['id'], FieldPreview> }

export type ReferenceEntityListItem = {
  id: string
  data: {
    name: string
    group: 'selected' | 'unselected'
  } & Entity
}

/**
 * This is a god composable for reference field. It handles all the logic
 * for loading and toggling entities in the field's list of entities (both
 * matched and unmatched).
 *
 * It is used in both the project table and entity view.
 */
export const useReferenceField = ({
  field,
  property,
}: {
  field: Ref<Field<'reference'>>
  property: Ref<Property<'reference'>>
}) => {
  const relatedProjectStore = useRelatedProjectsStore()
  const relatedProjectId = computed(() => property.value.config.projectId)
  const relatedProject = computed(() => relatedProjectStore.getRelatedProject(property.value.id))

  const currentProjectStore = useProject()
  const currentWorkspace = useCurrentWorkspace()

  /**
   * All entities to render as badges in the cell, including those that are in an
   * optimistic state.
   */
  const previewEntities = ref<FullOrPreviewEntity[]>([])

  /** From the backend - all selected entity IDs */
  const savedEntityIds = computed(
    () => field.value.manualEntityIds || field.value.toolEntityIds || [],
  )

  /**
   * IDs of all entities that should be shown as selected in the UI. Not all of these
   * may be saved on the backend yet.
   */
  const localEntityIds = ref<string[]>([])
  watch(
    savedEntityIds,
    (newIds) => {
      localEntityIds.value = [...newIds]
    },
    { immediate: true },
  )

  /** When true, we should show a loading skeleton in the list of related project entities */
  const showSkeletonList = computed(() => {
    const isLoadingData =
      selectedEntityLoader.status.value === 'loading' ||
      unselectedEntityLoader.status.value === 'loading'

    return isLoadingData && allLoadedEntities.value.length === 0
  })

  /** Skeleton items to render while loading */
  const skeletonItems = ref<ReferenceEntityListItem[]>([])

  /** Search term to filter entities in the dropdown by */
  const searchText = ref('')
  watchDebounced(
    searchText,
    () => {
      // Before resetting the list, set the skeleton items to the current list value so that
      // the skeleton lits takes up the same space as the reguler list in its pre-search state.
      skeletonItems.value = listItems.value
      resetAllData()
      loadMoreEntities()
    },
    { debounce: 300 },
  )

  /** Property used to get an entity's name */
  const nameProperty = computed(() => relatedProjectStore.getNameProperty(property.value.id))

  /**
   * Build a filter based on the text the user has entered in the
   * search box. This filter object is sent to the backend so that
   * the menu is populated with entities matching the search term.
   */
  const fieldFilter = computed(() =>
    nameProperty.value && isFilterableProperty(nameProperty.value) && searchText.value
      ? getSimpleFilterForProperty(nameProperty.value, searchText.value)
      : undefined,
  )

  /**
   * Utilities to load more selected entities from the related
   * project.
   *
   * We have separate loaders for selected and unselected entities
   * because we have no way sort by selected/unselected status
   * using the API
   */
  const selectedEntityLoader = usePaginatedLoader(
    ({ after, first }) => {
      assertIsNotNullOrUndefined(
        relatedProjectId.value,
        'Tried to load entities for unconfigured reference property',
      )

      if (savedEntityIds.value.length === 0) {
        /**
         * If there are no selected entities, we don't need to
         * make a request because and can simulate a response that
         * indicates there is no data to return and no next page.
         */
        return Promise.resolve({
          data: {
            data: [],
            metadata: {
              current_offset: null,
              end_cursor: null,
              has_next_page: false,
              total_count: 0,
              has_previous_page: false,
              page_size: 0,
              start_cursor: null,
            },
          },
          ok: true,
        })
      }

      const filters: SimpleFilter[] = [
        {
          subject: 'entity_id',
          matcher: {
            name: 'any_of',
            values: localEntityIds.value,
          },
        },
      ]

      if (fieldFilter.value) {
        filters.push(fieldFilter.value)
      }

      return listFilteredEntities({
        filter: {
          conjunction: 'and',
          filters,
        },
        projectId: relatedProjectId.value,
        workspaceId: currentWorkspace.value.id,
        after,
        first,
      })
    },
    {
      limit: 20,
      serializer: serializeEntity,
    },
  )

  /**
   * Utilities to load more unselected entities from the related
   * project.
   *
   * We have separate loaders for selected and unselected entities
   * because we have no way sort by selected/unselected status
   * using the API
   */
  const unselectedEntityLoader = usePaginatedLoader(
    ({ after, first }) => {
      assertIsNotNullOrUndefined(
        relatedProjectId.value,
        'Tried to load entities for unconfigured reference property',
      )

      const entityIdFilter: SimpleFilter | undefined = previewEntities.value?.length
        ? {
            subject: 'entity_id',
            matcher: {
              name: 'none_of',
              values: localEntityIds.value,
            },
          }
        : undefined

      const simpleFilters = [fieldFilter.value, entityIdFilter].filter((f) => !!f)

      return listFilteredEntities({
        filter:
          simpleFilters.length > 0 ? { conjunction: 'and', filters: simpleFilters } : undefined,
        projectId: relatedProjectId.value,
        workspaceId: currentWorkspace.value.id,
        after,
        first,
      })
    },
    {
      limit: 20,
      serializer: serializeEntity,
    },
  )

  const allLoadedEntities = computed(() => [
    ...selectedEntityLoader.data.value,
    ...unselectedEntityLoader.data.value,
  ])

  /** Clear all loaded selected/unselected entities */
  const resetAllData = () => {
    selectedEntityLoader.reset()
    unselectedEntityLoader.reset()
  }

  /**
   * Load more entities into the list. Selected entities will be
   * loaded first, then unselected entities.
   */
  const loadMoreEntities = async () => {
    if (!property.value.config.projectId) {
      // Not necessarily an error, the related project might have been
      // deleted
      return
    }

    if (selectedEntityLoader.canLoadMore.value) {
      await selectedEntityLoader.loadMore()
    }

    if (!selectedEntityLoader.canLoadMore.value && unselectedEntityLoader.canLoadMore.value) {
      await unselectedEntityLoader.loadMore()
    }
  }

  const ENTITY_AUTO_LOAD_ON_HOVER_DELAY = 500
  const throttledLoadMoreEntities = useThrottleFn(
    loadMoreEntities,
    ENTITY_AUTO_LOAD_ON_HOVER_DELAY,
    true,
    false,
  )

  /**
   * The previews from the backend don't include property types so we need to call this
   * composable to augment the preview fields with property types.
   */
  const fieldPreviews = useFieldPreviews(
    computed(() => field.value.subprojectPreview),
    computed(() => relatedProjectStore.getPropertiesForReference(property.value.id)),
  )

  const setFieldValue = useSetFieldValue()
  const updateFieldValue = () => {
    const noChanges =
      savedEntityIds.value.length === localEntityIds.value.length &&
      previewEntities.value.every((e) => savedEntityIds.value?.includes(e.id))
    if (noChanges) {
      return
    }

    invariant(currentProjectStore.projectId)

    setFieldValue({
      entityId: field.value.entityId,
      projectId: currentProjectStore.projectId,
      fields: {
        [property.value.id]: {
          field: field.value,
          newValue: localEntityIds.value.length
            ? {
                conjunction: 'and',
                filters: [
                  {
                    subject: 'entity_id',
                    matcher: {
                      name: 'any_of',
                      values: localEntityIds.value,
                    },
                  },
                ],
              }
            : null,
        },
      },
      workspaceId: currentWorkspace.value.id,
    })
  }

  const serializeFieldToText = useSerializeFieldToText()
  /**
   * Returns the name of the entity to display in the badge and at the top of
   * the preview popover.
   */
  const getRelatedEntityName = (entity: FullOrPreviewEntity): string => {
    if (!nameProperty.value) {
      return ENTITY_NAME_FALLBACK
    }

    const nameField = 'fields' in entity && entity.fields.get(nameProperty.value.id)
    if (nameField) {
      return serializeFieldToText(nameField) || ENTITY_NAME_FALLBACK
    }

    return ENTITY_NAME_FALLBACK
  }

  const getFullyLoadedEntity = (entityId: string) =>
    allLoadedEntities.value.find((entity) => entity.id === entityId)

  const entityPreviewPopover = useTooltip()

  const previewAnchor = ref<{
    el: HTMLElement
    entityId: string
    position: 'badge' | 'listitem'
  } | null>(null)

  // Entity List Item that is currently hovered
  const previewEntity = computed<{
    entity: FullOrPreviewEntity
    anchor: HTMLElement
    position: 'badge' | 'listitem'
  } | null>(() => {
    if (!previewAnchor.value) {
      return null
    }

    const { entityId } = previewAnchor.value

    const entity =
      getFullyLoadedEntity(entityId) || previewEntities.value.find((e) => e.id === entityId)
    if (!entity) {
      return null
    }

    return {
      entity,
      anchor: previewAnchor.value.el,
      position: previewAnchor.value.position,
    }
  })

  const debouncedPreviewEntity = useDebounce(previewEntity, entityPreviewPopover.debounceMs)

  /** Returns true if and only if there is an related entity with the given id */
  const entityIsSelected = (id: string) => localEntityIds.value.some((entityId) => entityId === id)

  const setPreviewAnchor = (
    el: Event['currentTarget'],
    entityId: string,
    position: 'badge' | 'listitem',
  ) => {
    if (el && el instanceof HTMLElement) {
      if (!getFullyLoadedEntity(entityId)) {
        throttledLoadMoreEntities()
      }
      previewAnchor.value = { el, entityId, position }
    }
  }

  // Trigger the preview dialog when the user hovers over a list item
  const onPreviewAnchorMouseover = async (
    el: Event['currentTarget'],
    entityId: string,
    position: 'badge' | 'listitem',
  ) => {
    setPreviewAnchor(el, entityId, position)
    entityPreviewPopover.trigger.onMouseOver()
  }

  // Remove the preview dialog when the user stops hovering over a list item
  const onPreviewAnchorMouseleave = () => {
    previewAnchor.value = null
    entityPreviewPopover.trigger.onMouseLeave()
  }

  const onPreviewAnchorClick = async (
    el: Event['currentTarget'],
    entityId: string,
    position: 'badge' | 'listitem',
  ) => {
    setPreviewAnchor(el, entityId, position)
    entityPreviewPopover.trigger.onClick()
  }

  /**
   * Toggles the entity in the list of selected entities.
   */
  const onToggleEntity = async (entity: ReferenceEntityListItem) => {
    /**
     * Trigger this event so that the preview dialog disappears. Without this,
     * the dialog loses its anchor and moves to the top left of the viewport.
     */

    if (entityIsSelected(entity.id)) {
      previewEntities.value = previewEntities.value.filter((e) => e.id !== entity.id)
      localEntityIds.value = localEntityIds.value.filter((id) => id !== entity.id)
    } else {
      previewEntities.value.push(entity.data)
      localEntityIds.value.push(entity.id)
    }
  }

  const listItems = computed<ReferenceEntityListItem[]>(() =>
    allLoadedEntities.value.map((entity) => {
      const nameField = nameProperty.value && entity.fields.get(nameProperty.value.id)
      const name = nameField ? serializeFieldToText(nameField) : ''
      return {
        id: entity.id,
        data: {
          ...entity,
          name,
          group: entityIsSelected(entity.id) ? 'selected' : 'unselected',
        },
      }
    }),
  )

  // Sync the selected entities when the backend value is updated
  watch(
    () => [savedEntityIds.value, fieldPreviews.value] as const,
    ([newIds, newPreviews]) => {
      // Build a map of entityId->preview so that the lookup is O(1)
      const previewMap = Object.fromEntries(previewEntities.value.map((e) => [e.id, e]))

      previewEntities.value = newIds.reduce<FullOrPreviewEntity[]>((acc, entityId) => {
        const fullEntity = allLoadedEntities.value.find((e) => e.id === entityId)
        if (fullEntity) {
          return [...acc, fullEntity]
        }

        const previewFieldMap = newPreviews[entityId]
        if (previewFieldMap) {
          return [
            ...acc,
            {
              id: entityId,
              fields: previewFieldMap,
            },
          ]
        }

        const previousPreview = previewMap[entityId]
        if (previousPreview) {
          return [...acc, previousPreview]
        }

        return acc
      }, [])
    },
    { immediate: true },
  )

  const entityLimit = computed(() => property.value.config.entityLimit)
  const hasReachedEntityLimit = computed(() => localEntityIds.value.length >= entityLimit.value)

  const noMatchFound = computed(
    () =>
      !!field.value.toolValue &&
      field.value.toolEntityIds?.length === 0 &&
      previewEntities.value.length === 0,
  )

  const BADGE_RENDER_LIMIT = 10
  const unrenderedCount = computed(() =>
    Math.max(0, savedEntityIds.value.length - BADGE_RENDER_LIMIT),
  )

  /** The entities that will be rendered as badges */
  const badgeEntities = computed(() => previewEntities.value.slice(0, BADGE_RENDER_LIMIT))

  const billingStore = useBilling()
  const numberFormatter = new Intl.NumberFormat()
  /** Label to render at the bottom of the reference entity popup menu */
  const entityCountLabel = computed(() => {
    if (!relatedProject.value) {
      return ''
    }

    const entityCount =
      billingStore.projectLimits[relatedProject.value.id]?.entity_count?.total?.limitUsage
    if (entityCount === undefined) {
      return '0 entities'
    }

    return `${numberFormatter.format(entityCount)} ${pluralize(entityCount, 'entity', 'entities')}`
  })

  const isElementScrolling = (el?: HTMLElement) =>
    Boolean(el?.scrollHeight && el.scrollHeight > el.clientHeight)

  /**
   * Information on the entity that is currently being previewed and its
   * anchor element (badge or listitem).
   */
  const getPreviewMeta = (
    listRef?: HTMLElement,
  ): {
    entity: FullOrPreviewEntity
    anchor: HTMLElement
    mouseover: () => void
    placement: AutoPlacementOptions
    offset?: OffsetOptions
  } | null => {
    if (!debouncedPreviewEntity.value) return null
    const { anchor, entity, position } = debouncedPreviewEntity.value

    const mouseover = () => onPreviewAnchorMouseover(anchor, entity.id, position)

    return {
      entity,
      anchor,
      mouseover,
      placement: {
        allowedPlacements:
          position === 'listitem'
            ? ['right-start', 'left-start', 'right', 'left', 'right-end', 'left-end']
            : ['bottom-start', 'top-start', 'bottom-end', 'top-end'],
      },
      /**
       * The preview is anchored either to a badge, or to a list item
       * When anchored to a list item, the prefered position is to the right of it.
       * We need to adjust the offset in that case, if the list container is scrolling,
       * as it will be placed above the scrollbar otherwise.
       */
      offset:
        position === 'listitem'
          ? (state) => ({
              mainAxis: isElementScrolling(listRef) && state.placement.startsWith('right') ? 16 : 5,
              alignmentAxis: -2,
            })
          : undefined,
    }
  }

  return {
    localEntityIds,
    searchText,
    resetAllData,
    loadMoreEntities,
    getRelatedEntityName,
    updateFieldValue,
    previewEntities,
    onToggleEntity,
    getPreviewMeta,
    onPreviewAnchorClick,
    onPreviewAnchorMouseleave,
    onPreviewAnchorMouseover,
    nameProperty,
    listItems,
    entityPreviewPopover,
    relatedProject,
    entityLimit,
    hasReachedEntityLimit,
    savedEntityIds,
    allLoadedEntities,
    showSkeletonList,
    skeletonItems,
    noMatchFound,
    unrenderedCount,
    badgeEntities,
    entityCountLabel,
  }
}
