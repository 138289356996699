<script setup lang="ts">
import type { ResourceRole } from '@/backend/types'
import { updateProject } from '@/backend/updateProject'
import { useBilling } from '@/modules/Billing/useBilling'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import { useAllowedRoles } from '@/modules/IdentityAndAccess/useAllowedRoles'
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import { useProjectCover } from '@/modules/Project/useProjectCover'
import ProjectCoverMenu from '@/modules/Projects/ProjectCoverMenu.vue'
import { useProjects } from '@/modules/Projects/useProjects'
import {
  useWorkspaceMembers,
  type Invitation,
  type WorkspaceMember,
} from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { toast } from '@/shared/toast'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import AutosizedTextarea from '@/sharedComponents/AutosizedTextarea.vue'
import ScrollGradient from '@/sharedComponents/ScrollGradient.vue'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import { computed, nextTick, ref, useTemplateRef, watch } from 'vue'
import PermissionsDialogInviteForm from './PermissionsDialogInviteForm.vue'
import PermissionsDialogProjectMember from './PermissionsDialogProjectMember.vue'
import ProjectDefaultPermission, { type ProjectDefaultRole } from './ProjectDefaultPermission.vue'
import SeatLimitBanner from './SeatLimitBanner.vue'
import { PERMISSIONS_DIALOG_ID } from './consts'
import { useProjectPermissionsStore } from './projectPermissionsStore'
import type { ProjectMemberRole } from './types'
const props = defineProps<{
  workspaceId: string
  open: boolean
  rootProjectId: string
}>()

const emit = defineEmits<{ (e: 'close'): void }>()

/** For a11y - HTML ID for the dialog's label */
const DIALOG_LABEL_ID = 'permissions-dialog-label'

const projectsStore = useProjects()
const rootProject = computed(() => projectsStore.getProjectById(props.rootProjectId))

const url = computed(
  () => `${window.location.host}/${props.workspaceId}/projects/${props.rootProjectId}`,
)

/**
 * Text to render on the copy link button. The text changes to 'Copied' for a short
 * time after the link is copied to the clipboard.
 */
const copyButtonText = ref<'Copy link' | 'Copied'>('Copy link')

/** Copy a link to the current project to the clipboard */
const onCopyLink = ({ showToast }: { showToast: boolean }) => {
  navigator.clipboard.writeText(`${window.location.protocol}//${url.value}`)

  if (showToast) {
    toast.success('Link copied to clipboard')
  }

  copyButtonText.value = 'Copied'
  setTimeout(() => {
    copyButtonText.value = 'Copy link'
  }, 2000)
}

const workspaceMembersStore = useWorkspaceMembers()

const projectPermissionsStore = useProjectPermissionsStore()
const onInviteUsers = async ({
  existingUsers,
  role,
  newUserEmails,
}: {
  existingUsers: WorkspaceMember[]
  role: ProjectMemberRole
  newUserEmails: string[]
}) => {
  try {
    const [, projectInvitations] = await Promise.all([
      await Promise.all(
        existingUsers.map((user) =>
          projectPermissionsStore.setProjectRole({
            projectId: props.rootProjectId,
            role,
            userId: user.id,
            workspaceId: props.workspaceId,
          }),
        ),
      ),
      projectPermissionsStore.sendProjectInvitations({
        projectId: props.rootProjectId,
        workspaceId: props.workspaceId,
        emails: newUserEmails,
        role,
      }),
    ])

    if (projectInvitations.length > 0) {
      // If we've invited new users, then add the invitations to the
      // list of workspace invitations
      const newWorkspaceInvitations: Invitation[] = projectInvitations.map((invite) => ({
        id: 'id' in invite ? invite.id : undefined,
        email: invite.email,
        role: 'editor',
        status: 'pending',
        workspaceId: props.workspaceId,
        inviteUrl: 'invite_url' in invite ? invite.invite_url : undefined,
      }))

      workspaceMembersStore.invitations = [
        ...workspaceMembersStore.invitations,
        ...newWorkspaceInvitations,
      ]
    }
  } catch {
    toast.error('Failed to invite users')
    throw new Error('Failed to invite users')
  }
}

const userStore = useUser()

type ProjectMember = WorkspaceMember & {
  projectRole: ResourceRole
  isCurrentUser: boolean
  type: 'workspaceMember'
}

const projectMembers = computed<ProjectMember[]>(() =>
  Object.entries(projectPermissionsStore.workspaceMemberRoles)
    .map(([userId, role]) => {
      const workspaceMember = workspaceMembersStore.workspaceMembers.find((m) => m.id === userId)
      // Ideally, all project members would also be workspace members. But there is a BE bug where
      // project members aren't deleted when a workspace member is deleted, so we could get
      // orphaned project members. This check is to handle that case - we filter them out
      // on the FE.
      // see https://vseven.slack.com/archives/C06UK9537B8/p1725526371109919
      if (!workspaceMember) {
        return null
      }

      return {
        ...workspaceMember,
        projectRole: role,
        isCurrentUser: userId === userStore.user?.id,
        type: 'workspaceMember' as const,
      }
    })
    .filter((member): member is ProjectMember => !!member?.projectRole),
)

const workspaceMembers = computed(() =>
  workspaceMembersStore.workspaceMembers.map((member) => ({
    member,
    projectRole: projectPermissionsStore.workspaceMemberRoles[member.id],
  })),
)

const onChangeRole = (userId: string, role: ResourceRole | null) => {
  if (role === null || role === 'editor' || role === 'reader') {
    try {
      projectPermissionsStore.setProjectRole({
        projectId: props.rootProjectId,
        userId,
        workspaceId: props.workspaceId,
        role,
      })
    } catch {
      toast.error('Failed to update role')
      throw new Error('Failed to update role')
    }
  }
}

const onDeleteInvitation = async (invitationId: string) => {
  await projectPermissionsStore.deleteProjectInvitation({
    invitationId,
    projectId: props.rootProjectId,
    workspaceId: props.workspaceId,
  })
  workspaceMembersStore.invitations = workspaceMembersStore.invitations.filter(
    (i) => i.id !== invitationId,
  )
}

const anyOneInWorkspaceProjectPermission = computed(
  () => projectPermissionsStore.defaultRole ?? 'noaccess',
)

const updateProjectDefaultRole = (role: ProjectDefaultRole) => {
  projectPermissionsStore.setProjectRole({
    userId: 'anyone_in_workspace',
    role: role === 'noaccess' ? null : role,
    workspaceId: props.workspaceId,
    projectId: props.rootProjectId,
  })
}

const inviteFormComponentRef = ref<InstanceType<typeof PermissionsDialogInviteForm> | null>(null)

const billingStore = useBilling()

const limitReached = computed(
  () =>
    (billingStore.seatUsage?.limitUsage ?? 0) +
      workspaceMembersStore.invitations.length +
      (inviteFormComponentRef.value?.newUserEmails.length ?? 0) >=
    (billingStore.seatUsage?.limitValue ?? 0),
)

const permissionsStore = usePermissionsStore()

const allowedInviteRoles = useAllowedRoles({
  permissionName: 'invite_members',
  scope: 'project',
  rolesSubset: ['reader', 'editor'],
})

const allowedUpdateRoles = useAllowedRoles({
  permissionName: 'update_members',
  rolesSubset: ['reader', 'editor'],
  scope: 'project',
})

const projects = useProjects()

const localName = ref('')
const localDescription = ref('')

watch(
  () => props.open,
  (open) => {
    if (open) {
      localName.value = rootProject.value?.name || ''
      localDescription.value = rootProject.value?.description || ''
    }
  },
  { immediate: true },
)

const hasError = ref(false)

const inFlightUpdate = ref<ReturnType<typeof updateProject>>()

const isDirty = computed(
  () =>
    localName.value !== rootProject.value?.name ||
    localDescription.value !== rootProject.value?.description,
)

const revert = async (e: Event) => {
  await inFlightUpdate.value
  localName.value = rootProject.value?.name || ''
  localDescription.value = rootProject.value?.description || ''
  e.stopPropagation()
}

const saving = computed(() => !!inFlightUpdate.value)

const save = async () => {
  if (!isDirty.value) {
    return
  }

  hasError.value = false

  const name = localName.value.trim() === '' ? null : localName.value.trim()
  const description = localDescription.value.trim() === '' ? null : localDescription.value.trim()

  if (name === null || name.length < 3) {
    hasError.value = true
    toast.error('Project name must be at least 3 characters long')
    return
  }

  if (description === null || description.length < 3) {
    hasError.value = true
    toast.error('Please describe what this project should be used for')
    return
  }

  await inFlightUpdate.value

  assertIsNotNullOrUndefined(rootProject.value)
  const project = rootProject.value

  try {
    inFlightUpdate.value = updateProject(project.workspaceId, project.id, { name, description })
    const result = await inFlightUpdate.value
    inFlightUpdate.value = undefined

    if (!result.ok) {
      hasError.value = true
      return
    }

    projects.updateProject(project.id, {
      name: result.data.name,
      description: result.data.description,
    })
  } catch {
    inFlightUpdate.value = undefined
    hasError.value = true
    return
  }
}

const saveAndClose = async () => {
  await inFlightUpdate.value
  await save()

  if (hasError.value) {
    return
  }

  emit('close')
}

const { uploadFileAsProjectCover, getRandomProjectCoverURL, fetchCoverFile } = useProjectCover()

const setRandomProjectCover = async () => {
  const url = getRandomProjectCoverURL()
  const file = await fetchCoverFile(url)
  if (file) {
    uploadFileAsProjectCover(file, props.workspaceId, props.rootProjectId)
  }
}

const descriptionContainer = useTemplateRef('descriptionContainer')
</script>

<template>
  <ModalDialog
    :id="PERMISSIONS_DIALOG_ID"
    :open="open"
    placement="right"
    class="overflow-hidden"
    role="dialog"
    :outline="false"
    :aria-labelledby="DIALOG_LABEL_ID"
    @close="saveAndClose"
  >
    <div class="h-full">
      <div
        class="relative flex h-full w-[400px] flex-col overflow-hidden rounded-corner-10 border-0 bg-surface-tertiary-persist"
      >
        <div class="relative flex size-full flex-col justify-stretch gap-2">
          <IconButton
            size="lg"
            variant="transparent"
            class="absolute right-2 top-2 text-text-subtle"
            icon="close"
            aria-label="Close"
            rounded
            role="button"
            :disabled="saving"
            @click="nextTick(saveAndClose)"
            @mousedown.prevent
          />
          <div
            class="flex w-full flex-row items-center justify-start rounded-corner-10 bg-surface-tertiary-persist px-4 pt-4"
          >
            <!-- avatar, name, description, & close -->
            <div
              class="relative rounded-full border-2 border-dashed border-background-border-default p-0.5"
            >
              <div
                v-if="rootProject?.coverImageUploading"
                class="flex size-[64px] items-center justify-center"
              >
                <CircularProgress
                  size="xs"
                  class="animate-spin"
                />
              </div>
              <AvatarIcon
                v-else
                :full-text="rootProject?.name"
                size="xl"
                shape="circle"
                :url="rootProject?.coverImageUrls.high"
                :loading-condition="rootProject?.coverImageUploading ?? false"
                :error-condition="rootProject?.coverImageDownloadError"
                alt="Project cover"
              />
              <div
                class="absolute -bottom-1.5 -right-1.5 flex items-center rounded-corner-12 bg-surface-primary p-0.5 shadow-xs"
              >
                <ProjectCoverMenu
                  :teleport-target-id="PERMISSIONS_DIALOG_ID"
                  @update-cover="
                    $event && uploadFileAsProjectCover($event, workspaceId, rootProjectId)
                  "
                  @generate-cover="setRandomProjectCover"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <!-- name and description -->
            <AutosizedTextarea
              v-model="localName"
              mode="singleline"
              placeholder="Add a name"
              class="bg-background-transparent px-4 py-1 text-display-xs-20px-default outline-none"
              aria-label="Project name"
              maxlength="200"
              @keydown.escape="isDirty && revert($event)"
              @blur="save"
              @submit="save"
            />
            <div class="relative z-0 px-2">
              <ScrollGradient
                location="top"
                :container="descriptionContainer"
                size="md"
                class="from-background-transparent to-surface-tertiary-persist"
              />
              <div
                ref="descriptionContainer"
                class="go-scrollbar relative max-h-40 overflow-y-auto rounded-corner-8 outline-none transition-all focus-within:bg-background-transparent-hovered hover:bg-background-transparent-hovered"
              >
                <AutosizedTextarea
                  v-model="localDescription"
                  mode="multiline"
                  placeholder="Add a description"
                  class="rounded-corner-8 bg-background-transparent px-2 py-1.5 text-sm-12px-light outline-none"
                  aria-label="Project description"
                  @keydown.escape="isDirty && revert($event)"
                  @blur="save"
                  @submit="save"
                />
              </div>
              <ScrollGradient
                location="bottom"
                :container="descriptionContainer"
                size="md"
                class="from-background-transparent to-surface-tertiary-persist"
              />
            </div>
          </div>

          <!-- scrollable, white pane area of the dialog -->
          <div
            class="go-scrollbar flex min-h-0 grow flex-col gap-4 overflow-y-auto rounded-lg bg-surface-primary pt-4"
            data-test="permissions-dialog-scroll-container"
          >
            <!--share section-->
            <div class="flex w-full max-w-full flex-row justify-between gap-6 px-4">
              <div class="flex shrink grow-0 flex-col overflow-hidden">
                <p
                  :id="DIALOG_LABEL_ID"
                  class="cursor-pointer text-md-13px-default text-text"
                  @click="onCopyLink({ showToast: true })"
                >
                  Share this project
                </p>
                <span
                  class="shrink grow-0 cursor-pointer truncate text-xs-11px-light text-text-subtlest"
                  @click="onCopyLink({ showToast: true })"
                >
                  {{ url }}
                </span>
              </div>
              <DarwinButton
                rounded
                variant="outline"
                class="w-[88px] max-w-[88px] shrink-0"
                size="md"
                @click="onCopyLink({ showToast: false })"
                >{{ copyButtonText }}</DarwinButton
              >
            </div>

            <DividerLine color="subtle" />
            <!-- other -->

            <PermissionsDialogInviteForm
              v-if="permissionsStore.currentProjectPermissions.invite_members"
              ref="inviteFormComponentRef"
              :workspace-members="workspaceMembers"
              :pending-invite-emails="
                projectPermissionsStore.projectInvitations.map((invite) => invite.email)
              "
              :limit-reached="limitReached"
              :allowed-roles="allowedInviteRoles"
              class="px-4"
              @invite="onInviteUsers"
            />
            <div class="px-4">
              <ProjectDefaultPermission
                v-if="permissionsStore.currentProjectPermissions.update_members"
                :default-role="anyOneInWorkspaceProjectPermission as ProjectDefaultRole"
                @update:project-default-role="updateProjectDefaultRole"
              />
            </div>
            <div class="grow px-4">
              <ul>
                <PermissionsDialogProjectMember
                  v-for="member in projectMembers.toSorted((a, b) =>
                    a.projectRole === 'owner' ? -1 : 1,
                  )"
                  :key="member.id"
                  :member="member"
                  :is-current-user="member.isCurrentUser"
                  :role="member.projectRole"
                  :allowed-roles="allowedUpdateRoles"
                  @change:role="onChangeRole(member.id, $event)"
                />
                <PermissionsDialogProjectMember
                  v-for="invitation in projectPermissionsStore.projectInvitations"
                  :key="invitation.id"
                  :member="{ ...invitation, type: 'invitation' }"
                  :is-current-user="false"
                  :role="invitation.role"
                  :allowed-roles="allowedUpdateRoles"
                  @delete:invitation="onDeleteInvitation"
                />
              </ul>
            </div>
            <SeatLimitBanner v-if="limitReached" />
          </div>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
