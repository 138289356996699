<script setup lang="ts">
import PaywallDialogVisual from '@/illustrations/PaywallDialog.vue'
import { useBilling } from '@/modules/Billing/useBilling'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import { computed } from 'vue'
import { PLAN_NAMES } from './constants'
import PaywallDialogTableCell from './PaywallDialogTableCell.vue'

defineProps<{
  open: boolean
  workspaceId: string
}>()

defineEmits<{
  (e: 'close'): void
}>()

const billingStore = useBilling()

const demoFormLink = computed(() => {
  if (!billingStore.activePlan?.name || billingStore.activePlan.name === 'enterprise') {
    return
  }

  const map = {
    free: 'https://www.v7labs.com/go/talk-to-sales?utm_campaign=%5BProduct%5D%20Go%20Plan%20Upgrade&utm_source=website&utm_medium=go&utm_term=contact-sales&utm_content=free-limit-reached',
    pro: 'https://www.v7labs.com/go/talk-to-sales?utm_campaign=%5BProduct%5D%20Go%20Plan%20Upgrade&utm_source=website&utm_medium=go&utm_term=contact-sales&utm_content=pro-limit-reached',
  }

  return map[billingStore.activePlan.name]
})
</script>

<template>
  <ModalDialog
    :open="open"
    to="body"
    aria-label="Upgrade your plan"
    class="overflow-hidden"
    @close="$emit('close')"
  >
    <div
      class="relative max-h-[640px] max-w-[540px] overflow-y-auto overflow-x-hidden rounded-corner-16 pb-6 scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
    >
      <PaywallDialogVisual class="pointer-events-none absolute top-0" />
      <IconButton
        size="lg"
        variant="neutral"
        class="absolute right-2 top-2 text-text-subtle"
        icon="close"
        rounded
        @click="$emit('close')"
      />
      <div class="px-6">
        <div class="mt-32 w-full">
          <div class="mb-12 flex flex-col items-center gap-4">
            <div class="text-center text-display-sm-24px-bold text-text">
              <div>
                {{
                  billingStore.activePlan?.name === 'free' ? 'Join V7 Go and' : 'Go Enterprise and'
                }}
              </div>
              <div>unleash the power of AI</div>
            </div>
          </div>
          <table
            class="[tr:first-child>td]:bg-background-success w-full table-fixed border-collapse"
          >
            <thead>
              <tr>
                <th class="rounded-t-xl px-4 pt-4 text-xs-11px-default text-text-subtlest">
                  {{ PLAN_NAMES['enterprise'] }}
                </th>
              </tr>
              <tr>
                <td class="mb-8 px-4 pb-4 text-center text-xl-18px-bold text-text">
                  Talk to Sales
                </td>
              </tr>
              <tr>
                <td class="p-4">
                  <DarwinButton
                    variant="outline"
                    size="sm"
                    class="w-full"
                    target="_blank"
                    :href="demoFormLink"
                  >
                    Contact Sales
                  </DarwinButton>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="h-7 px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
              </tr>
              <tr>
                <PaywallDialogTableCell>Custom limit on fields</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell>Custom limit on tokens</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell>Unlimited Properties</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell>Custom limit of users</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell>Custom limit on workspaces</PaywallDialogTableCell>
              </tr>
              <tr>
                <td class="h-7 px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
              </tr>
              <tr>
                <PaywallDialogTableCell>Command queue priority</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell>Bring your own key</PaywallDialogTableCell>
              </tr>
              <tr>
                <td class="h-7 px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
              </tr>
              <tr>
                <PaywallDialogTableCell>SAML Single Sign-On (SSO)</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell>Custom data retention</PaywallDialogTableCell>
              </tr>
              <tr>
                <td class="h-7 px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
              </tr>
              <tr>
                <PaywallDialogTableCell>Invoice or card billing</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell>Annual payment</PaywallDialogTableCell>
              </tr>
              <tr>
                <PaywallDialogTableCell>Enterprise SLAs</PaywallDialogTableCell>
              </tr>
              <tr>
                <td class="h-7 px-4">
                  <DividerLine
                    color="subtle"
                    :width="1"
                  />
                </td>
              </tr>
              <tr>
                <PaywallDialogTableCell>Customer success manager</PaywallDialogTableCell>
              </tr>
              <tr>
                <td class="h-7"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
