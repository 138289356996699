<script setup lang="ts">
import { useProfileImage } from '@/modules/WorkspaceSettings/useProfileImage'
import type { WorkspaceMember } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import type { IconName } from '@/uiKit/IconName'
import { computed } from 'vue'

const props = defineProps<{
  user: WorkspaceMember
  iconName?: IconName
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  shape?: 'circle' | 'square'
  errorCondition?: boolean
  loadingCondition?: boolean
  noBackground?: boolean
}>()
const profileImageUrl = useProfileImage(computed(() => props.user.id))
</script>

<template>
  <AvatarIcon
    v-bind="$props"
    :url="profileImageUrl"
    :full-text="user?.fullName"
  />
</template>
