import { watch, type Ref } from 'vue'
import { useCaseStore } from './useCaseStore'

export const useLoadCases = (workspaceId: Ref<string>) => {
  const caseStore = useCaseStore()
  watch(
    workspaceId,
    async (newWorkspaceId) => {
      caseStore.$reset()
      caseStore.loadCases(newWorkspaceId)
    },
    { immediate: true },
  )
}
