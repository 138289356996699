<script setup lang="ts">
import {
  VuePDF,
  usePDF,
  type HighlightEventPayload,
  type TextLayerLoadedEventPayload,
} from '@tato30/vue-pdf'
import '@tato30/vue-pdf/style.css'
import { useElementSize } from '@vueuse/core'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { useNaturalZoom } from '@/sharedComposables/useNaturalZoom'
import ScaleButtons from './ScaleButtons.vue'
import { PDF_SEARCH_HIGHLIGHT_OPTIONS } from './constants'

const props = withDefaults(
  defineProps<{
    filePath: string
    storagePrefix?: string
    showSidebar?: boolean
    /**
     * If set, and the PDF has a text layer, all instances of this text
     * will be highlighted.
     */
    highlightText?: string
    border?: boolean
    fullWidthZoomControls?: boolean
  }>(),
  {
    storagePrefix: 'entityview-scale',
    groundingInfo: () => ({ sources: [], ocrPages: [] }),
    showPages: false,
    highlightText: undefined,
    fullWidthZoomControls: false,
  },
)

defineEmits<{
  highlight: [payload: HighlightEventPayload]
  textLoaded: [payloaded: TextLayerLoadedEventPayload]
}>()

const route = useRoute()
const projectId = [route.params.projectId].flat()[0]
const propertyId = [route.params.propertyId].flat()[0]
const { pdf, pages, download } = usePDF(computed(() => props.filePath))

defineExpose({
  download,
})

const storageKey = computed(() => `${props.storagePrefix}-proj-${projectId}-prop-${propertyId}`)
const {
  onZoom,
  scrollContainer: pdfContainer,
  scrollContainerChild,
  scale,
  setScale,
} = useNaturalZoom({ storageKey })

const { width: pdfContainerWidth } = useElementSize(pdfContainer)
</script>

<template v-if="filePath">
  <div
    class="relative size-full overflow-hidden p-1 pt-0"
    @wheel.ctrl.exact.prevent.stop="onZoom($event)"
    @wheel.meta.exact.prevent.stop="onZoom($event)"
  >
    <div class="flex size-full flex-col">
      <div
        class="size-full overflow-hidden"
        :class="border && 'rounded-corner-6 border border-border-subtle'"
      >
        <div
          id="pdf-container"
          ref="pdfContainer"
          class="go-scrollbar relative flex size-full flex-col flex-wrap items-center overflow-auto px-8 pb-12 pt-4 [contain:strict]"
        >
          <div
            id="pdf-container-child"
            ref="scrollContainerChild"
            class="inline-flex flex-col gap-4"
          >
            <div
              v-for="page in pages"
              :key="page"
              class="relative select-text scroll-my-10"
              :data-page="page"
            >
              <VuePDF
                :page="page"
                :pdf="pdf"
                text-layer
                :highlight-text="highlightText"
                :highlight-options="PDF_SEARCH_HIGHLIGHT_OPTIONS"
                :scale="scale !== 'fit' ? scale : undefined"
                :width="scale === 'fit' ? pdfContainerWidth : undefined"
                :source="filePath"
                class="relative w-fit overscroll-none transition"
                @highlight="$emit('highlight', $event)"
                @text-loaded="$emit('textLoaded', $event)"
              />
            </div>
          </div>
        </div>
      </div>

      <div :class="fullWidthZoomControls ? 'pt-1' : 'absolute bottom-3 flex w-full justify-center'">
        <ScaleButtons
          :scale="scale"
          :full-width-mode="fullWidthZoomControls"
          :download-url="props.filePath"
          @update:scale="setScale"
        />
      </div>
    </div>
  </div>
</template>
