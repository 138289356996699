import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const claude35SonnetTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Claude 3.5 Sonnet',
  description:
    'Claude 3.5 Sonnet is the strong model released by Anthropic with the speed and cost of the mid-tier model.',
  outputTypes: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  provider: ToolProvider.anthropic,
  icon: 'company-anthropic',
}
