<script setup lang="ts">
import EntityStageDivider from '@/modules/Cases/Sidebar/EntityStageDivider.vue'
import { useAllStageFields } from '@/modules/Cases/Sidebar/useAllStageFields'
import type { Field } from '@/modules/Project/Fields/types'
import { useEntity } from '@/modules/Project/useEntity'
import { useFieldSaving } from '@/modules/Project/useFieldSaving'
import { useFieldUploadQueue } from '@/modules/Project/useFieldUploadQueue'
import { usePrevOrNextEntity } from '@/modules/Project/usePrevOrNextEntity'
import { useProject } from '@/modules/Project/useProject'
import EntitySidebarLayout from '@/sharedComponents/EntitySidebarLayout.vue'
import SidebarField from '@/sharedComponents/SidebarField.vue'
import { watch } from 'vue'
import { isGroundableField } from '../../Project/Fields/utils/grounding'
import { useGroundingStore, type ClaimAndSource } from '../../Project/useGroundingStore'
import { useOpenGrounding } from '../../Project/useOpenGrounding'

const props = defineProps<{ entityId: string }>()

const entityStore = useEntity()
const projectStore = useProject()

const { loadAndSetEntity } = usePrevOrNextEntity()
useFieldUploadQueue(2)
const { saveFieldChange } = useFieldSaving()

const { fields } = useAllStageFields()

watch(
  () => [props.entityId, entityStore.areEntitiesStale, projectStore.projectLoaded],
  () => loadAndSetEntity(props.entityId),
  { immediate: true },
)

const groundingStore = useGroundingStore()
const { openGrounding } = useOpenGrounding()

const onClickClaim = async (field: Field, { claimId, sourceId }: ClaimAndSource) => {
  if (!isGroundableField(field) || !field.hasGrounding || !field.grounding) {
    return
  }

  await openGrounding({ propertyId: field.propertyId, entityId: field.entityId })
  groundingStore.clickClaim({ claimId, sourceId })
}

const onPreviewFile = (field: Field) => {
  groundingStore.reset()
  entityStore.activePropertyId = field.propertyId
}

/**
 * Returns true if the stage divider should be shown.
 * We only want to show the divider if it would be its first occurence in the list.
 * Also, `main` view should not have a divider.
 * @param itemIndex index of the item in the `fields` array.
 */
function showStageDivider(itemIndex: number) {
  const currentView = fields.value.at(itemIndex)?.view
  if (currentView?.id === projectStore.mainView?.id) return false

  const previousView = fields.value.at(itemIndex - 1)?.view
  return currentView?.id !== previousView?.id
}
</script>

<template>
  <EntitySidebarLayout>
    <template
      v-for="({ field, property, view }, index) in fields"
      :key="field.propertyId + field.entityId"
    >
      <EntityStageDivider
        v-if="showStageDivider(index)"
        class="col-span-full my-2"
        >{{ view.name }}</EntityStageDivider
      >
      <SidebarField
        :property="property"
        :field="field"
        @show-source="onClickClaim(field, $event)"
        @file:preview="onPreviewFile(field)"
        @submit="saveFieldChange(field, $event)"
      />
    </template>
  </EntitySidebarLayout>
</template>
