import { useSupportedMimeTypes } from '@/modules/Project/useSupportedMimeTypes'
import { toast } from '@/shared/toast'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAgentPicker } from './useAgentPicker'
import { useCaseBackend } from './useCaseBackend'
import { useCasePendingQueryStore } from './useCasePendingQueryStore'
import { useCaseStore } from './useCaseStore'
import { useCaseUploadStore } from './useCaseUploadStore'

/**
 * Single source of truth for creation of a new case.
 *
 * The intent is to allow picking files and an agent,
 * but delay doing any case creation until the input form is submitted.
 */
export const useNewCase = () => {
  const router = useRouter()
  const supportedMimeTypes = useSupportedMimeTypes()

  const { agents, agentId } = useAgentPicker()

  const { createCase } = useCaseBackend()

  const uploadStore = useCaseUploadStore()

  const caseStore = useCaseStore()

  const status = ref<'initial' | 'creating' | 'uploading' | 'waiting' | 'sending'>('initial')

  const messageText = ref('')

  const pendingQueriesStore = useCasePendingQueryStore()

  const startNewCase = async (props: {
    workspaceId: string
    initialMessage: string
    /** Determine which router method to use for navigation. Defaults to 'push'. */
    navigate?: 'push' | 'replace' | false
  }) => {
    const res = await createCase({ workspaceId: props.workspaceId })

    if (!res.ok) {
      toast.error('Failed to create a case, please contact support if this issue persists.')
      return
    }

    const caseId = res.data.id
    caseStore.setActiveCase({ workspaceId: props.workspaceId, caseId })
    pendingQueriesStore.addPendingQuery({
      text: props.initialMessage,
      agentId: agentId.value,
      uploadIds: uploadStore.uploads.map((u) => u.id),
      type: 'create',
    })

    const navigate = props.navigate ?? 'push'
    if (navigate === 'push') {
      router.push({ name: 'Case', params: { caseId } })
    } else if (navigate === 'replace') {
      router.replace({ name: 'Case', params: { caseId } })
    }

    return caseId
  }

  const attachments = computed(() => uploadStore.attachments)

  return {
    addIntegrationFiles: uploadStore.addIntegrationFiles,
    addLibraryFiles: uploadStore.addLibraryFiles,
    addPendingUploads: uploadStore.addPendingUploads,
    agentId,
    agents,
    attachments,
    messageText,
    removeUpload: uploadStore.removeUpload,
    startNewCase,
    status,
    supportedMimeTypes,
  }
}
