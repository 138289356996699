<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

/**
 * Indenting content a bit so that header button can have text aligned
 * with cell text and have a bit of space on the left for button padding
 */
const roomForHeaderButton = 'pl-2'
</script>

<template>
  <div
    :class="
      twMerge(
        'grid h-9 grow grid-cols-[repeat(11,1fr)_32px] items-center',
        String($props.class || ''),
      )
    "
  >
    <!-- File name -->
    <div
      class="col-span-6 flex h-full items-center gap-1.5 text-md-13px-default"
      :class="[roomForHeaderButton]"
    >
      <slot name="filename" />
    </div>

    <!-- File type -->
    <div
      class="col-span-1 flex h-full items-center truncate text-sm-12px-light"
      :class="[roomForHeaderButton]"
    >
      <slot name="fileType" />
    </div>

    <!-- Added time -->
    <div
      class="col-span-2 truncate text-sm-12px-light"
      :class="[roomForHeaderButton]"
    >
      <slot name="createdAt" />
    </div>

    <!-- Updated by -->
    <div
      class="col-span-2 flex items-center truncate text-sm-12px-light"
      :class="[roomForHeaderButton]"
    >
      <slot name="updatedBy" />
    </div>

    <!-- Actions -->
    <div
      class="justify-self-end pr-1.5"
      :class="[roomForHeaderButton]"
    >
      <slot name="actions" />
    </div>
  </div>
</template>
