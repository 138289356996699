<script setup lang="ts">
import SelectContent from '@/modules/Project/SelectContent.vue'
import { useReasoning } from '@/modules/Project/useReasoning'
import { useSelectField } from '@/modules/Project/useSelectField'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import Menu from '@/uiKit/Menu'
import { computed, ref } from 'vue'
import type { SidebarEmits, SidebarProps } from '../../field-types'

const props = defineProps<SidebarProps<'multi_select'>>()

const emit = defineEmits<SidebarEmits<'multi_select'>>()

/**
 * The component doesn't render a fragment directly,
 * but the wrapping component in the template does, so we must disable inheritAttrs and
 * bind them to the correct slot element
 */
defineOptions({ inheritAttrs: false })

const isOpen = ref(false)
const field = computed(() => props.field)
const { localValue, optionColours, valueToColorMap } = useSelectField(
  field,
  computed(() => props.property),
)
const { hasReasoning } = useReasoning(field)

const onChangeOpen = (newOpen: boolean) => {
  isOpen.value = newOpen
  // the desired behaviour is to submit updated options when the menu closes
  if (!newOpen) {
    emit('submit', localValue.value)
  }
}
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    :positioning="{ sameWidth: true }"
    class="min-w-0 overflow-hidden outline-none"
    @change:open="onChangeOpen"
  >
    <button
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      class="size-full rounded-corner-6 p-1 pr-0 hover:bg-background-transparent-hovered focus-visible:bg-background-transparent-hovered"
      :class="{ 'bg-background-transparent-hovered': isOpen }"
    >
      <div class="size-full overflow-hidden rounded-r-corner-6">
        <div
          class="flex size-full cursor-pointer flex-row flex-wrap items-center gap-1 overflow-hidden focus:outline-none"
        >
          <div
            v-if="hasReasoning && localValue?.length === 0"
            class="h-full max-w-full truncate whitespace-nowrap pl-2 text-text-subtlest"
          >
            No match found
          </div>
          <div
            v-else-if="!localValue || localValue.length === 0"
            class="h-full max-w-full truncate whitespace-nowrap pl-2 text-text-subtlest"
          >
            Select options
          </div>
          <BadgeItem
            v-for="v in localValue"
            :key="v"
            v-memo="[v, ...optionColours]"
            :label="v"
            size="sm"
            :variant="'warning'"
            class="whitespace-nowrap"
            :rainbow-color="valueToColorMap[v]"
          />
        </div>
      </div>
    </button>
    <SelectContent
      :property="property"
      :readonly="false"
      :value="localValue"
      @submit="localValue = $event"
    />
  </Menu.Root>
</template>
