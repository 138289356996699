<script lang="ts">
const BACKDROP_DATA_ATTR = 'data-modal-backdrop'

/**
 * For a given event, will return the dialog root element if the event target
 * is a descendent of a ModalDialog component. Otherwise, returns null. This can
 * be used to ensure that menus and other popups remain open when clicking inside
 * a dialog.
 */
export const getModalDialogAncestor = (e: Event): Element | null =>
  (e.target instanceof Element && e.target.closest(`[${BACKDROP_DATA_ATTR}]`)) || null
</script>

<script lang="ts" setup>
/**
 * Implementation of
 * https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/V7-Design-System-(WIP)?type=design&node-id=1696-12342&mode=design&t=wFkzpO9SwKxhAPQZ-0
 *
 * Because some things around it are still unclear, this is built in a very configurable way.
 * As we iron out our scenarios, we may want to have prebuilt versions of this component,
 * that already assemble a dialog from it's building blocks and have a simpler API.
 */
import { onClickOutside } from '@vueuse/core'
import { nextTick, ref, useAttrs, useId, watch } from 'vue'

import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import IconButton from './IconButton.vue'
import TransitionOpacity from './TransitionOpacity.vue'
import { setPopoverTeleportTarget } from './utils/teleport'

const props = withDefaults(
  defineProps<{
    open: boolean
    /**
     * The element to which the dialog should be teleported. By default this
     * is the document body, but it can be set to any valid CSS selector.
     */
    to?: string
    placement?: 'center' | 'left' | 'right'
    backgroundBlur?: boolean
    backgroundGradient?: boolean
    outline?: boolean
    /**
     * @see the w3c spec for dialog element - https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/
     *
     * In the spec, it states that focus should be 'trapped' inside the
     * dialog. When the user is focused on the last tabbable element
     * inside a dialog, pressing tab should focus the first tabbable element
     * inside the dialog. i.e. focus should never leave an open dialog.
     *
     * This prop allows you to disable this behavior, for special cases where
     * you want to allow focus to leave the dialog.
     */
    disableFocusTrap?: boolean
    fullscreen?: boolean
    /**
     * When set, the dialog will be 'open' (i.e. rendered in the DOM), but
     * not visible. This is useful for cases where you want to preload heavy
     * content into the dialog, but don't want to show it to the user.
     */
    hidden?: boolean
    /**
     * Usually the dialog will automatically be placed on top of other content
     * because of its position in the DOM. However, sometimes you might need
     * to teleport the dialog to a different container, and DOM order alone
     * might not be enough to assure its rendered on top. In these cases,
     * this prop can be used to force a specific z-index.
     */
    forceZIndex?: number
  }>(),
  {
    to: 'body',
    placement: 'center',
    backgroundBlur: false,
    backgroundGradient: true,
    outline: true,
    disableFocusTrap: false,
    hideHeaderBorder: false,
    hideFooterBorder: false,
    fullscreen: false,
    hidden: false,
    forceZIndex: undefined,
  },
)

const emit = defineEmits<{ (e: 'close', event: Event): void }>()

defineOptions({ inheritAttrs: false })

const container = ref<HTMLElement>()

onClickOutside(container, (e) => emit('close', e))

const { activate, deactivate } = useFocusTrap(container)
watch(
  () => props.open,
  async (open) => {
    await nextTick()

    if (open && !props.disableFocusTrap) {
      activate()
    } else {
      deactivate()
    }
  },
  { immediate: true },
)

/**
 * Teleport all popovers within this component to <dialog> element. Otherwise they will be
 * teleported to the body element and will be hidden behind the dialog.
 */
const { id } = useAttrs()
const dialogId = useId()
setPopoverTeleportTarget(`#${id || dialogId}`)
</script>

<template>
  <Teleport :to="to">
    <TransitionOpacity>
      <div
        v-if="open"
        class="fixed inset-0"
        :class="{
          hidden,
          'bg-blanket-gradient': backgroundGradient,
        }"
        :style="{ zIndex: forceZIndex }"
        :[BACKDROP_DATA_ATTR]="''"
      ></div>
    </TransitionOpacity>

    <Transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-200 ease-in"
      enter-from-class="opacity-0 scale-[98%]"
      leave-to-class="opacity-0 scale-[98%]"
    >
      <div
        v-if="open"
        class="fixed inset-0 z-1 grid size-full bg-background-transparent"
        :class="{
          'place-items-center': placement === 'center',
          'place-items-start': placement === 'left',
          'place-items-end': placement === 'right',
          'p-2': fullscreen,
          'backdrop-blur-md': backgroundBlur,
          hidden: hidden,
        }"
        :style="{ zIndex: forceZIndex }"
        :[BACKDROP_DATA_ATTR]="''"
      >
        <dialog
          :id="dialogId"
          ref="container"
          open
          class="m-0 bg-surface-popover"
          :class="[
            fullscreen ? 'size-[calc(100%-16px)] min-h-0' : 'h-auto',
            placement === 'center' && 'left-1/2 -translate-x-1/2 rounded-corner-16',
            placement === 'left' && 'left-0 rounded-r-corner-16',
            placement === 'right' && 'inset-y-2 left-auto right-2 h-auto rounded-corner-16',
          ]"
          v-bind="$attrs"
          @keydown.escape="emit('close', $event)"
        >
          <slot>
            <div class="relative flex h-full min-h-0 max-w-[480px] flex-col overflow-hidden">
              <IconButton
                size="md"
                variant="transparent"
                class="absolute right-2 top-2 text-text-subtle"
                icon="close"
                rounded
                @click="emit('close', $event)"
              />

              <div
                v-if="$slots.header"
                :class="Boolean($slots.body || $slots.footer) && 'border-b border-border-subtle'"
              >
                <slot name="header" />
              </div>
              <div
                v-if="$slots.body"
                :class="!!$slots.footer && 'border-b border-border-subtle'"
                class="overflow-hidden p-4"
              >
                <slot name="body" />
              </div>
              <div class="flex flex-row items-center justify-end gap-2 p-2">
                <slot
                  name="footer"
                  :close="(e: Event) => emit('close', e)"
                />
              </div>
            </div>
          </slot>
        </dialog>
      </div>
    </Transition>
  </Teleport>
</template>
