<script setup lang="ts" generic="T">
import { computed, ref, watch } from 'vue'
import Menu from '.'
/**
 * Renders a search input that should appear at the top of a menu. This
 * component should never be used outside of `<Menu.Root>`.
 */

const props = withDefaults(
  defineProps<{
    /** Fallback text to show when there are no results */
    noResultText?: string
    /** List of all items to filter */
    items: T[]
    /** Either a key of the item to filter by or a custom predicate function */
    keyOrPredicate: keyof T | ((item: T, query: string) => unknown)
  }>(),
  { noResultText: 'No results found' },
)

const emit = defineEmits<{ (e: 'change', value: T[]): void }>()

defineOptions({ inheritAttrs: false })

const search = ref('')

const filteredItems = computed(() => {
  const keyOrPredicate = props.keyOrPredicate
  return typeof keyOrPredicate === 'function'
    ? props.items.filter((item) => keyOrPredicate(item, search.value))
    : props.items.filter((item) => {
        return String(item[keyOrPredicate])
          .toLowerCase()
          .includes(search.value.trim().toLowerCase())
      })
})

watch(filteredItems, () => emit('change', filteredItems.value), {
  immediate: true,
})
</script>

<template>
  <Menu.ControlledSearch
    v-model="search"
    v-bind="$attrs"
  />
  <Menu.Divider />
  <div
    v-if="filteredItems.length === 0"
    class="w-full p-2 text-text-subtlest"
  >
    {{ noResultText }}
  </div>
</template>
