<script setup lang="ts">
import { getBackgroundRainbowColor, getTextRainbowColor } from '@/uiKit/RainbowColor'
import { computed } from 'vue'

/**
 * Renders a button that is used in grounded field values to scroll to the source
 * for a grounding claim.
 */

const props = defineProps<{
  sourceId: number
  propertyId?: string
  isSelected?: boolean
}>()

const backgroundColor = computed(() =>
  props.isSelected
    ? getTextRainbowColor(`${props.propertyId}-${(props.sourceId + 1) * 8}`)
    : getBackgroundRainbowColor(`${props.propertyId}-${(props.sourceId + 1) * 8}`),
)

const color = computed(() =>
  props.isSelected
    ? 'var(--color-text-inverted-irreversible)'
    : getTextRainbowColor(`${props.propertyId}-${(props.sourceId + 1) * 8}`),
)
</script>

<template>
  <!--
    This element is registered as a webcomponent so that we can use it in our
    Shiki code highlighter (and any other non-Vue context). As such, we can't
    use Tailwind classes as they wouldn't reach into the shadow DOM. And we can't
    declare a custom class to use in the <style> tag because it doesn't work in
    both webcomponent/vuecomponent contexts. So inline styles are required.
  -->
  <button
    :aria-label="`Go to source ${sourceId + 1}`"
    :data-source="sourceId"
    :style="{
      backgroundColor,
      color,
      height: '16px',
      minWidth: '16px',
      cursor: 'pointer',
      borderRadius: '4px',
      border: 'none',
      padding: '0 4px',
      fontSize: '11px',
      lineHeight: '16px',
      fontWeight: '500',
      zIndex: '30',
    }"
  >
    {{ sourceId + 1 }}
  </button>
</template>
