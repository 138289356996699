import type { PropertyTool } from '@/backend/types'
import { awsOcrTool } from '@/modules/Project/Tools/registry/awsOcr.ts'
import { bingSearchTool } from '@/modules/Project/Tools/registry/bingSearch'
import { claude35HaikuTool } from '@/modules/Project/Tools/registry/claude35Haiku'
import { claude35HaikuVertexAiTool } from '@/modules/Project/Tools/registry/claude35HaikuVertexAi'
import { claude35SonnetTool } from '@/modules/Project/Tools/registry/claude35Sonnet'
import { claude35SonnetVertexAiTool } from '@/modules/Project/Tools/registry/claude35SonnetVertexAi'
import { claude37SonnetTool } from '@/modules/Project/Tools/registry/claude37Sonnet'
import { claude37SonnetVertexAiTool } from '@/modules/Project/Tools/registry/claude37SonnetVertexAi'
import { claude37ThinkingTool } from '@/modules/Project/Tools/registry/claude37Thinking'
import { claude37ThinkingVertexAiTool } from '@/modules/Project/Tools/registry/claude37ThinkingVertexAi'
import { claude3HaikuTool } from '@/modules/Project/Tools/registry/claude3Haiku'
import { claude3HaikuVertexAiTool } from '@/modules/Project/Tools/registry/claude3HaikuVertexAi'
import { claude3OpusTool } from '@/modules/Project/Tools/registry/claude3Opus'
import { claude3OpusVertexAiTool } from '@/modules/Project/Tools/registry/claude3OpusVertexAi'
import { claude3SonnetTool } from '@/modules/Project/Tools/registry/claude3Sonnet'
import { claude3SonnetVertexAiTool } from '@/modules/Project/Tools/registry/claude3SonnetVertexAi'
import { codeTool } from '@/modules/Project/Tools/registry/code'
import { dallE3Tool } from '@/modules/Project/Tools/registry/dalle3'
import { goTool } from '@/modules/Project/Tools/registry/go'
import { gpt35Tool } from '@/modules/Project/Tools/registry/gpt35'
import { gpt35AzureTool } from '@/modules/Project/Tools/registry/gpt35Azure'
import { gpt4Tool } from '@/modules/Project/Tools/registry/gpt4'
import { gpt4oTool } from '@/modules/Project/Tools/registry/gpt4o.ts'
import { gpt4oAzureTool } from '@/modules/Project/Tools/registry/gpt4oAzure'
import { gpt4oMiniTool } from '@/modules/Project/Tools/registry/gpt4oMini'
import { httpTool } from '@/modules/Project/Tools/registry/http'
import { imagenTool } from '@/modules/Project/Tools/registry/imagen'
import { manualTool } from '@/modules/Project/Tools/registry/manual.ts'
import { o1Tool } from '@/modules/Project/Tools/registry/o1'
import { o1MiniTool } from '@/modules/Project/Tools/registry/o1Mini'
import { o3MiniTool } from '@/modules/Project/Tools/registry/o3Mini'
import { ocrTool } from '@/modules/Project/Tools/registry/ocr'
import { urlScrapeTool } from '@/modules/Project/Tools/registry/urlScrape'
import { whisperTool } from '@/modules/Project/Tools/registry/whisper'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'
import { objectMap } from '@/shared/utils'
import { gemini15FlashTool } from './registry/gemini15Flash'
import { gemini15ProTool } from './registry/gemini15Pro'
import { gemini20FlashTool } from './registry/gemini20Flash'
import { gemini20FlashLiteTool } from './registry/gemini20FlashLite'
import { gemini20ProTool } from './registry/gemini20Pro'
import { geminiProTool } from './registry/geminiPro'
import { geminiProVisionTool } from './registry/geminiProVision'
import { webSearchTool } from './registry/webSearch'

const toolRegistry: Record<PropertyTool, ToolRegistryItem> = {
  aws_ocr: awsOcrTool,
  bing_search: bingSearchTool,
  claude_3_5_haiku_vertex_ai: claude35HaikuVertexAiTool,
  claude_3_5_haiku: claude35HaikuTool,
  claude_3_5_sonnet_vertex_ai: claude35SonnetVertexAiTool,
  claude_3_5_sonnet: claude35SonnetTool,
  claude_3_7_sonnet_vertex_ai: claude37SonnetVertexAiTool,
  claude_3_7_sonnet: claude37SonnetTool,
  claude_3_7_thinking_vertex_ai: claude37ThinkingVertexAiTool,
  claude_3_7_thinking: claude37ThinkingTool,
  claude_3_haiku_vertex_ai: claude3HaikuVertexAiTool,
  claude_3_haiku: claude3HaikuTool,
  claude_3_opus_vertex_ai: claude3OpusVertexAiTool,
  claude_3_opus: claude3OpusTool,
  claude_3_sonnet_vertex_ai: claude3SonnetVertexAiTool,
  claude_3_sonnet: claude3SonnetTool,
  code: codeTool,
  dall_e_3: dallE3Tool,
  gemini_1_5_flash: gemini15FlashTool,
  gemini_1_5_pro: gemini15ProTool,
  gemini_2_0_flash_lite: gemini20FlashLiteTool,
  gemini_2_0_flash: gemini20FlashTool,
  gemini_2_0_pro: gemini20ProTool,
  gemini_pro_vision: geminiProVisionTool,
  gemini_pro: geminiProTool,
  go: goTool,
  gpt_3_5_azure: gpt35AzureTool,
  gpt_3_5: gpt35Tool,
  gpt_4: gpt4Tool,
  gpt_4o_azure: gpt4oAzureTool,
  gpt_4o_mini: gpt4oMiniTool,
  gpt_4o: gpt4oTool,
  http: httpTool,
  imagen: imagenTool,
  manual: manualTool,
  o1_mini: o1MiniTool,
  o1: o1Tool,
  o3_mini: o3MiniTool,
  ocr: ocrTool,
  url_scrape: urlScrapeTool,
  web_search: webSearchTool,
  whisper: whisperTool,
}

export const tools = objectMap(toolRegistry, (tool, item) => [tool, { ...item, id: tool }])
