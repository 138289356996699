import { PropertyType } from '@/backend/types'
import { useProperty } from '@/modules/Project/useProperty.ts'
import { toast } from '@/shared/toast'
import { isCommandOrControlKey } from '@/shared/utils/event'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { usePopoverStore } from '@/sharedComposables/popoverStore'
import { captureException } from '@sentry/vue'
import { computed, ref, useId, watch } from 'vue'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import { usePropertySidebarIntegration } from './usePropertySidebarIntegration'

/**
 * This composable provides logic, state and event handlers for the popover menu
 * used to configure properties.
 */
export const usePropertyDropdown = () => {
  const menuIsOpen = ref(false)
  const {
    deleteProperty,
    deletePropertyConfirmationOpen,
    deselectProperty,
    hideProperty,
    reprocessColumn,
    saveProperty,
    createProperty,
  } = usePropertySidebarIntegration()

  const onCloseMenu = () => {
    deselectProperty()
  }
  const relatedProjectsStore = useRelatedProjectsStore()
  const workspacesStore = useWorkspaces()
  const propertyStore = useProperty()

  /**
   * Called when saving changes to a property.
   */
  const onUpdate = async ({ keepMenuOpen }: { keepMenuOpen?: boolean }) => {
    const updatedProperty = propertyStore.editedProperty
    if (!updatedProperty) {
      return
    }

    let isPropertySaved = false
    if (updatedProperty.type === 'reference' && updatedProperty.isOptimistic) {
      const res = await createProperty(updatedProperty)
      isPropertySaved = res.ok

      if (!res.ok) {
        captureException(new Error('Failed to create property'), { data: res.error })
        toast.error('Failed to create property')
      } else {
        assertIsNotNullOrUndefined(workspacesStore.currentWorkspace)
        await relatedProjectsStore.loadRelatedProject({
          property: { ...updatedProperty, id: res.data.id },
          workspaceId: workspacesStore.currentWorkspace.id,
        })
      }
    } else {
      isPropertySaved = await saveProperty('menu')
    }

    if (!keepMenuOpen && isPropertySaved) {
      menuIsOpen.value = false
      deselectProperty()
    }
  }

  const onReprocessColumn = async () => {
    const isPropertySaved = await saveProperty('menu')
    if (!isPropertySaved) return // leave the menu open if the save failed
    await reprocessColumn()
    deselectProperty()
    menuIsOpen.value = false
  }

  const openDeleteDialog = () => {
    menuIsOpen.value = false
    deletePropertyConfirmationOpen.value = true
  }

  const triggerRef = ref<HTMLElement>()
  // Close the menu by deselecting the property when a user clicks outside of the menu
  const onClickOutside = (e: PointerEvent | Event) => {
    deselectProperty()

    // If the user is clicking on the trigger when the menu is open, then we want to close
    // the menu and stop propagation so that the trigger doesn't open it again.
    const isClickingTrigger =
      e.target instanceof HTMLElement &&
      triggerRef.value instanceof HTMLElement &&
      triggerRef.value?.contains(e.target)
    if (isClickingTrigger) {
      e.stopPropagation()
    }
  }

  const canCloseCollectionMenu = computed(() => {
    return (
      PropertyType.collection !== propertyStore.editedProperty?.type ||
      (PropertyType.collection === propertyStore.editedProperty?.type &&
        propertyStore.editedProperty?.tool === 'manual') ||
      propertyStore.visibleProperties.length
    )
  })
  const collectionError = ref(false)
  watch(canCloseCollectionMenu, (canClose) => {
    if (canClose) {
      collectionError.value = false
    }
  })

  /** The user must have selected a related project in order to save a reference property. */
  const canSaveReferenceProperty = computed(() => {
    if (propertyStore.editedProperty?.type !== 'reference') {
      return true
    }

    return !!propertyStore.editedProperty.config.projectId
  })

  const popoverStore = usePopoverStore()
  const relatedProjectMenuItemId = useId()
  watch(canSaveReferenceProperty, (canSave) => {
    if (canSave && popoverStore.popover?.targetSelector === `#${relatedProjectMenuItemId}`) {
      popoverStore.setPopover(null)
    }
  })

  const validateCanSave = (): boolean => {
    if (!canCloseCollectionMenu.value) {
      collectionError.value = true
      return false
    }

    if (!canSaveReferenceProperty.value) {
      popoverStore.setPopover({
        text: 'Please select a source',
        placement: {
          allowedPlacements: ['left', 'right'],
        },
        targetSelector: `#${relatedProjectMenuItemId}`,
      })
      return false
    }

    return true
  }

  const onPromptKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && isCommandOrControlKey(e)) {
      e.stopPropagation()

      const canSave = validateCanSave()
      if (!canSave) {
        return
      }

      onUpdate({ keepMenuOpen: propertyStore.sidebarIsOpen })

      if (!propertyStore.sidebarIsOpen) {
        onCloseMenu()
      }
    }
  }

  return {
    deleteProperty,
    deletePropertyConfirmationOpen,
    hideProperty,
    menuIsOpen,
    onClickOutside,
    onCloseMenu,
    onReprocessColumn,
    onUpdate,
    openDeleteDialog,
    triggerRef,
    onPromptKeydown,
    canCloseCollectionMenu,
    collectionError,
    canSaveReferenceProperty,
    relatedProjectMenuItemId,
    validateCanSave,
  }
}
