<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed, ref } from 'vue'
import AgentCover from './AgentCover.vue'
import AgentPickerItem from './AgentPickerItem.vue'

type Agent = {
  id: string
  name: string | null
  description: string | null
  url: string | null
}

const props = defineProps<{ agents: Agent[] }>()

const modelValue = defineModel<string | null>()

const selectedAgent = computed(() =>
  modelValue.value ? props.agents.find((p) => p.id === modelValue.value) : undefined,
)

const filteredAgents = ref(props.agents)

const isSearchMatch = (item: Agent, search: string) =>
  item.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
  item.description?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps, menu }"
    close-on-select
  >
    <DarwinButton
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      size="sm"
      variant="transparent"
      rounded
      aria-label="Select an agent"
      :active="menu.open"
    >
      <template #leading-icon>
        <AgentCover
          v-if="selectedAgent"
          :url="selectedAgent.url"
        />
        <IconSprite
          v-else
          icon="ask-go"
          size="xxl"
        />
      </template>
      <div class="max-w-32 truncate">{{ selectedAgent?.name ?? 'Concierge' }}</div>
      <template #trailing-icon>
        <IconSprite icon="chevron-down" />
      </template>
    </DarwinButton>
    <Menu.Content>
      <Menu.Search
        :key-or-predicate="isSearchMatch"
        :items="agents"
        autofocus
        @change="filteredAgents = $event"
      />
      <div class="go-scrollbar max-h-[340px] overflow-y-auto overflow-x-hidden">
        <Menu.Item
          aria-label="Concierge"
          :aria-checked="modelValue === undefined || undefined"
          :aria-current="modelValue === undefined || undefined"
          element="div"
          role="listitem"
          class="w-full min-w-0 shrink-0"
          @select="modelValue = undefined"
        >
          <template #default="{ active, id }">
            <AgentPickerItem
              :id="id"
              label="Concierge"
              description="Answers generic questions and delegates to agents for specialist tasks."
              :selected="modelValue === undefined"
              :active="active"
              :url="null"
            >
              <template #icon>
                <IconSprite
                  icon="ask-go"
                  size="xxl"
                />
              </template>
              <ToolTip
                :open="active"
                title="Concierge"
                body="Answers generic questions and delegates to agents for specialist tasks."
                :placement="{ allowedPlacements: ['right'] }"
                :target-selector="`#${id}`"
              />
            </AgentPickerItem>
          </template>
        </Menu.Item>
        <template
          v-for="agent in filteredAgents"
          :key="agent.id"
        >
          <Menu.Item
            element="div"
            :aria-label="agent.name ?? 'Untitled Agent'"
            role="listitem"
            :aria-checked="agent.id === modelValue || undefined"
            :aria-current="agent.id === modelValue || undefined"
            class="shrink-0"
            @select="modelValue = agent.id"
          >
            <template #default="{ active, id }">
              <AgentPickerItem
                :id="id"
                :label="agent.name ?? 'Untitled Agent'"
                :description="agent.description ?? ''"
                :selected="agent.id === modelValue"
                :active="active"
                :url="agent.url"
              >
              </AgentPickerItem>
            </template>
          </Menu.Item>
        </template>
      </div>
    </Menu.Content>
  </Menu.Root>
</template>
