import { useSetFieldValue } from '@/shared/useSetFieldValue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import type { Ref } from 'vue'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import type { Field } from './Fields/types'
import { useFieldUpload } from './useFieldUpload'
import { useProject } from './useProject'

/**
 * Handles clearing of the manual value from a file field,
 * by both clearing the field and deleting the actual file from the storage provider.
 */
export const useFieldDeleteFile = (field: Ref<Field | undefined>) => {
  const { captureAnalyticsEvent } = useAnalytics()

  const projectStore = useProject()
  const workspacesStore = useWorkspaces()
  const uploadStore = useFieldUpload()

  const setFieldValue = useSetFieldValue()

  const deleteFile = async () => {
    if (!projectStore.projectId || !workspacesStore.currentWorkspace || !field.value) {
      return
    }

    const entityId = field.value.entityId
    const propertyId = field.value.propertyId
    const projectId = projectStore.projectId
    const workspaceId = workspacesStore.currentWorkspace.id

    const entity = projectStore.activeView?.entities?.find((e) => e?.id === entityId)
    if (!entity) {
      return
    }

    const parentEntityId = entity.parentEntityId || null

    // if the file is being uploaded, remove it from the upload queue
    // before deleting it
    if (field.value?.status == 'uploading') {
      uploadStore.removeUpload(workspaceId, projectId, entityId, propertyId, parentEntityId)
    }

    const fields = { [propertyId]: { field: field.value, newValue: null } }

    const res = await setFieldValue({ workspaceId, projectId, fields, entityId })

    if (res.ok) {
      captureAnalyticsEvent(ANALYTICS_EVENT.FILE_DELETED)
    }
  }

  return { deleteFile }
}
