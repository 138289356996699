<script setup lang="ts">
import { updateProject } from '@/backend/updateProject'
import { type Project, useProjects } from '@/modules/Projects/useProjects'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import { ref, watch } from 'vue'

const props = defineProps<{
  project: Project
}>()

const emit = defineEmits<{
  done: []
}>()

const projects = useProjects()

const localName = ref('')
watch(
  () => props.project.name,
  (next) => (localName.value = next || ''),
  { immediate: true },
)

const hasError = ref(false)

async function renameProject(newName: string) {
  hasError.value = false
  if (newName.length < 3) {
    hasError.value = true
    return
  }

  const result = await updateProject(props.project.workspaceId, props.project.id, { name: newName })

  if (!result.ok) {
    hasError.value = true
    return
  }

  projects.updateProject(props.project.id, { name: result.data.name })
  emit('done')
}

function revertProjectName() {
  localName.value = props.project.name || ''
  emit('done')
}
</script>

<template>
  <InlineTextField
    autofocus
    size="xs"
    :value="localName"
    :error="hasError"
    data-test="rename-project"
    @input="localName = $event"
    @submit="renameProject"
    @blur="renameProject(localName)"
    @esc="revertProjectName"
  />
</template>
