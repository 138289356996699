<template>
  <div class="flex w-full shrink-0 basis-10 items-center justify-between px-3 text-sm-12px-default">
    <!-- Breadcrumbs slot -->
    <div class="min-w-0">
      <slot name="breadcrumbs" />
    </div>

    <!-- Actions slot -->
    <div class="shrink-0">
      <slot name="actions" />
    </div>
  </div>
</template>
