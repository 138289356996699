<script setup lang="ts">
import AppHeader from '@/sharedComponents/AppHeader.vue'
import CrumbItem from '@/sharedComponents/CrumbItem.vue'
import SupportButton from '@/sharedComponents/SupportButton.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'
import { useHasIntercomChat } from '@/sharedComposables/useHasIntercomChat'
import ProjectCreateButton from '../Projects/ProjectCreateButton.vue'
defineProps<{ workspaceId: string }>()
const { isOpen } = useSidebar()
const hasIntercomChat = useHasIntercomChat()
</script>

<template>
  <AppHeader>
    <template #crumbs>
      <CrumbItem
        class="transition-transform duration-300 ease-in-out-quint"
        :class="isOpen ? 'translate-x-0' : 'translate-x-12'"
        :to="{ name: 'Workspace' }"
        :active="true"
        >Recent projects
      </CrumbItem>
    </template>
    <template #actions>
      <div class="flex gap-2">
        <ProjectCreateButton
          :workspace-id="workspaceId"
          size="sm"
        />
        <SupportButton v-if="hasIntercomChat" />
      </div>
    </template>
  </AppHeader>
</template>
