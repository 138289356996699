import { useLoadBilling } from '@/modules/Billing/useLoadBilling'
import { useLoadAllCases } from '@/modules/Cases/useLoadAllCases'
import { useLoadCases } from '@/modules/Cases/useLoadCases'
import { useLoadWorkspacePermissions } from '@/modules/IdentityAndAccess/useLoadWorkspacePermissions'
import { useLoadLibrary } from '@/modules/Library/useLoadLibrary'
import { useLoadModelProviders } from '@/modules/WorkspaceSettings/useLoadModelProviders'
import type { Ref } from 'vue'

/**
 * When the workspace changes, there is lots of auxiliary data that needs to be loaded.
 * e.g. the active billing plan, the library items, the user's permissions on the workspace, etc.
 *
 * This composable groups all the composition functions that load this data.
 */
export const useLoadWorkspaceData = (workspaceId: Ref<string>) => {
  useLoadBilling(workspaceId)
  useLoadLibrary(workspaceId)
  useLoadModelProviders(workspaceId)
  useLoadWorkspacePermissions(workspaceId)
  useLoadCases(workspaceId)
  useLoadAllCases(workspaceId)
}
