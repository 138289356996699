<script setup lang="ts">
import ProjectSidebarToggle from '@/modules/Projects/ProjectSidebarToggle.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'

const { isOpen } = useSidebar()
</script>

<template>
  <div class="relative flex items-center">
    <ProjectSidebarToggle
      :disable-tooltip="isOpen"
      class="transition duration-300 ease-in-out-quint"
      :class="isOpen ? '-translate-x-2 opacity-0' : 'translate-x-0 opacity-100'"
    />
    <div
      :class="isOpen ? '-translate-x-9' : 'translate-x-0'"
      class="ml-2 transition duration-300 ease-in-out-quint"
    >
      <slot />
    </div>
  </div>
</template>
