<script setup lang="ts">
import CaseAgentForm from '@/modules/Cases/AgentForm/CaseAgentForm.vue'
import CaseDataLiveProject from '@/modules/Cases/Sidebar/CaseDataLiveProject.vue'
import { useProjectNavigator } from '@/modules/Cases/Sidebar/useProjectNavigator'
import { provideProjectNavigationContext } from '@/modules/Project/Navigation/projectNavigationContext'
import IconButton from '@/uiKit/IconButton.vue'
import { useDropZone } from '@vueuse/core'
import { computed, onBeforeMount, useTemplateRef, watch } from 'vue'
import FileUploadMenu from '../Project/FileUploadMenu.vue'
import AgentPicker from './AgentPicker.vue'
import CaseMessageInput from './CaseMessageInput.vue'
import { isFullCase } from './types'
import { useCaseStore } from './useCaseStore'
import { useNewCase } from './useNewCase'

const props = defineProps<{
  workspaceId: string
  agentId?: string
  entityId?: string
}>()

const {
  addIntegrationFiles,
  addLibraryFiles,
  addPendingUploads,
  agentId: selectedAgentId,
  agents,
  attachments,
  status,
  messageText,
  removeUpload,
  startNewCase,
  supportedMimeTypes,
} = useNewCase()

const caseStore = useCaseStore()

const projectNavigator = useProjectNavigator()
provideProjectNavigationContext(projectNavigator)

/**
 * Track the scroll position of the container so that we can add
 * a fade-out effect to the bottom of the chat messages when there
 * is content behind the chat input.
 */
const scrollContainerRef = useTemplateRef('scroll-container')

const { isOverDropZone } = useDropZone(scrollContainerRef, {
  onDrop: (files) => {
    if (files && files.length > 0) {
      addPendingUploads(files)
    }
  },
})

const inputPlaceholder = computed(() =>
  isFullCase(caseStore.activeCase) && caseStore.activeCase.messages.length
    ? 'Ask a follow up'
    : 'Ask a question',
)

// automatically set the agent id from prop (route query) if provided
// this only happens on route change
watch(
  () => props.agentId,
  (newAgentId) => (selectedAgentId.value = newAgentId),
  { immediate: true },
)

onBeforeMount(() => {
  caseStore.activeCaseId = null
})
</script>

<template>
  <div class="relative grid h-full grid-cols-[1fr_auto]">
    <div
      ref="scroll-container"
      class="relative flex min-h-0 flex-col"
    >
      <CaseDataLiveProject
        v-if="agentId && entityId"
        :project-id="agentId"
      >
        <CaseAgentForm :entity-id="entityId" />
      </CaseDataLiveProject>
      <div
        v-else
        ref="message-input"
        class="absolute inset-x-8 bottom-0 flex min-w-0 grow justify-center bg-surface-primary pb-3"
      >
        <CaseMessageInput
          v-model="messageText"
          :attachments="attachments"
          class="go-column w-full shrink"
          :placeholder="inputPlaceholder"
          :is-dropping-files="isOverDropZone"
          :disabled="status !== 'initial'"
          @submit="
            startNewCase({
              workspaceId: props.workspaceId,
              initialMessage: messageText,
            })
          "
          @delete-attachment="removeUpload"
        >
          <template #actions>
            <FileUploadMenu
              v-slot="{ getTriggerProps, menu }"
              library-picker-confirm-label="Attach"
              :file-input-props="{ accept: supportedMimeTypes.join(', ') }"
              @import:library="addLibraryFiles($event)"
              @import:computer="addPendingUploads($event)"
              @import:google="addIntegrationFiles"
            >
              <IconButton
                aria-label="Add attachment"
                variant="transparent"
                rounded
                size="lg"
                icon="plus"
                :active="menu?.open"
                v-bind="getTriggerProps()"
              />
            </FileUploadMenu>
            <AgentPicker
              v-model="selectedAgentId"
              :agents="agents"
            />
          </template>
        </CaseMessageInput>
      </div>
    </div>
  </div>
</template>
