<script setup lang="ts">
import { useTutorialSidebar } from '@/modules/Workspaces/TutorialSidebar.vue'
import AppHeader from '@/sharedComponents/AppHeader.vue'
import CrumbItem from '@/sharedComponents/CrumbItem.vue'
import SidebarToggle from '@/sharedComponents/SidebarToggle.vue'
import SupportButton from '@/sharedComponents/SupportButton.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'
import { useHasIntercomChat } from '@/sharedComposables/useHasIntercomChat'

defineProps<{ workspaceId: string }>()

const { isOpen } = useSidebar()
const { isOpen: isTutorialSidebarOpen } = useTutorialSidebar()

const hasIntercomChat = useHasIntercomChat()
</script>

<template>
  <AppHeader>
    <template #crumbs>
      <CrumbItem
        class="transition-transform duration-300 ease-in-out-quint"
        :class="isOpen ? 'translate-x-0' : 'translate-x-12'"
        :to="{ name: 'WorkspaceProjectsV2' }"
        :active="true"
        >Home
      </CrumbItem>
    </template>
    <template #actions>
      <div class="flex gap-2">
        <SupportButton v-if="hasIntercomChat" />
        <SidebarToggle
          :is-open="isTutorialSidebarOpen"
          open-tooltip="Show tutorials"
          close-tooltip="Hide tutorials"
          side="right"
          @toggle="isTutorialSidebarOpen = !isTutorialSidebarOpen"
        />
      </div>
    </template>
  </AppHeader>
</template>
