<script setup lang="ts">
import { useLibraryBackend } from '@/modules/Library/useLibraryBackend'
import LibraryItemActions from '@/modules/Workspaces/KnowledgeHub/Files/Actions/LibraryItemActions.vue'
import { useRowContext } from '@/modules/Workspaces/KnowledgeHub/Files/rowContext'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'

defineEmits<{ menuOpen: [boolean] }>()

const { item } = useRowContext()

const workspace = useCurrentWorkspace()
const { deleteItem } = useLibraryBackend()

/** When used in a modal, clicking on an item would close the modal if its teleported to the body */
const disableTeleport = true

function onDelete() {
  deleteItem({ itemId: item.value.id, workspaceId: workspace.value.id })
}
</script>

<template>
  <LibraryItemActions>
    <template #default="{ open, rename }">
      <Menu.Root
        v-slot="{ getTriggerProps }"
        :positioning="{ placement: 'bottom-end', offset: { mainAxis: 4 } }"
        close-on-select
        :disable-teleport="disableTeleport"
        @change:open="$emit('menuOpen', $event)"
      >
        <IconButton
          v-bind="getTriggerProps()"
          icon="more-dots"
          size="md"
          variant="transparent"
          :aria-label="`Edit ${item.name}`"
          @click.stop
        />

        <Menu.Content class="min-w-40">
          <!-- Open, Download -->
          <Menu.Item
            :icon="open.icon"
            :label="open.label"
            v-bind="open.props"
          />

          <!-- Rename -->
          <Menu.Item
            :icon="rename.icon"
            :label="rename.label"
            v-bind="rename.props"
          />

          <Menu.Divider />

          <!-- Delete -->
          <Menu.Item
            critical
            icon="trash"
            label="Delete"
            @click.stop="onDelete"
          />
        </Menu.Content>
      </Menu.Root>
    </template>
  </LibraryItemActions>
</template>
