import type { components } from '@/api'
import { setFieldValue, type UpdateEntityFieldValue } from '@/backend/setFieldValue'
import { PropertyType } from '@/backend/types'
import type { Field, FileCollectionField, FileField } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import { useEntity } from '@/modules/Project/useEntity'
import { serializeFields, useProject } from '@/modules/Project/useProject'
import { isObject } from '@/shared/utils'
import { toast } from './toast'
import { extractNumberStringUnit } from './utils/string'

type FileIntegration = components['schemas']['TYPED_FILE_INTEGRATION']
type FileURL = components['schemas']['TYPED_FILE_URL']
type FilePayload = FileIntegration | FileURL

export type SaveValueParams = {
  workspaceId: string
  projectId: string
  entityId: string
  fields: Record<Property['id'], { field: Field; newValue: Field['manualValue'] | FilePayload }>
}
/**
 * For a given field, returns the object that should be
 * sent to the backend when saving the field's manual value.
 */
const getBackendValue = (field: Field): UpdateEntityFieldValue => {
  if (field.manualValue === null) {
    return null
  }

  switch (field.type) {
    case PropertyType.reference:
      return {
        reference: field.manualValue,
      }
    case PropertyType.single_select:
    case PropertyType.multi_select:
    case PropertyType.user_select:
    case PropertyType.file_collection:
    case PropertyType.collection: {
      return {
        options: typeof field.manualValue === 'string' ? [field.manualValue] : field.manualValue,
      }
    }
    case PropertyType.file: {
      return { file_url: field.manualValue }
    }
    case PropertyType.json: {
      return { json: field.manualValue }
    }
    case PropertyType.url: {
      return { url: field.manualValue }
    }
    case PropertyType.text: {
      return { text: field.manualValue }
    }
    case PropertyType.number: {
      return { text: field.manualValueRaw }
    }
  }
}

/**
 * Composable function that returns a function that will save a new field value
 * on the backend. The function will optimistically update the field's value in
 * the store, and revert the change if the backend request fails.
 */
export const useSetFieldValue = () => {
  const entityStore = useEntity()
  const projectStore = useProject()

  const saveValue = async ({
    fields,
    projectId,
    workspaceId,
    entityId,
  }: SaveValueParams): ReturnType<typeof setFieldValue> => {
    const oldFields = Object.values(fields).map(({ field }) => ({ ...field }))
    const newFields: typeof fields = {}
    const errors: string[] = []

    const propsWaitingForCompute: string[] = []

    // Optimistically update each field in the store
    Object.entries(fields).forEach(([propertyId, { field, newValue }]) => {
      const newField = { ...field }

      const property = projectStore.propertiesById[newField.propertyId]
      if (property && property.tool !== 'manual' && !newField.toolValueUpdatedBy) {
        propsWaitingForCompute.push(property.name)
        return
      }

      if (!isFilePayload(newValue)) {
        newField.manualValue = newValue
      }

      if (isUploadType(newField)) {
        if (newValue === null) {
          newField.manualFilename = null
          newField.manualValue = null
        } else if (isFileUrl(newValue)) {
          newField.manualValue = newValue.file_url
          newField.manualFilename = newValue.file_name || ''
        } else if (isFileIntegration(newValue)) {
          newField.manualValue = newValue.file_id
          newField.manualFilename = newValue.file_name || ''
        }
      }

      if (newField.type === PropertyType.number) {
        /**
         * The backend will pull the unit out of the string the user
         * types, (e.g. $123), so we need to do the same here. Otherwise
         * the UI will flash `NaN` for strings that contain a unit.
         */
        const { number, unit } = extractNumberStringUnit(newField.manualValue)

        if (number === null && newField.manualValue !== null) {
          errors.push('The value you entered is an unsupported format.')
          return
        } else {
          newField.manualValueRaw = newField.manualValue
          newField.manualValue = number
          newField.manualValueUnit = unit
        }
      }

      entityStore.updateField(newField)
      projectStore.updateField(newField)

      newFields[propertyId] = { field: newField, newValue }
    })

    if (propsWaitingForCompute.length > 0) {
      const list = propsWaitingForCompute.join(', ')
      toast.warning(`Please wait for the following properties to be computed: ${list}`)
    }

    // Build the new propertyId=>value mapping to send to the backend
    const newFieldValues = Object.fromEntries(
      Object.entries(newFields).map(([propertyId, { field, newValue }]) => {
        if (isUploadType(field) && isFilePayload(newValue)) {
          return [propertyId, newValue]
        }

        const value = getBackendValue(field)
        return [propertyId, value]
      }),
    )

    if (errors.length > 0) {
      return { ok: false, error: { code: 'invalid_input', message: errors[0] } }
    }

    if (Object.entries(newFieldValues).length === 0) {
      return {
        ok: true,
        data: {
          fields: {},
          id: entityId,
          project_id: projectId,
        },
      }
    }

    const result = await setFieldValue({
      workspaceId,
      projectId,
      entityId,
      fields: newFieldValues,
    })

    if (result.ok) {
      /**
       * Although we sent an optimistic update, the optimistic field might not contain
       * all the information that the backend returned. Now that we have all the updated
       * information, we update the field in the store again.
       */
      Array.from(serializeFields(result.data.fields).values()).forEach((f) => {
        projectStore.updateField(f)
        entityStore.updateField(f)
      })
    } else {
      oldFields.forEach((oldField) => {
        entityStore.updateField(oldField)
        projectStore.updateField(oldField)
      })
    }

    return result
  }

  return saveValue
}

const isUploadType = (field: Field): field is FileField | FileCollectionField =>
  field.type === PropertyType.file || field.type === PropertyType.file_collection

function isFilePayload(newValue: unknown): newValue is FileURL | FileIntegration {
  return isFileUrl(newValue) || isFileIntegration(newValue)
}

function isFileUrl(payload: unknown): payload is FileURL {
  return isObject(payload) && 'file_url' in payload && 'file_name' in payload
}

function isFileIntegration(payload: unknown): payload is FileIntegration {
  return (
    isObject(payload) &&
    'connection_id' in payload &&
    'integration_id' in payload &&
    'file_id' in payload
  )
}
