<script setup lang="ts">
// Figma: https://www.figma.com/file/Xo7wQGCNhUmbTnF2Wbkcvj/AGIDB?type=design&node-id=4797-621286&mode=design&t=3mwPi9xYS0BXpc7J-0
import { PropertyType } from '@/backend/types'
import type { Property } from '@/modules/Project/Properties/types'
import { tools } from '@/modules/Project/Tools/tool-registry'
import { FIELD_TYPES_LABELS } from '@/modules/WorkspaceSettings/propertyConfig'
import { assertIsNotNullOrUndefined, invariant } from '@/shared/utils/typeAssertions'
import LabelValueMenuItem from '@/sharedComponents/LabelValueMenuItem.vue'
import MentionableTextInput from '@/sharedComponents/MentionableTextInput.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { getModalDialogAncestor } from '@/uiKit/ModalDialog.vue'
// eslint-disable-next-line no-restricted-imports
import PopupMenu from '@/uiKit/PopupMenu.vue'
import SwitchButton from '@/uiKit/SwitchButton.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { setPopoverTeleportTarget } from '@/uiKit/utils/teleport'
import { type OffsetOptions } from '@floating-ui/vue'
import { onClickOutside, onKeyStroke } from '@vueuse/core'
import { computed, onMounted, ref, useId, type StyleValue } from 'vue'
import { useRouter } from 'vue-router'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useLibraryStore } from '../Library/libraryStore'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'
import CollectionTypeProperties from './CollectionTypeProperties.vue'
import { HORIZONTAL_OFFSET, VERTICAL_OFFSET } from './constants'
import GroundingTooltip from './GroundingTooltip.vue'
import { TYPE_ICON } from './icons'
import ModelInputBadge from './ModelInputBadge.vue'
import ModelInputMenu from './ModelInputMenu.vue'
import NumberFormatMenu from './NumberFormatMenu.vue'
import ProjectBadge from './ProjectBadge.vue'
import ProjectRelationMenu from './ProjectRelationMenu.vue'
import RelationEntityLimit from './RelationEntityLimit.vue'
import SelectTypeOptions from './SelectTypeOptions.vue'
import ToolMenu from './ToolMenu.vue'
import { useFilters } from './useFilters'
import { useGroundingToggle } from './useGroundingToggle'
import { useMentionableInputs } from './useMentionableInputs'
import { useModelInputs } from './useModelInputs'
import { usePinnedColumn } from './usePinnedColumn'
import { useProperty } from './useProperty'
import { usePropertyDropdown } from './usePropertyDropdown'
import { usePropertyMeta } from './usePropertyMeta'
import { useRelatedProject } from './useRelatedProject'
import UserListMenu from './UserListMenu.vue'
import { useSorting } from './useSorting'

defineProps<{
  /**
   * Some of the actions in this menu (e.g. sorting, pinning) only affect the table
   * view, so we must explicitly choose to show these actions when required.
   */
  showTableActions?: boolean
}>()

const emit = defineEmits<{
  (e: 'delete' | 'hide' | 'close' | 'reprocess'): void
  (e: 'update', v: { keepMenuOpen: boolean }): void
  (name: 'click:outside', event: PointerEvent | Event): void
}>()

const welcomeTour = useWelcomeTour()
const propertyStore = useProperty()
const pinnedColumn = usePinnedColumn()
const permissionStore = usePermissionsStore()

const {
  onPromptKeydown,
  canCloseCollectionMenu,
  collectionError,
  relatedProjectMenuItemId,
  validateCanSave,
} = usePropertyDropdown()

const updateValue = (keepMenuOpen: boolean) => {
  emit('update', { keepMenuOpen })
}

// The dropdowns use <Teleport> to render outside of the component, so we need
// to store refs to each to ensure that we only close the menu when the user
// clicks outside ALL of the menus.
const rootElement = ref()
const toolSubmenu = ref()
const typeSubmenu = ref()
const inputSubmenu = ref()

const reprocessColumnConfirmationOpen = ref(false)

const toolSubmenuIsOpen = ref(false)
const typeSubmenuIsOpen = ref(false)
const inputSubmenuIsOpen = ref(false)
const projectRelationSubmenuIsOpen = ref(false)
const someSubmenuIsOpen = computed(
  () => toolSubmenuIsOpen.value || inputSubmenuIsOpen.value || projectRelationSubmenuIsOpen.value,
)

onClickOutside(
  rootElement,
  (e) => {
    // Keep the menu open if clicking inside a ModalDialog component
    if (getModalDialogAncestor(e)) {
      return
    }

    if (welcomeTour.status === 'IN_PROGRESS') return
    // If one of the submenus is open, a click outside of the entire nested
    // menu structure should close the submenu, but not the entire menu.
    if (someSubmenuIsOpen.value || reprocessColumnConfirmationOpen.value) {
      toolSubmenuIsOpen.value = false
      typeSubmenuIsOpen.value = false
      inputSubmenuIsOpen.value = false
      projectRelationSubmenuIsOpen.value = false
      reprocessColumnConfirmationOpen.value = false
      e.stopPropagation()
      return
    }

    const canSave = validateCanSave()
    if (!canSave) {
      return
    }

    // Save the unsaved changes and close the dialog
    if (propertyStore.isDirty) {
      updateValue(false)
      return
    }

    emit('click:outside', e)
  },
  {
    ignore: [
      toolSubmenu,
      typeSubmenu,
      inputSubmenu,
      '#color-preset-menu',
      '#reprocess-property-confirmation',
    ],
  },
)

const { inputIdOptions, selectedInputIdOptions } = useModelInputs()

onKeyStroke('Escape', () => {
  if (welcomeTour.status === 'IN_PROGRESS') return
  if (someSubmenuIsOpen.value || reprocessColumnConfirmationOpen.value) {
    toolSubmenuIsOpen.value = false
    typeSubmenuIsOpen.value = false
    inputSubmenuIsOpen.value = false
    projectRelationSubmenuIsOpen.value = false
    reprocessColumnConfirmationOpen.value = false
    return
  }

  if (!canCloseCollectionMenu.value) {
    collectionError.value = true
    return
  }

  emit('close')
})

const onOpenSidebar = () => {
  propertyStore.sidebarIsOpen = true
}

const onDelete = () => {
  emit('delete')
}

const submenuOffset: OffsetOptions = ({ rects }) => ({
  alignmentAxis: -rects.reference.height + VERTICAL_OFFSET,
  mainAxis: HORIZONTAL_OFFSET,
})

const TOOL_SUBMENU_ID = 'property-configuration-tool-submenu'
const INPUT_SUBMENU_ID = 'property-configuration-input-submenu'

const { captureAnalyticsEvent } = useAnalytics()
onMounted(() => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_PROPERTY_MENU)
})

const onRecomputeStaleFields = () => {
  reprocessColumnConfirmationOpen.value = true

  captureAnalyticsEvent(ANALYTICS_EVENT.STALE_FIELDS_RECOMPUTED)
}

const { isRecomputeEnabled } = usePropertyMeta()
const isPropertyEditorOpensInSidebar = useFeatureFlags(FeatureFlag.AB_PROPERTY_EDITOR)

const show = computed(() => {
  return {
    group: {
      selectOptions:
        PropertyType.single_select === propertyStore.editedProperty?.type ||
        PropertyType.multi_select === propertyStore.editedProperty?.type,
      typeProperties:
        PropertyType.collection === propertyStore.editedProperty?.type &&
        propertyStore.editedProperty?.tool !== 'manual',
      recompute:
        isRecomputeEnabled.value && permissionStore.currentProjectPermissions.recalculate_entities,
      userSelect: PropertyType.user_select === propertyStore.editedProperty?.type,
    },
  }
})

const isKnowledgeHubEnabled = useFeatureFlags(FeatureFlag.KNOWLEDGE_HUB)
const router = useRouter()
const libraryStore = useLibraryStore()
const onOpenLibrary = () => {
  if (isKnowledgeHubEnabled.value) {
    router.push({ name: 'Files' })
    return
  }
  libraryStore.dialogIsOpen = true
  emit('close')
}

const onToggleLibraryItem = (itemId: string) => {
  assertIsNotNullOrUndefined(libraryStore.library, 'Library is not loaded')

  const type = libraryStore.getItemType(itemId)
  const propertyId =
    type === 'file' ? libraryStore.library.fileProperty.id : libraryStore.library.textProperty.id

  propertyStore.toggleInputId({ propertyId, entityId: itemId })

  if (selectedInputIdOptions.value.some((o) => o.id === itemId)) {
    captureAnalyticsEvent(ANALYTICS_EVENT.LIBRARY_INPUT_IN_PROPERTY)
  }
}

const selectedFilterId = ref<string | null>(null)

const onOpenFilterMenu = (property: Property) => {
  selectedFilterId.value = property.id
}

const onInputSubmenuChange = ($event: boolean) => {
  inputSubmenuIsOpen.value = $event
  if (!$event) {
    selectedFilterId.value = null
  }
}

const { onAddMention, onRemoveMention } = useMentionableInputs()

const { isGroundingEnabled, setGroundingState, areConditionsFilled } = useGroundingToggle()

// this will adjust the max height to the menu without event listeners. The use of constant is intentional
const MENU_HEIGHT_OFFSET = 196
const MENU_MIN_WIDTH = '360px'
const menuStyle: StyleValue = {
  'max-height': 'calc(100vh - ' + MENU_HEIGHT_OFFSET + 'px)',
  'min-width': MENU_MIN_WIDTH,
}

const sortingStore = useSorting()
const filterStore = useFilters()
function sort(direction: 'asc' | 'desc') {
  const id = propertyStore.savedProperty?.id
  if (!id) return

  if (sortingStore.hasRule(id)) {
    sortingStore.updateRuleDirection(id, direction)
  } else {
    sortingStore.addRule(id, direction)
  }
  filterStore.viewFilters = true
}

const { onChangeRelatedProject, relatedProject, relatedProjectCoverImage } = useRelatedProject()

const selectedTool = computed(() => {
  const tool = propertyStore.editedProperty?.tool
  invariant(tool)
  return tools[tool]
})

const rootId = useId()
setPopoverTeleportTarget(`#${rootId}`)
</script>

<template>
  <ListMenuContainer
    v-if="propertyStore.editedProperty"
    :id="rootId"
    ref="rootElement"
    class="no-scrollbar flex min-w-[360px] max-w-[400px] origin-top flex-col overflow-y-scroll"
    :style="{ ...menuStyle, minWidth: MENU_MIN_WIDTH }"
    v-bind="$attrs"
  >
    <div
      class="contents"
      role="form"
    >
      <div class="w-full p-1">
        <InlineTextField
          class="w-full"
          placeholder="Name"
          size="sm"
          :value="propertyStore.editedProperty.name"
          aria-label="Name"
          @input="propertyStore.editedProperty.name = $event"
          @submit="updateValue(true)"
        />
      </div>
      <DividerLine
        class="w-full"
        color="subtle"
        :width="1"
      />
      <div class="flex w-full flex-col p-1">
        <ToolTip
          title="You cannot change property type once the property has been created. Create a new property instead."
          :placement="{ allowedPlacements: ['right-start'] }"
        >
          <LabelValueMenuItem
            id="type-combobox"
            label="Type"
            :submenu="null"
            :value="'test'"
          >
            <div class="flex grow items-center justify-end gap-2">
              <IconSprite
                data-test="menu-item-icon"
                class="fill-icon-subtle text-icon-subtle"
                :icon="TYPE_ICON[propertyStore.editedProperty?.type]"
              />
              <div
                class="text-text"
                data-test="property-type"
              >
                {{ FIELD_TYPES_LABELS[propertyStore.editedProperty?.type] }}
              </div>
            </div>
          </LabelValueMenuItem>
        </ToolTip>

        <NumberFormatMenu
          v-if="propertyStore.editedProperty.type === 'number'"
          v-slot="{ getTriggerProps, isOpen }"
          :positioning="{
            placement: 'right-start',
            offset: {
              crossAxis: -2,
              mainAxis: 4,
            },
          }"
          :is-custom-format="propertyStore.editedProperty.config.isCustomFormat"
          :decimal-places="propertyStore.editedProperty.config.format.decimalPlaces"
          :thousands-separator="propertyStore.editedProperty.config.format.thousandsSeparator"
          :negative-format="propertyStore.editedProperty.config.format.negativeFormat"
          :right-align="propertyStore.editedProperty.config.format.rightAlign"
          @change:is-custom="propertyStore.editedProperty.config.isCustomFormat = $event"
          @change:format="propertyStore.editedProperty.config.format = $event"
        >
          <button
            v-bind="getTriggerProps()"
            class="text-left"
          >
            <LabelValueMenuItem
              id="number-format-menu-trigger"
              label="Data format"
              :submenu="{
                isOpen,
                id: 'number-format-menu',
              }"
            >
              {{ propertyStore.editedProperty.config.isCustomFormat ? 'Custom' : 'Automatic' }}
            </LabelValueMenuItem>
          </button>
        </NumberFormatMenu>

        <template v-if="propertyStore.editedProperty.type === 'reference'">
          <PopupMenu
            placement="right-start"
            :offset="submenuOffset"
            class="w-full"
            :open="projectRelationSubmenuIsOpen && !!propertyStore.editedProperty.isOptimistic"
            @change:open="
              propertyStore.editedProperty.isOptimistic && (projectRelationSubmenuIsOpen = $event)
            "
          >
            <template #trigger>
              <LabelValueMenuItem
                :id="relatedProjectMenuItemId"
                label="Related to"
                :submenu="
                  propertyStore.editedProperty.isOptimistic
                    ? {
                        isOpen: toolSubmenuIsOpen,
                        id: 'related-project-dropdown',
                      }
                    : null
                "
                placeholder="Select a source"
                :readonly="!propertyStore.editedProperty.isOptimistic"
              >
                <ProjectBadge
                  v-if="relatedProject"
                  :name="relatedProject.name"
                  :cover-image-url="relatedProjectCoverImage"
                  :readonly="!propertyStore.editedProperty.isOptimistic"
                  size="sm"
                />
                <div
                  v-else
                  class="text-sm-12px-default text-text-subtlest"
                >
                  Select a source
                </div>
              </LabelValueMenuItem>
            </template>
            <template #dropdown>
              <ProjectRelationMenu
                :value="propertyStore.editedProperty.config.projectId"
                @change="
                  (id) => {
                    onChangeRelatedProject(id)
                    projectRelationSubmenuIsOpen = false
                  }
                "
              />
            </template>
          </PopupMenu>
          <LabelValueMenuItem
            id="reference-property-limit"
            label="Limit"
            class="!py-0.5"
            :submenu="null"
          >
            <template #label="{ labelId }">
              <div
                :id="labelId"
                class="grow text-text"
              >
                Limit
              </div>
            </template>
            <template #default="{ labelId }">
              <RelationEntityLimit
                v-model="propertyStore.editedProperty.config.entityLimit"
                :label-id="labelId"
              />
            </template>
          </LabelValueMenuItem>
        </template>

        <PopupMenu
          placement="right-start"
          :offset="submenuOffset"
          class="w-full"
          :open="toolSubmenuIsOpen"
          @change:open="toolSubmenuIsOpen = $event"
        >
          <template #trigger>
            <LabelValueMenuItem
              id="tool-combobox"
              label="Tool"
              :value="selectedTool.label"
              :icon="selectedTool.icon"
              :submenu="{
                isOpen: toolSubmenuIsOpen,
                id: TOOL_SUBMENU_ID,
              }"
            />
          </template>
          <template #dropdown>
            <ToolMenu
              :id="TOOL_SUBMENU_ID"
              ref="toolSubmenu"
              :active-tool="propertyStore.editedProperty.tool"
              :active-type="propertyStore.editedProperty?.type"
              class="min-w-[240px]"
              :grounded="isGroundingEnabled"
              @change="
                (value) => {
                  if (!propertyStore.editedProperty) return
                  propertyStore.editedProperty.tool = value
                  toolSubmenuIsOpen = false
                }
              "
            />
          </template>
        </PopupMenu>

        <LabelValueMenuItem
          id="grounding-toggle-listitem"
          label="AI citations"
          :submenu="null"
        >
          <ToolTip
            :disabled="areConditionsFilled"
            :placement="{ allowedPlacements: ['top-end'] }"
          >
            <SwitchButton
              :checked="isGroundingEnabled"
              size="sm"
              color="blue"
              aria-label="AI citations"
              :disabled="!areConditionsFilled"
              @change="setGroundingState"
            />
            <template #content><GroundingTooltip /></template>
          </ToolTip>
        </LabelValueMenuItem>

        <PopupMenu
          v-if="propertyStore.editedProperty?.tool !== 'manual'"
          placement="right-start"
          :offset="{ alignmentAxis: -2, mainAxis: 4 }"
          class="w-full"
          cover-trigger
          :open="inputSubmenuIsOpen"
          :menu-attrs="{ class: 'z-10' }"
          @change:open="onInputSubmenuChange"
        >
          <template #trigger>
            <LabelValueMenuItem
              id="input-combobox"
              ref="inputTrigger"
              label="Inputs"
              alignment="top"
              :submenu="{
                isOpen: inputSubmenuIsOpen,
                id: INPUT_SUBMENU_ID,
              }"
            >
              <div
                v-if="selectedInputIdOptions.length > 0"
                class="flex w-full flex-wrap justify-end gap-1"
              >
                <ModelInputBadge
                  v-for="o in selectedInputIdOptions"
                  :key="o.id"
                  :input="o.data"
                  @click:filter="o.data.group === 'Properties' && onOpenFilterMenu(o.data)"
                />
              </div>
              <div
                v-else
                class="grow text-right text-text-subtlest"
              >
                Select inputs
              </div>
            </LabelValueMenuItem>
          </template>
          <template #dropdown>
            <ModelInputMenu
              :items="inputIdOptions"
              :open-with-filter-property-id="selectedFilterId"
              :selected-inputs="propertyStore.editedProperty.inputs"
              @toggle:property="propertyStore.toggleInputId"
              @toggle:library-item="onToggleLibraryItem"
              @open:library="onOpenLibrary"
              @update:filters="propertyStore.setInput"
            />
          </template>
        </PopupMenu>

        <div
          v-if="selectedTool.usesPrompt"
          class="w-full py-0.5"
        >
          <!--
              👇 these styles are here so that the prompt editor doesn't make
              the menu expand horizontally when the user types into it.
              See https://codesandbox.io/p/sandbox/contenteditable-vs-textarea-4xxmjp for a minimal example
          -->
          <MentionableTextInput
            :style="{ width: MENU_MIN_WIDTH, minWidth: '100%' }"
            :code-style="propertyStore.editedProperty?.tool === 'code'"
            :value="propertyStore.editedProperty?.description || ''"
            :items="
              inputIdOptions.map((option) => ({
                ...option.data,
                icon: option.data.group === 'Properties' ? TYPE_ICON[option.data.type] : undefined,
              }))
            "
            @update:text="propertyStore.editedProperty.description = $event"
            @add:mention="onAddMention"
            @remove:mention="onRemoveMention"
            @open:library="onOpenLibrary"
            @keydown.capture="onPromptKeydown"
          />
        </div>
      </div>
      <DividerLine
        class="w-full"
        color="subtle"
        :width="1"
      />

      <div
        v-if="Object.values(show.group).some(Boolean)"
        class="flex w-full flex-col p-1"
      >
        <UserListMenu v-if="show.group.userSelect">
          <template #trigger="{ isOpen, submenuId, selectedUsers }">
            <LabelValueMenuItem
              id="user-combobox"
              ref="userTrigger"
              label="Users"
              alignment="top"
              :submenu="{
                isOpen,
                id: submenuId,
              }"
            >
              <div
                v-if="selectedUsers.length > 0"
                class="flex w-full flex-wrap justify-end gap-1 self-start"
              >
                <BadgeItem
                  v-for="o in selectedUsers"
                  :key="o.value"
                  :label="o.value"
                  variant="selected"
                  size="sm"
                  leading-icon="user"
                />
              </div>

              <div
                v-else
                class="grow text-right text-text-subtlest"
              >
                Select users
              </div>
            </LabelValueMenuItem>
          </template>
        </UserListMenu>
        <SelectTypeOptions
          v-if="show.group.selectOptions"
          @insert="updateValue(true)"
        />

        <CollectionTypeProperties
          v-if="show.group.typeProperties"
          :error="collectionError"
        />

        <DarwinButton
          v-if="show.group.recompute"
          variant="neutral"
          size="sm"
          class="w-full"
          type="button"
          @click="onRecomputeStaleFields"
        >
          <template #leading-icon>
            <IconSprite icon="process" />
          </template>
          Recompute all stale fields
        </DarwinButton>
      </div>

      <DividerLine
        v-if="Object.values(show.group).some(Boolean)"
        class="w-full"
        color="subtle"
        :width="1"
      />

      <template v-if="showTableActions">
        <div class="flex w-full flex-col p-1">
          <ListMenuItem
            icon="arrow-top"
            label="Sort ascending"
            role="button"
            @select="sort('asc')"
          />
          <ListMenuItem
            icon="arrow-bottom"
            label="Sort descending"
            role="button"
            @select="sort('desc')"
          />
        </div>
        <DividerLine
          class="w-full"
          color="subtle"
          :width="1"
        />
      </template>

      <div class="flex w-full flex-col p-1">
        <ListMenuItem
          v-if="pinnedColumn.canPin && showTableActions"
          :icon="pinnedColumn.isPinned ? 'pin-fill' : 'pin'"
          :label="pinnedColumn.isPinned ? 'Unpin' : 'Pin'"
          role="button"
          @select="pinnedColumn.togglePin"
        />

        <ToolTip
          :arrow="true"
          :placement="{ allowedPlacements: ['right', 'left'] }"
          title="Automations, API, property IDs, and other advanced settings are here."
        >
          <ListMenuItem
            icon="settings"
            label="More settings"
            role="button"
            @select="onOpenSidebar"
          />
        </ToolTip>
        <ListMenuItem
          v-if="!isPropertyEditorOpensInSidebar && showTableActions"
          icon="hide"
          label="Hide from view"
          role="button"
          @select="$emit('hide')"
        />
        <ListMenuItem
          icon="trash"
          label="Delete Property"
          critical
          role="button"
          @select="onDelete"
        />
      </div>
    </div>
  </ListMenuContainer>
  <ConfirmationDialog
    id="reprocess-property-confirmation"
    :open="reprocessColumnConfirmationOpen"
    title="Recompute all stale fields for this property?"
    description="This may take a while and become quite costly."
    variant="black"
    confirm-text="Confirm"
    @confirm="emit('reprocess')"
    @close="reprocessColumnConfirmationOpen = false"
  />
</template>
