<script setup lang="ts">
import LibraryDocument from '@/modules/Library/LibraryDocument.vue'
import { useLibraryDocument } from '@/modules/Library/useLibraryDocument'
import { getItemLinkInfo } from '@/modules/Workspaces/KnowledgeHub/Files/Actions/getItemLinkInfo'
import { useRowContext } from '@/modules/Workspaces/KnowledgeHub/Files/rowContext'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import type { IconName } from '@/uiKit/IconName'
import { computed } from 'vue'

type Action = {
  label: string
  icon: IconName
  props: Record<string, unknown>
}

const { item } = useRowContext()

const { isEditingLibraryDocument, onSaveDocument, activeItem, onEditDocument, onClose } =
  useLibraryDocument()

const workspace = useCurrentWorkspace()
const rowContext = useRowContext()

const openAction = computed<Action>(() => {
  const { label, icon, link } = getItemLinkInfo(item.value)
  const props: Record<string, unknown> = {
    'aria-label': label,
  }

  if (link) {
    props.role = 'link'
    props['data-href'] = link.url
  }

  props.onClick = (e: Event) => {
    e.stopPropagation()
    if (link) {
      window.open(link.url, link.target)
    } else {
      onEditDocument(item.value)
    }
  }

  return { label, icon, props }
})

const renameAction = computed<Action>(() => ({
  label: 'Rename',
  icon: 'edit' as IconName,
  props: {
    'aria-label': 'Rename',
    onClick(e: Event) {
      e.stopPropagation()
      rowContext.isRenaming.value = true
    },
  },
}))

function onDocumentSave(event: { content: string; title: string }) {
  onSaveDocument({ content: event.content, title: event.title, workspaceId: workspace.value.id })
}
</script>

<template>
  <slot
    :open="openAction"
    :rename="renameAction"
  />

  <Teleport to="body">
    <LibraryDocument
      v-if="activeItem?.type === 'text'"
      :aria-label="`Edit ${activeItem?.name || ''}`"
      :open="isEditingLibraryDocument"
      :initial-title="activeItem?.name"
      :initial-content="activeItem?.content"
      @close="onClose"
      @save="onDocumentSave"
    />
  </Teleport>
</template>
