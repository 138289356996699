import { PropertyType } from '@/backend/types'
import { computed, type Ref } from 'vue'
import type { Field } from './Fields/types'

export const useReasoning = (field: Ref<Field | undefined>) => {
  const reasoning = computed(() =>
    field.value?.type === PropertyType.single_select ||
    field.value?.type === PropertyType.multi_select ||
    field.value?.type === PropertyType.number
      ? field.value?.metadata?.motivation
      : null,
  )

  const hasReasoning = computed(() => !!reasoning.value)

  return { reasoning, hasReasoning }
}
