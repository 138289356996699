<script setup lang="ts">
import { adoptEntity } from '@/backend/adoptEntity'
import AgentLogo from '@/modules/Cases/Sidebar/AgentLogo.vue'
import { useNewCase } from '@/modules/Cases/useNewCase'
import { getSidebarComponent } from '@/modules/Project/Fields/field-registry'
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import { useEntity } from '@/modules/Project/useEntity'
import { useFieldSaving } from '@/modules/Project/useFieldSaving'
import { useFieldUploadQueue } from '@/modules/Project/useFieldUploadQueue'
import { usePrevOrNextEntity } from '@/modules/Project/usePrevOrNextEntity'
import { useProject } from '@/modules/Project/useProject'
import { useProjects } from '@/modules/Projects/useProjects'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { toast } from '@/shared/toast'
import { invariant } from '@/shared/utils/typeAssertions'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps<{ entityId: string }>()

/** Whether we're in the middle of adopting an entity into a case */
const isAdoptingEntity = ref(false)

useFieldUploadQueue(2)

const router = useRouter()
const projects = useProjects()
const entityStore = useEntity()
const currentProjectStore = useProject()
const { saveFieldChange } = useFieldSaving()
const currentWorkspace = useCurrentWorkspace()
const { loadAndSetEntity } = usePrevOrNextEntity()

const { startNewCase, agentId } = useNewCase()

const entity = computed(() => {
  return entityStore.entity
})

/** Project is coming in async, but we'll only work on the form when it arrives */
const project = computed(() => {
  return projects.getProjectById(currentProjectStore.projectId || '')
})

const fields = computed(() => {
  const fields: { property: Property; field: Field }[] = []
  if (!entity.value) return fields

  for (const property of currentProjectStore.visibleProperties) {
    if (property.tool !== 'manual') continue
    // No support for manual collection entry yet
    if (property.type === 'collection') continue

    const field = entity.value.fields.get(property.id)
    invariant(field)

    fields.push({ property, field })
  }

  return fields
})

/**
 * We need to load an entity first before editing,
 * our form field components all assume that they are editing an existing entity.
 */
watch(
  [() => props.entityId, () => currentProjectStore.projectLoaded],
  ([entityId, isLoaded]) => {
    if (!isLoaded) return
    loadAndSetEntity(entityId)
  },
  { immediate: true },
)

/**
 * Adopt the entity into a case, and redirect to the case.
 */
async function onSubmit() {
  if (isAdoptingEntity.value) return

  invariant(project.value?.id)
  agentId.value = project.value.id

  const caseId = await startNewCase({
    workspaceId: currentWorkspace.value.id,
    initialMessage: '',
    navigate: false,
  })

  if (!caseId) return
  isAdoptingEntity.value = true

  try {
    const response = await adoptEntity({
      workspaceId: currentWorkspace.value.id,
      caseId: caseId,
      entityId: props.entityId,
      projectId: project.value.id,
    })

    if (!response.ok) {
      throw new Error(response.error.message)
    }

    router.replace({
      name: 'Case',
      params: { caseId, workspaceId: currentWorkspace.value.id },
      query: { agentId: project.value.id },
    })
  } catch {
    toast.error('Failed to adopt entity')
  } finally {
    isAdoptingEntity.value = false
  }
}
</script>

<template>
  <div
    v-if="currentProjectStore && project"
    class="mx-auto max-w-[560px] overflow-auto py-16"
  >
    <div class="flex flex-col gap-8">
      <AgentLogo
        :project="project"
        class="size-14"
      >
        <div class="size-14" />
      </AgentLogo>

      <div class="flex flex-col gap-2 break-words">
        <h1 class="text-display-md-28px">{{ project.name }}</h1>
        <div class="text-lg-15px-light">
          {{ project.description }}
        </div>
      </div>

      <DividerLine color="subtle" />

      <div class="text-lg-15px-default">Fill in the inputs to run the agent</div>

      <!-- Form -->
      <div
        v-if="entity"
        class="flex flex-col gap-4"
      >
        <!-- Field form inputs -->
        <div
          v-for="item in fields"
          :key="item.property.id"
          class="flex flex-col gap-1 text-xs-11px-default"
        >
          <!-- Field label -->
          <div>{{ item.property.name }}</div>

          <!-- Field value form input -->
          <div class="w-full rounded-corner-10 bg-background-gray-subtlest">
            <component
              :is="getSidebarComponent(item.field)"
              :field="item.field"
              :property="item.property"
              @submit="saveFieldChange(item.field, $event)"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-end">
        <IconButton
          icon="arrow-right"
          size="lg"
          class="w-12"
          variant="black"
          aria-label="Start agent"
          rounded
          @click="onSubmit"
        />
      </div>
    </div>
  </div>
</template>
