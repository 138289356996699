<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'

const emit = defineEmits<{
  (e: 'reset-filters'): void
}>()
</script>

<template>
  <div class="flex size-full h-full grow flex-col items-center justify-center">
    <div class="flex grow flex-col items-center justify-center">
      <div class="text-sm-12px-default text-text">No case matching your search</div>
      <div class="text-sm-12px-light text-text-subtle">
        You can create a new one or reset your filters.
      </div>
    </div>
    <div class="flex flex-col items-center justify-center">
      <DarwinButton
        variant="transparent"
        size="md"
        @click="emit('reset-filters')"
      >
        <template #leading-icon>
          <IconSprite icon="close" />
        </template>
        Reset filters
      </DarwinButton>
    </div>
  </div>
</template>
