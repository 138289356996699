<script lang="ts" setup>
import CaseDropdown from '@/modules/Cases/CaseDropdown.vue'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { format as formatDate } from 'date-fns'
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import { FALLBACK_CASE_NAME } from './serializers'
import type { Case, PartialCase } from './types'

const props = defineProps<{ workspaceId: string; targetCase: Case | PartialCase }>()

const emit = defineEmits<{
  (e: 'remove' | 'rename', value: string): void
}>()

const workspaceMemberStore = useWorkspaceMembers()

const caseRoute = computed(() => ({
  name: 'Case',
  params: { workspaceId: props.workspaceId, caseId: props.targetCase.id },
}))

const formattedCreatedDate = computed(() =>
  formatDate(props.targetCase?.createdAt ?? '', 'EEE d MMM yyyy · HH:mm'),
)

const creatorName = computed(() =>
  workspaceMemberStore.getMemberNameFromId(props.targetCase?.userId ?? ''),
)
</script>

<template>
  <RouterLink
    v-if="targetCase"
    tabindex="0"
    class="group relative flex h-[76px] w-full flex-1 items-start justify-between gap-2 overflow-hidden rounded-corner-20 bg-background-gray-sunken px-5 hover:bg-background-gray-sunken-hovered"
    :to="caseRoute"
  >
    <div class="flex size-full grow items-center justify-start gap-4">
      <div class="flex h-full flex-col items-center justify-center">
        <div
          class="flex size-8 flex-col items-center justify-center rounded-full bg-background-gray-subtlest"
        >
          <IconSprite
            class="size-4 text-icon-subtlest"
            size="md"
            icon="case-bubble-fill"
          />
        </div>
      </div>
      <div class="z-2 flex w-full shrink grow flex-col justify-between gap-2">
        <div class="flex w-full flex-col gap-1">
          <div class="line-clamp-1 text-md-13px-default text-text">
            {{ targetCase.name ?? FALLBACK_CASE_NAME }}
          </div>
          <div class="line-clamp-2 text-xs-11px-light text-text-subtle">
            {{ formattedCreatedDate }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex size-full grow items-center justify-end gap-2">
      <div
        class="flex items-center gap-2 transition-opacity group-hover:pointer-events-none group-hover:opacity-0"
      >
        <AvatarIcon
          shape="circle"
          :project="targetCase.userId"
          :full-text="creatorName"
        />
        <div class="text-xs-11px-light text-text-subtle">
          {{ creatorName }}
        </div>
      </div>
      <div
        class="pointer-events-none absolute opacity-0 transition-opacity group-hover:pointer-events-auto group-hover:opacity-100"
      >
        <CaseDropdown
          :workspace-id="workspaceId"
          :case-id="targetCase.id"
          size="lg"
          @delete="emit('remove', targetCase.id)"
          @rename="emit('rename', $event)"
        />
      </div>
    </div>
  </RouterLink>
</template>
