import type { components } from '@/api'
import { post } from '@/backend/client'

type Path = `/api/workspaces/${string}/cases/${string}/adopt_entity`
type Params = components['schemas']['Cases.AdoptEntityRequest']
type Response = components['schemas']['Cases.CaseResponse']

/**
 * Attaches an entity to a case
 */
export function adoptEntity(props: {
  workspaceId: string
  entityId: string
  caseId: string
  projectId: string
}) {
  return post<Params, Response, Path>(
    `/api/workspaces/${props.workspaceId}/cases/${props.caseId}/adopt_entity`,
    { entity_id: props.entityId, project_id: props.projectId },
  )
}
