<template>
  <div
    class="flex h-14 w-full flex-row items-center gap-2 rounded-lg p-3 hover:bg-background-transparent-hovered"
  >
    <!-- Icon -->
    <div class="mr-1 w-8 shrink-0 grow-0">
      <slot name="icon" />
    </div>

    <!-- Name & Description -->
    <div class="flex grow flex-col">
      <div class="text-sm-12px-default">
        <slot name="name" />
      </div>
      <div class="text-xs-11px-default text-text-subtlest">
        <slot name="description" />
      </div>
    </div>

    <!-- Actions -->
  </div>
</template>
