<script setup lang="ts">
import { useProjectNavigator } from '@/modules/Cases/Sidebar/useProjectNavigator'
import { provideProjectNavigationContext } from '@/modules/Project/Navigation/projectNavigationContext'
import { useSupportedMimeTypes } from '@/modules/Project/useSupportedMimeTypes'
import ScrollGradient from '@/sharedComponents/ScrollGradient.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { useDropZone, useLocalStorage } from '@vueuse/core'
import { computed, nextTick, ref, useTemplateRef, type ComponentPublicInstance } from 'vue'
import FileUploadMenu from '../Project/FileUploadMenu.vue'
import type { GoogleDriveFile } from '../Workspaces/KnowledgeHub/Integrations/GoogleDrive/useGoogleDriveConnection'
import AgentPicker from './AgentPicker.vue'
import CaseMessageInput from './CaseMessageInput.vue'
import CaseQuery from './CaseQuery.vue'
import CaseResponse from './CaseResponse.vue'
import { isFullCase } from './types'
import { useAgentPicker } from './useAgentPicker'
import { useCasePendingQueryStore } from './useCasePendingQueryStore'
import { useCaseQueryEdit } from './useCaseQueryEdit'
import { useCaseScroll } from './useCaseScroll'
import { useCaseStore } from './useCaseStore'
import { useCaseUploadStore } from './useCaseUploadStore'
import { useChatBubbles } from './useChatBubbles'
import { useIsCaseReady } from './useIsCaseReady'

const props = defineProps<{
  caseId: string
  workspaceId: string
  agentId?: string
}>()

const emit = defineEmits<{ 'select-agent': [string] }>()

const caseStore = useCaseStore()

const projectNavigator = useProjectNavigator()
provideProjectNavigationContext(projectNavigator)

const uploadStore = useCaseUploadStore()

const attachments = computed(() => {
  const pendingUploadIds = pendingStore.pendingQueries.map((q) => q.uploadIds).flat()
  return uploadStore.attachments.filter((a) => !pendingUploadIds.includes(a.uploadId))
})

const { agentId: selectedAgentId, agents } = useAgentPicker()
const messageText = useLocalStorage(`messageText-${props.caseId}`, '')

const pendingStore = useCasePendingQueryStore()

const setPendingMessage = async (text: string) => {
  if (editedMessageId.value) {
    pendingStore.addPendingQuery({
      text,
      agentId: selectedAgentId.value ?? undefined,
      uploadIds: uploadStore.uploads.map((u) => u.id),
      type: 'edit' as const,
      messageId: editedMessageId.value,
    })
  } else {
    pendingStore.addPendingQuery({
      text,
      agentId: selectedAgentId.value ?? undefined,
      uploadIds: uploadStore.uploads.map((u) => u.id),
      type: 'create' as const,
    })
  }

  editedMessageId.value = null
  messageText.value = ''

  await nextTick()
  scrollToBottom()
  setAutoScrollToBottom(true)
}

const { isReady } = useIsCaseReady()

const deleteAttachment = (attachmentId: string) => {
  uploadStore.removeUpload(attachmentId)
  const pendingQuery = pendingStore.pendingQueries.find((q) => q.uploadIds.includes(attachmentId))
  if (pendingQuery) {
    const idx = pendingQuery?.uploadIds.indexOf(attachmentId)
    if (idx > -1) pendingQuery.uploadIds.splice(idx, 1)
  }
}

const deletePendingQuery = (queryId: string) => {
  const idx = pendingStore.pendingQueries.findIndex((q) => q.id === queryId)
  if (idx > -1) {
    const pendingQuery = pendingStore.pendingQueries[idx]
    pendingQuery.uploadIds.forEach((id) => uploadStore.removeUpload(id))
    pendingStore.pendingQueries.splice(idx, 1)
  }
}

const { editedMessageId, cancelEdit, editQuery } = useCaseQueryEdit(messageText, selectedAgentId)

const rerunQuery = (queryId: string) => {
  editQuery(queryId)
  setPendingMessage(messageText.value)
}

/**
 * Track the height of the floating input so that we can add an equivalent
 * amount of padding to the scroll container to prevent the input from
 * covering the end of the last message.
 */

const inputRef = useTemplateRef<HTMLElement>('message-input')
const lastQueryRef = ref<HTMLElement | null>(null)
const lastResponseRef = ref<HTMLElement | null>(null)
const scrollContainerRef = useTemplateRef('scroll-container')
const { scrollToBottom, chatPadding, inputPadding, setAutoScrollToBottom } = useCaseScroll({
  containerRef: scrollContainerRef,
  inputRef: inputRef,
  lastQueryRef,
  lastResponseRef,
})

const mainContainerRef = useTemplateRef('mainContainer')
const { isOverDropZone } = useDropZone(mainContainerRef, {
  onDrop: (files) => {
    if (files && files.length > 0) {
      uploadStore.addPendingUploads(files)
    }
  },
})

const inputPlaceholder = computed(() =>
  isFullCase(caseStore.activeCase) && caseStore.activeCase.messages.length
    ? 'Ask a follow up'
    : 'Ask a question',
)

/**
 * Because we can be in an edited state,
 * in which case all messages after the one we're editing are due to be discarded,
 * we compute the actual messages we want to show in chat bubles here.
 */
const bubbleMessageIds = computed(() => {
  if (!isFullCase(caseStore.activeCase)) {
    return []
  }

  const allIds = caseStore.activeCase.messages.map((m) => m.id)

  // we could have an edited message, meaning it's currently in the input field
  // or we could've set it to dispatch, so it's in pending store
  const editedId =
    editedMessageId.value || pendingStore.pendingQueries.find((q) => q.type === 'edit')?.id

  if (!editedId) {
    return allIds
  }

  const idx = allIds.indexOf(editedId)
  return allIds.slice(idx + 1)
})

const { bubbles } = useChatBubbles(bubbleMessageIds)

const addIntegrationFiles = (files: GoogleDriveFile[]) => uploadStore.addIntegrationFiles(files)

const supportedMimeTypes = useSupportedMimeTypes()

const assignRefIfLastQuery = (el: Element | ComponentPublicInstance | null, index: number) => {
  if (index === bubbles.value.length - 1 || index === bubbles.value.length - 2) {
    lastQueryRef.value = el as HTMLElement | null
  }
}

const assignRefIfLastResponse = (el: Element | ComponentPublicInstance | null, index: number) => {
  if (index === bubbles.value.length - 1) {
    lastResponseRef.value = el as HTMLElement | null
  }
}
</script>

<template>
  <div
    ref="mainContainer"
    class="relative flex min-h-0 flex-col"
  >
    <ScrollGradient
      :container="scrollContainerRef"
      class="inset-x-8 from-background-transparent to-surface-secondary"
      location="top"
      size="md"
    />
    <div
      ref="scroll-container"
      :style="{ paddingBottom: `${inputPadding}px` }"
      class="go-scrollbar relative min-h-0 grow scroll-pb-8 flex-col items-center gap-2 overflow-auto p-8"
    >
      <div
        v-if="isFullCase(caseStore.activeCase)"
        class="go-column flex flex-col gap-2"
        role="log"
        :aria-label="caseStore.activeCase.name ?? 'Chat history'"
      >
        <template
          v-for="(bubble, index) in bubbles"
          :key="bubble.id"
        >
          <CaseQuery
            v-if="bubble.type === 'sent'"
            :ref="(el) => assignRefIfLastQuery(el, index)"
            :message="bubble.message"
            role="listitem"
            class="w-full"
            :disabled="!isReady"
            @delete-attachment="deleteAttachment"
            @delete-query="deletePendingQuery(bubble.message.id)"
            @edit-query="editQuery(bubble.message.id)"
          />

          <CaseResponse
            v-else-if="bubble.type === 'received'"
            :ref="(el) => assignRefIfLastResponse(el as HTMLElement, index)"
            :disabled="!isReady"
            :messages="bubble.messages"
            :previous-query-id="bubble.previousQueryId"
            role="listitem"
            class="w-full"
            @select-agent="emit('select-agent', $event)"
            @rerun-query="rerunQuery"
          />
        </template>
      </div>
      <div
        :style="{ height: `${chatPadding}px` }"
        class="pointer-events-none bg-background-transparent"
      />
    </div>

    <ScrollGradient
      :container="scrollContainerRef"
      class="inset-x-8 shrink-0 from-background-transparent to-surface-secondary"
      location="bottom"
      :style="{ marginBottom: `${inputPadding}px` }"
      size="md"
    />
    <div
      ref="message-input"
      class="absolute inset-x-8 bottom-0 z-1 flex min-w-0 grow justify-center bg-surface-secondary pb-3"
    >
      <CaseMessageInput
        v-model="messageText"
        class="go-column w-full shrink"
        :attachments="attachments"
        :placeholder="inputPlaceholder"
        :is-dropping-files="isOverDropZone"
        :is-editing="!!editedMessageId"
        :disabled="!isReady"
        @submit="setPendingMessage(messageText)"
        @delete-attachment="deleteAttachment"
        @cancel="cancelEdit"
      >
        <template #actions>
          <FileUploadMenu
            v-slot="{ getTriggerProps, menu }"
            library-picker-confirm-label="Attach"
            :file-input-props="{ accept: supportedMimeTypes.join(', ') }"
            @import:library="uploadStore.addLibraryFiles($event)"
            @import:computer="uploadStore.addPendingUploads($event)"
            @import:google="addIntegrationFiles"
          >
            <IconButton
              aria-label="Add attachment"
              variant="transparent"
              rounded
              size="lg"
              icon="plus"
              :active="menu?.open"
              v-bind="getTriggerProps()"
            />
          </FileUploadMenu>
          <AgentPicker
            v-model="selectedAgentId"
            :agents="agents"
          />
        </template>
      </CaseMessageInput>
    </div>
  </div>
</template>
