<script lang="ts">
const isOpen = ref(false)

export function useTemplateModal() {
  return {
    open() {
      isOpen.value = true
    },
    close() {
      isOpen.value = false
    },
    isOpen,
  }
}
</script>

<script setup lang="ts">
import Illustration from '@/assets/images/illustration.svg'
import { useProjectCreateModal } from '@/modules/Projects/ProjectCreateModal.vue'
import { useTemplates } from '@/modules/Projects/useTemplates'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import HotKey from '@/uiKit/HotKey.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import NavigationList from '@/uiKit/NavigationList.vue'
import NavigationListItem from '@/uiKit/NavigationListItem.vue'
import TextField from '@/uiKit/TextField.vue'
import { refDebounced, useIntervalFn } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useCreateProject } from '../Projects/useCreateProject'
import TemplateCard from './TemplateCard.vue'

const props = defineProps<{
  workspaceId: string
}>()

const search = ref('')
const searchDebounced = refDebounced(search, 300, { maxWait: 1000 })

const selectedTemplateCategory = ref<string | null>(null)

const isCloning = ref(false)
function closeModal() {
  if (isCloning.value) return
  isOpen.value = false
}

watch(
  () => isOpen.value,
  () => {
    if (!isOpen.value) {
      search.value = ''
    }
  },
)

const templateStore = useTemplates()
useIntervalFn(
  () => {
    templateStore.refreshTemplates()
  },
  1000 * 60 * 5, // Refresh every 5 minutes, to minimize FOUC and unsigned images
  { immediateCallback: true },
)

watch(
  () => isOpen.value,
  (open) => {
    if (!open) return
    templateStore.refreshTemplates()
  },
)

const filteredTemplates = computed(() => {
  return templateStore.templates.filter((template) => {
    if (template.name === null) {
      return false
    }
    if (template.parent_property) {
      return false
    }
    if (selectedTemplateCategory.value) {
      const categoryTag = '[' + selectedTemplateCategory.value + ']'
      return template.name.includes(categoryTag)
    }

    return template.name.toLowerCase().includes(searchDebounced.value.toLowerCase())
  })
})

const router = useRouter()

const { createNewProject } = useCreateProject()
const onCreateNewProject = async () => {
  isCloning.value = true

  const result = await createNewProject(props.workspaceId)

  isCloning.value = false

  if (!result.ok) {
    return
  }

  closeModal()
  router.push({
    name: 'WorkspaceProject',
    params: { workspaceId: props.workspaceId, projectId: result.data.id },
  })
}

const onSearchInput = (s: string) => {
  search.value = s
  if (s) {
    selectedTemplateCategory.value = null
  }
}

const projectCreateModal = useProjectCreateModal()
</script>

<template>
  <ModalDialog
    :open="isOpen"
    :background-blur="true"
    @close="closeModal"
  >
    <IconButton
      icon="close"
      variant="neutral"
      size="md"
      rounded
      class="absolute right-4 top-4 z-10"
      :disabled="isCloning"
      @click="closeModal"
    />
    <div
      class="flex h-[640px] w-[900px] rounded-corner-16 bg-surface-primary-persist"
      data-test="template-modal"
    >
      <div class="relative flex w-[240px] shrink-0 flex-col justify-between py-2">
        <div class="flex flex-col gap-2">
          <h2 class="px-4 py-2 text-display-xs-20px-default text-text">
            Templates
            <sup class="text-xs-11px-bold text-text-warning">{{ filteredTemplates.length }}</sup>
          </h2>
          <div class="px-2">
            <TextField
              size="sm"
              placeholder="Search"
              aria-label="Search templates"
              :value="search"
              autofocus
              class="w-auto transition-transform duration-200"
              :disabled="isCloning"
              @input="(s) => onSearchInput(s)"
            >
              <template #trailing-icon>
                <div class="flex w-8 translate-x-2.5 justify-end overflow-hidden">
                  <div
                    class="transition-all duration-300"
                    :class="[
                      search === searchDebounced
                        ? 'translate-x-0 opacity-0 delay-100'
                        : '-translate-x-2.5 opacity-100',
                    ]"
                  >
                    <CircularProgress size="sm" />
                  </div>
                </div>
              </template>
            </TextField>
          </div>

          <NavigationList>
            <NavigationListItem
              class="group/item"
              data-test="template-filter-item"
              aria-label="All templates"
              :force-active-state="!!searchDebounced || selectedTemplateCategory === null"
              @click="selectedTemplateCategory = null"
            >
              All
            </NavigationListItem>
            <NavigationListItem
              v-for="(category, index) in templateStore.templateCategories"
              :key="index"
              class="group/item"
              data-test="template-filter-item"
              aria-label="Filter templates"
              :force-active-state="selectedTemplateCategory === category"
              @click="selectedTemplateCategory = category || null"
            >
              <div class="flex w-full items-center justify-between gap-2">
                <p class="truncate">
                  {{ category }}
                </p>
              </div>
            </NavigationListItem>
          </NavigationList>
        </div>

        <div class="p-2 px-4">
          <DarwinButton
            variant="outline"
            class="w-full"
            :rounded="true"
            name="Start from scratch"
            size="md"
            @click="
              () => {
                closeModal()
                projectCreateModal.open()
              }
            "
          >
            Start from scratch
          </DarwinButton>
        </div>
      </div>
      <div class="relative mr-1 flex size-full grow flex-col overflow-hidden rounded-r-corner-16">
        <div class="box-content h-full w-[675px] overflow-y-scroll">
          <div
            v-if="isCloning"
            class="flex h-full w-[660px] flex-col items-center justify-center gap-2"
          >
            <CircularProgress
              size="sm"
              class="animate-spin"
            />
            <p class="pr-1 text-sm-12px-default text-text-subtle">
              A new project is being created...
            </p>
          </div>

          <div
            v-if="filteredTemplates.length"
            class="grid grid-cols-2 gap-1 py-1 pr-1"
            :class="[isCloning ? 'hidden' : '']"
          >
            <TemplateCard
              v-for="template in filteredTemplates"
              :key="template.id"
              :template="template"
              :workspace-id="workspaceId"
              @is-cloning="isCloning = true"
              @close="
                () => {
                  isCloning = false
                  closeModal()
                }
              "
            />
          </div>
          <!-- Empty state -->
          <div
            v-else
            class="flex h-[640px] flex-col items-center justify-center gap-2"
          >
            <Illustration />
            <p class="mb-2 text-sm-12px-default text-text">No results found</p>
            <p class="mb-2 text-center text-sm-12px-light text-text-subtle">
              You can start from scratch. If you need help for<br />
              setting up your project, just Ask Go by<br />
              pressing
              <span class="inline-block">
                <HotKey
                  class="inline-block"
                  hotkey="BracketRight"
                  size="xs"
              /></span>
              in your project.
            </p>
            <DarwinButton
              variant="outline"
              :rounded="true"
              size="md"
              @click="onCreateNewProject"
            >
              Start from scratch
            </DarwinButton>
          </div>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
