import { remove } from './client'
import type { BackendMeta } from './types'

export type DeleteCase = BackendMeta<'/api/workspaces/{workspace_id}/cases/{case_id}', 'delete'>

export type DeleteCaseParams = {
  workspaceId: string
  caseId: string
}

export const deleteCase = async ({ caseId, workspaceId }: DeleteCaseParams) =>
  remove<undefined, DeleteCase['successResponse'], DeleteCase['path']>(
    `/api/workspaces/${workspaceId}/cases/${caseId}`,
    undefined,
  )
