import type { PropertyType } from '@/backend/types'
import type { Field } from '@/modules/Project/Fields/types'
import { assertIsNotNullOrUndefined, invariant } from '@/shared/utils/typeAssertions'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'
import { addUnit, DEFAULT_FORMAT } from './Fields/utils/number'
import type { NumberPropertyFormat, Property } from './Properties/types'
import type { FieldPreview } from './types/fieldPreview'
import { useProject } from './useProject'
import { useResolveProjectRoute } from './useResolveProjectRoute'

type Serializer<Type extends PropertyType> = (
  field: Field<Type> | FieldPreview<Type>,
  property?: Property,
) => string

/**
 * Returns a function that serializes any field into a string representing
 * its value. Is used in clipboard operations and any other situation where
 * we need to display a single text value to represent a given field.
 *
 * The function returned by the composable optionally
 * accepts the field property as the second argument.
 * We should move in the direction of this being required,
 * as otheriwse, it is assumed the field's entity belongs to the currently selected project,
 * which more and more isn't true.
 */
export const useSerializeFieldToText = () => {
  const workspaceMemberStore = useWorkspaceMembers()
  const projectStore = useProject()
  const workspacesStore = useWorkspaces()
  const resolveSubProjectRoute = useResolveProjectRoute()

  const userSerializer: Serializer<'user_select'> = (field) => {
    let userIds = field.manualValue
    if (!userIds || userIds.length === 0) {
      userIds = field.toolValue
    }

    if (!userIds || userIds.length === 0) {
      return ''
    }

    const users = userIds.reduce<string[]>((acc, userId) => {
      const user = workspaceMemberStore.workspaceMembers.find((member) => member.id === userId)
      if (!user) {
        return acc
      }

      const name = user.fullName || user.email || user.id

      return [...acc, name]
    }, [])

    return users.join(', ')
  }

  /**
   * Collections should be serialized to a URL that will open the collection.
   */
  const collectionSerializer: Serializer<'collection'> = (field, givenProperty) => {
    assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')
    assertIsNotNullOrUndefined(projectStore.projectId, 'No current project')

    if ('manualName' in field && field.manualName) {
      return field.manualName
    }

    const property = givenProperty || projectStore.propertiesById[field.propertyId]
    if (!property) {
      return ''
    }

    if (property.type !== field.type) {
      throw new Error('Property type mismatch')
    }

    const subprojectId = property.config.subprojectConfig.child_project_id
    const route = resolveSubProjectRoute({
      parentEntityId: field.entityId,
      parentProjectId: projectStore.projectId,
      projectId: subprojectId,
      workspaceId: workspacesStore.currentWorkspace.id,
      parentPropertyId: property.id,
    })

    return `${window.location.origin}${route.fullPath}`
  }

  const fileCollectionSerializer: Serializer<'file_collection'> = (field, givenProperty) => {
    assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')
    assertIsNotNullOrUndefined(projectStore.projectId, 'No current project')
    const property = givenProperty || projectStore.propertiesById[field.propertyId]
    if (!property) {
      return ''
    }

    if (property.type !== field.type) {
      throw new Error('Property type mismatch')
    }

    const subprojectId = property.config.subprojectConfig.child_project_id

    const route = resolveSubProjectRoute({
      parentEntityId: field.entityId,
      parentProjectId: projectStore.projectId,
      projectId: subprojectId,
      workspaceId: workspacesStore.currentWorkspace.id,
    })

    return `${window.location.origin}${route.fullPath}`
  }

  const numberSerializer: Serializer<'number'> = (field, givenProperty) => {
    // These are the styles that show the number in parentheses when it's negative.
    const PARENTHESIS_STYLES: Array<NumberPropertyFormat['negativeFormat']> = [
      'colored_parentheses',
      'parentheses',
    ]

    const property = givenProperty || projectStore.propertiesById[field.propertyId]
    invariant(property, 'Property not found in store when serializing number field')
    invariant(property?.type === 'number', 'Number property not found when serializing')

    const format = property.config.isCustomFormat ? property.config.format : DEFAULT_FORMAT
    const formatter = new Intl.NumberFormat(navigator.language, {
      minimumFractionDigits: format.decimalPlaces === 'auto' ? undefined : format.decimalPlaces,
      maximumFractionDigits: format.decimalPlaces === 'auto' ? undefined : format.decimalPlaces,
      useGrouping: format.thousandsSeparator,
      signDisplay: format.negativeFormat === 'minus' ? 'auto' : 'never',
    })

    const numericLiteral = field.manualValue || field.toolValue
    if (numericLiteral !== null) {
      const formattedNumber = formatter.format(numericLiteral as Intl.StringNumericLiteral)

      if (PARENTHESIS_STYLES.includes(format.negativeFormat) && Number(numericLiteral) < 0) {
        return `(${addUnit(formattedNumber, field)})`
      }

      return addUnit(formattedNumber, field)
    }

    return ''
  }

  /**
   * Maps each field type to its serializer function. Is typed in such a way
   * that will cause a TS error if a new field type is added and not handled
   * here.
   */
  const fieldClipboardSerializers: { [T in Field['type']]: Serializer<T> } = {
    text: textSerializer,
    file: fileSerializer,
    json: jsonSerializer,
    multi_select: multiSelectSerializer,
    single_select: singleSelectSerializer,
    url: urlSerializer,
    user_select: userSerializer,
    collection: collectionSerializer,
    file_collection: fileCollectionSerializer,
    reference: referenceSerializer,
    number: numberSerializer,
  }

  const serializeField = <Type extends Field['type']>(
    field: Field<Type> | FieldPreview<Type>,
    property?: Property,
  ) => {
    const serializer = fieldClipboardSerializers[field.type]
    return serializer(field, property)
  }

  return serializeField
}

/**
 * TODO: GO-2985 - serialize to text once we have entity previews in the field response
 */
function referenceSerializer(field: Pick<Field<'reference'>, 'manualEntityIds' | 'toolEntityIds'>) {
  if (field.manualEntityIds && field.manualEntityIds.length > 0) {
    return field.manualEntityIds.join(', ')
  }

  if (field.toolEntityIds && field.toolEntityIds.length > 0) {
    return field.toolEntityIds.join(', ')
  }

  return ''
}

function textSerializer(field: Pick<Field<'text'>, 'manualValue' | 'toolValue'>) {
  return field.manualValue || field.toolValue || ''
}

function fileSerializer(field: Pick<Field<'file'>, 'manualFilename' | 'toolFilename'>) {
  return field.manualFilename || field.toolFilename || ''
}

function singleSelectSerializer(field: Pick<Field<'single_select'>, 'manualValue' | 'toolValue'>) {
  return field.manualValue?.at(0) || field.toolValue?.at(0) || ''
}

function multiSelectSerializer(field: Pick<Field<'multi_select'>, 'manualValue' | 'toolValue'>) {
  if (field.manualValue) {
    return field.manualValue.join(', ')
  }

  if (field.toolValue) {
    return field.toolValue.join(', ')
  }

  return ''
}

function jsonSerializer(field: Pick<Field<'json'>, 'manualValue' | 'toolValue'>) {
  return field.manualValue || field.toolValue || ''
}

function urlSerializer(field: Pick<Field<'url'>, 'manualValue' | 'toolValue'>) {
  return field.manualValue || field.toolValue || ''
}
