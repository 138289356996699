import type { EntityIdFilter } from '@/modules/Project/Filters/types'

export function buildEntityIdFilter(entityIds: Iterable<string> = []): EntityIdFilter {
  return {
    subject: 'entity_id',
    matcher: {
      name: 'any_of',
      values: Array.from(entityIds),
    },
  }
}
