<script
  setup
  lang="ts"
  generic="T extends LibraryItem['type'], FilteredItem extends LibraryItem & { type: T }"
>
import { useLibraryStore, type LibraryItem } from '@/modules/Library/libraryStore'
import { useSearchField } from '@/modules/Workspaces/KnowledgeHub/Common/useSearchField'
import { provideFileTableContext } from '@/modules/Workspaces/KnowledgeHub/Files/context'
import FileTable from '@/modules/Workspaces/KnowledgeHub/Files/FileTable.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ModalDialogHeader from '@/uiKit/ModalDialogHeader.vue'
import TextField from '@/uiKit/TextField.vue'
import { useElementSize, useResizeObserver } from '@vueuse/core'
import { computed, ref, useTemplateRef, watch } from 'vue'

const props = defineProps<{
  /** If provided, only LibraryItems of this type will be shown */
  only?: T
  confirmLabel: string
}>()

const emit = defineEmits<{
  select: [libraryItems: Array<FilteredItem>]
  close: []
}>()

const { query, eventHandlers } = useSearchField()
const libraryStore = useLibraryStore()

const selection = ref(new Set<string>())

const container = useTemplateRef('container')
const { height: currentContentHeight } = useElementSize(container)
/**
 * When the user filters files, number of visible file rows will likely get smaller.
 * This will cause the container/dialog to change size as the user types.
 * To prevent the dialog from jumping around, set its minHeight to be the max height we've seen so far.
 */
const maxSeenContainerHeight = ref(0)

/** Clear maxSeenContainerHeight when we resize the viewport because it might be too large */
useResizeObserver(document.body, () => (maxSeenContainerHeight.value = 0))

watch(
  currentContentHeight,
  (val) => {
    maxSeenContainerHeight.value = Math.max(val, maxSeenContainerHeight.value)
  },
  { immediate: true },
)

provideFileTableContext({
  filterOwners: ref([]),
  filterFileType: ref([]),
  filterItemType: computed(() => props.only),
  isSelected(id: string) {
    return selection.value.has(id)
  },
  setSelected(id: string, state: boolean) {
    if (state) {
      selection.value.add(id)
    } else {
      selection.value.delete(id)
    }
  },
})

function onSelect() {
  const selectedIds = Array.from(selection.value)
  const selectedItems = selectedIds.map((id) => libraryStore.getItem(id)) as FilteredItem[]

  emit('select', selectedItems)
}
</script>

<template>
  <div
    ref="container"
    data-testid="file-picker-container"
    :style="{ minHeight: maxSeenContainerHeight ? `${maxSeenContainerHeight}px` : undefined }"
    class="flex flex-col overflow-auto"
  >
    <div>
      <ModalDialogHeader title="Library" />
      <IconButton
        aria-label="Close Library"
        icon="close"
        variant="transparent"
        size="md"
        rounded
        class="absolute right-2 top-2"
        @click="$emit('close')"
      />
    </div>
    <hr class="border-border-subtle" />
    <div class="flex shrink grow flex-col gap-4 overflow-auto px-12 py-6">
      <!-- Search -->
      <TextField
        :value="query"
        size="md"
        rounded
        placeholder="Search"
        @input="query = $event"
        v-on="eventHandlers"
      >
        <template #leading-icon>
          <IconSprite
            icon="search"
            class="text-icon-subtle"
          />
        </template>
      </TextField>
      <!-- Files -->
      <FileTable
        :search="query"
        class="shrink overflow-auto"
        full-row-selection
      />
    </div>
    <footer class="flex shrink-0 justify-end gap-2 bg-surface-secondary p-2">
      <!-- Buttons -->
      <div class="flex gap-2">
        <DarwinButton
          variant="transparent"
          size="md"
          rounded
          @click="selection.clear()"
        >
          Deselect all
        </DarwinButton>
        <DarwinButton
          variant="black"
          size="md"
          rounded
          :disabled="selection.size === 0"
          @click="onSelect"
        >
          {{ confirmLabel }}
        </DarwinButton>
      </div>
    </footer>
  </div>
</template>
