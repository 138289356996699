import type { components } from '@/api'
import { get } from './client'

export type GetEffectivePlanPath = `/api/workspaces/${string}/billing/effective-plan`
export type GetEffectivePlanResponse = components['schemas']['Billing.Plan']

/**
 * Gets the effective plan in the given workspace.
 */
export const getEffectivePlan = (workspaceId: string) =>
  get<undefined, GetEffectivePlanResponse, GetEffectivePlanPath>(
    `/api/workspaces/${workspaceId}/billing/effective-plan`,
    undefined,
  )
