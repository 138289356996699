import { post } from './client'
import type { BackendMeta } from './types'

export type StartCaseFileUpload = BackendMeta<
  '/api/workspaces/{workspace_id}/cases/{case_id}/start_file_upload',
  'post'
>

/**
 * Fires a network request to he backend to provide a file url to upload a file to
 * The backend action will generate a presigned url for the file and return it,
 * and also save it onto the manual_value of the given field.
 */
export const startCaseFileUpload = (workspaceId: string, caseId: string, filename: string) =>
  post<
    StartCaseFileUpload['body'],
    StartCaseFileUpload['successResponse'],
    StartCaseFileUpload['path']
  >(`/api/workspaces/${workspaceId}/cases/${caseId}/start_file_upload`, { filename })
