import { useLimitedAction } from '@/modules/Billing/useLimitedAction'
import type { LibraryFile } from '@/modules/Library/libraryStore'
import type { FileCollectionField, FileField } from '@/modules/Project/Fields/types'
import { promiseQueue } from '@/modules/Workspaces/KnowledgeHub/Files/promiseQueue'
import type { GoogleDriveFile } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/useGoogleDriveConnection'
import { useIntegrationStore } from '@/modules/Workspaces/KnowledgeHub/useIntegrationStore'
import { toast } from '@/shared/toast'
import { useSetFieldValue } from '@/shared/useSetFieldValue'
import { invariant } from '@/shared/utils/typeAssertions'

type FieldContext = {
  field: FileField | FileCollectionField
  entityId: string
  workspaceId: string
  projectId: string
}

const maxConcurrent = 10
const maxBatchSize = 100

export function useFileUrlUpload() {
  return { uploadGoogleDriveFiles, uploadLibraryFiles }
}

function uploadLibraryFiles(props: FieldContext & { files: LibraryFile[] }) {
  const { field, entityId, workspaceId, projectId } = props
  const [firstFile, ...otherFiles] = props.files
  invariant(firstFile)
  if (props.files.length > maxBatchSize) {
    toast.error(`You can upload up to ${maxBatchSize} files at a time`)
    return
  }

  const setFieldValue = useSetFieldValue()

  const uploadFirstFile = () => {
    return setFieldValue({
      workspaceId,
      projectId,
      entityId,
      fields: {
        [field.propertyId]: {
          field,
          newValue: { file_name: firstFile.filename, file_url: firstFile.fileUrl },
        },
      },
    })
  }

  const { addEntity } = useLimitedAction()
  const createEntitiesForOtherFiles = otherFiles.map((file) => () => {
    return addEntity({
      projectId,
      workspaceId,
      fields: { [field.propertyId]: { file_name: file.filename, file_url: file.fileUrl } },
    })
  })

  return promiseQueue([uploadFirstFile, ...createEntitiesForOtherFiles], { maxConcurrent })
}

async function uploadGoogleDriveFiles(props: FieldContext & { files: GoogleDriveFile[] }) {
  const { field, entityId, workspaceId, projectId } = props
  const [firstFile, ...otherFiles] = props.files
  invariant(firstFile)

  if (props.files.length > maxBatchSize) {
    toast.error(`You can upload up to ${maxBatchSize} files at a time`)
    return
  }

  const integrationStore = useIntegrationStore()
  const driveConnection = integrationStore.findConnection('google_drive')
  invariant(driveConnection)

  const setFieldValue = useSetFieldValue()

  const setValueOnFirstField = () => {
    const fields = {
      [field.propertyId]: {
        field,
        newValue: {
          connection_id: driveConnection.id,
          integration_id: driveConnection.integrationId,
          file_id: firstFile.id,
          file_name: firstFile.name,
        },
      },
    }
    return setFieldValue({ workspaceId, projectId, entityId, fields })
  }

  const { addEntity } = useLimitedAction()
  const createEntitiesForOtherFiles = otherFiles.map((file) => () => {
    return addEntity({
      projectId,
      workspaceId,
      fields: {
        [field.propertyId]: {
          connection_id: driveConnection.id,
          integration_id: driveConnection.integrationId,
          file_id: file.id,
          file_name: file.name,
        },
      },
    })
  })

  await promiseQueue([setValueOnFirstField, ...createEntitiesForOtherFiles], { maxConcurrent })
}
