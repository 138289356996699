<script setup lang="ts">
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import CaseCard from './CaseCard.vue'
import type { Case, PartialCase } from './types'

defineProps<{ workspaceId: string; cases: (PartialCase | Case)[]; loading?: boolean }>()

const emit = defineEmits<{
  (e: 'remove', id: string): void
  (e: 'rename', id: string, name: string): void
}>()
</script>

<template>
  <nav
    class="contents"
    aria-label="Dashboard"
  >
    <!-- Loading Skeletons-->
    <template v-if="loading">
      <div class="grid w-full grid-cols-1 gap-4">
        <LoadingSkeleton
          v-for="n in cases.length"
          :key="n"
          :status="true"
          class="h-[76px] w-full"
        />
      </div>
    </template>
    <div
      v-else
      class="grid w-full grid-cols-1 gap-1"
    >
      <CaseCard
        v-for="targetCase in cases"
        :key="targetCase.id"
        v-memo="[targetCase.id, targetCase.updatedAt]"
        :target-case="targetCase"
        :workspace-id="workspaceId"
        @remove="emit('remove', targetCase.id)"
        @rename="emit('rename', targetCase.id, $event)"
      />
    </div>
  </nav>
</template>
