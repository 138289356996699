<script setup lang="ts">
import { toast } from '@/shared/toast'
import { invariant } from '@/shared/utils/typeAssertions'
import AppHeader from '@/sharedComponents/AppHeader.vue'
import SidebarToggle from '@/sharedComponents/SidebarToggle.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import { computed, ref, watch } from 'vue'
import { FALLBACK_CASE_NAME } from './serializers'
import { useCaseBackend } from './useCaseBackend'
import { useCaseStore } from './useCaseStore'

const props = defineProps<{ caseId?: string; workspaceId: string }>()

const { isOpen } = useSidebar()

const localCaseName = ref('')
const caseStore = useCaseStore()
watch(
  () => caseStore.activeCase,
  (newCase) => {
    localCaseName.value = newCase?.name || ''
  },
  { immediate: true },
)

const { updateCaseName } = useCaseBackend()

const backHomeRoute = computed(() => ({ name: 'WorkspaceCasesV2' }))

const onChangeName = async (newName: string) => {
  invariant(props.caseId, 'No caseId when changing case name')
  const res = await updateCaseName({
    caseId: props.caseId,
    name: newName,
    workspaceId: props.workspaceId,
  })

  if (!res.ok) {
    toast.error('Failed to update case name. If this issue persists, please contact support.')
  }
}
</script>

<template>
  <AppHeader>
    <template #crumbs>
      <DarwinButton
        size="sm"
        variant="transparent"
        class="transition-transform duration-300 ease-in-out-quint"
        :class="isOpen ? 'translate-x-0' : 'translate-x-12'"
        rounded
        :to="backHomeRoute"
      >
        <template #leading-icon>
          <IconSprite icon="chevron-left" />
        </template>
        Back home
      </DarwinButton>
    </template>
    <InlineTextField
      class="w-80 focus-within:[&>input]:placeholder:opacity-0"
      centered
      size="sm"
      :value="localCaseName"
      :placeholder="FALLBACK_CASE_NAME"
      @change="onChangeName"
    />
    <template #actions>
      <div class="flex flex-row gap-2">
        <SidebarToggle
          open-tooltip="Open case sidebar"
          close-tooltip="Close case sidebar"
          side="right"
          label="Toggle case sidebar"
          :is-open="caseStore.showSidebar"
          @toggle="caseStore.showSidebar = !caseStore.showSidebar"
        />
      </div>
    </template>
  </AppHeader>
</template>
