<script setup lang="ts">
import CaseSidebar from '@/modules/Cases/Sidebar/CaseSidebar.vue'
import { useProjectNavigator } from '@/modules/Cases/Sidebar/useProjectNavigator'
import { useCaseChannel } from '@/modules/Cases/useCaseChannel'
import { provideProjectNavigationContext } from '@/modules/Project/Navigation/projectNavigationContext'
import { toast } from '@/shared/toast'
import { isFileField } from '@/shared/utils/typeGuards'
import ResizablePanels from '@/sharedComponents/ResizablePanels.vue'
import { computed, onBeforeUnmount, onMounted, onUnmounted, toRef, watch } from 'vue'
import { useRouter } from 'vue-router'
import type { Field } from '../Project/Fields/types'
import { canPreviewFileField } from '../Project/Fields/utils/file'
import { useEntity } from '../Project/useEntity'
import { useGroundingStore } from '../Project/useGroundingStore'
import CaseChat from './CaseChat.vue'
import CaseFileViewer from './CaseFileViewer.vue'
import { useCasePendingQueryStore } from './useCasePendingQueryStore'
import { useCaseStore } from './useCaseStore'
import { useCaseUploadStore } from './useCaseUploadStore'
import { useQueryDispatcher } from './useQueryDispatcher'

const props = defineProps<{
  caseId: string
  workspaceId: string
  agentId?: string
}>()

const caseStore = useCaseStore()
const entityStore = useEntity()
const groundingStore = useGroundingStore()
const pendingStore = useCasePendingQueryStore()
const uploadStore = useCaseUploadStore()

const caseChannel = useCaseChannel(props.workspaceId, toRef(props, 'caseId'))
const router = useRouter()

const projectNavigator = useProjectNavigator()
provideProjectNavigationContext(projectNavigator)

useQueryDispatcher({
  caseId: computed(() => props.caseId),
  workspaceId: computed(() => props.workspaceId),
})

/** The file (if any) that we render in the file viewer. */
const activeFile = computed(() => {
  if (groundingStore.activeInputField) {
    return groundingStore.activeInputField
  }

  const activeField =
    entityStore.activePropertyId && entityStore.entity?.fields.get(entityStore.activePropertyId)
  if (!activeField || !isFileField(activeField)) {
    return null
  }

  return activeField
})

const openAgentInSidebar = (agentId: string) => {
  projectNavigator.navigate({ projectId: agentId })
  caseStore.showSidebar = true
}

const availableFileFields = computed<Field<'file' | 'file_collection'>[]>(() =>
  Array.from(entityStore.entity?.fields.values() || []).filter(
    // Only show file fields that can be previewed from within Go
    (f): f is Field<'file' | 'file_collection'> => isFileField(f) && canPreviewFileField(f),
  ),
)

const onCloseFileViewer = () => {
  entityStore.activePropertyId = null
  groundingStore.reset()
}

const onChangeFileField = (propertyId: string) => {
  groundingStore.activeInputId = propertyId
  entityStore.activePropertyId = propertyId
}

watch(
  () => props.caseId,
  (caseId) => caseStore.setActiveCase({ caseId, workspaceId: props.workspaceId }),
  { immediate: true },
)

watch(
  () => caseStore.latestOutput,
  (output) => {
    if (!output) return
    caseStore.showSidebar = true
    projectNavigator.navigate({ projectId: output.project.id, entityId: output.entity.id })
  },
  { immediate: true },
)

watch(
  () => caseStore.activeCaseId,
  (newCaseId, oldCaseId) => {
    caseStore.showSidebar = false
    uploadStore.clearUploads()
    onCloseFileViewer()
    stopUploads()
    pendingStore.pendingQueries = []
    projectNavigator.reset()

    const activeCaseGotRemoved = !newCaseId && !!oldCaseId
    if (activeCaseGotRemoved) {
      toast.error('This case has been deleted. You have been automatically redirected.')
      router.push({ name: 'WorkspaceProjectsV2' })
    }
  },
)

let stopUploads: () => void = () => {}

onMounted(() => {
  stopUploads = uploadStore.start({
    queueSize: 1,
    caseId: props.caseId,
    workspaceId: props.workspaceId,
  })
})

onBeforeUnmount(async () => {
  stopUploads()
  uploadStore.clearUploads()
  groundingStore.reset()
  pendingStore.pendingQueries = []
  await caseChannel.leaveChannel()
})

onUnmounted(() => {
  caseStore.activeCaseId = null
})
</script>

<template>
  <ResizablePanels
    class="bg-surface-secondary"
    storage-key="case-layout"
    :panels="[
      {
        id: 'main',
        minWidth: 350,
        defaultPercentage: 60,
      },
      {
        id: 'sidebar',
        minWidth: 350,
        defaultPercentage: 40,
        show: computed(() => caseStore.showSidebar),
      },
    ]"
  >
    <template #main>
      <CaseFileViewer
        v-if="activeFile"
        :field="activeFile"
        :available-file-fields="availableFileFields"
        class="h-full"
        @close="onCloseFileViewer"
        @change:field="onChangeFileField"
      />

      <CaseChat
        v-else
        :case-id="caseId"
        :workspace-id="workspaceId"
        class="h-full"
        @select-agent="openAgentInSidebar"
      />
    </template>
    <template #sidebar>
      <CaseSidebar class="size-full overflow-auto" />
    </template>
  </ResizablePanels>
</template>
