<script setup lang="ts">
import type { Project } from '@/modules/Projects/useProjects'

import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import ProjectCardV2 from './ProjectCardV2.vue'

defineProps<{ workspaceId: string; projects: Project[]; loading: boolean }>()

const emit = defineEmits<{
  (e: 'remove', id: string): void
  (e: 'rename', id: string, name: string): void
  (e: 'set-cover-image-download-error', id: string, value: boolean): void
}>()

const onSetCoverImageDownloadError = (id: string, value: boolean) => {
  emit('set-cover-image-download-error', id, value)
}
</script>

<template>
  <nav
    class="contents"
    aria-label="Dashboard"
  >
    <!-- Loading Skeletons-->
    <template v-if="loading">
      <div class="grid w-full grid-cols-2 gap-4">
        <LoadingSkeleton
          v-for="n in projects.length || 2"
          :key="n"
          :status="true"
          class="h-[260px] min-w-[200px] max-w-[360px] !rounded-corner-20"
        />
      </div>
    </template>
    <template v-else>
      <div class="grid w-full grid-cols-2 gap-1">
        <ProjectCardV2
          v-for="project in projects"
          ref="card"
          :key="project.id"
          :project="project"
          @remove="emit('remove', project.id)"
          @rename="emit('rename', project.id, $event)"
          @set-cover-image-download-error="onSetCoverImageDownloadError"
        />
      </div>
    </template>
  </nav>
</template>
