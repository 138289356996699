<script setup lang="ts">
import ProjectSidebarToggle from '@/modules/Projects/ProjectSidebarToggle.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'

const { isOpen } = useSidebar()
</script>

<template>
  <div class="px-3 py-2">
    <div class="flex flex-row items-center justify-between">
      <div class="relative flex items-center">
        <div
          class="absolute left-0 z-10 flex flex-row items-center transition duration-300 ease-in-out-quint"
          :class="
            isOpen
              ? 'pointer-events-none translate-x-[-8px] opacity-0'
              : 'translate-x-0 opacity-100'
          "
        >
          <ProjectSidebarToggle :disable-tooltip="isOpen" />
          <div class="ml-[9.5px] h-[20px] w-[2px] rounded border border-border-subtle"></div>
        </div>
        <slot name="crumbs"></slot>
      </div>
      <slot />
      <div>
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>
