<script lang="ts" setup>
// FIGMA: https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/V7-Design-System-(WIP)?node-id=262:4309&mode=dev
// this one should be mostly fully implemented, but not fully clarified in figma, so there may be details to iron out
import { computed, nextTick, onMounted, ref, useSlots, type InputHTMLAttributes } from 'vue'

import type { IconName } from './IconName'
import IconSprite from './IconSprite.vue'

const props = defineProps<{
  placeholder?: string
  value?: string
  size: 'sm' | 'xs'
  centered?: boolean
  error?: boolean
  autofocus?: boolean
  readonly?: boolean
  ariaLabel?: string
  icon?: IconName
  inputAttrs?: InputHTMLAttributes
}>()

const emit = defineEmits<{
  (e: 'change' | 'input' | 'submit', value: string): void
  (e: 'blur' | 'backspace' | 'esc' | 'focus', event: Event): void
}>()

defineExpose({
  focus: () => input.value?.focus(),
  /**
   * Can be used to reset the input value while the field is focused.
   * To use, first set the ref bound to the value prop of the component, then call this method.
   */
  reset: () => input.value && (input.value.value = props.value ?? ''),
})

const emitChange = (event: Event): void => emit('change', (event.target as HTMLInputElement).value)
const emitInput = (event: Event): void => emit('input', (event.target as HTMLInputElement).value)
const emitSubmit = (event: Event): void => emit('submit', (event.target as HTMLInputElement).value)
const emitEsc = (event: Event): void => emit('esc', event)
const emitBackspace = (event: Event): void => {
  if ((event.target as HTMLInputElement).value === '') {
    emit('backspace', event)
  }
}

const input = ref<HTMLInputElement>()

const active = ref(false)

onMounted(() => {
  nextTick(() => {
    if (!props.autofocus) {
      return
    }
    input.value?.focus()
  })
})

const focusing = ref(false)

const slots = useSlots()
const hasSuffix = computed(() => !!slots.suffix)
</script>

<template>
  <div
    class="flex items-center bg-background-gray-subtlest"
    :class="[
      size === 'sm' && ['rounded-corner-8', 'px-1.5 py-1'],
      size === 'xs' && ['rounded-corner-6', 'px-0 py-0.5'],
      !error && [
        'bg-background-transparent',
        'hover:[&:not(:disabled)]:bg-background-transparent-hovered',
      ],
      error && [
        'bg-background-critical-subtle',
        'focus:bg-background-critical-subtle',
        'hover:bg-background-critical-subtle',
      ],
      hasSuffix && 'pr-1',
    ]"
  >
    <IconSprite
      v-if="icon"
      class="text-icon-subtlest"
      :icon="icon"
    />
    <input
      ref="input"
      type="text"
      class="w-full bg-background-transparent px-1 py-0.5 text-sm-12px-default text-text transition-colors placeholder:text-text-subtlest aria-readonly:cursor-pointer focus:outline-none disabled:cursor-not-allowed disabled:text-text-disabled disabled:placeholder:text-text-disabled"
      :class="[centered && 'text-center', hasSuffix && 'pr-0']"
      data-1p-ignore
      :placeholder="placeholder"
      :value="value"
      :aria-label="ariaLabel"
      :readonly="readonly"
      v-bind="inputAttrs"
      @mousedown="active = true"
      @mouseup="active = false"
      @focus="emit('focus', $event)"
      @focusin="focusing = true"
      @focusout="focusing = false"
      @blur="emit('blur', $event)"
      @change="emitChange"
      @input="emitInput"
      @keydown.enter="emitSubmit"
      @keydown.esc="emitEsc"
      @keydown.backspace="emitBackspace"
    />
    <slot name="suffix" />
  </div>
</template>
