<script lang="ts" setup>
import AgentLogo from '@/modules/Cases/Sidebar/AgentLogo.vue'
import CaseDataRow from '@/modules/Cases/Sidebar/CaseDataRow.vue'
import TitleWithCount from '@/modules/Cases/Sidebar/TitleWithCount.vue'
import { useCaseStore } from '@/modules/Cases/useCaseStore'
import { useProjectNavigationContext } from '@/modules/Project/Navigation/projectNavigationContext'
import type { Entity } from '@/modules/Project/useProject'
import type { Project } from '@/modules/Projects/useProjects'
import { pluralize } from '@/shared/utils/string'
import { computed } from 'vue'

type AgentUsage = Record<Project['id'], { entityIds: Set<Entity['id']>; project: Project }>

const caseStore = useCaseStore()
const navContext = useProjectNavigationContext({ required: true })

/**
 * Group outputs by project
 */
const agentUsage = computed(() => {
  const grouped: AgentUsage = {}

  const outputs = caseStore.activeCaseOutputs || []

  for (const output of outputs) {
    const project = output.project
    const entity = output.entity

    if (!grouped[project.id]) {
      grouped[project.id] = { entityIds: new Set(), project }
    }

    grouped[project.id].entityIds.add(entity.id)
  }

  return grouped
})

function onAgentClick(project: Project) {
  navContext.navigate({ projectId: project.id })
}
</script>

<template>
  <!-- CaseUsedAgentList -->
  <div
    v-if="Object.keys(agentUsage).length"
    class="flex flex-col gap-2 px-2 py-4"
  >
    <TitleWithCount class="ml-2.5">
      <template #title>Agents</template>
      <template #count>{{ Object.keys(agentUsage).length }}</template>
    </TitleWithCount>
    <div
      v-for="(usage, agentId) in agentUsage"
      :key="agentId"
      @click="onAgentClick(usage.project)"
    >
      <CaseDataRow class="cursor-pointer">
        <template #icon>
          <AgentLogo :project="usage.project" />
        </template>
        <template #name>
          <div>
            {{ usage.project.name }}
          </div>
        </template>
        <template #description>
          {{ usage.entityIds.size }} {{ pluralize(usage.entityIds.size, 'entity', 'entities') }}
        </template>
      </CaseDataRow>
    </div>
  </div>
</template>
