<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import type { IconName } from '@/uiKit/IconName'
import IconSprite from '@/uiKit/IconSprite.vue'
import { computed } from 'vue'

const props = defineProps<{
  name: string
  description: string
  isPreview: boolean
  isActive: boolean
  icon: IconName
}>()

const emit = defineEmits<{
  activate: []
}>()

const buttonText = computed(() => {
  if (props.isPreview) {
    return props.isActive ? 'Requested' : 'I want this'
  }

  return props.isActive ? 'Connected' : 'Connect'
})

function onClick() {
  if (!props.isActive) {
    emit('activate')
  }
}
</script>

<template>
  <div
    class="group relative flex h-64 flex-col rounded-corner-12 bg-surface-secondary p-4 transition-all hover:bg-background-transparent-hovered"
  >
    <h3 class="text-md-13px-default">
      {{ name }}
    </h3>
    <p
      v-if="description"
      class="translate-y-0 text-sm-12px-light text-text-subtle"
    >
      {{ description }}
    </p>

    <IconSprite
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      :icon="icon"
      size="xxxxl"
    />
    <div class="mt-auto">
      <DarwinButton
        :variant="isActive ? 'neutral' : 'outline'"
        size="sm"
        rounded
        class="before:absolute before:inset-0 before:content-['']"
        :disabled="isPreview && isActive"
        @click="onClick"
      >
        <template
          v-if="isActive && !isPreview"
          #leading-icon
        >
          <IconSprite
            icon="check-circle-fill"
            size="md"
            class="text-icon-success"
          />
        </template>
        {{ buttonText }}
      </DarwinButton>
    </div>
  </div>
</template>
