<script setup lang="ts">
import { useLibraryStore } from '@/modules/Library/libraryStore'
import { useLibraryBackend } from '@/modules/Library/useLibraryBackend'
import { useUploadLibraryFile } from '@/modules/Library/useUploadLibraryFile'
import ActionBar from '@/modules/Workspaces/KnowledgeHub/Files/ActionBar.vue'
import { provideFileTableContext } from '@/modules/Workspaces/KnowledgeHub/Files/context'
import FileDropIndicator from '@/modules/Workspaces/KnowledgeHub/Files/FileDropIndicator.vue'
import FilesTitleBar from '@/modules/Workspaces/KnowledgeHub/Files/FilesTitleBar.vue'
import FileTable from '@/modules/Workspaces/KnowledgeHub/Files/FileTable.vue'
import { promiseQueue } from '@/modules/Workspaces/KnowledgeHub/Files/promiseQueue'
import { useLibraryDropzone } from '@/modules/Workspaces/KnowledgeHub/Files/useLibraryDropzone'
import PageContainer from '@/modules/Workspaces/KnowledgeHub/Layout/PageContainer.vue'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import { computed, ref, useTemplateRef, watch } from 'vue'

const libraryStore = useLibraryStore()
const { deleteItem } = useLibraryBackend()
const { startUpload } = useUploadLibraryFile()
const workspace = useCurrentWorkspace()

const searchTerm = ref('')
const dropZoneRef = useTemplateRef('dropzone')
const selectedFiles = ref(new Set<string>())
const isBulkDeleteConfirmationOpen = ref(false)

const { isOverDropZone, draggedFiles } = useLibraryDropzone({
  dropZoneRef,
  async onDrop(files) {
    const uploadStarters = files.map((file) => () => startUpload(file))
    promiseQueue(uploadStarters, { maxConcurrent: 4 })
  },
})

provideFileTableContext({
  filterOwners: ref([]),
  filterFileType: ref([]),
  isSelected(id: string) {
    return selectedFiles.value.has(id)
  },
  setSelected(id: string, state: boolean) {
    if (state) {
      selectedFiles.value.add(id)
    } else {
      selectedFiles.value.delete(id)
    }
  },
})

const deletionConfirmationText = computed(() => {
  if (selectedFiles.value.size === 1) {
    return 'Are you sure you want to delete this file?'
  } else if (selectedFiles.value.size === libraryStore.libraryItems.length) {
    return 'Are you sure you want to delete all files?'
  }
  return `Are you sure you want to delete ${selectedFiles.value.size} files?`
})

function onMassSelectionChange(selectAll: boolean) {
  if (selectAll) {
    for (const item of libraryStore.libraryItems) {
      selectedFiles.value.add(item.id)
    }
  } else {
    selectedFiles.value.clear()
  }
}

function onDeleteSelection() {
  isBulkDeleteConfirmationOpen.value = false
  deleteItem({ itemId: Array.from(selectedFiles.value), workspaceId: workspace.value.id })
}

watch(
  () => libraryStore.libraryItems,
  () => {
    const nextSelection = new Set<string>()
    for (const item of libraryStore.libraryItems) {
      if (selectedFiles.value.has(item.id)) {
        nextSelection.add(item.id)
      }
    }
    selectedFiles.value = nextSelection
  },
  { immediate: true },
)
</script>

<template>
  <div
    ref="dropzone"
    class="size-full"
  >
    <PageContainer class="relative flex flex-col gap-6">
      <FilesTitleBar @search-term="searchTerm = $event" />
      <!-- 👇 mb-16 is so that last row is not obscured by the action bar -->
      <FileTable
        :search="searchTerm"
        class="mb-16"
      />
      <Transition
        enter-active-class="transition duration-200 ease-in-out-quint"
        enter-from-class="opacity-0 translate-y-2 scale-95"
        enter-to-class="opacity-100 translate-y-0 scale-100"
        leave-active-class="transition duration-200 ease-in-out-quint"
        leave-from-class="opacity-100 translate-y-0 scale-100"
        leave-to-class="opacity-0 translate-y-2 scale-95"
      >
        <ActionBar
          v-if="selectedFiles.size > 0"
          :all="selectedFiles.size === libraryStore.libraryItems.length"
          :selected="selectedFiles.size"
          class="fixed bottom-4 left-1/2 -translate-x-1/2"
          @select="onMassSelectionChange"
          @delete="isBulkDeleteConfirmationOpen = true"
        />
      </Transition>

      <FileDropIndicator
        :show="isOverDropZone"
        class="pointer-events-none absolute inset-0 size-full"
        :files="draggedFiles"
      />

      <ConfirmationDialog
        :open="isBulkDeleteConfirmationOpen"
        title="Delete files"
        :description="deletionConfirmationText"
        @close="isBulkDeleteConfirmationOpen = false"
        @confirm="onDeleteSelection"
      />
    </PageContainer>
  </div>
</template>
