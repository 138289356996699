import { getUser } from '@/backend/getUser'
import ErrorPage from '@/modules/App/ErrorPage.vue'
import LogInErrorPage from '@/modules/App/LogInErrorPage.vue'
import VerifyEmailPage from '@/modules/App/VerifyEmailPage.vue'
import StripeCheckoutRedirect from '@/modules/Billing/StripeCheckoutRedirect.vue'
import { stripePortalRedirect } from '@/modules/Billing/stripePortalRedirect'
import { casesRoutes } from '@/modules/Cases/routes'
import WorkspaceCases from '@/modules/Cases/WorkspaceCases.vue'
import AcceptInvitation, {
  INVITATION_STORAGE_KEY,
} from '@/modules/IdentityAndAccess/AcceptInvitation.vue'
import LogoutPage from '@/modules/IdentityAndAccess/LogoutPage.vue'
import { serializeUser, useUser } from '@/modules/IdentityAndAccess/useUser'
import EmptyEntityView from '@/modules/Project/EmptyEntityView.vue'
import EntityView from '@/modules/Project/EntityView.vue'
import WorkspaceProject from '@/modules/Project/ProjectView.vue'
import WorkflowView from '@/modules/Project/WorkflowView.vue'
import ProjectSidebar from '@/modules/Projects/ProjectSidebar.vue'
import WorkspaceProjects from '@/modules/Projects/WorkspaceProjects.vue'
import { knowledgeHubRoutes } from '@/modules/Workspaces/KnowledgeHub/routes'
import WorkspaceDashboard from '@/modules/Workspaces/WorkspaceDashboard.vue'
import WorkspaceDetails from '@/modules/Workspaces/WorkspaceDetails.vue'
import WorkspaceHomepage from '@/modules/Workspaces/WorkspaceHomepage.vue'
import WorkspaceHomepageCrumbs from '@/modules/Workspaces/WorkspaceHomepageCrumbs.vue'
import WorkspaceList from '@/modules/Workspaces/WorkspaceList.vue'
import WorkspacePageHeader from '@/modules/Workspaces/WorkspacePageHeader.vue'
import WorkspaceSettingsAiModels from '@/modules/WorkspaceSettings/WorkspaceSettingsAiModels.vue'
import WorkspaceSettingsApiKeys from '@/modules/WorkspaceSettings/WorkspaceSettingsApiKeys.vue'
import WorkspaceSettingsBilling from '@/modules/WorkspaceSettings/WorkspaceSettingsBilling.vue'
import WorkspaceSettingsPlans from '@/modules/WorkspaceSettings/WorkspaceSettingsPlans.vue'
import WorkspaceSettingsProfile from '@/modules/WorkspaceSettings/WorkspaceSettingsProfile.vue'
import WorkspaceSettingsSidebar from '@/modules/WorkspaceSettings/WorkspaceSettingsSidebar.vue'
import WorkspaceSettingsUsers from '@/modules/WorkspaceSettings/WorkspaceSettingsUsers.vue'
import WorkspaceSettingsWorkspace from '@/modules/WorkspaceSettings/WorkspaceSettingsWorkspace.vue'
import { useAnalytics } from '@/sharedComposables/useAnalytics'
import { useUtmParams } from '@/sharedComposables/useUtmParams'
import { useAuth0, type AuthorizationParams } from '@auth0/auth0-vue'
import { watchEffect } from 'vue'
import { RouterView, createRouter, createWebHistory } from 'vue-router'
import WorkspaceDashboardCrumbs from './modules/Workspaces/WorkspaceDashboardCrumbs.vue'

const shouldBlockMobileUse = { value: true }
export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: 'AcceptInvitation',
      path: '/accept-invitation',
      component: AcceptInvitation,
      props: (route) => ({
        workspaceId: route.query.workspace_id,
        projectId: route.query.project_id,
        inviteToken: route.query.token,
      }),
    },
    // Visited when the user is redirect to Go after a Stripe checkout
    {
      name: 'StripeCheckoutRedirect',
      path: '/plan_checkout',
      component: StripeCheckoutRedirect,
      props: (route) => ({ status: route.query.status, workspaceId: route.query.workspace_id }),
    },
    // Visited when the user is redirected to Go after updating their billing details in the Stripe portal
    {
      name: 'StripePortalRedirect',
      path: '/plan_management',
      redirect: stripePortalRedirect,
    },
    {
      name: 'NotAvailableOnMobile',
      path: '/not-available-on-mobile',
      component: ErrorPage,
      props: {
        title: "Go isn't available on Mobile",
        message: 'Please use a desktop or laptop to access Go.',
        showButton: false,
      },
    },
    {
      name: 'LogInError',
      path: '/login-error',
      component: LogInErrorPage,
      beforeEnter: (to, from) => {
        if (!from.name && !to.redirectedFrom?.name) {
          // Prevent malicious actors injecting custom errors into the app, only
          // continue with the navigation if the error was triggered from within
          // the app.
          return {
            name: 'LogInError',
            query: {},
          }
        }
      },
      props: (route) => ({ message: route.query.message }),
    },
    {
      name: 'VerifyEmail',
      path: '/verify-email',
      component: VerifyEmailPage,
    },
    {
      name: 'ErrorPage',
      path: '/error',
      component: ErrorPage,
      beforeEnter: (to, from) => {
        if (!from.name && !to.redirectedFrom?.name) {
          // Prevent malicious actors injecting custom errors into the app, only
          // continue with the navigation if the error was triggered from within
          // the app.
          return {
            name: 'ErrorPage',
            query: {},
          }
        }
      },
      props: (route) => ({ title: route.query.title, message: route.query.message }),
    },
    {
      name: 'Home',
      path: '/',
      components: {
        default: WorkspaceList,
        BreadCrumbs: RouterView,
      },
      props: {
        BreadCrumbs: () => ({ name: 'BreadCrumbs' }),
      },
      children: [
        {
          name: 'HomeV2',
          path: ':workspaceId/home',
          redirect: { name: 'WorkspaceProjectsV2' },
          components: { default: WorkspaceDashboard },
          props: {
            default: (route) => ({ workspaceId: route.params.workspaceId }),
            Sidebar: () => ({ name: 'Sidebar' }),
          },
          children: [
            {
              name: 'WorkspaceProjectsV2',
              path: 'projects',
              props: {
                default: true,
                Sidebar: (route) => ({
                  name: 'Sidebar',
                  workspaceId: route.params.workspaceId,
                  open: true,
                }),
                BreadCrumbs: (route) => ({
                  name: 'BreadCrumbs',
                  workspaceId: route.params.workspaceId,
                }),
              },
              components: {
                default: WorkspaceProjects,
                Sidebar: ProjectSidebar,
                BreadCrumbs: WorkspaceDashboardCrumbs,
              },
            },
            {
              path: 'cases',
              name: 'WorkspaceCasesV2',
              props: {
                default: true,
                Sidebar: (route) => ({
                  name: 'Sidebar',
                  workspaceId: route.params.workspaceId,
                  open: true,
                }),
                BreadCrumbs: (route) => ({
                  workspaceId: route.params.workspaceId,
                }),
              },
              components: {
                default: WorkspaceCases,
                Sidebar: ProjectSidebar,
                BreadCrumbs: WorkspaceDashboardCrumbs,
              },
            },
          ],
        },
        {
          name: 'Workspace',
          path: ':workspaceId',
          components: { default: WorkspaceDetails, BreadCrumbs: RouterView },
          redirect: { name: 'WorkspaceProjects' },
          props: {
            default: (route) => ({ workspaceId: route.params.workspaceId }),
            Sidebar: () => ({ name: 'Sidebar' }),
            BreadCrumbs: () => ({ name: 'BreadCrumbs' }),
          },
          children: [
            // /projects - Homepage
            {
              path: 'projects',
              name: 'WorkspaceProjects',
              props: {
                default: true,
                Sidebar: (route) => ({
                  name: 'Sidebar',
                  workspaceId: route.params.workspaceId,
                  open: true,
                }),
                // BreadCrumbs: (route) => ({
                //   name: 'BreadCrumbs',
                //   workspaceId: route.params.workspaceId,
                // }),
                BreadCrumbs: false,
              },
              components: {
                default: WorkspaceHomepage,
                Sidebar: ProjectSidebar,
                BreadCrumbs: WorkspaceHomepageCrumbs,
              },
            },
            ...casesRoutes,
            // /projects/:projectId - Project
            {
              name: 'WorkspaceProject',
              path: 'projects/:projectId',
              redirect: {
                name: 'WorkspaceProjectTable',
              },
              children: [
                // /projects/:projectId - Project Table
                {
                  name: 'WorkspaceProjectTable',
                  path: '',
                  props: {
                    default: (route) => ({
                      workspaceId: route.params.workspaceId,
                      projectId: route.params.projectId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: WorkspaceProject,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/views/:viewId - Project View
                {
                  name: 'WorkspaceProjectTableView',
                  path: 'views/:viewId',
                  props: {
                    default: (route) => ({
                      workspaceId: route.params.workspaceId,
                      projectId: route.params.projectId,
                      viewId: route.params.viewId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: WorkspaceProject,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/entities - Project Entities (empty state)
                {
                  name: 'WorkspaceProjectEntityViewEmpty',
                  path: 'entities',
                  props: {
                    default: (route) => ({
                      projectId: route.params.projectId,
                      workspaceId: route.params.workspaceId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EmptyEntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/entities/:entityId - Entity View
                {
                  name: 'WorkspaceProjectEntityView',
                  path: 'entities/:entityId',
                  props: {
                    default: (route) => ({
                      entityId: route.params.entityId,
                      projectId: route.params.projectId,
                      workspaceId: route.params.workspaceId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/views/:viewId/entities/:entityId - Entity View (specific project view)
                {
                  name: 'WorkspaceProjectEntitySubView',
                  path: 'views/:viewId/entities/:entityId',
                  props: {
                    default: (route) => ({
                      entityId: route.params.entityId,
                      projectId: route.params.projectId,
                      workspaceId: route.params.workspaceId,
                      viewId: route.params.viewId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/workflow - Workflow View
                {
                  name: 'WorkflowView',
                  path: 'workflow',
                  props: {
                    default: (route) => ({
                      workspaceId: route.params.workspaceId,
                      projectId: route.params.projectId,
                    }),
                    BreadCrumbs: true,
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                  },
                  components: {
                    default: WorkflowView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
              ],
            },
            // /projects/:projectId/collections/:parentProjectId - SubProject
            {
              name: 'WorkspaceSubProject',
              path: 'projects/:parentProjectId/collections/:projectId',
              redirect: {
                name: 'WorkspaceSubProjectTable',
              },
              children: [
                // /projects/:projectId/collections/:parentProjectId - SubProject Table
                {
                  name: 'WorkspaceSubProjectTable',
                  path: '',
                  props: {
                    default: (route) => ({
                      workspaceId: route.params.workspaceId,
                      parentProjectId: route.params.parentProjectId,
                      projectId: route.params.projectId,
                      parentEntityId: route.query.parentEntityId,
                      parentViewId: route.query.parentViewId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: WorkspaceProject,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/collections/:parentProjectId/entities - SubProject Entities (empty state)
                {
                  name: 'WorkspaceSubProjectEntityViewEmpty',
                  path: 'entities',
                  props: {
                    default: (route) => ({
                      parentProjectId: route.params.parentProjectId,
                      projectId: route.params.projectId,
                      workspaceId: route.params.workspaceId,
                      parentEntityId: route.query.parentEntityId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EmptyEntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/collections/:parentProjectId/entities/:entityId - SubProject Entity View
                {
                  name: 'WorkspaceSubProjectEntityView',
                  path: 'entities/:entityId',
                  props: {
                    default: (route) => ({
                      entityId: route.params.entityId,
                      parentProjectId: route.params.parentProjectId,
                      projectId: route.params.projectId,
                      workspaceId: route.params.workspaceId,
                      parentEntityId: route.query.parentEntityId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/collections/:parentProjectId/views/:viewId - SubProject View
                {
                  name: 'WorkspaceSubProjectTableView',
                  path: 'views/:viewId',
                  props: {
                    default: (route) => ({
                      parentProjectId: route.params.parentProjectId,
                      projectId: route.params.projectId,
                      viewId: route.params.viewId,
                      workspaceId: route.params.workspaceId,
                      parentEntityId: route.query.parentEntityId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: WorkspaceProject,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/collections/:parentProjectId/views/:viewId/entities/:entityId - SubProject Entity View (specific view)
                {
                  name: 'WorkspaceSubProjectEntitySubView',
                  path: 'views/:viewId/entities/:entityId',
                  props: {
                    default: (route) => ({
                      entityId: route.params.entityId,
                      parentProjectId: route.params.parentProjectId,
                      projectId: route.params.projectId,
                      viewId: route.params.viewId,
                      workspaceId: route.params.workspaceId,
                      parentEntityId: route.query.parentEntityId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
              ],
            },
            // /settings - Workspace Settings
            {
              path: 'settings',
              name: 'WorkspaceSettings',
              redirect: { name: 'WorkspaceSettingsProfile' },
              props: {
                default: true,
                Sidebar: {
                  open: true,
                },
                BreadCrumbs: false,
              },
              children: [
                {
                  path: 'profile',
                  name: 'WorkspaceSettingsProfile',
                  components: {
                    default: WorkspaceSettingsProfile,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
                {
                  path: 'general',
                  redirect: { name: 'WorkspaceSettingsWorkspace' },
                },
                {
                  path: 'workspace',
                  name: 'WorkspaceSettingsWorkspace',
                  components: {
                    default: WorkspaceSettingsWorkspace,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
                {
                  path: 'users',
                  name: 'WorkspaceSettingsUsers',
                  components: {
                    default: WorkspaceSettingsUsers,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
                {
                  path: 'plans',
                  name: 'WorkspaceSettingsPlans',
                  components: {
                    default: WorkspaceSettingsPlans,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
                {
                  path: 'billing',
                  name: 'WorkspaceSettingsBilling',
                  components: {
                    default: WorkspaceSettingsBilling,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
                {
                  path: 'api-keys',
                  name: 'WorkspaceSettingsApiKeys',
                  components: {
                    default: WorkspaceSettingsApiKeys,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                },
                {
                  path: 'ai-models',
                  name: 'WorkspaceSettingsAiModels',
                  components: {
                    default: WorkspaceSettingsAiModels,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
              ],
            },
            // /knowledge-hub - Knowledge Hub
            ...knowledgeHubRoutes,
          ],
        },
      ],
    },
    {
      name: 'logout',
      path: '/logout',
      component: LogoutPage,
    },
  ],
})

function watchEffectOnceAsync<T>(watcher: () => T) {
  return new Promise<void>((resolve) => {
    const stopWatch = watchEffect(() => {
      if (watcher()) {
        resolve()
        stopWatch()
      }
    })
  })
}

const isMobile = () =>
  ['Android', 'iPad', 'iPhone'].some((userAgent) => navigator.userAgent.includes(userAgent))

const { identifyUser } = useAnalytics()

const { setUtmParams } = useUtmParams()
router.beforeEach(async (to) => {
  const { isLoading, isAuthenticated, loginWithRedirect, user: userInfo, error } = useAuth0()

  setUtmParams(to)

  // First things first, let's check if the user is from a mobile device
  if (shouldBlockMobileUse.value && isMobile()) {
    if (to.name === 'NotAvailableOnMobile') {
      return
    }
    return {
      name: 'NotAvailableOnMobile',
    }
  }

  let errorCode: string | null = error.value?.error_description
  let errorMessage: string | null = null
  try {
    if (errorCode) {
      const parsed = JSON.parse(errorCode)
      if (typeof parsed.code === 'string' && typeof parsed.message === 'string') {
        errorCode = parsed.code
        errorMessage = parsed.message
      }
    }
  } catch {
    // ignore
  }

  if (errorCode === 'email_not_verified') {
    if (to.name === 'VerifyEmail') return
    return { name: 'VerifyEmail' }
  } else if (errorCode === 'log_in_with_work_email') {
    if (to.name === 'LogInError') return
    return { name: 'LogInError', query: { message: 'Please log in with your work email' } }
  } else if (errorMessage) {
    if (to.name === 'LogInError') return
    return { name: 'LogInError', query: { message: errorMessage } }
  } else if (errorCode) {
    if (to.name === 'LogInError') return
    return { name: 'LogInError', query: { message: `Something went wrong: ${errorCode}` } }
  }

  const invitationFromStorage = localStorage.getItem(INVITATION_STORAGE_KEY)
  /**
   * The user might have:
   * 1. Clicked an invitation link from their email
   * 2. Created a Go account and then been presented with a 'verify your email' message
   * 3. Clicked the 'Open Gmail/Outlook' link to be directed to their email inbox
   * 4. Clicked the 'verify email' link from their email inbox
   *
   * At this point, we want them to accept the invite that set off this
   * chain of events, without needing them to click the first email link
   * AGAIN. So if they have an invitation in local storage and none in
   * the query, we redirect them to the AcceptInvitation page.
   */
  if (invitationFromStorage && !to.query.token) {
    const invitation = JSON.parse(invitationFromStorage)
    localStorage.removeItem(INVITATION_STORAGE_KEY)
    return {
      name: 'AcceptInvitation',
      query: invitation,
    }
  }

  const userStore = useUser()

  const getAndSetUser = async () => {
    const response = await getUser()

    if (!response.ok && response.error?.code === 'not_found') {
      if (!userInfo?.value?.email) {
        throw new Error('No email associated with user')
      }
    }

    if (response.ok) {
      userStore.setUser(serializeUser(response.data))
      identifyUser()
    }
  }

  const login = async () => {
    if (isAuthenticated.value) {
      if (!userStore?.user) {
        await getAndSetUser()
      }

      return
    }

    const authorizationParams: AuthorizationParams = { scope: 'profile email' }

    if (to.query.screen_hint === 'signup') authorizationParams.screen_hint = 'signup'

    // The user is not authenticated, so redirect them to Auth0 to log in.
    // But before doing so,
    if (to.name === 'AcceptInvitation') {
      localStorage.setItem(INVITATION_STORAGE_KEY, JSON.stringify(to.query))

      const { workspace_id, project_id, token } = to.query
      authorizationParams.screen_hint = 'signup'
      authorizationParams.scope = `profile email invitation:${workspace_id}:${project_id ?? ''}:${token}`
    }

    await loginWithRedirect({
      appState: { target: to.fullPath },
      authorizationParams,
    })

    if (isAuthenticated.value) {
      await getAndSetUser()
    }

    return
  }

  if (!isLoading.value) {
    return login()
  }

  // if we are loading, wait for the loading to finish before trying to log in, then kill the watcher
  await watchEffectOnceAsync(() => !isLoading.value)

  return login()
})
