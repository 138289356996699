import type { Trace } from '@/modules/Project/routing/useRouteTrace'

export type NavigationParams = {
  projectId?: string
  viewId?: string
  parentProjectId?: string
  parentEntityId?: string
  parentViewId?: string
  parentPropertyId?: string

  trace?: Trace
  entityId?: string
}

export function isEntityParams(
  params: NavigationParams,
): params is NavigationParams & { entityId: string } {
  return !!params.entityId
}

export function isProjectParams(
  params: NavigationParams,
): params is NavigationParams & { projectId: string } {
  return !!params.projectId && !isEntityParams(params)
}
