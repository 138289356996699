<script setup lang="ts">
import IconSprite from '@/uiKit/IconSprite.vue'
import { ref } from 'vue'

const model = defineModel<boolean>({ default: true })

const open = ref(model)

const onTransitionStart = (el: Element): void => {
  if (!(el instanceof HTMLElement)) {
    return
  }
  el.style.height = `${el.scrollHeight}px`
}

const onTransitionEnd = (el: Element): void => {
  if (!(el instanceof HTMLElement)) {
    return
  }
  el.style.height = ''
}

const onToggle = (): void => {
  open.value = !open.value
}
</script>

<template>
  <li class="h-full list-none">
    <div class="flex h-7 w-full px-2">
      <button
        v-if="$slots['header']"
        class="flex w-full items-center gap-2 rounded-corner-8 px-1.5 py-2 hover:bg-background-gray-subtlest focus:outline-none focus-visible:ring-2"
        aria-label="Header"
        :aria-expanded="open"
        @click="onToggle"
      >
        <IconSprite
          icon="caret-triangle-right"
          data-test="expansion-panel-chevron"
          class="h-1.5 w-4 text-icon-subtlest transition-transform duration-200 ease-in-out"
          :class="{ 'rotate-90': open }"
        />
        <div class="text-left">
          <slot name="header" />
        </div>
      </button>
    </div>
    <Transition
      enter-active-class="transition-all duration-200 ease-in-out overflow-hidden"
      enter-from-class="h-0"
      enter-to-class="h-auto"
      leave-active-class="transition-all duration-200 ease-in-out overflow-hidden"
      leave-from-class="h-auto"
      leave-to-class="h-0"
      @enter="onTransitionStart"
      @after-enter="onTransitionEnd"
      @before-leave="onTransitionStart"
      @after-leave="onTransitionEnd"
    >
      <div
        v-if="open"
        class="flex size-full grow"
        :class="{ 'pb-2': open }"
      >
        <slot name="default" />
      </div>
    </Transition>
  </li>
</template>
