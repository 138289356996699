<script setup lang="ts">
import { invariant } from '@/shared/utils/typeAssertions'
import { useStorage } from '@vueuse/core'
import { computed, ref, watch, type Ref } from 'vue'

type PanelConfig = {
  id: string
  minWidth: number
  defaultPercentage?: number
  show?: Ref<boolean>
}

const props = defineProps<{ panels: PanelConfig[]; storageKey?: string }>()

type PanelSizes = Record<string, number>

const initialPanelSizes = getInitialPanelSizes()

const panelRefs = ref<Record<string, HTMLElement | null>>({})
const panelSizes: Ref<PanelSizes> = ref(initialPanelSizes)

const visiblePanels = computed(() => {
  return props.panels.filter((p) => p.show?.value ?? true)
})

const storageRef = computed(() => {
  if (!props.storageKey) return null
  return useStorage(props.storageKey, initialPanelSizes, undefined, { writeDefaults: false })
})

/**
 * We might or might not have a storage key, so we need to watch the storageRef
 * and update the panelSizes accordingly.
 * If there is a storage key, we will swap the ref to the storageRef and sync through it.
 */
watch(
  storageRef,
  (nextStorage, prevStorage) => {
    if (nextStorage) {
      panelSizes.value = nextStorage.value
    } else if (!nextStorage && prevStorage) {
      // If storage is removed, disconnect panel sizes from the previously used storage
      panelSizes.value = getInitialPanelSizes()
    }
  },
  { immediate: true },
)

function getVisiblePanelWidths(): [PanelSizes, number] {
  const panelWidths: Record<string, number> = {}
  let totalWidth = 0
  for (const panel of visiblePanels.value) {
    const panelEl = panelRefs.value[panel.id]
    invariant(panelEl instanceof HTMLElement)
    panelWidths[panel.id] = panelEl.clientWidth
    totalWidth += panelEl.clientWidth
  }
  return [panelWidths, totalWidth]
}

function onDragHandleMouseDown(panelIndex: number, e: MouseEvent) {
  // Block interactions with the document while resizing (clicking, hover, selection)
  document.body.setAttribute('inert', 'true')

  const [panelWidthsPx, totalWidthPx] = getVisiblePanelWidths()

  const leftPanelConfig = visiblePanels.value.at(panelIndex)
  const rightPanelConfig = visiblePanels.value.at(panelIndex + 1)
  invariant(leftPanelConfig && rightPanelConfig)

  const leftPanel = panelRefs.value[leftPanelConfig.id]
  const rightPanel = panelRefs.value[rightPanelConfig.id]
  invariant(leftPanel && rightPanel)

  const startingScreenX = e.screenX
  const handleMouseMove = ({ screenX }: MouseEvent) => {
    const delta = screenX - startingScreenX

    const nextLeftWidth = panelWidthsPx[leftPanelConfig.id] + delta
    const nextRightWidth = panelWidthsPx[rightPanelConfig.id] - delta

    // If either panel would get too small, stop resizing
    if (nextLeftWidth < leftPanelConfig.minWidth || nextRightWidth < rightPanelConfig.minWidth) {
      return
    }

    leftPanel.style.width = nextLeftWidth + 'px'
    rightPanel.style.width = nextRightWidth + 'px'
  }

  const handleMouseUp = () => {
    const leftPercentage = (100 * leftPanel.clientWidth) / totalWidthPx
    const rightPercentage = (100 * rightPanel.clientWidth) / totalWidthPx

    panelSizes.value[leftPanelConfig.id] = leftPercentage
    panelSizes.value[rightPanelConfig.id] = rightPercentage

    leftPanel.style.width = 'unset'
    rightPanel.style.width = 'unset'

    document.body.removeAttribute('inert')
    document.removeEventListener('mousemove', handleMouseMove)
  }

  document.addEventListener('mousemove', handleMouseMove)
  document.addEventListener('mouseup', handleMouseUp, { once: true })
}

/**
 * Initialize the default sizes for the panels.
 *
 * This is done by first assigning the default percentages where specified,
 * and then distributing the remaining space evenly among the panels that don't have a default percentage.
 */
function getInitialPanelSizes(): PanelSizes {
  const sizes: PanelSizes = {}

  // First pass: assign default percentages where specified
  let remaining = 100
  let unassignedPanels = 0

  for (const panel of props.panels) {
    if (panel.defaultPercentage) {
      sizes[panel.id] = panel.defaultPercentage
      remaining -= panel.defaultPercentage
    } else {
      unassignedPanels++
    }
  }

  // Second pass: distribute remaining space evenly
  const defaultSize = unassignedPanels > 0 ? remaining / unassignedPanels : 0
  for (const panel of props.panels) {
    if (!panel.defaultPercentage) {
      sizes[panel.id] = defaultSize
    }
  }

  return sizes
}
</script>

<template>
  <div class="flex size-full">
    <template
      v-for="(panel, i) in visiblePanels"
      :key="panel.id"
    >
      <!-- Panel -->
      <div
        :ref="(el) => (panelRefs[panel.id] = el as HTMLElement | null)"
        class="relative h-full grow overflow-auto"
        :style="{ width: panelSizes[panel.id] + '%', minWidth: panel.minWidth + 'px' }"
      >
        <slot :name="panel.id" />
      </div>

      <!-- Handle -->
      <div
        v-if="i < visiblePanels.length - 1"
        class="relative h-full w-px shrink-0 cursor-col-resize border-l border-border-subtle"
        @mousedown.stop="onDragHandleMouseDown(i, $event)"
      >
        <!-- Odd margin and width because the handle is 1px wide and this should be spread out evenly on both sides -->
        <div class="absolute z-1 ml-[-7px] h-full w-[15px]" />
      </div>
    </template>
  </div>
</template>
