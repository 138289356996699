<script setup lang="ts">
import { cloneProject } from '@/backend/cloneProject'
import { updateProject } from '@/backend/updateProject'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import { PROJECT_NAME_FALLBACK } from '@/modules/Project/constants'
import { useProjectCover } from '@/modules/Project/useProjectCover'
import { useProjects } from '@/modules/Projects/useProjects'
import { copyToClipboard } from '@/shared/clipboard'
import { toast } from '@/shared/toast'
import { omit } from '@/shared/utils'
import { getWindow } from '@/shared/utils/window'
import IconButton, { type IconButtonSize } from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import DeleteProjectDialog from './DeleteProjectDialog.vue'
import RenameProjectDialog from './RenameProjectDialog.vue'

const props = withDefaults(
  defineProps<{
    workspaceId: string
    projectId: string
    size?: IconButtonSize
  }>(),
  {
    size: 'xs',
  },
)

const isDeleting = ref(false)
const isRenaming = ref(false)
const newProjectName = ref('')

const { resolve, currentRoute, replace, push } = useRouter()
const onDeleteProject = () => {
  isDeleting.value = false
  // Handle the case where the user has deleted the project that
  // they are currently viewing
  if (currentRoute.value.params.projectId === props.projectId) {
    replace({ name: 'WorkspaceProjects', params: { workspaceId: props.workspaceId } })
  }
}

const link = computed(() =>
  resolve({
    name: 'WorkspaceProject',
    params: { workspaceId: props.workspaceId, projectId: props.projectId },
  }),
)

const projectsStore = useProjects()

const handleRename = async (value: string) => {
  if (value.trim() === '') return

  const result = await updateProject(props.workspaceId, props.projectId, {
    name: value.trim(),
  })

  if (result.ok) {
    projectsStore.updateProject(props.projectId, { name: result.data.name })
  } else {
    toast.error('Failed to rename project. Please try again later.')
  }

  isRenaming.value = false
}

const permissionsStore = usePermissionsStore()
const canDelete = computed(() => {
  return !!permissionsStore.allProjectPermissions[props.projectId]?.delete_projects
})
const canUpdate = computed(() => {
  return !!permissionsStore.allProjectPermissions[props.projectId]?.update_projects
})

defineOptions({
  inheritAttrs: false,
})

const project = computed(() => projectsStore.getProjectById(props.projectId))

const menuRef = ref()

const onCloneProject = async () => {
  if (!project.value) {
    return
  }

  const yymmddhhmm = new Date().toLocaleString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })

  const clonedProjectName = `${project.value.name} (${yymmddhhmm})`

  // Show loading toast
  const loadingToast = toast.loading(`Cloning ${project.value.name}...`)

  const response = await cloneProject({
    name: clonedProjectName,
    workspaceId: props.workspaceId,
    templateId: project.value.id,
    cloneEntities: false,
  })

  toast.dismiss(loadingToast)

  if (response.ok) {
    push({
      name: 'WorkspaceProject',
      params: { workspaceId: props.workspaceId, projectId: response.data.id },
    })
  } else {
    toast.error('Failed to clone project. Please try again later.')
  }
}

const { uploadFileAsProjectCover, getRandomProjectCoverURL, fetchCoverFile } = useProjectCover()

const handleCoverPicked = (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0]
  if (!file) {
    return
  }

  uploadFileAsProjectCover(file, props.workspaceId, props.projectId)
}

const setRandomCover = async () => {
  const url = getRandomProjectCoverURL()
  const file = await fetchCoverFile(url)
  if (file) {
    uploadFileAsProjectCover(file, props.workspaceId, props.projectId)
  }
}
</script>

<template>
  <Menu.Root
    v-if="project"
    ref="menuRef"
    v-slot="{ getTriggerProps }"
    close-on-select
  >
    <IconButton
      icon="more-dots"
      data-test="project-menu-trigger"
      :size="size"
      variant="transparent"
      v-bind="{ ...omit(getTriggerProps(), ['onSelect', 'disabled']), ...$attrs }"
      @click.prevent
    >
      Click me
    </IconButton>
    <Menu.Content
      class="min-w-56"
      side="top"
      align="end"
      :side-offset="5"
      :align-offset="0"
    >
      <Menu.Item
        icon="arrow-top-right"
        @select="() => getWindow()?.open(link.href, '_blank')"
      >
        Open in a new tab
      </Menu.Item>
      <Menu.Item
        icon="arrow-top-right"
        @select="() => getWindow()?.open(link.href, '_blank', 'toolbar=0,location=0,menubar=0')"
      >
        Open in a new window
      </Menu.Item>
      <ToolTip
        class="w-full"
        arrow
        :placement="{ allowedPlacements: ['left'] }"
        :title="projectId"
      >
        <Menu.Item
          icon="copy"
          @select="() => copyToClipboard('Project ID', projectId)"
        >
          Copy project ID
        </Menu.Item>
      </ToolTip>
      <Menu.Divider />
      <Menu.Item
        icon="picture"
        class="relative"
      >
        Upload cover picture

        <input
          class="absolute inset-0 size-full cursor-pointer opacity-0"
          type="file"
          @change="handleCoverPicked"
        />
      </Menu.Item>
      <Menu.Item
        icon="switch"
        @select="setRandomCover"
      >
        Generate cover picture
      </Menu.Item>

      <Menu.Divider v-if="canUpdate || canDelete" />

      <Menu.Item
        v-if="canUpdate"
        icon="edit"
        @select="
          () => {
            isRenaming = true
            newProjectName = project!.name || PROJECT_NAME_FALLBACK
          }
        "
      >
        Rename project
      </Menu.Item>
      <Menu.Item
        v-if="canUpdate"
        icon="copy"
        @select="onCloneProject"
      >
        Clone project
      </Menu.Item>
      <Menu.Item
        v-if="canDelete"
        icon="trash"
        critical
        @select="isDeleting = true"
      >
        Delete project
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>

  <DeleteProjectDialog
    :project-id="projectId"
    :workspace-id="workspaceId"
    :open="isDeleting"
    @delete="onDeleteProject"
    @close="isDeleting = false"
  />

  <RenameProjectDialog
    v-model:value="newProjectName"
    :open="isRenaming"
    @rename="handleRename"
    @close="isRenaming = false"
  />
</template>
