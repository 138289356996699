<template>
  <span class="inline-flex flex-row items-start gap-0.5">
    <span class="text-sm-12px-default">
      <slot name="title" />
    </span>
    <span
      v-if="$slots.count"
      class="text-xxs-8px-default text-text-warning"
    >
      <slot name="count" />
    </span>
  </span>
</template>
