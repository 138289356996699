<script setup lang="ts">
/**
 * Renders a div to be placed above or below a scrollable element to
 * give a fade-out effect.
 * The div will appear on the relevant edge depending on scroll state of the given container.
 *
 * Because of the way tailwind works, gradient colors need to be given as a class name.
 *
 * Otherwise, the utility class will not be included in tailwind's bundle.
 */
import { useScroll } from '@vueuse/core'
import { computed, nextTick, onMounted } from 'vue'

const props = defineProps<{
  container: HTMLElement | null | undefined
  location: 'top' | 'bottom'
  size: 'md' | 'lg' | 'xl'
}>()

const { arrivedState, measure } = useScroll(computed(() => props.container))

// Otherwise, the scroll state might not get measured correctly in some cases
onMounted(() => nextTick(() => measure()))

const visible = computed(() =>
  props.location === 'bottom' ? !arrivedState.bottom : !arrivedState.top,
)

const SIZE_CLASSES = {
  md: 'h-10',
  lg: 'h-20',
  xl: 'h-40',
} as const

const sizeClass = computed(() => SIZE_CLASSES[props.size])
</script>

<template>
  <div
    class="pointer-events-none absolute inset-x-0 z-1 rounded-corner-8 from-0% to-100% transition-all"
    :class="[
      ...[visible ? sizeClass : 'h-0'],
      ...[location === 'top' ? ['top-0', 'bg-gradient-to-t'] : ['bottom-0', 'bg-gradient-to-b']],
    ]"
  />
</template>
