<script setup lang="ts">
import CrumbItem from '@/modules/Workspaces/Crumbs/CrumbItem.vue'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed } from 'vue'

const { workspaceId } = useRouteParams()

const route = computed(() => ({
  name: 'Workspace',
  params: { workspaceId: workspaceId.value },
}))
</script>

<template>
  <CrumbItem :to="route">
    <template v-if="$slots.default">
      <slot />
    </template>
    <template v-else> All projects</template>
  </CrumbItem>
</template>
