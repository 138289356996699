import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const manualTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'User input',
  description: 'Manual user input.',
  outputTypes: [
    PropertyType.text,
    PropertyType.file,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.url,
  ],
  provider: ToolProvider.internal,
  icon: 'human-task',
  usesPrompt: false,
}
