import { PropertyTool, PropertyType, ToolProvider } from '@/backend/types'

/**
 * The types of properties that can be created. Note that the order of this array
 * is important, as there is a product requirement than the property types appear
 * in a specific order.
 */
export const FIELD_TYPES_OPTIONS = [
  { type: PropertyType.text, label: 'Text' },
  { type: PropertyType.file, label: 'File' },
  { type: PropertyType.number, label: 'Number' },
  { type: PropertyType.collection, label: 'Collection' },
  { type: PropertyType.single_select, label: 'Single Select' },
  { type: PropertyType.multi_select, label: 'Multi Select' },
  { type: PropertyType.url, label: 'URL' },
  { type: PropertyType.reference, label: 'Reference' },
  { type: PropertyType.json, label: 'JSON' },
  { type: PropertyType.user_select, label: 'User' },
  { type: PropertyType.file_collection, label: 'Page Splitter' },
]

export const FIELD_TYPES_LABELS: Record<PropertyType, string> = {
  [PropertyType.file]: 'File',
  [PropertyType.file_collection]: 'Page Splitter',
  [PropertyType.text]: 'Text',
  [PropertyType.single_select]: 'Single Select',
  [PropertyType.multi_select]: 'Multi Select',
  [PropertyType.user_select]: 'User',
  [PropertyType.json]: 'JSON',
  [PropertyType.collection]: 'Collection',
  [PropertyType.url]: 'URL',
  [PropertyType.reference]: 'Reference',
  [PropertyType.number]: 'Number',
} as const

/**
 * Maps each property type to a default tool that will be selected when
 * creating a new poperty of that type.
 */
export const FIELD_DEFAULT_TOOL: Record<PropertyType, PropertyTool> = {
  [PropertyType.file]: PropertyTool.manual,
  [PropertyType.file_collection]: PropertyTool.manual,
  [PropertyType.text]: PropertyTool.gpt_4o,
  [PropertyType.single_select]: PropertyTool.gpt_4o,
  [PropertyType.multi_select]: PropertyTool.gpt_4o,
  [PropertyType.user_select]: PropertyTool.manual,
  [PropertyType.json]: PropertyTool.gpt_4o,
  [PropertyType.collection]: PropertyTool.gpt_4o,
  [PropertyType.url]: PropertyTool.manual,
  [PropertyType.reference]: PropertyTool.gpt_4o,
  [PropertyType.number]: PropertyTool.gpt_4o,
}

export const TOOL_PROVIDER_LABELS: Record<ToolProvider, string> = {
  [ToolProvider.anthropic]: 'Anthropic',
  [ToolProvider.google_ai]: 'Google',
  [ToolProvider.open_ai]: 'OpenAI',
  [ToolProvider.internal]: 'V7',
  [ToolProvider.azure_ocr]: 'Azure OCR',
  [ToolProvider.bing_search]: 'Bing Search',
  [ToolProvider.fire_crawl]: 'Firecrawl',
  [ToolProvider.azure_open_ai]: 'Azure OpenAI',
  [ToolProvider.amazon_textract]: 'Amazon Textract',
  [ToolProvider.vertex_ai_claude]: 'Vertex AI Claude',
  [ToolProvider.perplexity]: 'Perplexity',
}

/**
 * Providers that require a project ID to be specified when creating an API key.
 * Ideally temporary place to store this information.
 */
export const TOOL_PROVIDERS_REQUIRING_PROJECT_ID: ToolProvider[] = [
  ToolProvider.google_ai,
  ToolProvider.azure_ocr,
  ToolProvider.azure_open_ai,
]
