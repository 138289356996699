<script setup lang="ts">
import { listUsers } from '@/backend/listUsers'
import { listWorkspaces } from '@/backend/listWorkspaces'
import { useLoadingPrediction } from '@/modules/App/useLoadingPrediction'
import ProjectSidebarSkeleton from '@/modules/Projects/ProjectSidebarSkeleton.vue'
import ProjectUpdateModal from '@/modules/Projects/ProjectUpdateModal.vue'
import type { Workspace } from '@/modules/Workspaces/useWorkspaces'
import {
  serializeWorkspaceMember,
  useWorkspaceMembers,
} from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import WorkspaceSettingsSidebarSkeleton from '@/modules/WorkspaceSettings/WorkspaceSettingsSidebarSkeleton.vue'
import PageHeaderSkeleton from '@/sharedComponents/PageHeaderSkeleton.vue'
import SidebarLayout from '@/sharedComponents/SidebarLayout.vue'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { useAuth0 } from '@auth0/auth0-vue'
import * as sentry from '@sentry/vue'
import { watch } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'
import NoWorkspacePage from './NoWorkspacePage.vue'
import { useWorkspaceChannel } from './useWorkspaceChannel'
import { useWorkspaces } from './useWorkspaces'
/**
 * Primary component of the '/' route
 *
 * Renders a list of workspaces as navigation and contains a nested view for
 * the currently selected workspace.
 */
const router = useRouter()
const route = useRoute()
const store = useWorkspaces()
const workspaceMembersStore = useWorkspaceMembers()
const { predictedPage } = useLoadingPrediction()

let channel: ReturnType<typeof useWorkspaceChannel>

watch(
  () => store.currentWorkspace,
  async (newWorkspace?: Workspace) => {
    if (!newWorkspace) return
    if (channel) {
      await channel.leaveChannel()
    }
    channel = useWorkspaceChannel(newWorkspace.id)

    // load workspace members
    const userLoader = useDataLoader(() => listUsers(newWorkspace.id))
    const userResponse = await userLoader.load()
    if (userResponse.ok) {
      const { data } = userResponse
      workspaceMembersStore.setWorkspaceMembers(data.data.map(serializeWorkspaceMember))
    }
  },
)

const { load, status } = useDataLoader(listWorkspaces)
const { isAuthenticated } = useAuth0()

watch(
  isAuthenticated,
  async (isLoggedIn) => {
    if (!isLoggedIn) {
      return
    }
    let result = await load()
    /**
     * It should never be the case that there are 0 workspaces, as the
     * backend will create a workspace on any API request if none exist.
     * However, we could fall foul of a race condition, so in the case
     * that there are no workspaces, we should retry the request.
     * See https://vseven.slack.com/archives/C05V37QGF9P/p1712597621482809
     */
    if (result.ok && result.data.data.length === 0) {
      sentry.captureException(new Error('No workspaces found'))
      result = await load()
    }

    if (result.ok) {
      store.setWorkspaces(
        result.data.data.map((w) => ({
          id: w.id,
          name: w.name,
          role: w.role,
          iconUrl: w.icon_url,
          createdAt: w.created_at,
        })),
      )

      if (route.name === 'Home' && store.workspaces.length > 0) {
        const lastWorkspaceId = store.lastUsedWorkspace?.id || store.workspaces[0].id
        router.replace(`/${lastWorkspaceId}`)
      }
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <template v-if="status === 'loading'">
    <SidebarLayout>
      <template #sidebar>
        <ProjectSidebarSkeleton
          v-if="predictedPage === 'home' || predictedPage === 'project'"
          :open="predictedPage === 'home'"
        />
        <WorkspaceSettingsSidebarSkeleton v-else />
      </template>
      <template #page-header>
        <PageHeaderSkeleton v-if="predictedPage !== 'settings'" />
      </template>
      <template #page> </template>
    </SidebarLayout>
  </template>
  <div v-else-if="status === 'errored'">There was an error when loading your workspaces</div>
  <NoWorkspacePage v-else-if="status === 'loaded' && store.workspaces.length === 0" />
  <RouterView v-else />
  <ProjectUpdateModal
    v-if="store.currentWorkspace?.id"
    :workspace-id="store.currentWorkspace?.id"
  />
</template>
