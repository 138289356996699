<script setup lang="ts">
import SidebarToggle from '@/sharedComponents/SidebarToggle.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'
import { watchDebounced } from '@vueuse/core'
import { ref } from 'vue'

defineProps<{ disableTooltip?: boolean }>()
const { toggle, isOpen } = useSidebar()

// We use this to avoid lingering tooltips during
// the toggle animation.
// https://www.loom.com/share/6ac21f00be7d4cf5bce6da6089d49833?sid=88548566-09a7-422c-bbf9-4c0e87351b93
const disableAnimation = ref(false)
watchDebounced(
  disableAnimation,
  (value) => {
    if (!value) return
    disableAnimation.value = false
  },
  { debounce: 500 },
)
</script>

<template>
  <SidebarToggle
    :disabled="disableTooltip"
    :is-open="isOpen"
    side="left"
    open-tooltip="Expand sidebar"
    close-tooltip="Collapse sidebar"
    hotkey="["
    label="Toggle sidebar"
    @toggle="toggle"
  />
</template>
