<script setup lang="ts">
import { PropertyType } from '@/backend/types'
import type {
  MultiSelectProperty,
  SingleSelectProperty,
  UserSelectProperty,
} from '@/modules/Project/Properties/types'
import { keyboardTargetIsInput } from '@/shared/utils/event'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import { computed, nextTick, ref, toRef, watch } from 'vue'
import type { Field } from './Fields/types'
import SelectEditor from './SelectEditor.vue'
import { useSelectField } from './useSelectField'
import { useTableCellFocus } from './useTableCellFocus'

const props = defineProps<{
  value?: string | string[] | null
  field: Field<'single_select' | 'multi_select' | 'user_select'>
  property: SingleSelectProperty | MultiSelectProperty | UserSelectProperty
  isFocused: boolean
  isSelected: boolean
  isModelOutput?: boolean
  hasSelectedRange?: boolean
  hasReasoning?: boolean
}>()

const emit = defineEmits<{
  (e: 'submit', option: string[] | null): void
  (e: 'blur' | 'focus'): void
}>()

const { localValue, selectOptions, valueToColorMap, optionColours } = useSelectField(
  computed(() => props.field),
  computed(() => props.property),
)

const isMulti = computed(() => props.property.type === PropertyType.multi_select)
const isUser = computed(() => props.property.type === PropertyType.user_select)

const badgesWrapper = ref<HTMLDivElement>()

watch(
  () => props.isFocused,
  async (isFocused, wasFocused) => {
    await nextTick()
    if (isFocused && !wasFocused) {
      badgesWrapper.value?.focus()
    }
  },
  { immediate: true },
)

/**
 * When in edit mode, typing a character should focus the select editor and
 * populate the search input with the typed character.
 */
const onKeydown = (e: KeyboardEvent) => {
  // This is the only way I could find to differentiate between
  // 1. Keys that should be taken as input values for the text cell
  // 2. Keys that should be used for navigation or other browser commands
  // Hopefully we can come up with a better way.
  const shouldOverwriteValue =
    e.key.length === 1 && e.key !== ' ' && !e.ctrlKey && !e.metaKey && !e.altKey
  if (shouldOverwriteValue) {
    // The event will continue propagating, so the keypress will be registered by the
    // browser against the contenteditable div
    emit('focus')
  }

  const shouldClearValue =
    (e.key === 'Backspace' || e.key === 'Delete') && !keyboardTargetIsInput(e)
  if (shouldClearValue) {
    localValue.value = []
    emit('submit', null)
  }
}

const root = ref<HTMLElement>()
useTableCellFocus({
  cell: root,
  isFocused: toRef(props, 'isFocused'),
  isSelected: toRef(props, 'isSelected'),
})

const badgeLabel = (v: string) => {
  if (isUser.value) {
    const option = selectOptions.value.find((o) => o.id === v)
    return option ? option.data.label : v
  }
  return v
}
</script>

<template>
  <div
    ref="root"
    class="size-full min-w-0 overflow-hidden outline-none"
    @keydown="onKeydown"
  >
    <SelectEditor
      v-if="isFocused || isSelected"
      :is-focused="isFocused"
      :is-selected="isSelected"
      :is-multi="isMulti"
      :is-user="isUser"
      :value="localValue"
      :options="selectOptions"
      :value-to-color-map="valueToColorMap"
      :has-selected-range="hasSelectedRange"
      :is-model-output="isModelOutput"
      :has-reasoning="hasReasoning"
      class="min-w-full"
      :class="!hasSelectedRange && 'absolute left-0 top-0 z-10 pr-2'"
      @submit="
        (value) => {
          $emit('submit', value)
          // optimistic update in both cases
          localValue = value
        }
      "
    />

    <div
      v-else
      ref="badgesWrapper"
      class="size-full p-1 pr-0"
    >
      <div class="size-full overflow-hidden rounded-r-corner-6">
        <div
          class="flex size-full cursor-pointer flex-row items-center gap-1 overflow-hidden focus:outline-none"
        >
          <div
            v-if="hasReasoning && localValue?.length === 0"
            class="h-5 max-w-full truncate whitespace-nowrap pl-2 text-text-subtlest"
          >
            No match found
          </div>
          <BadgeItem
            v-for="v in localValue"
            :key="v"
            v-memo="[v, ...optionColours]"
            :leading-icon="isUser ? 'user-fill' : undefined"
            :label="badgeLabel(v)"
            size="sm"
            :variant="isUser ? 'blue' : 'warning'"
            class="whitespace-nowrap"
            :rainbow-color="valueToColorMap[v]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
