<script setup lang="ts">
import AccountDropdown from '@/modules/IdentityAndAccess/AccountDropdown.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import NavigationList from '@/uiKit/NavigationList.vue'
import NavigationListItem from '@/uiKit/NavigationListItem.vue'

import { deleteCase } from '@/backend/deleteCase'
import { updateCase } from '@/backend/updateCase'
import { useBilling } from '@/modules/Billing/useBilling'
import { useSidebar } from '@/sharedComponents/useSidebar'
import DividerLine from '@/uiKit/DividerLine.vue'
import { useStorage } from '@vueuse/core'
import { computed, nextTick, ref } from 'vue'

import { useCaseStore } from '@/modules/Cases/useCaseStore'
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import { useLibraryStore } from '@/modules/Library/libraryStore'
import BookDemoBanner from '@/modules/Projects/BookDemoBanner.vue'
import { toast } from '@/shared/toast'
import { useMeterColor } from '@/shared/useMeterColor'
import { useHotKey } from '@/sharedComposables/useHotKey'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import FlowerProgressVue from '@/uiKit/FlowerProgress.vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useCsvImportModal } from '../Workspaces/CsvImportModal.vue'
import { useTemplateModal } from '../Workspaces/TemplateModal.vue'
import { useProjectCreateModal } from './ProjectCreateModal.vue'
import ProjectSidebarContent from './ProjectSidebarContent.vue'
import ProjectSidebarLimitUsage from './ProjectSidebarLimitUsage.vue'
import ProjectSidebarToggle from './ProjectSidebarToggle.vue'
const props = defineProps<{
  workspaceId: string
  open: boolean
}>()

const templateModal = useTemplateModal()
const csvModal = useCsvImportModal()

const isHomeActive = useFeatureFlags(FeatureFlag.CASES)

const isKnowledgeHubActive = useFeatureFlags(FeatureFlag.KNOWLEDGE_HUB)

const billingStore = useBilling()

const { isOpen, toggle } = useSidebar(computed(() => props.open))
useHotKey('[', toggle)

const seatUsage = computed(() => billingStore.seatUsage)
const seatUsageColor = useMeterColor({
  current: computed(() => seatUsage.value?.limitUsage ?? 0),
  max: computed(() => seatUsage.value?.limitValue ?? 0),
  defaultColor: 'blue',
})

const projectCreateModal = useProjectCreateModal()

const showUsage = computed(
  () => billingStore.activePlan?.name === 'free' || billingStore.activePlan?.name === 'pro',
)

const libraryStore = useLibraryStore()

const permissionsStore = usePermissionsStore()

/**
 * Returns true if one of the sidebar dialogs is open. Is used so that
 * we can disable the active state for the current link if a dialog
 * is open.
 */
const someDialogIsOpen = computed(
  () => libraryStore.dialogIsOpen || templateModal.isOpen.value || csvModal.isOpen.value,
)

const { captureAnalyticsEvent } = useAnalytics()
const onClick = () => {
  captureAnalyticsEvent(ANALYTICS_EVENT.CLICK_GO_PRO)
}

const NEW_PROJECT_BUTTON_ID = 'new-project-button-sidebar'

/** Height of a demo banner, relevant when animating it to 0 when closing */
const demoBannerHeight = ref(0)
const demoBannerContainer = ref<HTMLElement>()
const userStore = useUser()
const showBookDemoBanner = useStorage(`show-book-demo-banner-${userStore.user?.id || ''}`, true)

async function onDemoBannerClose() {
  if (demoBannerContainer.value) {
    demoBannerHeight.value = demoBannerContainer.value.clientHeight
    await nextTick()
  }
  showBookDemoBanner.value = false
}

const areCasesEnabled = useFeatureFlags(FeatureFlag.CASES)
const caseStore = useCaseStore()

const onRenameCase = async (id: string, name: string) => {
  const targetCase = caseStore.getCaseById(id)
  if (!targetCase) {
    toast.error('Case not found')
    return
  }

  const revert = caseStore.setCase({ ...targetCase, name })

  const res = await updateCase({ workspaceId: props.workspaceId, caseId: id, name })
  if (!res.ok) {
    revert()
    toast.error('Failed to rename case')
  }
}
const onRemoveCase = async (id: string) => {
  const revert = caseStore.removeCase(id)
  const res = await deleteCase({ workspaceId: props.workspaceId, caseId: id })
  if (!res.ok && res.error.status !== 404) {
    revert()
    toast.error('Failed to delete case')
  }
}
</script>

<template>
  <nav
    class="relative flex h-full flex-col transition-all duration-300 ease-in-out-quint"
    :class="isOpen ? 'w-[240px]' : 'w-[0px]'"
    :aria-hidden="!isOpen"
    aria-label="Main"
  >
    <ProjectSidebarToggle
      data-test="sidebar-toggle"
      class="absolute right-2 top-2 z-1 transition duration-300 ease-in-out-quint"
      :class="isOpen ? 'translate-x-0' : 'translate-x-12'"
      :disable-tooltip="!isOpen"
    />
    <div
      class="flex h-full w-[240px] flex-col overflow-hidden transition duration-300 ease-in-out-quint"
      :class="isOpen ? 'scale-100 opacity-100' : 'scale-90 opacity-0'"
    >
      <div class="max-w-full p-2 pr-[36px]">
        <AccountDropdown :display-current-workspace-name="true" />
      </div>
      <NavigationList
        v-if="permissionsStore.workspacePermissionsLoadingState === 'loaded'"
        data-test="sidebar-links"
      >
        <NavigationListItem
          v-if="isHomeActive"
          :to="{ name: 'HomeV2' }"
          leading-icon="home"
          leading-icon-active="home-fill"
        >
          Home
        </NavigationListItem>
        <NavigationListItem
          v-else
          :to="{ name: 'WorkspaceProjects' }"
          :force-active-state="someDialogIsOpen ? false : undefined"
          leading-icon="clock"
          leading-icon-active="clock-fill"
        >
          Recent projects
        </NavigationListItem>
        <template v-if="permissionsStore.workspacePermissions.create_projects">
          <NavigationListItem
            data-test="new-project-button-sidebar"
            :aria-labelledby="NEW_PROJECT_BUTTON_ID"
            leading-icon="circle-plus"
            leading-icon-active="circle-plus-fill"
            @click="projectCreateModal.open"
          >
            <div class="flex w-full items-center justify-between">
              <div :id="NEW_PROJECT_BUTTON_ID">New project</div>
            </div>
          </NavigationListItem>
          <NavigationListItem
            v-if="!areCasesEnabled"
            data-test="explore-templates-sidebar"
            aria-label="Explore templates"
            leading-icon="templates"
            leading-icon-active="templates-fill"
            :force-active-state="templateModal.isOpen.value"
            @click="templateModal.open"
          >
            Explore templates
          </NavigationListItem>
          <NavigationListItem
            v-if="!areCasesEnabled"
            aria-label="Import CSV file"
            leading-icon="table"
            leading-icon-active="table-fill"
            :force-active-state="csvModal.isOpen.value"
            @click="csvModal.open"
          >
            Import CSV file
          </NavigationListItem>
        </template>
        <NavigationListItem
          v-if="areCasesEnabled"
          leading-icon="new-case"
          leading-icon-active="new-case-fill"
          :to="{ name: 'New Case', params: { workspaceId } }"
        >
          New case
        </NavigationListItem>
        <NavigationListItem
          v-if="isKnowledgeHubActive"
          :force-active-state="someDialogIsOpen ? false : undefined"
          aria-label="Knowledge Hub"
          aria-haspopup="dialog"
          leading-icon="folder"
          leading-icon-active="folder-fill"
          :to="{ name: 'KnowledgeHub' }"
        >
          Knowledge Hub
        </NavigationListItem>

        <NavigationListItem
          v-if="!isKnowledgeHubActive"
          aria-haspopup="dialog"
          leading-icon="folder"
          leading-icon-active="folder-fill"
          :force-active-state="libraryStore.dialogIsOpen"
          aria-labelledby="library-sidebar-button"
          @click="libraryStore.dialogIsOpen = !libraryStore.dialogIsOpen"
        >
          <div id="library-sidebar-button">Library</div>
        </NavigationListItem>
        <NavigationListItem
          v-if="
            permissionsStore.workspacePermissions.invite_members ||
            permissionsStore.workspacePermissions.add_members ||
            permissionsStore.workspacePermissions.remove_members ||
            permissionsStore.workspacePermissions.update_members
          "
          :to="{ name: 'WorkspaceSettingsUsers' }"
          data-test="invite-members-button-sidebar"
          leading-icon="user-plus"
          leading-icon-active="user-plus-fill"
        >
          <div class="flex w-full items-center justify-between">
            <div>Invite users</div>
            <div
              v-if="seatUsage && isFinite(seatUsage.limitValue)"
              class="flex items-center gap-2"
            >
              <div class="text-text-subtle">
                {{ seatUsage.limitUsage }}/{{ seatUsage.limitValue }}
              </div>
              <FlowerProgressVue
                aria-label="Seat usage"
                :color="seatUsageColor"
                :max="seatUsage.limitValue"
                :value="seatUsage.limitUsage"
              />
            </div>
          </div>
        </NavigationListItem>
      </NavigationList>
      <DividerLine
        color="subtle"
        :width="1"
        class="mt-2"
      />
      <div class="flex min-h-1 grow">
        <ProjectSidebarContent
          :workspace-id="workspaceId"
          :some-dialog-is-open="someDialogIsOpen"
          @remove-case="onRemoveCase"
          @rename-case="onRenameCase"
          @create-project="templateModal.open()"
        />
      </div>
      <template v-if="showUsage && billingStore.activePlan">
        <DividerLine
          color="subtle"
          :width="1"
        />
        <ProjectSidebarLimitUsage
          v-if="billingStore.fieldUsage"
          :usage="billingStore.fieldUsage"
          :billing-period="billingStore.activePlan.billingPeriod"
        />
        <DarwinButton
          v-if="
            billingStore.activePlan.name === 'free' &&
            permissionsStore.workspacePermissions.manage_billing
          "
          class="mx-3 mb-3"
          variant="neutral"
          size="sm"
          rounded
          :to="{ name: 'WorkspaceSettingsPlans' }"
          @click="onClick"
        >
          Upgrade
        </DarwinButton>
        <Transition
          leave-from-class="demo-banner-leave-from"
          leave-to-class="h-0"
        >
          <!-- Container used so that banner measurement includes margins. -->
          <!-- Could possibly be converted to transition transform rather than height but that  -->
          <!-- would require the layout of usage meters above to be positioned using transform. -->
          <!-- For now, it's a tiny area and doesn't jank when animating. -->
          <div
            v-if="showBookDemoBanner"
            ref="demoBannerContainer"
            class="transition-all duration-300 ease-in-out-quint"
          >
            <BookDemoBanner
              class="mx-2 mb-2"
              @close="onDemoBannerClose"
            />
          </div>
        </Transition>
      </template>
    </div>
  </nav>
</template>

<style scoped>
.demo-banner-leave-from {
  height: v-bind(demoBannerHeight + 'px');
}
</style>
