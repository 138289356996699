import { getEffectivePlan } from '@/backend/getEffectivePlan'
import { listPlans } from '@/backend/listPlans'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { watch, type Ref } from 'vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { serializePlan, serializePlanTemplate, useBilling } from './useBilling'

/**
 * Loads billing data for the current workspace and stores it in the billing store.
 * Loads new billing data when the workspace changes.
 */
export const useLoadBilling = (workspaceId: Ref<string>) => {
  const billingStore = useBilling()
  const isPricingDisabled = useFeatureFlags(FeatureFlag.DISABLE_PRICING_PLANS_PAGE)
  const plansLoader = useDataLoader(() => {
    return listPlans(workspaceId.value)
  })
  const effectivePlanLoader = useDataLoader(() => {
    return getEffectivePlan(workspaceId.value)
  })

  /**
   * On load (or when the workspaceId changes), load the workspace plans and
   * invitations into the store.
   */
  watch(
    () => workspaceId.value,
    async () => {
      if (isPricingDisabled.value) {
        const effectivePlanResponse = await effectivePlanLoader.load()
        if (!effectivePlanResponse.ok) {
          return
        }

        billingStore.activePlan = serializePlan(effectivePlanResponse.data)
        return
      }

      const plansResponse = await plansLoader.load()
      if (!plansResponse.ok) {
        return
      }

      billingStore.activePlan = serializePlan(plansResponse.data.effective_plan)
      billingStore.setPlanCatalog(plansResponse.data.templates.map(serializePlanTemplate))
      billingStore.customerPortalUrl = plansResponse.data.customer_portal_url ?? null
    },
    { immediate: true },
  )
}
