<script setup lang="ts">
import { PropertyType } from '@/backend/types'
import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import type { LibraryFile } from '@/modules/Library/libraryStore'
import { injectFieldContext } from '@/modules/Project/Fields/fieldContext'
import { useFileUrlUpload } from '@/modules/Project/useFileUrlUpload'
import { type GoogleDriveFile } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/useGoogleDriveConnection'
import { invariant } from '@/shared/utils/typeAssertions'
import type * as zagMenu from '@zag-js/menu'
import { computed, toValue, type InputHTMLAttributes } from 'vue'
import FileUploadMenu from './FileUploadMenu.vue'

/**
 * Provides a dropdown menu that will upload files to a field within a project.
 */

const props = defineProps<{
  /** Prevent opening the menu */
  disabled?: boolean
  /** Attributes to pass to the hidden file input when uploading local files */
  fileInputProps?: InputHTMLAttributes
  positioning?: zagMenu.Props['positioning']
}>()

const emit = defineEmits<{
  'import:computer': [File[]]
  'import:library': [LibraryFile[]]
  'menu:close': []
  'done:google': []
  'done:library': []
}>()

/** We should conceal dropdown functionality if the knowledge hub is not enabled */
const isKnowledgeHubEnabled = useFeatureFlags(FeatureFlag.KNOWLEDGE_HUB)
const fieldContext = injectFieldContext()
const { uploadGoogleDriveFiles, uploadLibraryFiles } = useFileUrlUpload()

/** Whether a dropdown menu is used, or clicking opens native file picker */
const hasMenu = computed(() => {
  return (
    !props.disabled &&
    isKnowledgeHubEnabled.value &&
    (toValue(fieldContext.field).type === PropertyType.file ||
      toValue(fieldContext.field).type === PropertyType.file_collection)
  )
})

async function onLibrarySelect(files: LibraryFile[] = []) {
  const { field, workspaceId, projectId } = fieldContext.getValues()
  invariant(field.type === PropertyType.file || field.type === PropertyType.file_collection)

  await uploadLibraryFiles({ files, field, entityId: field.entityId, workspaceId, projectId })
}

async function onDriveSelect(files: GoogleDriveFile[]) {
  const { field, workspaceId, projectId } = fieldContext.getValues()
  invariant(field.type === PropertyType.file)

  try {
    await uploadGoogleDriveFiles({
      files,
      field,
      entityId: field.entityId,
      workspaceId,
      projectId,
    })
  } finally {
    emit('done:google')
  }
}
</script>

<template>
  <FileUploadMenu
    v-slot="{ getTriggerProps, menu }"
    library-picker-confirm-label="Add to project"
    :file-input-props="fileInputProps"
    :positioning="positioning"
    @import:computer="$emit('import:computer', $event)"
    @import:google="onDriveSelect"
    @import:library="onLibrarySelect"
    @menu:close="$emit('menu:close')"
    @done:library="$emit('done:library')"
  >
    <slot
      :get-trigger-props="hasMenu ? getTriggerProps : () => ({})"
      :menu="hasMenu ? menu : undefined"
    />
  </FileUploadMenu>
</template>
