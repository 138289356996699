<script setup lang="ts">
import { usePopoverStore } from '@/sharedComposables/popoverStore'
import PopOver from '@/uiKit/PopOver.vue'
import { ref, watch } from 'vue'

const popoverStore = usePopoverStore()

const uploadProgress = ref(0)
const PROGRESS_INTERVAL = 5000
const UPDATE_INTERVAL = 100
const interval = ref<NodeJS.Timeout | null>(null)

const popoverLabel = 'app-popover-label'

watch(
  () => popoverStore.popover?.targetSelector,
  (newVal) => {
    if (newVal) {
      // Reset progress
      uploadProgress.value = 0
      if (interval.value) {
        clearInterval(interval.value)
      }

      // Simulate progress.
      interval.value = setInterval(() => {
        uploadProgress.value += UPDATE_INTERVAL / PROGRESS_INTERVAL
      }, UPDATE_INTERVAL)
    }
  },
)

watch(
  () => uploadProgress.value,
  (newVal) => {
    if (newVal >= 1) {
      popoverStore.popover = null
      if (interval.value) {
        clearInterval(interval.value)
      }
    }
  },
)
</script>

<template>
  <PopOver
    v-if="popoverStore.popover"
    open
    :target-selector="popoverStore.popover.targetSelector"
    :placement="popoverStore.popover.placement"
    arrow
    role="dialog"
    :aria-labelledby="popoverLabel"
  >
    <template #content>
      <div
        class="relative flex w-max max-w-[352px] items-center rounded-corner-6 border border-border bg-surface-popover-inverted px-2 py-1.5 text-text-inverted-irreversible"
      >
        <div :id="popoverLabel">
          {{ popoverStore.popover.text }}
        </div>
      </div>
    </template>
  </PopOver>
</template>
