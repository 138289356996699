import { post } from './client'
import type { BackendMeta } from './types'

export type AddCase = BackendMeta<'/api/workspaces/{workspace_id}/cases', 'post'>

export type AddCaseParams = {
  workspaceId: string
  name?: string
}

export const addCase = async ({ name, workspaceId }: AddCaseParams) =>
  post<AddCase['body'], AddCase['successResponse'], AddCase['path']>(
    `/api/workspaces/${workspaceId}/cases`,
    {
      name,
    },
  )
