<script setup lang="ts">
/**
 * Renders a search input that should appear at the top of a menu. This
 * component should never be used outside of `<Menu.Root>`.
 */

const model = defineModel<string>({ default: '' })

const onKeydown = (e: KeyboardEvent) => {
  // Ordinarily, when focus is in a menu descendant then pressing the space
  // key will
  // select the active item. We don't want this to happen when the focus
  // is in the search input, so stop the event from propagating.
  if (e.code === 'Space') {
    e.stopPropagation()
  }
}
</script>

<template>
  <input
    v-model="model"
    placeholder="Search"
    class="max-h-7 min-h-7 w-full bg-background-transparent px-2.5 py-1 text-sm-12px-default text-text transition-colors placeholder:text-text-subtlest aria-readonly:cursor-pointer focus:outline-none disabled:cursor-not-allowed disabled:text-text-disabled disabled:placeholder:text-text-disabled"
    v-bind="$attrs"
    @keydown="onKeydown"
  />
</template>
