import { defineStore } from 'pinia'

import { createInvitations as createInvitationsOnBackend } from '@/backend/createInvitations'
import { removeInvitation as removeInvitationOnBackend } from '@/backend/removeInvitation'
import { removeMember as removeMemberOnBackend } from '@/backend/removeMember'
import type {
  User as BackendUser,
  InvitationResponse,
  WorkspaceMemberResponse,
} from '@/backend/types'
import { updateWorkspaceMember } from '@/backend/updateWorkspaceMember'
import { captureException } from '@sentry/vue'
import { ref } from 'vue'
import { PAYWALL_EVENT, usePaywallStore } from '../Billing/paywall'
import { roleOrder } from '../IdentityAndAccess/roleConfig'
import { useWorkspaces } from '../Workspaces/useWorkspaces'

export type WorkspaceMember = {
  id: BackendUser['id']
  email: BackendUser['email']
  firstName: BackendUser['first_name']
  lastName: BackendUser['last_name']
  fullName?: string | null
  role: WorkspaceMemberResponse['role']
  lastActive?: BackendUser['last_activity_at']
}

/**
 * Most users have names. But the API could return `null`, so handle this case.
 * Ths function will always return a string that can be used to label a user.
 */
export const getMemberName = (member: WorkspaceMember): string => {
  let memberName = ''
  if (member) {
    memberName = [member.firstName, member.lastName].filter(Boolean).join(' ')
  }
  return memberName || member.email || member.id
}

/** Serializes a backend workspace member object into a frontend workspace member */
export const serializeWorkspaceMember = (payload: WorkspaceMemberResponse): WorkspaceMember => {
  if (typeof payload.user === 'string') {
    throw new Error('Cannot serialise workspace member')
  }

  const fullName =
    [payload.user.first_name, payload.user.last_name].filter(Boolean).join(' ') || null

  return {
    email: payload.user.email,
    firstName: payload.user.first_name,
    id: payload.user.id,
    lastName: payload.user.last_name,
    fullName,
    role: payload.role,
    lastActive: payload.user.last_activity_at,
  }
}

export type Invitation = {
  id?: InvitationResponse['id']
  email: InvitationResponse['email']
  status: InvitationResponse['status']
  expiresAt?: InvitationResponse['expires_at']
  inviteUrl?: InvitationResponse['invite_url']
  role: InvitationResponse['role']
  workspaceId: InvitationResponse['workspace_id']
}

/** Serializes a invitation object into a frontend invitation object */
export const serializeInvitation = (payload: InvitationResponse): Invitation => ({
  email: payload.email,
  expiresAt: payload.expires_at,
  id: payload.id,
  inviteUrl: payload.invite_url,
  role: payload.role,
  status: payload.status,
  workspaceId: payload.workspace_id,
})

/**
 * Store for managing workspace users. Details the users who are members of the
 * current workspace, and any pending invitations to join the workspace.
 *
 * Provides methods that are used to:
 * - Send/resend invitations
 * - Cancel existing invitations
 * - Remove existing members
 */
export const useWorkspaceMembers = defineStore('workspaceMembers', () => {
  const workspaceMembers = ref<WorkspaceMember[]>([])
  const setWorkspaceMembers = (newUsers: WorkspaceMember[]) => {
    workspaceMembers.value = sortMembers(newUsers)
  }

  const sortMembers = (members: WorkspaceMember[]) =>
    members.sort((a, b) => {
      const roleComparison = roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role)
      if (roleComparison !== 0) {
        return roleComparison
      }
      const firstNameA = a.firstName || ''
      const firstNameB = b.firstName || ''
      return firstNameA.localeCompare(firstNameB)
    })

  const invitations = ref<Invitation[]>([])
  const setInvitations = (newInvitations: Invitation[]) => {
    invitations.value = newInvitations
  }

  const paywallStore = usePaywallStore()
  const workspaceStore = useWorkspaces()
  const createInvitations = async (newInvitations: Array<Pick<Invitation, 'email' | 'role'>>) => {
    const workspaceId = workspaceStore.currentWorkspace?.id
    if (!workspaceId) {
      throw new Error('Cannot send invitations without an active workspace')
    }

    // In case the request fails and we need to revert
    const oldInvitations = [...invitations.value]

    invitations.value = [
      ...invitations.value,
      ...newInvitations.map((invitation) => ({
        ...invitation,
        status: 'pending' as const,
        workspaceId,
        role: invitation.role,
      })),
    ]

    const response = await createInvitationsOnBackend({
      workspaceId,
      invitations: newInvitations.map((i) => ({
        email: i.email,
        role: i.role,
      })),
    })

    if (!response.ok) {
      invitations.value = oldInvitations
      throw new Error('Failed to create invitations')
    }

    /**
     * After sending a set of invitations, we need to update the invitations. For each invitation
     * in our original set of invitations (updated with optimistic ui), there are 3 options:
     * 1. The invitation is not one that was created in this request - keep it as is
     * 2. The invitation is a new one but errored - remove it
     * 3. The invitation is a new one and was successful - update it with the invite url
     */
    invitations.value = invitations.value.reduce<Invitation[]>((acc, invitation) => {
      const invitationInResponse = response.data.data.find((i) => i.email === invitation.email)

      // 1. The invitation is not one that was created in this request - keep it as is
      if (!invitationInResponse) {
        return [...acc, invitation]
      }

      // 2. The invitation is a new one but errored - remove it
      if (invitationInResponse.state === 'error') {
        // If the workspace is already at its user limit, then the user will be unable
        // to create any new invitations.
        if (invitationInResponse.error_message.match(/exceeded the limit/)) {
          paywallStore.open({ action: PAYWALL_EVENT.SEND_INVITATION })
        }
        return acc
      }

      // 3. The invitation is a new one and was successful - update it with the invite url
      return [
        ...acc,
        {
          ...invitation,
          inviteUrl: invitationInResponse.invite_url,
        },
      ]
    }, [])
  }

  const updateInvitation = async (newInvitation: Pick<Invitation, 'email' | 'role'>) => {
    const workspaceId = workspaceStore.currentWorkspace?.id
    if (!workspaceId) {
      throw new Error('Cannot send invitation without an active workspace')
    }

    const response = await createInvitationsOnBackend({
      workspaceId,
      invitations: [
        {
          email: newInvitation.email,
          role: newInvitation.role,
        },
      ],
    })

    if (!response.ok || !response.data) {
      throw new Error('Failed to update the invitation')
    }

    const invitationFromResponse = response.data.data[0]

    if (invitationFromResponse?.state === 'error') {
      throw new Error('Failed to update the invitation')
    }

    invitations.value = invitations.value.map((i) =>
      i.email === newInvitation.email
        ? {
            ...i,
            email: newInvitation.email,
            role: invitationFromResponse.role,
            inviteUrl: invitationFromResponse.invite_url,
          }
        : i,
    )
  }

  const revokeInvitation = async (invitationId: string) => {
    const workspaceId = workspaceStore.currentWorkspace?.id
    if (!workspaceId) {
      throw new Error('Cannot revoke invitation without an active workspace')
    }
    const response = await removeInvitationOnBackend({
      workspaceId,
      invitationId,
    })

    if (!response.ok) {
      throw new Error('Failed to remove invitation')
    }

    invitations.value = invitations.value.filter((i) => i.id !== invitationId)
  }

  const removeMember = async (userId: string) => {
    const workspaceId = workspaceStore.currentWorkspace?.id
    if (!workspaceId) {
      throw new Error('Cannot remove member without an active workspace')
    }

    const response = await removeMemberOnBackend({
      workspaceId,
      userId,
    })

    if (!response.ok) {
      throw new Error('Failed to remove member')
    }

    workspaceMembers.value = workspaceMembers.value.filter((u) => u.id !== userId)
  }

  const updateMemberRole = async ({
    role,
    userId,
    workspaceId,
  }: {
    userId: string
    role: Extract<WorkspaceMember['role'], 'worker' | 'admin' | 'editor' | 'reader'>
    workspaceId: string
  }) => {
    const member = workspaceMembers.value.find((u) => u.id === userId)
    if (!member) {
      throw new Error('Member not found')
    }
    const oldRole = member.role

    member.role = role
    const res = await updateWorkspaceMember({
      role,
      userId,
      workspaceId,
    })

    if (!res.ok) {
      member.role = oldRole
      captureException('Failed to update user role', {
        extra: {
          role,
          userId,
          workspaceId,
          errorCode: res.error.code,
          errorMessage: res.error.message,
        },
      })
    }
  }

  /**
   * Get a workspace member by their id. Will throw if the member is not found,
   * so can only be used in contexts where the member is expected to exist.
   */
  const getMember = (id: string) => {
    const member = workspaceMembers.value.find((u) => u.id === id)
    return member
  }

  /** Get a user-friendly name from a workspace member's id */
  const getMemberNameFromId = (id: string) => {
    const member = getMember(id)
    if (!member) return id

    return getMemberName(member)
  }

  return {
    workspaceMembers,
    setWorkspaceMembers,
    revokeInvitation,
    removeMember,
    invitations,
    setInvitations,
    createInvitations,
    updateInvitation,
    updateMemberRole,
    getMember,
    getMemberNameFromId,
  }
})
