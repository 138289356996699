import type { NavigationParams } from '@/modules/Project/Navigation/navigationTypes'
import { invariant } from '@/shared/utils/typeAssertions'
import { inject, provide, type InjectionKey, type Ref } from 'vue'

export type ProjectNavigationContext = {
  breadcrumbs: Ref<NavigationParams[]>
  navigate: (params: NavigationParams) => void
  reset: () => void
}

export const projectNavigationContextToken: InjectionKey<ProjectNavigationContext> = Symbol(
  'projectNavigationContext',
)

export function useProjectNavigationContext(props: { required: true }): ProjectNavigationContext
export function useProjectNavigationContext(props?: {
  required?: boolean
}): ProjectNavigationContext | null
export function useProjectNavigationContext(props?: {
  required?: boolean
}): ProjectNavigationContext | null {
  const ctx = inject(projectNavigationContextToken, null)
  if (props?.required) {
    invariant(ctx, 'Missing context › `provideProjectNavigationContext()`')
  }

  return ctx
}

export function provideProjectNavigationContext(ctx: ProjectNavigationContext) {
  provide(projectNavigationContextToken, ctx)
}
