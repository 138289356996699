/**
 * Get a single parameter from the route.
 *
 * @returns The single parameter or undefined if the value is not an array.
 */
export function getSingleParam(value?: string | string[] | null): string | undefined {
  if (Array.isArray(value)) {
    return value[0]
  }

  return value || undefined
}
