<script setup lang="ts">
import { useGroundingStore } from '@/modules/Project/useGroundingStore'
import { useNodeViewContext } from '@prosemirror-adapter/vue'
import { computed } from 'vue'
import ClaimIndicatorCe from '../ClaimIndicator.ce.vue'
import type { ClaimAttrs } from './schema'
/**
 * Renders a pill to represent a grounding claim in the editor. When
 * clicked, it will apply a mark to all text outside of the claim to make
 * it less visible.
 */

const { contentRef, node } = useNodeViewContext()

const attrs = computed(() => node.value.attrs as ClaimAttrs)

const groundingStore = useGroundingStore()
const isSelected = computed(() => {
  return Boolean(
    groundingStore.selectedClaimsAndSource &&
      groundingStore.selectedClaimsAndSource.sourceId === Number(attrs.value.sourceId) &&
      groundingStore.selectedClaimsAndSource.claimIds.includes(Number(attrs.value.claimId)) &&
      groundingStore.field?.propertyId === attrs.value.propertyId,
  )
})
</script>

<template>
  <div class="inline-flex h-full items-center px-1">
    <ClaimIndicatorCe
      :ref="contentRef"
      :data-claim-pill-claim="attrs.claimId"
      :data-claim-pill-source="attrs.sourceId"
      :data-from="attrs.range[0]"
      :data-to="attrs.range[1]"
      :source-id="Number(attrs.sourceId)"
      :property-id="attrs.propertyId"
      :is-selected="isSelected"
    />
  </div>
</template>
