import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import type { Field } from './Fields/types'
import { useEntity } from './useEntity'

/**
 * A store for managing the entity sidebar state.
 */
export const useEntitySidebar = defineStore('entitySidebar', () => {
  const entityStore = useEntity()

  const sidebarIsOpen = ref(false)
  const selectedFileField = ref<{ field: Field; groundingField?: Field } | null>(null)

  // if the entity is no longer selected, close the sidebar and clear file field
  // this happens when we navigate to a different view or project
  watch(
    () => entityStore.entityId,
    () => {
      if (!entityStore.entityId) {
        sidebarIsOpen.value = false
      }
    },
  )

  const { projectId } = useRouteParams()
  watch(projectId, () => {
    sidebarIsOpen.value = false
  })

  const selectedEntityId = computed(() => entityStore.entityId ?? null)
  const selectedEntity = computed(() => entityStore.entity ?? null)

  const setSelectedEntityId = (id: string | null) => {
    entityStore.setEntityId(id ?? undefined)
    selectedFileField.value = null
  }

  const setSelectedFileField = (
    field: Field<'file' | 'file_collection'>,
    groundingField?: Field,
  ) => {
    selectedFileField.value = { field, groundingField }
  }

  const clearSelectedFileField = () => {
    selectedFileField.value = null
  }

  const reset = () => {
    sidebarIsOpen.value = false
    selectedFileField.value = null
    entityStore.setEntityId(undefined)
  }

  return {
    sidebarIsOpen,
    selectedEntityId,
    selectedEntity,
    selectedFileField,
    setSelectedEntityId,
    setSelectedFileField,
    clearSelectedFileField,
    reset,
  }
})
