import { useUser } from '@/modules/IdentityAndAccess/useUser'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { invariant } from '@/shared/utils/typeAssertions'
import { computed, type Ref } from 'vue'
import {
  isFullCase,
  type Bubble,
  type BubbleMessageAgentRun,
  type BubbleMessagePlaceholder,
  type BubbleMessageQuery,
  type BubbleMessageResponse,
  type BubbleMessageToolRun,
  type BubbleSent,
  type Case,
  type CaseInputAttachment,
  type MessageAgentRun,
  type MessageFullResponse,
  type MessagePartialResponse,
  type MessageQuery,
  type MessageToolRun,
} from './types'
import { useCasePendingQueryStore } from './useCasePendingQueryStore'
import { useCaseStore } from './useCaseStore'
import { useCaseUploadStore } from './useCaseUploadStore'
import { getMessageAttachments } from './utils'

const getBubbleType = (message: Case['messages'][number]): Bubble['type'] =>
  message.type === 'query' ? 'sent' : 'received'

export const useChatBubbles = (messageIds: Ref<string[]>) => {
  const caseStore = useCaseStore()
  const pendingStore = useCasePendingQueryStore()
  const uploadStore = useCaseUploadStore()
  const userStore = useUser()
  const membersStore = useWorkspaceMembers()

  const getCurrentUser = () =>
    userStore.user
      ? {
          id: userStore.user.id,
          fullName: `${userStore.user.firstName} ${userStore.user.lastName}`,
          email: userStore.user.email || '',
        }
      : null

  const getUser = (userId: string) => {
    const member = membersStore.workspaceMembers.find((member) => member.id === userId)
    return member
      ? { id: member.id, fullName: member.fullName || null, email: member.email || null }
      : null
  }

  const createBubbleMessageQuery = (message: MessageQuery): BubbleMessageQuery => ({
    id: message.id,
    messageType: 'query',
    attachments: getMessageAttachments(message, caseStore.activeCase),
    text: message.text,
    user: getUser(message.userId),
    createdAt: message.createdAt,
    updatedAt: message.updatedAt,
    agentId: message.agentId,
  })

  const createBubbleMessageAgentRun = (message: MessageAgentRun): BubbleMessageAgentRun => {
    const activeCase = caseStore.activeCase
    invariant(isFullCase(activeCase))

    // If the agent run failed, it will sometimes lack an outputId
    // so we use the agentId to find the output
    // Note that we only care about the output's agent here so that's why we get to do this
    const output = message.outputId
      ? activeCase.outputs.find((output) => output.id === message.outputId)
      : activeCase.outputs.find((output) => output.project.id === message.agentId)

    const project = output?.project
    const agent = project
      ? {
          id: project.id,
          name: project.name || '',
          coverHighRes: project.coverImageUrls.high,
          coverLowRes: project.coverImageUrls.low,
        }
      : null

    return {
      id: message.id,
      messageType: 'agent_run',
      outputId: message.outputId,
      attachments: [],
      agent,
      createdAt: message.createdAt,
      updatedAt: message.updatedAt,
      inProgress: message.inProgress,
    }
  }

  const createBubbleMessageResponse = (
    message: MessageFullResponse | MessagePartialResponse,
  ): BubbleMessageResponse | BubbleMessagePlaceholder =>
    message.text.length > 0
      ? {
          id: message.id,
          messageType: message.type,
          attachments: [],
          text: message.text,
          createdAt: message.createdAt,
          updatedAt: message.updatedAt,
        }
      : {
          id: message.id,
          messageType: 'response-placeholder',
          text: 'Thinking',
          createdAt: message.createdAt,
          updatedAt: message.updatedAt,
        }

  const createBubbleMessageToolRun = (message: MessageToolRun): BubbleMessageToolRun => ({
    id: message.id,
    messageType: 'tool_run',
    attachments: [],
    toolName: message.toolName,
    createdAt: message.createdAt,
    updatedAt: message.updatedAt,
    inProgress: message.inProgress,
  })

  const createBubble = (message: Case['messages'][number], previousBubble?: Bubble): Bubble => {
    if (message.type === 'query') {
      return { id: message.id, type: 'sent', message: createBubbleMessageQuery(message) }
    }

    const previousQueryId = previousBubble?.type === 'sent' ? previousBubble.message.id : null

    if (message.type === 'agent_run') {
      return {
        id: message.id,
        type: 'received',
        messages: [createBubbleMessageAgentRun(message)],
        previousQueryId,
      }
    }

    if (message.type === 'response' || message.type === 'partial_response') {
      return {
        id: message.id,
        type: 'received',
        messages: [createBubbleMessageResponse(message)],
        previousQueryId,
      }
    }

    return {
      id: message.id,
      type: 'received',
      messages: [createBubbleMessageToolRun(message)],
      previousQueryId,
    }
  }

  const pendingBubbles = computed<BubbleSent[]>(() =>
    pendingStore.pendingQueries.map((q) => ({
      id: q.id,
      type: 'sent',
      message: {
        id: q.id,
        messageType: 'pending_query',
        attachments: q.uploadIds
          .map((id) => uploadStore.attachmentsMap.get(id))
          .filter((a): a is CaseInputAttachment => a !== undefined),
        text: q.text,
        user: getCurrentUser(),
        agentId: q.agentId || null,
        createdAt: q.createdAt,
        updatedAt: q.updatedAt,
      },
    })),
  )

  const bubbles = computed(() => {
    const result: Bubble[] = []

    if (!isFullCase(caseStore.activeCase)) {
      return result
    }

    caseStore.activeCase.messages
      .filter((m) => messageIds.value.includes(m.id))
      .toSorted((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
      .forEach((message) => {
        const currentBubble = result.at(-1)
        if (!currentBubble || currentBubble.type !== getBubbleType(message)) {
          result.push(createBubble(message, currentBubble))
          return
        }

        if (currentBubble.type === 'received' && message.type === 'agent_run') {
          currentBubble.messages.push(createBubbleMessageAgentRun(message))
          return
        }

        if (
          currentBubble.type === 'received' &&
          (message.type === 'response' || message.type === 'partial_response')
        ) {
          currentBubble.messages.push(createBubbleMessageResponse(message))
          return
        }

        if (currentBubble.type === 'received' && message.type === 'tool_run') {
          currentBubble.messages.push(createBubbleMessageToolRun(message))
          return
        }
      })

    result.push(...pendingBubbles.value)

    const attachments = pendingBubbles.value.at(0)?.message.attachments || []
    const areSomeUploading = attachments.some((a) => !['processing', 'complete'].includes(a.status))
    if (attachments.length > 0) {
      const now = new Date().toISOString()
      result.push({
        type: 'received',
        id: 'tmp-understanding-files-response',
        messages: [
          {
            id: 'tmp-understanding-files-response',
            messageType: 'response-placeholder',
            text: areSomeUploading ? 'Uploading' : 'Understanding files',
            createdAt: now,
            updatedAt: now,
          },
        ],
        previousQueryId: pendingBubbles.value.at(-1)?.message.id || null,
      })
    }

    return result
  })

  return { bubbles }
}
