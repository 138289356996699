import type { APIResult } from '@/backend/types'
import { ref } from 'vue'

export type DataLoaderStatus = 'idle' | 'loading' | 'loaded' | 'errored'

/**
 * Wrapper around data fetching which provides a fetch status
 *
 * Intended to be used whenever we need to fetch data from the backend
 *
 * # Usage
 *
 * Pass in a backend middleware function wrapped into a parameterless function:
 *
 * ```typescript
 * const { status, load } = useDataLoader(() => listProjects(props.workspaceId))
 * ```
 *
 * When used this way, it will automatically infer correct result typing
 *
 * ```
 * const result = await load()
 *
 * if (result.ok) {
 *   result.data // resolved value of the result promise
 * }
 * ```
 */
export const useDataLoader = <T, Args extends unknown[]>(
  loadFn: (...args: Args) => Promise<APIResult<T>>,
) => {
  const status = ref<DataLoaderStatus>('idle')

  const load = async (...args: Args) => {
    status.value = 'loading'
    // Pass the arguments to loadFn
    const result = await loadFn(...args)
    status.value = result.ok ? 'loaded' : 'errored'
    return result
  }

  return { status, load }
}
