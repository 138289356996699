<script setup lang="ts">
import { ENTITY_NAME_FALLBACK } from '@/modules/Project/constants'
import type { SidebarEmits } from '@/modules/Project/Fields/field-types'
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import ReferenceEntityPreview from '@/modules/Project/ReferenceEntityPreview.vue'
import { useRelatedProjectsStore } from '@/modules/Project/relatedProjectsStore'
import { useOpenReferenceTableView } from '@/modules/Project/useOpenReferenceTableView'
import { useReferenceField } from '@/modules/Project/useReferenceField'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import Menu from '@/uiKit/Menu'
import PopOver from '@/uiKit/PopOver.vue'
import { useInfiniteScroll } from '@vueuse/core'
import { computed, useTemplateRef } from 'vue'
import ReferenceSidebarMenuItem from './ReferenceSidebarMenuItem.vue'

const props = defineProps<{
  field: Field<'reference'>
  property: Property<'reference'>
}>()

defineEmits<SidebarEmits<'reference'>>()

/**
 * The component doesn't render a fragment directly,
 * but the wrapping component in the template does, so we must disable inheritAttrs and
 * bind them to the correct slot element
 */
defineOptions({ inheritAttrs: false })

const {
  badgeEntities,
  entityCountLabel,
  entityLimit,
  entityPreviewPopover,
  getRelatedEntityName,
  getPreviewMeta,
  listItems,
  loadMoreEntities,
  localEntityIds,
  nameProperty,
  onToggleEntity,
  onPreviewAnchorMouseover,
  onPreviewAnchorMouseleave,
  onPreviewAnchorClick,
  relatedProject,
  savedEntityIds,
  searchText,
  unrenderedCount,
  updateFieldValue,
} = useReferenceField({
  field: computed(() => props.field),
  property: computed(() => props.property),
})

const selectedEntities = computed(() =>
  listItems.value.filter((item) => item.data.group === 'selected'),
)
const unselectedEntities = computed(() =>
  listItems.value.filter((item) => item.data.group === 'unselected'),
)

const scrollContainerRef = useTemplateRef('scrollContainer')
useInfiniteScroll(scrollContainerRef, loadMoreEntities, { distance: 200 })

const entityPreviewRef = useTemplateRef('entityPreview')

/** Save the field value when the menu closes */
const saveOnClose = (open: boolean) => {
  if (!open) {
    updateFieldValue()
  }
}

const relatedProjectStore = useRelatedProjectsStore()

const { openReferenceTableView } = useOpenReferenceTableView(relatedProject, savedEntityIds)

const floatingMenu = useTemplateRef<HTMLDivElement & { listRef: HTMLElement }>('floatingMenu')

/**
 * Information on the entity that is currently being previewed and its
 * anchor element (badge or listitem).
 */
const previewMeta = computed(() => getPreviewMeta(floatingMenu.value?.listRef))
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps, menu }"
    ref="floatingMenu"
    :positioning="{ sameWidth: true, offset: { mainAxis: 2, crossAxis: 1 } }"
    @change:open="saveOnClose"
  >
    <button
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      class="flex size-full min-h-7 min-w-0 flex-wrap items-center gap-1 overflow-x-hidden rounded-corner-8 p-1 outline-none hover:bg-background-transparent-hovered focus-visible:bg-background-transparent-hovered"
      :class="menu.open ? 'bg-background-transparent-hovered' : 'bg-background-transparent'"
    >
      <div
        v-if="!badgeEntities.length"
        class="h-5 max-w-full truncate whitespace-nowrap pl-1.5 text-text-subtlest"
      >
        No related entities
      </div>
      <BadgeItem
        v-for="entity in badgeEntities"
        :key="entity.id"
        ref="badgeRefs"
        :label="getRelatedEntityName(entity)"
        :title="undefined"
        size="sm"
        variant="neutral"
        class="shrink whitespace-nowrap hover:bg-background-gray-subtlest-hovered"
        :class="[menu.open && 'pointer-events-none']"
        @mouseover="onPreviewAnchorMouseover($event.currentTarget, entity.id, 'badge')"
        @mouseleave="onPreviewAnchorMouseleave"
        @click="onPreviewAnchorClick($event.currentTarget, entity.id, 'badge')"
      />
      <DarwinButton
        v-if="unrenderedCount > 0"
        variant="outline"
        size="xxs"
        @click.stop="openReferenceTableView"
        >+{{ unrenderedCount }}</DarwinButton
      >
    </button>
    <Menu.Content>
      <Menu.ControlledSearch v-model="searchText" />
      <Menu.Divider />
      <Menu.GroupTitle>
        <div class="flex w-full items-center justify-between">
          <div>Related Entities</div>
          <div>{{ localEntityIds.length }}/{{ entityLimit }}</div>
        </div>
      </Menu.GroupTitle>
      <div
        ref="scrollContainer"
        class="go-scrollbar max-h-96 max-w-full overflow-y-auto overflow-x-hidden"
        @mouseleave="entityPreviewPopover.trigger.onMouseLeave"
      >
        <ReferenceSidebarMenuItem
          v-for="item in selectedEntities"
          :key="item.id"
          :label="item.data.name || ENTITY_NAME_FALLBACK"
          :data-sidebar-entity-id="item.data.id"
          selected
          @toggle="onToggleEntity(item)"
          @mouseover="onPreviewAnchorMouseover($event.currentTarget, item.data.id, 'listitem')"
        />
        <Menu.Divider v-if="selectedEntities.length && unselectedEntities.length" />
        <ReferenceSidebarMenuItem
          v-for="item in unselectedEntities"
          :key="item.id"
          :data-sidebar-entity-id="item.data.id"
          :label="item.data.name || ENTITY_NAME_FALLBACK"
          :selected="false"
          @toggle="onToggleEntity(item)"
          @mouseover="onPreviewAnchorMouseover($event.currentTarget, item.data.id, 'listitem')"
        />
      </div>
      <ListMenuItem
        :label="entityCountLabel"
        class="pl-[26px] text-sm-12px-default text-text-subtlest"
        default-hover-disabled
      />
    </Menu.Content>
    <PopOver
      v-if="previewMeta?.anchor && nameProperty && relatedProject"
      :open="entityPreviewPopover.isOpen.value"
      :aria-labelledby="entityPreviewRef?.labelId"
      :placement="previewMeta?.placement"
      :target-selector="previewMeta?.anchor"
      :offset="previewMeta?.offset"
    >
      <template #content>
        <!-- The sidebar is replacing entity view, so we render without the ability to open it -->
        <ReferenceEntityPreview
          v-if="previewMeta"
          ref="entityPreview"
          :can-open-entity="false"
          :entity="previewMeta.entity"
          :name-property-id="nameProperty.id"
          :project="relatedProject"
          :properties="relatedProjectStore.getPropertiesForReference(props.property.id)"
          @mouseover="previewMeta.mouseover"
          @mouseleave="onPreviewAnchorMouseleave"
        />
      </template>
    </PopOver>
  </Menu.Root>
</template>
