<script setup lang="ts">
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import PopOver from '@/uiKit/PopOver.vue'
import { useInfiniteScroll } from '@vueuse/core'
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue'
import { useUser } from '../IdentityAndAccess/useUser'
import { ENTITY_NAME_FALLBACK } from './constants'
import EntityDialog from './EntityDialog.vue'
import { isFilterableProperty } from './Filters/types'
import ReferenceEntityPreview from './ReferenceEntityPreview.vue'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import { useReferenceField, type ReferenceEntityListItem } from './useReferenceField'

const props = defineProps<{
  field: Field<'reference'>
  property: Property<'reference'>
  projectId: string
}>()

const relatedProjectStore = useRelatedProjectsStore()

const userStore = useUser()
const localField = ref(props.field)
watch(
  () => props.field,
  (newField) => {
    /**
     * Only update the local field if it was updated by somebody else. This is
     * because the user's updates in this component will cause websocket
     * updates to the field. We want to ignore those updates because they
     * cause the order of items to jump around.
     */
    if (newField.manualValueUpdatedBy !== userStore.user?.id) {
      localField.value = newField
    }
  },
)

const {
  entityPreviewPopover,
  listItems,
  loadMoreEntities,
  nameProperty,
  onToggleEntity,
  searchText,
  updateFieldValue,
  relatedProject,
  onPreviewAnchorMouseleave,
  onPreviewAnchorMouseover,
  localEntityIds,
  hasReachedEntityLimit,
  entityLimit,
  allLoadedEntities,
  skeletonItems,
  getPreviewMeta,
  showSkeletonList,
} = useReferenceField({
  field: localField,
  property: computed(() => props.property),
})

onMounted(() => {
  loadMoreEntities()
})

const toggleEntity = async (item: ReferenceEntityListItem) => {
  onToggleEntity(item)
  onPreviewAnchorMouseleave()
  await updateFieldValue()
}

const list = useTemplateRef<HTMLElement & { listRef: HTMLElement }>('list')
useInfiniteScroll(() => list.value?.listRef, loadMoreEntities, { distance: 200 })

const entityPreviewRef = useTemplateRef('entityPreview')

const dialogEntityId = ref<string | null>(null)

const previewMeta = computed(() => getPreviewMeta(list.value?.listRef))
</script>

<template>
  <div
    class="flex size-full flex-col"
    v-bind="$attrs"
  >
    <ListMenu
      v-if="nameProperty && relatedProject"
      ref="list"
      class="go-scrollbar box-border flex h-auto max-h-[500px] min-h-[70px] shrink grow-0 select-none flex-col overflow-y-auto rounded-none border-t border-border-subtle !bg-[transparent] shadow-none"
      :items="showSkeletonList ? skeletonItems : listItems"
      :external-search="isFilterableProperty(nameProperty)"
      :group-by-predicate="(item) => item.data.group"
      :group-order="['selected', 'unselected']"
      :hide-no-results-text="showSkeletonList"
      :aria-label="`Entities from ${relatedProject.name}`"
      deselect-on-mouse-leave
      @update:search="searchText = $event"
      @scroll="onPreviewAnchorMouseleave"
    >
      <template #group-title="{ group }">
        <div
          v-if="group.key === 'selected'"
          class="flex h-7 items-center justify-between pl-[9px] pr-2.5 text-xs-11px-default text-text-subtlest"
        >
          <div>Related Entities</div>
          <div>{{ localEntityIds.length }}/{{ entityLimit }}</div>
        </div>
      </template>
      <template #item="{ item, active, key, setActiveItem }">
        <ListMenuCheckboxItem
          :key="key"
          :data-entity-id="item.data.id"
          :active="active"
          :checked="item.data.group === 'selected'"
          :label="item.data.name"
          :disabled="hasReachedEntityLimit"
          @mousemove="setActiveItem(key)"
          @mouseover="onPreviewAnchorMouseover($event.currentTarget, item.data.id, 'listitem')"
          @mouseleave="onPreviewAnchorMouseleave"
          @select="toggleEntity(item)"
        >
          <template #default>
            <LoadingSkeleton
              :status="showSkeletonList"
              class="w-full"
            >
              <div
                class="line-clamp-1 w-max"
                :class="showSkeletonList && 'opacity-0'"
              >
                {{ item.data.name || ENTITY_NAME_FALLBACK }}
                <span
                  v-if="!item.data.name"
                  class="text-text-subtle"
                >
                  {{ item.data.id }}
                </span>
              </div>
            </LoadingSkeleton>
          </template></ListMenuCheckboxItem
        >
      </template>
    </ListMenu>
  </div>
  <PopOver
    v-if="previewMeta && nameProperty && relatedProject"
    :open="entityPreviewPopover.isOpen.value"
    :target-selector="previewMeta.anchor"
    :placement="previewMeta.placement"
    :offset="previewMeta.offset"
    class="contents"
    :aria-labelledby="entityPreviewRef?.labelId"
  >
    <template #content>
      <ReferenceEntityPreview
        v-if="previewMeta"
        ref="entityPreview"
        :can-open-entity="true"
        :entity="previewMeta.entity"
        :name-property-id="nameProperty.id"
        :project="relatedProject"
        :properties="relatedProjectStore.getPropertiesForReference(props.property.id)"
        @mouseover="previewMeta.mouseover"
        @mouseleave="onPreviewAnchorMouseleave"
        @open:entity="dialogEntityId = previewMeta.entity.id"
      />
    </template>
  </PopOver>
  <EntityDialog
    v-if="relatedProject"
    :intial-entity-id="dialogEntityId"
    :initial-loaded-entities="allLoadedEntities"
    :entity-ids="localEntityIds"
    :properties="relatedProjectStore.getPropertiesForReference(props.property.id)"
    :project="relatedProject"
  />
</template>
