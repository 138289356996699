<script setup lang="ts">
import { useFieldStatusIndicator } from '@/modules/Project/useFieldStatusIndicator'
import { useTextField } from '@/modules/Project/useTextField'
import CaseMarkdownWrapper from '@/sharedComponents/CaseMarkdownWrapper.vue'
import MarkdownWithGroundingClaims from '@/sharedComponents/MarkdownWithGroundingClaims/MarkdownWithGroundingClaims.vue'
import { computed } from 'vue'
import type { SidebarEmits, SidebarProps } from '../../field-types'
import { getFieldClaims } from '../../utils/grounding'

const props = defineProps<SidebarProps<'text'>>()
defineEmits<SidebarEmits<'text'>>()
defineOptions({ inheritAttrs: false })

const field = computed(() => props.field)
const { localValue } = useTextField(field)
const { isModelOutput } = useFieldStatusIndicator(field)
</script>

<template>
  <CaseMarkdownWrapper>
    <MarkdownWithGroundingClaims
      v-bind="$attrs"
      class="rounded-corner-8 px-2 py-1 hover:bg-background-transparent-hovered"
      :value="localValue || ''"
      :claims="isModelOutput ? getFieldClaims(field) : []"
      :property-id="property.id"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @save="$emit('submit', $event)"
      @show-source="$emit('show-source', $event)"
    />
  </CaseMarkdownWrapper>
</template>
