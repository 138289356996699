<script setup lang="ts">
import CsvPlaceholder from '@/illustrations/library-csv.svg'
import ImagePlaceholder from '@/illustrations/library-image.svg'
import PdfPlaceholder from '@/illustrations/library-pdf.svg'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { computed } from 'vue'

const props = defineProps<{
  name: string
  progress: number | null
  deletable?: boolean
  status?: 'error' | 'processing' | string
}>()

defineEmits<{ delete: [] }>()

const extension = computed(() => {
  const parts = props.name.split('.')
  if (parts.length < 2) {
    return null
  }
  return parts.pop()?.toLowerCase()
})
</script>

<template>
  <div
    role="listitem"
    :aria-label="name"
    class="flex h-7 w-max items-center gap-2 whitespace-nowrap rounded-corner-8 p-2"
    :class="[
      deletable ? 'pr-1' : 'pr-2',
      status === 'error' ? 'bg-background-critical-subtle' : 'bg-surface-tertiary',
    ]"
  >
    <svg
      v-if="status === 'processing'"
      view-box="0 0 12 12"
      class="size-3 rounded-corner-4 bg-background-transparent hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        class="animate-pulse fill-background-stages-model opacity-10 transition-opacity"
      />
      <circle
        cx="6"
        cy="6"
        r="3"
        class="fill-background-stages-model"
      />
    </svg>
    <div
      v-else-if="!!extension || progress !== null"
      class="flex size-[14px] items-center justify-center pl-px pr-[0.62px]"
    >
      <CircularProgress
        v-if="progress !== null"
        :value="progress"
        size="xs"
      />
      <CsvPlaceholder v-else-if="extension === 'csv'" />
      <PdfPlaceholder v-else-if="extension === 'pdf' || extension === 'Document'" />
      <ImagePlaceholder v-else />
    </div>
    <div class="text-xs-11px-default text-text-subtle">{{ name }}</div>

    <IconButton
      v-if="deletable"
      class="-ml-1"
      :aria-label="`Remove ${name}`"
      icon="close"
      size="sm"
      variant="transparent"
      @click="$emit('delete')"
    />
  </div>
</template>
