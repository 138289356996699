import type { RouteRecordRaw } from 'vue-router'
import ProjectSidebar from '../Projects/ProjectSidebar.vue'
import Case from './Case.vue'
import CaseBreadcrumbs from './CaseBreadcrumbs.vue'
import NewCase from './NewCase.vue'

export const casesRoutes: RouteRecordRaw[] = [
  {
    path: 'cases/new',
    name: 'New Case',
    props: {
      default: (route) => ({
        workspaceId: route.params.workspaceId,
        agentId: route.query.agentId,
        entityId: route.query.entityId,
      }),
      Sidebar: (route) => ({
        workspaceId: route.params.workspaceId,
        open: true,
      }),
      BreadCrumbs: true,
    },
    components: {
      default: NewCase,
      Sidebar: ProjectSidebar,
      BreadCrumbs: CaseBreadcrumbs,
    },
  },
  {
    path: 'cases/:caseId',
    name: 'Case',
    props: {
      default: (route) => ({
        workspaceId: route.params.workspaceId,
        agentId: route.query.agentId,
        caseId: route.params.caseId,
      }),
      Sidebar: (route) => ({
        workspaceId: route.params.workspaceId,
        open: true,
      }),
      BreadCrumbs: true,
    },
    components: {
      default: Case,
      Sidebar: ProjectSidebar,
      BreadCrumbs: CaseBreadcrumbs,
    },
  },
]
