import type { components } from '@/api'
import type { Field, FileField, GroundableField } from '@/modules/Project/Fields/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, type ComputedRef } from 'vue'
import { getClaimStartAndEnd, isGroundableField } from './Fields/utils/grounding'
import { useSerializeFieldToText } from './useSerializeFieldToText'

type BackendGrounding = components['schemas']['Projects.Grounding.Grounding']
type BackendSource = BackendGrounding['sources'][number]
type BoundingBox = BackendSource['bounding_boxes'][number]

export type ClaimAndSource = {
  claimId: number
  sourceId: number
}

export type Source = {
  id: number
  boundingBoxes: BoundingBox[] & { 0: BoundingBox }
  propertyId: BackendSource['property_id']
}

export type Claim = {
  id: number
  start: number
  end: number
  sources: number[] & { 0: number }
}

/**
 * Holds all the state related to grounding claims and sources for a given
 * field.
 */
export const useGroundingStore = defineStore('grounding', () => {
  /**
   * The field that contains grounding claims and sources.
   */
  const field = ref<GroundableField | null>(null)

  /**
   * All fields that are inputs to the grounded field, including fields
   * that have no sources.
   */
  const inputFields = ref<FileField[]>([])

  const activeInputId = ref<string | null>(null)

  const serializeFieldToText = useSerializeFieldToText()

  /**
   * All claims & sources for the current field. The claims are linked to sources in a many-many
   * relationship, and are joined by the `claim.sources: number[]` field that contains IDs of the
   * sources for the claim.
   */
  const claimsAndSources: ComputedRef<{ sources: Source[]; claims: Claim[] }> = computed(() => {
    if (!field.value?.grounding) return { claims: [], sources: [] }

    const referencedSourceIds = field.value.grounding.claims.reduce((acc, claim) => {
      claim.source_indices.forEach(acc.add, acc)
      return acc
    }, new Set<number>())

    const compareBoundingBoxes = (a: BoundingBox, b: BoundingBox) =>
      a.page - b.page || a.ymin - b.ymin || a.xmin - b.xmin

    const backendSources = field.value.grounding.sources
      .map((s) => ({ ...s, bounding_boxes: s.bounding_boxes.toSorted(compareBoundingBoxes) }))
      .filter((s) => s.bounding_boxes.length && referencedSourceIds.has(s.index))
      .toSorted(
        (a, b) =>
          a.property_id.localeCompare(b.property_id) ||
          compareBoundingBoxes(a.bounding_boxes[0], b.bounding_boxes[0]),
      )

    const claims = field.value.grounding.claims
      .toSorted((a, b) => a.location.offset - b.location.offset)
      .reduce<{ acc: Claim[]; lastEnd: number }>(
        ({ acc, lastEnd }, claim) => {
          if (!field.value) {
            return { acc, lastEnd }
          }

          const adjustedStartEnd = getClaimStartAndEnd(
            claim,
            field.value,
            serializeFieldToText(field.value),
            lastEnd,
          )
          if (!adjustedStartEnd) {
            return { acc, lastEnd }
          }

          const { start, end } = adjustedStartEnd

          const sources = claim.source_indices
            .map((source_index) => backendSources.findIndex(({ index }) => index === source_index))
            .filter((idx) => idx !== -1)
            .toSorted()

          if (sources.length)
            acc.push({ id: -1, start, end, sources: [sources[0], ...sources.slice(1)] })

          return { acc, lastEnd: end }
        },
        { acc: [], lastEnd: 0 },
      )
      .acc.map((claim, idx) => ({ ...claim, id: idx }))

    const sources: Source[] = backendSources.map((source, idx) => ({
      id: idx,
      propertyId: source.property_id,
      boundingBoxes: [source.bounding_boxes[0], ...source.bounding_boxes.slice(1)],
    }))

    return { sources, claims }
  })

  const claims = computed(() => claimsAndSources.value.claims)
  const sources = computed(() => claimsAndSources.value.sources)

  /**
   * Get all sources that are associated with a given property.
   */
  const getSourcesForProperty = (propertyId: string) =>
    sources.value.filter((source) => source.propertyId === propertyId)

  const activeInputField = computed(() =>
    inputFields.value.find((field) => field.propertyId === activeInputId.value),
  )

  const activeInputSources = computed(() =>
    activeInputId.value ? getSourcesForProperty(activeInputId.value) : [],
  )

  /**
   * The source bounding box that should be highlighted, and any claims that
   * should be highlighted along with it.
   */
  const selectedClaimsAndSource = ref<{ claimIds: number[]; sourceId: number } | null>(null)

  /**
   * Click a source box, causing it and all of its associated claims to be
   * highlighted.
   */
  const clickSource = (sourceId: number) => {
    const source = sources.value[sourceId]
    assertIsNotNullOrUndefined(source, 'No source found for the given index.')

    activeInputId.value = source.propertyId

    const selectedClaims = claims.value
      .filter((claim) => claim.sources.includes(sourceId))
      .map((c) => c.id)
    selectedClaimsAndSource.value = { claimIds: selectedClaims, sourceId }
  }

  /**
   * Click a claim indicator, causing it and its single associated source to be
   * highlighted.
   */
  const clickClaim = ({ claimId, sourceId }: ClaimAndSource) => {
    selectedClaimsAndSource.value = { claimIds: [claimId], sourceId }
  }

  const hasGroundingClaim = (field: Field) => isGroundableField(field) && field.hasGrounding

  const reset = () => {
    field.value = null
    inputFields.value = []
    activeInputId.value = null
  }

  return {
    field,
    inputFields,
    activeInputField,
    sources,
    activeInputSources,
    getSourcesForProperty,
    claims,
    activeInputId,
    clickClaim,
    clickSource,
    reset,
    hasGroundingClaim,
    selectedClaimsAndSource,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGroundingStore, import.meta.hot))
}
