import { confirmCaseFilesUploaded } from '@/backend/confirmCaseFilesUploaded'
import { performCaseAsyncFileUpload } from '@/backend/performCaseAsyncFileUpload'
import { startCaseFileUpload } from '@/backend/startCaseFileUpload'
import { uploadFile } from '@/backend/uploadFile'
import { invariant } from '@/shared/utils/typeAssertions'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import type { LibraryFile } from '../Library/libraryStore'
import type { GoogleDriveFile } from '../Workspaces/KnowledgeHub/Integrations/GoogleDrive/useGoogleDriveConnection'
import { useIntegrationStore } from '../Workspaces/KnowledgeHub/useIntegrationStore'
import { isFullCase, type CaseFile, type CaseInputAttachment } from './types'
import { useCaseStore } from './useCaseStore'

const uploadToAttachment = (item: QueuedItem, file: CaseFile | null): CaseInputAttachment => {
  if (item.status === 'ready' || item.status === 'getting-url') {
    return {
      uploadId: item.id,
      type: 'file',
      name: item.fileName,
      fileId: null,
      progress: 0,
      status: 'registering',
    }
  }

  if (item.status === 'registered' || item.status === 'uploading') {
    return {
      uploadId: item.id,
      type: 'file',
      name: item.fileName,
      fileId: item.fileId,
      progress: item.progress,
      status: 'uploading',
    }
  }

  if (item.status === 'uploaded' || item.status === 'confirming') {
    return {
      uploadId: item.id,
      type: 'file',
      name: item.fileName,
      fileId: item.fileId,
      progress: 1,
      status: 'confirming',
    }
  }

  if (item.status === 'library-added') {
    return {
      uploadId: item.id,
      type: 'library-file',
      name: item.record.name,
      fileId: item.fileId,
      progress: 1,
      status: 'uploading',
    }
  }

  const FILE_STATUS_MAP: Record<
    Exclude<CaseFile['status'], undefined>,
    CaseInputAttachment['status']
  > = {
    uploading: 'processing',
    computing: 'processing',
    complete: 'complete',
    error: 'error',
  } as const

  if (item.status === 'done') {
    return {
      uploadId: item.id,
      type: 'file',
      name: item.fileName,
      fileId: item.fileId,
      progress: null,
      status: file?.status ? FILE_STATUS_MAP[file.status] : 'processing',
    }
  }

  if (item.status === 'library-uploaded') {
    return {
      uploadId: item.id,
      type: 'library-file',
      name: item.record.name,
      fileId: item.fileId,
      progress: null,
      status: file?.status ? FILE_STATUS_MAP[file.status] : 'processing',
    }
  }

  if (item.status === 'integration-added') {
    return {
      uploadId: item.id,
      type: 'integration-file',
      name: item.record.name,
      fileId: item.fileId,
      progress: 1,
      status: 'uploading',
    }
  }

  if (item.status === 'integration-uploaded') {
    return {
      uploadId: item.id,
      type: 'integration-file',
      name: item.record.name,
      fileId: item.fileId,
      progress: null,
      status: file?.status ? FILE_STATUS_MAP[file.status] : 'processing',
    }
  }

  // Unsupported integration files are defined as status: unknown
  // this ignores them
  invariant(item.status === 'error', 'Unknown attachment status')
  return {
    uploadId: item.id,
    type: 'file',
    name: item.fileName,
    fileId: null,
    progress: null,
    status: 'error',
  }
}

export type PendingItem = {
  id: string
  file: File
  fileName: string
  status: 'ready' | 'getting-url'
  url?: string
}

export type RegisteredItem = {
  id: string
  fileId: string
  file: File
  fileName: string
  progress: number
  status: 'registered' | 'uploading'
  url: string
}

export type UploadedItem = {
  id: string
  fileId: string
  fileName: string
  status: 'uploaded' | 'confirming'
  errorCode?: string
  url: string
}

export type ConfirmedItem = {
  id: string
  fileId: string
  fileName: string
  status: 'done'
}

export type LibraryFileItem =
  | {
      id: string
      record: Omit<LibraryFile, 'fileUrl'> & { fileUrl: string }
      file: File | null
      fileId: null
      status: 'library-added'
    }
  | {
      id: string
      record: Omit<LibraryFile, 'fileUrl'> & { fileUrl: string }
      file: File | null
      fileId: string
      status: 'library-uploaded'
    }

export type IntegrationFileItem =
  | {
      id: string
      record: GoogleDriveFile
      fileId: null
      status: 'integration-added'
      connectionId: string
    }
  | {
      id: string
      record: GoogleDriveFile
      fileId: string
      status: 'integration-uploaded'
      connectionId: string
    }

export type ErroredItem = {
  id: string
  fileName: string
  fileId: string | null
  errorCode: string
  status: 'error'
}

type QueuedItem =
  | PendingItem
  | RegisteredItem
  | UploadedItem
  | ConfirmedItem
  | LibraryFileItem
  | IntegrationFileItem
  | ErroredItem

/**
 * Queue-like handler of pending uploads for case queries
 *
 *
 * Performs 3 parallel operations
 * - registeres pending uploads using the "startCaseFileUpload" API
 * - uploads registered files using the "uploadFile" API
 * - confirms uploaded files using the "confirmCaseFilesUploaded" API
 *
 * All 3 operations run concurrently, `queueSize` at a time
 *
 * That means with a `queueSize` of 2, at any give time
 *
 * - up to 2 files will be getting registered
 * - up to 2 files will be getting created
 * - becuase the endpoint supports passing multiple ids, up to 2 confirmations will be running, each containing any number of fileIds
 *
 */
export const useCaseUploadStore = defineStore('caseUploads', () => {
  const caseStore = useCaseStore()
  const integrationStore = useIntegrationStore()
  const uploads = ref<QueuedItem[]>([])

  const attachments = computed(() =>
    uploads.value.map((u) => {
      const fileId = 'fileId' in u ? u.fileId : null

      const file =
        fileId && isFullCase(caseStore.activeCase)
          ? caseStore.activeCase.files.find((f) => f.id === fileId) || null
          : null

      return uploadToAttachment(u, file)
    }),
  )

  const attachmentsMap = computed(() => new Map(attachments.value.map((a) => [a.uploadId, a])))

  const addPendingUploads = (files: File[]) => {
    const ids: string[] = []
    files.forEach((file) => {
      const id = crypto.randomUUID()
      uploads.value.push({ file, fileName: file.name, id, status: 'ready' })
      ids.push(id)
    })
    return ids
  }

  /**
   * Add previously uploaded files to the upload store,
   * so they can be used together with new files, when editing a query.
   */
  const addDoneUploads = (attachments: CaseInputAttachment[]) => {
    const ids: string[] = []
    attachments.forEach((a) => {
      invariant(a.type === 'file', 'Only file attachments can be done')
      invariant(a.fileId, 'File ID is required for done uploads')
      const id = crypto.randomUUID()
      uploads.value.push({ id, status: 'done', fileName: a.name, fileId: a.fileId })
      ids.push(id)
    })
    return ids
  }

  /**
   * Change state of the given upload object to "starting".
   * This means the request to start the upload has been fired to the backend.
   * Once a response is received, the next step is to call `setUploadUploading`
   * and actually upload the file.
   */
  const setPendingUploadGettingUrl = (id: string) => {
    const upload = uploads.value.find((u) => u.id === id)
    if (upload) {
      upload.status = 'getting-url'
    }
  }

  /**
   * Convert the given pending upload into a proper upload by associating it
   * with the given entity id and moving it to the upload map.
   */
  const upgradePendingUpload = (id: string, fileId: string, url: string) => {
    const idx = uploads.value.findIndex((u) => u.id === id && u.status === 'getting-url')
    if (idx === -1) {
      return
    }
    const pendingUpload = uploads.value[idx]
    if (!pendingUpload || pendingUpload.status !== 'getting-url') {
      return
    }

    const upload: RegisteredItem = {
      file: pendingUpload.file,
      fileId,
      fileName: pendingUpload.fileName,
      id,
      progress: 0,
      status: 'registered',
      url,
    }

    uploads.value.splice(idx, 1, upload)
  }

  const setPendingUploadError = (id: string, errorCode: string) => {
    const item = uploads.value.find(
      (u): u is PendingItem => u.status === 'getting-url' && u.id === id,
    )

    if (!item) {
      return
    }

    const idx = uploads.value.findIndex((u) => u.id === id)
    if (idx > -1) {
      uploads.value.splice(idx, 1, {
        id: item.id,
        status: 'error',
        errorCode,
        fileName: item.fileName,
        fileId: null,
      })
    }
  }

  /**
   * Change state of the given upload object to "uploading".
   * This means we got the url to upload to and have started the upload.
   * We will now be calling the setProgress action until done
   */
  const setRegisteredUploadUploading = (fileId: string, url: string) => {
    const upload = uploads.value.find(
      (u): u is RegisteredItem => u.status === 'registered' && u.fileId === fileId,
    )
    if (upload) {
      upload.status = 'uploading'
      upload.url = url
    }
  }

  /**
   * Set upload progress of the given upload object
   */
  const setRegisteredUploadProgress = (fileId: string, progress: number) => {
    const upload = uploads.value.find(
      (u): u is RegisteredItem => u.status === 'uploading' && u.fileId === fileId,
    )
    if (upload) {
      upload.progress = progress
    }
  }

  const setRegisteredUploadError = (fileId: string, errorCode: string) => {
    const upload = uploads.value.find(
      (u): u is RegisteredItem => u.status === 'uploading' && u.fileId === fileId,
    )

    if (!upload) {
      return
    }

    const idx = uploads.value.findIndex((u) => u.id === upload.id && u.status === 'uploading')
    if (idx > -1) {
      uploads.value.splice(idx, 1, {
        id: upload.id,
        status: 'error',
        errorCode,
        fileName: upload.fileName,
        fileId: upload.fileId,
      })
    }
  }

  const upgradeRegisteredUpload = (fileId: string) => {
    const upload = uploads.value.find(
      (u): u is RegisteredItem => u.status === 'uploading' && u.fileId === fileId,
    )
    if (!upload) {
      return
    }
    const index = uploads.value.findIndex((u) => u.status === 'uploading' && u.fileId === fileId)
    const uploadedUpload: UploadedItem = {
      id: upload.id,
      fileId,
      fileName: upload.fileName,
      status: 'uploaded',
      url: upload.url,
    }

    uploads.value.splice(index, 1, uploadedUpload)
  }

  const setUploadedUploadsConfirming = (fileIds: string[]) => {
    fileIds.forEach((fileId) => {
      const upload = uploads.value.find(
        (u): u is UploadedItem => u.status === 'uploaded' && u.fileId === fileId,
      )
      if (upload) {
        upload.status = 'confirming'
      }
    })
  }

  /**
   * Mark the upload as fully done. The next step is to clear it from the store.
   */
  const setUploadedUploadDone = (fileId: string) => {
    const upload = uploads.value.find(
      (u): u is UploadedItem => u.status === 'confirming' && u.fileId === fileId,
    )
    if (!upload) return
    const idx = uploads.value.indexOf(upload)
    if (idx === -1) return

    uploads.value.splice(idx, 1, {
      id: upload.id,
      status: 'done',
      fileName: upload.fileName,
      fileId,
    })
  }

  /**
   * Put the given upload into an error state.
   *
   * The error string is currently a free-form string. Once we have proper error handling,
   * we should make it an enumerated set of codes, so the UI can consistently rely on them.
   */
  const setUploadedUploadError = (fileId: string, errorCode: string) => {
    const upload = uploads.value.find(
      (u): u is UploadedItem => u.status === 'confirming' && u.fileId === fileId,
    )
    if (!upload) {
      return
    }

    const idx = uploads.value.findIndex((u) => u.id === upload.id && u.status === 'confirming')
    if (idx > -1) {
      uploads.value.splice(idx, 1, {
        id: upload.id,
        status: 'error',
        errorCode,
        fileName: upload.fileName,
        fileId: upload.fileId,
      })
    }
  }

  /**
   * Remove an object from the store
   */
  const removeUpload = (id: string) => {
    const idx = uploads.value.findIndex((u) => u.id === id)
    if (idx > -1) {
      uploads.value.splice(idx, 1)
    }
  }

  const addLibraryFiles = (files: LibraryFile[]) => {
    const items: LibraryFileItem[] = []
    files.forEach((f) => {
      if (!f.fileUrl) {
        return
      }
      const record = { ...f, fileUrl: f.fileUrl }
      const id = crypto.randomUUID()
      const item: LibraryFileItem = {
        file: null,
        fileId: null,
        id,
        record,
        status: 'library-added',
      }
      items.push(item)
    })
    uploads.value.push(...items)
  }

  const addIntegrationFiles = async (files: GoogleDriveFile[]) => {
    const driveConnection = integrationStore.findConnection('google_drive')
    invariant(driveConnection, 'Google Drive connection not found')

    const items: IntegrationFileItem[] = []
    files.forEach((f) => {
      const id = crypto.randomUUID()
      const item: IntegrationFileItem = {
        id,
        record: f,
        fileId: null,
        status: 'integration-added',
        connectionId: driveConnection.id,
      }
      items.push(item)
    })
    uploads.value.push(...items)
  }

  const clearUploads = () => {
    uploads.value = []
  }

  const setLibraryFileError = (id: string, errorCode: string) => {
    const item = uploads.value.find(
      (u): u is LibraryFileItem => u.status === 'library-added' && u.id === id,
    )
    if (!item) {
      return
    }

    const idx = uploads.value.findIndex((u) => u.id === id)
    if (idx > -1) {
      uploads.value.splice(idx, 1, {
        id: item.id,
        status: 'error',
        errorCode,
        fileName: item.record.filename,
        fileId: null,
      })
    }
  }

  const setIntegrationFileError = (id: string, errorCode: string) => {
    const item = uploads.value.find(
      (u): u is IntegrationFileItem => u.status === 'integration-added' && u.id === id,
    )

    if (!item) {
      return
    }

    const idx = uploads.value.findIndex((u) => u.id === id)
    if (idx > -1) {
      uploads.value.splice(idx, 1, {
        ...item,
        status: 'error',
        errorCode,
        fileName: item.record.name,
        fileId: item.fileId,
      })
    }
  }

  const registrationCount = ref(0)
  const uploadCount = ref(0)
  const confirmCount = ref(0)
  const libraryCount = ref(0)
  const integrationCount = ref(0)
  const queueSize = ref(0)

  const registrationQueue = computed(() => {
    return {
      nextItem: uploads.value.find((u) => u.status === 'ready'),
      hasCapacity: registrationCount.value < queueSize.value,
    }
  })

  const uploadQueue = computed(() => {
    return {
      nextItem: uploads.value.find((u): u is RegisteredItem => u.status === 'registered'),
      hasCapacity: uploadCount.value < queueSize.value,
    }
  })

  const confirmQueue = computed(() => {
    return {
      items: uploads.value.filter((u): u is UploadedItem => u.status === 'uploaded'),
      hasCapacity: confirmCount.value < queueSize.value,
    }
  })

  const libraryQueue = computed(() => {
    return {
      nextItem: uploads.value.find((u): u is LibraryFileItem => u.status === 'library-added'),
      hasCapacity: libraryCount.value < queueSize.value,
    }
  })

  const integrationQueue = computed(() => {
    return {
      nextItem: uploads.value.find(
        (u): u is IntegrationFileItem => u.status === 'integration-added',
      ),
      hasCapacity: integrationCount.value < queueSize.value,
    }
  })

  const start = (opts: { queueSize: number; workspaceId: string; caseId: string }) => {
    queueSize.value = opts.queueSize
    const { workspaceId, caseId } = opts

    const stopRegistrationQueue = watch(
      registrationQueue,
      async (q) => {
        const { nextItem, hasCapacity } = q
        if (!nextItem || nextItem.status !== 'ready' || !hasCapacity) {
          return
        }

        registrationCount.value += 1

        setPendingUploadGettingUrl(nextItem.id)

        const result = await startCaseFileUpload(workspaceId, caseId, nextItem.file.name)

        if (result.ok) {
          upgradePendingUpload(nextItem.id, result.data.file_id, result.data.file_upload_url)
        } else {
          setPendingUploadError(nextItem.id, result.error.code)
        }

        registrationCount.value -= 1
      },
      { immediate: true },
    )

    const stopUploadQueue = watch(
      uploadQueue,
      async (q) => {
        const { nextItem, hasCapacity } = q
        if (!nextItem || !hasCapacity) {
          return
        }

        uploadCount.value += 1

        setRegisteredUploadUploading(nextItem.fileId, nextItem.url)

        const result = await uploadFile(nextItem.url, nextItem.file, (progress) =>
          setRegisteredUploadProgress(nextItem.fileId, progress),
        )

        if (!result.ok) {
          setRegisteredUploadError(nextItem.fileId, result.error.code)
        } else {
          upgradeRegisteredUpload(nextItem.fileId)
        }

        uploadCount.value -= 1
      },
      { immediate: true },
    )

    const stopConfirmQueue = watch(
      confirmQueue,
      async (q) => {
        if (q.items.length === 0 || !q.hasCapacity) {
          return
        }

        confirmCount.value += 1
        const fileIds = q.items.map((u) => u.fileId)
        setUploadedUploadsConfirming(fileIds)

        const result = await confirmCaseFilesUploaded(workspaceId, caseId, fileIds)

        if (result.ok) {
          q.items.forEach((u) => setUploadedUploadDone(u.fileId))
        } else {
          q.items.forEach((u) => setUploadedUploadError(u.fileId, result.error.code))
        }

        confirmCount.value -= 1
      },
      { immediate: true },
    )

    const stopLibraryQueue = watch(
      libraryQueue,
      async (q) => {
        const { nextItem, hasCapacity } = q
        if (!nextItem || !hasCapacity) {
          return
        }

        libraryCount.value += 1

        const result = await performCaseAsyncFileUpload(workspaceId, caseId, {
          type: 'url',
          fileUrl: nextItem.record.fileUrl,
          fileName: nextItem.record.filename,
        })

        if (result.ok) {
          nextItem.fileId = result.data.file_id
          nextItem.status = 'library-uploaded'
        } else {
          setLibraryFileError(nextItem.id, result.error.code)
        }

        libraryCount.value -= 1
      },
      { immediate: true },
    )

    const stopIntegrationQueue = watch(
      integrationQueue,
      async (q) => {
        const { nextItem, hasCapacity } = q
        if (!nextItem || !hasCapacity) {
          return
        }

        integrationCount.value += 1

        const result = await performCaseAsyncFileUpload(workspaceId, caseId, {
          type: 'integration',
          integrationId: 'google_drive',
          connectionId: nextItem.connectionId,
          fileId: nextItem.record.id,
          fileName: nextItem.record.name,
        })

        if (result.ok) {
          nextItem.fileId = result.data.file_id
          nextItem.status = 'integration-uploaded'
        } else {
          setIntegrationFileError(nextItem.id, result.error.code)
        }

        integrationCount.value -= 1
      },
      { immediate: true },
    )

    return () => {
      queueSize.value = 0
      stopRegistrationQueue()
      stopUploadQueue()
      stopConfirmQueue()
      stopLibraryQueue()
      stopIntegrationQueue()
    }
  }

  const getFileIds = (uploadIds: string[]) => {
    const fileIds: string[] = []
    uploadIds.forEach((id) => {
      const attachment = attachmentsMap.value.get(id)
      if (attachment && 'fileId' in attachment && attachment.fileId) {
        fileIds.push(attachment.fileId)
      }
    })

    return fileIds
  }

  return {
    addDoneUploads,
    addPendingUploads,
    removeUpload,
    clearUploads,
    addLibraryFiles,
    addIntegrationFiles,
    uploads,
    attachments,
    attachmentsMap,
    getFileIds,
    start,
  }
})
