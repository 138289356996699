<script setup lang="ts">
import CaseProjectSelector from '@/modules/Cases/Sidebar/CaseProjectSelector.vue'
import {
  isEntityParams,
  isProjectParams,
  type NavigationParams,
} from '@/modules/Project/Navigation/navigationTypes'
import { useProjectNavigationContext } from '@/modules/Project/Navigation/projectNavigationContext'
import { useEntity } from '@/modules/Project/useEntity'
import type { Entity } from '@/modules/Project/useProject'
import { useProjects, type Project } from '@/modules/Projects/useProjects'
import CrumbItem from '@/modules/Workspaces/Crumbs/CrumbItem.vue'
import EntityCrumbComponent from '@/modules/Workspaces/Crumbs/EntityCrumb.vue'
import CrumbChevron from '@/sharedComponents/CrumbChevron.vue'
import { computed, ref } from 'vue'

type BaseCrumb = { params: NavigationParams; id: string; isLast: boolean }
type EntityCrumb = BaseCrumb & { type: 'entity'; entity: Entity }
type ProjectCrumb = BaseCrumb & { type: 'project'; project: Project }
type UnknownCrumb = BaseCrumb & { type: 'unknown' }
type Crumb = EntityCrumb | ProjectCrumb | UnknownCrumb

const projectStore = useProjects()
const entityStore = useEntity()

const navContext = useProjectNavigationContext({ required: true })

const breadcrumbs = computed<Crumb[]>(() => {
  return navContext.breadcrumbs.value.map((crumb) => {
    const id = Object.values(crumb).join('-')
    const isLast = crumb === navContext.breadcrumbs.value.at(-1)
    const shared: BaseCrumb = { params: crumb, id, isLast }

    if (isEntityParams(crumb)) {
      const entity = entityStore.entities.find((e) => e.id === crumb.entityId)
      if (entity?.entity) {
        return { ...shared, entity: entity.entity, type: 'entity' } satisfies EntityCrumb
      }
    }

    if (isProjectParams(crumb)) {
      const project = projectStore.projects.find((p) => p.id === crumb.projectId)
      if (project) {
        return { ...shared, project, type: 'project' } satisfies ProjectCrumb
      }
    }

    return { ...shared, type: 'unknown' }
  })
})

/** Useful for debugging to be able to jump to various projects */
const showProjectSelector = ref(import.meta.env.DEV)

const isRootActive = computed(() => navContext.breadcrumbs.value.length === 0)

function clearNavigation() {
  navContext.breadcrumbs.value = []
}
</script>

<template>
  <div class="flex flex-row items-center">
    <CrumbItem
      :active="isRootActive"
      @click="clearNavigation()"
      >All data</CrumbItem
    >

    <!-- Project selector for debugging purposes -->
    <CaseProjectSelector
      v-if="showProjectSelector"
      class="w-4 border-dashed"
    />

    <template
      v-for="crumb in breadcrumbs"
      :key="crumb.id"
    >
      <CrumbChevron />

      <EntityCrumbComponent
        v-if="crumb.type === 'entity'"
        :entity="crumb.entity"
        :active="crumb.isLast"
        mask
      />

      <CrumbItem
        v-else
        :active="crumb.isLast"
        @click="navContext.navigate(crumb.params)"
        >{{ crumb.type === 'project' ? crumb.project.name : crumb.id }}</CrumbItem
      >
    </template>
  </div>
</template>
