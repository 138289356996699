import { ref, watch, type Ref } from 'vue'
import type { Field } from './Fields/types'
import { useFieldValue } from './useFieldValue'

/**
 * Contains controlled and derived state used when rendering a component for
 * a text field. The state/logic exposed here can be used in any place in the
 * UI where a text field is rendered (e.g. table cell, cases sidebar, etc).
 */
export const useTextField = (field: Ref<Field<'text'>>) => {
  const localValue = ref<Field<'text'>['manualValue']>('')

  const savedValue = useFieldValue(field)
  watch(
    savedValue,
    (newValue) => {
      localValue.value = newValue
    },
    { immediate: true },
  )

  return { localValue }
}
