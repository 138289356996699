import type { Claim } from './useGroundingStore'

/**
 * The grounded text, with claims inserted at the correct positions. Claims are
 * serialized in a way that will be picked up and parsed by our custom
 * markdown-it plugin.
 */
export const getTextWithClaims = (
  claims: Claim[],
  value: string,
  startDelimiter?: string,
  endDelimiter?: string,
) => {
  startDelimiter = startDelimiter ?? '<'
  endDelimiter = endDelimiter ?? '>'

  type ClaimStartOrEnd = { position: number; type: 'start' | 'end' } & Claim
  const claimMarks = claims.reduce<Array<ClaimStartOrEnd>>((acc, claim) => {
    acc.push({ position: claim.start, type: 'start', ...claim })
    acc.push({ position: claim.end, type: 'end', ...claim })
    return acc
  }, [])

  const getSerializedClaims = (claim: ClaimStartOrEnd) => {
    if (claim.type === 'start') {
      return `${startDelimiter}CLAIM_START:${claim.id}${endDelimiter}`
    }

    if (claim.type === 'end') {
      return claim.sources
        .map(
          (sourceId) =>
            `${startDelimiter}CLAIM:${claim.id}:${sourceId}:${claim.start}:${claim.end}${endDelimiter}`,
        )
        .join('')
    }
  }

  const toolValueWithClaims = claimMarks
    // Process the claims in reverse order to avoid changing the positions of
    // text that is yet to be processed
    .toSorted((a, b) => b.position - a.position)
    .reduce((text, claim) => {
      const insertClaimAt = claim.position
      return `${text.slice(0, insertClaimAt)}${getSerializedClaims(claim)}${text.slice(insertClaimAt)}`
    }, value)

  return toolValueWithClaims
}
