import { serializeProperty } from '@/modules/Project/useProject'
import type { Project } from '@/modules/Projects/useProjects'
import { useCanSeeProject } from '@/sharedComposables/useCanSeeProject'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { recentProjectsLoader } from './useRecentProjectsDataLoader'

export const useRecentProjects = defineStore('recentProjects', () => {
  const recentProjects = ref<Project[]>([])

  const canSeeProject = useCanSeeProject()

  const loadingStatus = ref<'idle' | 'loading' | 'loaded' | 'errored'>('idle')
  const refresh = async (
    workspaceId: string,
    options: { disableLoadingStatus?: boolean } = {},
  ): Promise<void> => {
    /**
     * In some cases (e.g. when a single project has been updated) we don't
     * want a loading skeleton to flash into view, so we don't set the loading
     * status.
     */
    if (!options.disableLoadingStatus) {
      loadingStatus.value = 'loading'
    }

    try {
      const result = await recentProjectsLoader.load(workspaceId)

      if (result.ok) {
        recentProjects.value = result.data.data.filter(canSeeProject).map((p) => ({
          id: p.id,
          name: p.name,
          workspaceId: p.workspace_id,
          propertyCount: p.properties.length,
          parentProperty: p.parent_property ? serializeProperty(p.parent_property) : undefined,
          coverImageUrls: p.cover_image_urls,
          updatedAt: p.updated_at,
          properties: p.properties.map(serializeProperty),
          description: p.description,
        }))
        loadingStatus.value = 'loaded'
      } else {
        loadingStatus.value = 'errored'
      }
    } catch {
      loadingStatus.value = 'errored'
    }
  }

  const setCoverImageDownloadError = (id: string, value: boolean): void => {
    const project = recentProjects.value.find((p) => p.id === id)
    if (project) {
      project.coverImageDownloadError = value
    }
  }

  const setCoverImageUploading = (id: string, value: boolean): void => {
    resetCoverImagesUploading()

    const project = recentProjects.value.find((p) => p.id === id)
    if (project) {
      project.coverImageUploading = value
    }
  }

  const resetCoverImagesUploading = (): void => {
    for (let i = 0; i < recentProjects.value.length; i++) {
      recentProjects.value[i].coverImageUploading = false
    }
  }

  return {
    status: loadingStatus,
    refresh,
    recentProjects,
    setCoverImageDownloadError,
    setCoverImageUploading,
  }
})
