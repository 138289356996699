<script setup lang="ts">
import { type LibraryItem } from '@/modules/Library/libraryStore'
import { useFileTableContext } from '@/modules/Workspaces/KnowledgeHub/Files/context'
import FileNameCell from '@/modules/Workspaces/KnowledgeHub/Files/FileNameCell.vue'
import FileRowMenu from '@/modules/Workspaces/KnowledgeHub/Files/FileRowMenu.vue'
import FileTableRow from '@/modules/Workspaces/KnowledgeHub/Files/FileTableRow.vue'
import FileTableRowGrid from '@/modules/Workspaces/KnowledgeHub/Files/FileTableRowGrid.vue'
import { getFileTypeName } from '@/modules/Workspaces/KnowledgeHub/Files/library-item'
import { provideRowContext } from '@/modules/Workspaces/KnowledgeHub/Files/rowContext'
import UserAvatar from '@/modules/Workspaces/KnowledgeHub/Files/UserAvatar.vue'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { useElementHover, useTimeAgo } from '@vueuse/core'
import { computed, ref, useTemplateRef } from 'vue'

const props = defineProps<{ item: LibraryItem }>()

const members = useWorkspaceMembers()
const selection = useFileTableContext()

const isSelected = computed(() => selection.isSelected(props.item.id))

const isRenaming = ref(false)

/** Menu state is used to make sure that row stays highlighted and menu trigger stays visible */
const isMenuOpen = ref(false)

const user = computed(() => members.workspaceMembers.find((u) => u.id === props.item.updatedBy))

const row = useTemplateRef('row')
const isHovering = useElementHover(computed(() => row.value?.$el))

const isIncomplete = computed(() => props.item.status !== 'complete')

provideRowContext({
  isRenaming,
  isHovering,
  item: computed(() => props.item),
})
</script>

<template>
  <FileTableRow
    ref="row"
    :class="{ selected: isSelected, 'text-text-disabled': isIncomplete }"
    class="group"
  >
    <template #grid>
      <FileTableRowGrid
        class="rounded-lg"
        data-selection-boundary
        :class="[
          isSelected
            ? 'bg-background-selected group-hover:bg-background-selected-hovered'
            : 'group-hover:bg-background-transparent-hovered',
          isMenuOpen &&
            (isSelected ? '!bg-background-selected-hovered' : '!bg-background-transparent-hovered'),
        ]"
      >
        <template #filename>
          <FileNameCell />
        </template>

        <template #fileType>
          <span class="truncate">
            {{ getFileTypeName(item) }}
          </span>
        </template>

        <template #createdAt>
          <span class="capitalize">
            {{ useTimeAgo(item.createdAt) }}
          </span>
        </template>

        <template #updatedBy>
          <div class="flex gap-1.5">
            <UserAvatar
              v-if="user"
              :class="{ 'opacity-20': isIncomplete }"
              shape="circle"
              size="xs"
              :user="user"
            />
            {{ user?.fullName }}
          </div>
        </template>

        <template #actions>
          <div
            class="h-6 opacity-0 focus-within:opacity-100 group-hover:opacity-100"
            :class="{ '!opacity-100': isMenuOpen }"
          >
            <FileRowMenu
              v-if="!isIncomplete"
              @menu-open="isMenuOpen = $event"
            />
          </div>
        </template>
      </FileTableRowGrid>
    </template>
  </FileTableRow>
</template>

<style scoped>
.selected:has(+ .selected) [data-selection-boundary] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.selected + .selected [data-selection-boundary] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
