<script setup lang="ts">
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import TextField from '@/uiKit/TextField.vue'

withDefaults(
  defineProps<{
    placeholder?: string
    error?: string
    warning?: string
    info?: string
    label?: string
    size: 'sm' | 'md' | 'lg'
    rounded?: boolean
    autofocus?: boolean
    required?: boolean
    disabled?: boolean
    name?: string
  }>(),
  {
    autofocus: undefined,
    error: undefined,
    placeholder: 'Search',
    info: undefined,
    label: undefined,
    required: undefined,
    rounded: undefined,
    warning: undefined,
    disabled: false,
    name: undefined,
  },
)

const emit = defineEmits<{
  'update:value': [value: string | undefined]
}>()

const modelValue = defineModel<string | undefined>()

const onChange = (value: string | undefined) => {
  modelValue.value = value
  emit('update:value', value)
}
</script>

<template>
  <TextField
    :size="size"
    :aria-label="label"
    :placeholder="placeholder"
    :autofocus="autofocus"
    :required="required"
    :disabled="disabled"
    :name="name"
    :rounded="rounded"
    :info="info"
    :warning="warning"
    :label="label"
    :error="error"
    :value="modelValue"
    type="search"
    @input="onChange"
  >
    <template #leading-icon>
      <IconSprite
        icon="search"
        class="text-icon-subtle"
      />
    </template>
    <template
      v-if="modelValue"
      #trailing-icon
    >
      <IconButton
        icon="close"
        size="xs"
        variant="transparent"
        rounded
        aria-label="Clear search"
        @click="() => onChange('')"
      />
    </template>
  </TextField>
</template>
