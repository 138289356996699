import { useProjects } from '@/modules/Projects/useProjects'
import { computed, ref } from 'vue'

export const useAgentPicker = () => {
  const agentId = ref<string>()
  const projectStore = useProjects()

  const agents = computed(() =>
    projectStore.projects.map((p) => ({
      id: p.id,
      name: p.name,
      description: p.description,
      url: p.coverImageUrls.low || p.coverImageUrls.high,
    })),
  )

  return {
    agentId,
    agents,
  }
}
