<script setup lang="ts">
import { provide, ref } from 'vue'

// Add ref for scrollable container
const scrollableContainer = ref<HTMLElement | null>(null)

// Expose the ref to parent components
defineExpose({
  scrollableContainer,
})

// Provide the scrollableContainer ref
provide('scrollableContainer', scrollableContainer)
</script>

<template>
  <div class="flex size-full bg-surface-tertiary">
    <div
      id="sidebar"
      class="flex min-w-2 overflow-hidden"
      data-test="sidebar"
    >
      <div class="flex size-full flex-col">
        <slot name="sidebar" />
      </div>
    </div>
    <div
      id="page-container"
      class="flex grow flex-col [contain:strict]"
    >
      <slot name="page-header" />
      <div
        ref="scrollableContainer"
        class="min-h-0 grow overflow-y-auto rounded-tl-lg bg-surface-primary scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
      >
        <slot name="page" />
      </div>
    </div>
  </div>
</template>
