<script setup lang="ts">
import { injectFieldContext } from '@/modules/Project/Fields/fieldContext'
import type { Field } from '@/modules/Project/Fields/types'
import FileFieldUpload from '@/modules/Project/FileFieldUpload.vue'
import { useFieldUpload } from '@/modules/Project/useFieldUpload'
import { useTable } from '@/modules/Project/useTable'
import { toast } from '@/shared/toast'
import { getFilesAndDirectoriesFromDragEvent, type Directory } from '@/shared/utils/file'
import { wait } from '@/shared/utils/time'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import FileUpload from '@/uiKit/FileUpload.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { captureException } from '@sentry/vue'
import type { FileRejectDetails } from '@zag-js/file-upload'
import { computed, ref, toRef, watch, type ButtonHTMLAttributes } from 'vue'
import { RouterLink } from 'vue-router'
import { useResolveProjectRoute } from './useResolveProjectRoute'
import { useScrollOnSelected } from './useScrollOnSelected'
import { useSupportedMimeTypes } from './useSupportedMimeTypes'
import { useTableCellFocus } from './useTableCellFocus'

const props = defineProps<{
  workspaceId: string
  projectId: string
  field: Field<'file' | 'file_collection'>
  filename: string | null
  isFocused: boolean
  isSelected: boolean
  pdfProjectId?: string | null
}>()

const emit = defineEmits<{
  (e: 'uploaded', url: string): void
  (e: 'cleared' | 'blur'): void
  (e: 'upload:files', files: File[]): void
  (e: 'upload:directories', directories: Directory[]): void
}>()

const supportedMimeTypes = useSupportedMimeTypes(computed(() => props.field))
const uploadStore = useFieldUpload()
const { parentEntityId } = useRouteParams()
const table = useTable()

const upload = computed(() =>
  uploadStore.getUpload({
    workspaceId: props.workspaceId,
    projectId: props.projectId,
    entityId: props.field.entityId,
    propertyId: props.field.propertyId,
    parentEntityId: parentEntityId.value,
  }),
)
const uploadStatus = computed(() => upload.value?.status || 'idle')
const uploadFileName = computed(() => upload.value?.fileName)
const uploadProgress = computed(() => upload.value?.progress || 0)

watch(uploadStatus, (status) => {
  const completeStatuses: (typeof status)[] = ['done', 'error', 'idle', 'ready']
  const isComplete = completeStatuses.includes(status)
  if (isComplete && props.isFocused) {
    emit('blur')
  }
  if (upload.value?.status === 'error') {
    toast.error(upload.value.error)
    uploadStore.removeUpload(
      props.workspaceId,
      props.projectId,
      props.field.entityId,
      props.field.propertyId,
      parentEntityId.value,
    )
  }
})

const dropZone = ref<HTMLElement | null>(null)
const cellContent = computed(() => props.filename || uploadFileName.value)
const fieldContext = injectFieldContext()

/**
 * If the cell has some placeholder content (optimistic name), we want to prevent
 * opening the menu or dropping other files before the BE processes the file.
 */
const preventUploads = computed(() => !!cellContent.value)

watch(
  () => props.isSelected,
  (isSelected) => (isSelected ? dropZone.value?.focus() : dropZone.value?.blur()),
  { immediate: true },
)

useTableCellFocus({
  cell: dropZone,
  isFocused: toRef(props, 'isFocused'),
  isSelected: toRef(props, 'isSelected'),
})
useScrollOnSelected(dropZone, toRef(props, 'isSelected'))

const resolveSubProjectRoute = useResolveProjectRoute()
const subProjectRoute = computed(() =>
  cellContent.value && props.pdfProjectId
    ? resolveSubProjectRoute({
        parentEntityId: props.field.entityId,
        projectId: props.pdfProjectId,
        parentProjectId: props.projectId,
        workspaceId: props.workspaceId,
      })
    : undefined,
)

const onDrop = async (e: DragEvent) => {
  const filesAndDirectories = await getFilesAndDirectoriesFromDragEvent(e)
  if (!filesAndDirectories) {
    return
  }

  /**
   * TODO: GO-2797
   * Handle dragging a combination of files+directories to an empty file cell
   */
  const directories = filesAndDirectories.filter((f): f is Directory => !(f instanceof File))
  if (directories && directories.length > 0) {
    emit('upload:directories', directories)
    /**
     * If the user dropped a selection of directories, then prevent the FileUpload
     * from processing the files and emitting a duplicate event.
     */
    e.stopImmediatePropagation()
    e.preventDefault()
  }
}

const MAX_FILE_COUNT = 100
const onRejectFiles = ({ files }: FileRejectDetails) => {
  const errors = files.flatMap((file) => file.errors)

  /**
   * Handle the first error only, the messaging for multiple errors
   * would be really unclear so the user can simply play wack-a-mole
   */
  const firstError = errors[0]
  switch (firstError) {
    case 'FILE_INVALID_TYPE':
      toast.error(`Invalid file type, supported types are: ${supportedMimeTypes.value.join(', ')}`)
      return
    case 'FILE_EXISTS':
      toast.error('File already exists')
      return
    case 'FILE_TOO_LARGE':
      toast.error('File is too large')
      return
    case 'FILE_TOO_SMALL':
      toast.error('File is too small')
      return
    case 'FILE_INVALID':
      toast.error('Invalid file')
      return
    case 'TOO_MANY_FILES': {
      toast.error(`You can only upload up to ${MAX_FILE_COUNT.toLocaleString()} files at a time`)
      return
    }
    default:
      captureException(new Error('Unhandled file upload error'), {
        data: {
          error: firstError,
        },
      })
      toast.error('An error occurred')
  }
}

/**
 * Prevent menu from opening when ArrowDown and ArrowUp are pressed.
 * It's a standard menu behavior, but we don't want it in the table cell.
 * We want to keep the ability to navigate through cells using the keyboard.
 */
function preventMenuOpeningOnKeyboardNav(props: ButtonHTMLAttributes) {
  return {
    ...props,
    onKeydown(e: KeyboardEvent) {
      if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
        props.onKeydown?.(e)
      }
    },
  }
}

/**
 * Make sure that cell is selected and unfocused.
 * This is needed in order to be able to use keyboard navigation after interacting with the menu.
 * We want to call this when
 * - The menu closes
 * - User completes the flow with any of the file pickers
 */
async function selectCell() {
  // We need to wait for the next JS tick because `useTableInteractions` has an onKeyStroke('Escape')
  // listener that will clear selection after the sync part of this function executes.
  await wait()
  const { row, column } = fieldContext.getValues()
  table.clearFocusedAndSelected()
  table.selectCell(row, column)
}

function onFileChange(files: File[]) {
  // Blur the cell because otherwise the it's selected and focused,
  // and file content will be previewed in a popover, then disappear during “Understanding this file”,
  // then pop up again.
  emit('blur')
  emit('upload:files', files)
}
</script>

<template>
  <FileUpload
    v-slot="{ rootProps, dropzoneProps, hiddenInputProps, isDragging }"
    :accept="supportedMimeTypes.join(', ')"
    :max-files="MAX_FILE_COUNT"
    @reject="onRejectFiles"
    @change="onFileChange"
  >
    <div
      v-bind="{ ...rootProps, ...$attrs }"
      class="w-full"
    >
      <FileFieldUpload
        v-slot="{ getTriggerProps, menu }"
        :disabled="preventUploads"
        :file-input-props="{
          accept: supportedMimeTypes.join(', '),
          multiple: MAX_FILE_COUNT > 1,
        }"
        :positioning="{ placement: 'bottom-start', offset: { mainAxis: 8, crossAxis: 0 } }"
        @import:computer="onFileChange"
        @menu:close="selectCell"
        @done:google="selectCell"
        @done:library="selectCell"
      >
        <div
          v-bind="{
            ...dropzoneProps,
            ...preventMenuOpeningOnKeyboardNav(getTriggerProps()),
          }"
          ref="dropZone"
          :class="
            isDragging && 'rounded-corner-4 border border-border-focused bg-background-selected'
          "
          class="group/file flex h-8 shrink grow basis-0 flex-row content-center items-center justify-start truncate px-1.5 py-1 text-sm-12px-light text-text focus:outline-none"
          @drop="onDrop"
          @click="(menu?.setOpen(true), /* Needed because el is a div, not a button */ void 0)"
        >
          <input
            v-bind="hiddenInputProps"
            data-test="file-upload"
            :disabled="preventUploads"
          />
          <div
            v-if="isDragging"
            class="truncate text-text-selected"
          >
            Drag and drop to upload content
          </div>

          <div v-else-if="subProjectRoute">
            <div
              data-table-cell-content
              class="line-clamp-1 flex h-8 basis-0 items-center truncate rounded-corner-4 px-1 py-2 focus-within:box-border"
            >
              <RouterLink
                title="Open PDF"
                :to="subProjectRoute"
                aria-label="Open PDF"
                @click.stop
              >
                <div
                  class="flex h-5 items-center gap-1 rounded-corner-6 px-1 hover:bg-background-blue-subtle"
                >
                  <IconSprite
                    class="text-icon-primary"
                    icon="file-fill"
                    size="xs"
                  />
                  <p
                    class="border border-background-transparent border-b-icon-gray-subtle text-sm-12px-default text-icon-primary hover:border-b-background-transparent"
                  >
                    {{ cellContent }}
                  </p>
                </div>
              </RouterLink>
            </div>
          </div>
          <div
            v-else-if="cellContent"
            class="inline-flex h-5 items-center justify-center gap-1 truncate rounded-md bg-background-gray-subtlest px-1"
          >
            <CircularProgress
              v-if="uploadStatus === 'uploading'"
              class="shrink-0"
              size="sm"
              :value="uploadProgress"
            />
            <div
              v-else
              class="flex size-3.5 items-center justify-center text-text-subtle"
              data-test="file-uploaded-icon"
            >
              <IconSprite icon="file" />
            </div>
            <div class="shrink truncate text-sm-12px-default text-text-subtle">
              {{ cellContent }}
            </div>
          </div>

          <div
            v-else
            class="inline-flex h-5 shrink items-center gap-0.5 truncate rounded-md bg-background-gray-subtlest px-1 group-hover/cell:visible hover:cursor-pointer hover:bg-background-gray-subtlest-hovered"
            :class="isSelected ? 'visible' : 'invisible'"
            role="button"
          >
            <div class="size-3.5 shrink-0 text-icon-subtle">
              <IconSprite icon="plus" />
            </div>
            <div class="truncate text-sm-12px-default text-text-subtle">
              Add file or drag and drop
            </div>
          </div>
        </div>
      </FileFieldUpload>
    </div>
  </FileUpload>
</template>
