import { exhaustiveGuard } from '@/shared/utils/typeAssertions'
import { type Entity } from '../Project/useProject'
import { type Project } from '../Projects/useProjects'
import type {
  Case,
  CaseResponse,
  MessageResponse,
  PaginatedCaseResponse,
  PartialCase,
} from './types'

export const FALLBACK_CASE_NAME = 'Untitled Case'

export const serializePaginatedCase = (caseResponse: PaginatedCaseResponse): PartialCase => ({
  id: caseResponse.id,
  createdAt: caseResponse.created_at,
  updatedAt: caseResponse.updated_at,
  name: caseResponse.name || FALLBACK_CASE_NAME,
  userId: caseResponse.user_id,
  projectIds: [],
})

export const serializeFile = (file: CaseResponse['files'][number]) => ({
  id: file.id,
  name: file.name,
  status: file.status,
})
/**
 * Serializes a case response from the backend into a full case object that
 * can be used in the frontend on the /cases/:caseId view.
 *
 * The projects and entities passed into this function are full project/entity
 * objects from the IDs in the case's outputs
 */
export const serializeCase = ({
  caseResponse,
  projects,
  entities,
}: {
  caseResponse: CaseResponse
  projects: Project[]
  entities: Entity[]
}): Case => {
  const outputs = caseResponse.outputs.reduce<Case['outputs']>((acc, output) => {
    const project = projects.find((p) => p.id === output.project_id)
    const entity = entities.find((e) => e.id === output.entity_id)

    if (project && entity) {
      acc.push({ id: output.id, project, entity, createdAt: output.created_at })
    }

    return acc
  }, [])

  return {
    id: caseResponse.id,
    name: caseResponse.name || FALLBACK_CASE_NAME,
    createdAt: caseResponse.created_at,
    updatedAt: caseResponse.updated_at,
    projectIds: [],
    userId: caseResponse.user_id,
    messages: caseResponse.messages.map(serializeMessage),
    files: caseResponse.files.map(serializeFile),
    outputs,
    sources: [],
  }
}

export function serializeMessage(message: MessageResponse): Case['messages'][number] {
  switch (message.type) {
    case 'partial_response': {
      return {
        id: message.id,
        type: 'partial_response',
        createdAt: message.created_at,
        updatedAt: message.updated_at,
        text: message.text,
      }
    }
    case 'query': {
      return {
        id: message.id,
        type: 'query',
        agentId: message.agent_id,
        createdAt: message.created_at,
        fileIds: message.file_ids,
        text: message.text,
        updatedAt: message.updated_at,
        userId: message.user_id,
      }
    }
    case 'response': {
      return {
        id: message.id,
        type: 'response',
        createdAt: message.created_at,
        updatedAt: message.updated_at,
        text: message.text,
      }
    }
    case 'agent_run': {
      return {
        type: 'agent_run',
        id: message.id,
        agentId: message.agent_id,
        createdAt: message.created_at,
        inProgress: message.in_progress,
        outputId: message.output_id,
        updatedAt: message.updated_at,
      }
    }

    case 'tool_run': {
      return {
        type: 'tool_run',
        id: message.id,
        createdAt: message.created_at,
        updatedAt: message.updated_at,
        toolName: message.tool_name,
        inProgress: message.in_progress,
      }
    }
    default: {
      return exhaustiveGuard(message, 'Unhandled message type')
    }
  }
}
