<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'
import { useId, useTemplateRef } from 'vue'
import ListMenuItem, { type ListMenuItemProps } from '../ListMenuItem.vue'
import { MenuCtx } from './Root.vue'

type Props = Omit<ListMenuItemProps, 'element'> & {
  /** If set to true, this item will be active when the menu first renders */
  selectedOnMount?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'select' | 'highlight'): void
}>()

defineOptions({
  name: 'MenuItem',
})

const rootEl = useTemplateRef('rootEl')

const { initItem, menu } = MenuCtx.get()
const id = useId()
initItem({
  onSelect: () => emit('select'),
  id,
  onHighlight: () => {
    rootEl.value?.$el.scrollIntoView({ block: 'nearest' })
    emit('highlight')
  },
})

if (props.selectedOnMount) {
  menu.value.setHighlightedValue(id)
}
</script>

<template>
  <ListMenuItem
    ref="rootEl"
    element="button"
    v-bind="{ ...props, ...menu.getItemProps({ value: id }) }"
    :class="twMerge('text-left', String($attrs.class || ''))"
  >
    <template #prefix>
      <slot
        :id="id"
        :active="menu.highlightedValue === id"
        name="prefix"
      />
    </template>
    <slot
      :id="id"
      :active="menu.highlightedValue === id"
    />
    <template #suffix>
      <slot
        :id="id"
        name="suffix"
        :active="menu.highlightedValue === id"
      />
    </template>
  </ListMenuItem>
</template>
