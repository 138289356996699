<script setup lang="ts">
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed, useTemplateRef } from 'vue'
import type { Field } from './Fields/types'
import type { Property } from './Properties/types'
import { useNumberField } from './useNumberField'

const props = defineProps<{
  field: Field<'number'>
  property: Property<'number'>
}>()

const inputRef = useTemplateRef('input')

const { workspaceId, projectId } = useRouteParams()
const { inputFieldValue, syncLocalValue, saveFieldValue, shouldBeRed } = useNumberField(
  computed(() => props.field),
  computed(() => props.property),
)

const onSaveChanges = () => {
  saveFieldValue({
    projectId: projectId.value,
    workspaceId: workspaceId.value,
  })
}

const onReset = () => {
  syncLocalValue()
  inputRef.value?.blur()
}

const onFocusInput = () => {
  inputRef.value?.focus()
}
</script>

<template>
  <form
    class="flex size-full cursor-text items-start justify-start px-3 py-1.5"
    @submit.prevent="onSaveChanges"
    @click="onFocusInput"
  >
    <input
      ref="input"
      v-model="inputFieldValue"
      inputmode="numeric"
      class="outline-none"
      :class="[shouldBeRed ? 'text-text-critical' : 'text-text']"
      @blur="onSaveChanges"
      @keydown.esc="onReset"
    />
  </form>
</template>
