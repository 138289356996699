import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useElementBounding, useElementSize, useStorage } from '@vueuse/core'
import { computed, ref, watch, type ComputedRef, type Ref } from 'vue'

export type ScaleType = number | 'fit'

/**
 * Provides functionality to allow a user to scroll into a container 'naturally', where
 * the element at the cursor position remains under the cursor after the zoom level changes.
 *
 * The composable returns:
 * - `scrollContainer` - a ref to the container element that should be scrolled
 * - `scrollContainerChild` - a ref to the child element that should be zoomed
 * - `scale` - the current scale level
 * - `onZoom` - a function that should be called when a wheel event is detected
 */
export const useNaturalZoom = ({
  storageKey,
  originalWidth,
  translation,
}: {
  storageKey: ComputedRef<string>
  originalWidth?: Ref<number>
  translation?: Ref<{ x: number; y: number }>
}) => {
  const scrollContainer = ref<HTMLElement | null>(null)
  const scrollContainerChild = ref<HTMLElement | null>(null)

  const {
    height: containerHeight,
    top: containerTop,
    left: containerLeft,
    width: containerWidth,
  } = useElementBounding(scrollContainer)
  const { height: childHeight, width: childWidth } = useElementSize(scrollContainerChild)

  const scale = useStorage<ScaleType>(storageKey.value, 'fit', undefined, {
    // We need to do this serialization magic because the value is stored as a string
    // and in previous versions of the app probably "null" was written into the storage
    serializer: {
      read: (v: string) => {
        if (v === 'fit' || (!isNaN(Number(v)) && v !== null)) {
          return v === 'fit' ? 'fit' : Number(v)
        }
        return 'fit'
      },
      write: (v: ScaleType) => String(v),
    },
  })

  const fitAsAbsolute = computed<number>(() => {
    if (!originalWidth) {
      return 0.99
    }

    return containerWidth.value / originalWidth.value
  })

  /**
   * When the user zooms in or out, we use this ref to store the coordinates of their zoom event
   * so that we can keep the element under the cursor in the same position after the zoom. This
   * ref will be null if the user has not zoomed in or out with a scroll event, and instead
   * uses the UI controls to zoom.
   */
  const scrollEventProportions = ref<{
    container: { x: number; y: number }
    child: { x: number; y: number }
  } | null>(null)

  /**
   * We have 2 ways of scrolling the container:
   * 1. The normal way, give the container `overflow-auto` and let the browser handle it
   * 2. Apply a transform to the child element to simulate scrolling, so that
   * we can smoothly apply 'zoom+scroll' transitions
   *
   * This function returns:
   * - The container's scrolltop in case (1)
   * - What the scrolltop would be if we didn't have any scaling or transforms in case (2)
   */
  const getEffectiveScrollTop = () => {
    if (!scrollContainerChild.value) {
      return 0
    }

    if (!translation) {
      return scrollContainer.value?.scrollTop || 0
    }

    const { translate } = scrollContainerChild.value.style
    if (!translate) {
      return 0
    }

    const yTranslatePx = translate.split(' ').at(-1)
    if (!yTranslatePx) {
      return 0
    }

    const yTranslate = parseInt(yTranslatePx.replace('px', ''))

    const scale = Number(scrollContainerChild.value?.style.scale) || 1
    return -yTranslate / scale
  }

  /**
   * Set the scale when zooming in/out with a scroll event
   */
  const onZoom = (event: WheelEvent) => {
    assertIsNotNullOrUndefined(scrollContainer.value)
    assertIsNotNullOrUndefined(scrollContainerChild.value)

    scrollEventProportions.value = {
      container: {
        // What proportion across the container width was the scroll event?
        x: (event.clientX - containerLeft.value) / containerWidth.value,
        // What proportion down the container height was the scroll event?
        y: (event.clientY - containerTop.value) / containerHeight.value,
      },
      child: {
        // What proportion across the child width was the scroll event?
        x:
          (event.clientX - containerLeft.value + scrollContainer.value.scrollLeft) /
          childWidth.value,
        // What proportion down the child height was the scroll event?
        y: (event.clientY - containerTop.value + getEffectiveScrollTop()) / childHeight.value,
      },
    }

    // console.log(scrollEventProportions.value)

    let newScale: ScaleType
    if (scale.value === 'fit') {
      // If scale is fit, then when scrolling in/out we want to jump to the next
      // largest/smallest multiple of 0.25
      if (event.deltaY < 0) {
        const nextScaleUp = Math.ceil(fitAsAbsolute.value * 4) / 4
        newScale = nextScaleUp
      } else {
        const nextScaleDown = Math.floor(fitAsAbsolute.value * 4) / 4
        newScale = nextScaleDown
      }
    } else {
      const inc = 0.125
      if (event.deltaY > 0) {
        newScale = Math.max(scale.value - inc, 0.25)
      } else {
        newScale = Math.min(scale.value + inc, 2)
      }
    }

    if (translation) {
      /**
       * There be dragons in this block. The block is responsible for making
       * sure that when emulating scroll with a CSS transform, the part of the
       * document under the cursor stays the same before/after scrolling.
       */

      // current 'scroll top', if we didn't have any scaling or transforms
      const scrollTop = getEffectiveScrollTop()

      // Current css scale
      const oldCssScale = Number(scrollContainerChild.value?.style.scale) || 1

      // What the new scale will be
      const oldScaleAsAbsolute = typeof scale.value === 'number' ? scale.value : fitAsAbsolute.value
      const scaleDiffMultiple = newScale / oldScaleAsAbsolute
      const newCssScale = oldCssScale * scaleDiffMultiple

      // Convert our emulated scrolltop to a new y translate value
      const newYTranslate = -scrollTop * newCssScale

      // Adjust the translate so that the part of the document under the cursor
      // remains the same
      const trimmedPx = (1 - scaleDiffMultiple) * containerHeight.value
      const translateAdjustment = trimmedPx * scrollEventProportions.value.container.y

      translation.value = {
        x: translation.value.x,
        y: newYTranslate + translateAdjustment,
      }
    }

    scale.value = newScale
  }

  /**
   * Set the scale explicitly, without a scroll event (e.g. when interacting
   * with the scale controls in the UI)
   */
  const setScale = (newScale: ScaleType) => {
    assertIsNotNullOrUndefined(scrollContainer.value)
    assertIsNotNullOrUndefined(scrollContainerChild.value)
    const oldCssScale = Number(scrollContainerChild.value?.style.scale) || 1

    // Set the target scroll position to the same position in the child,
    // and the center of the container
    scrollEventProportions.value = {
      container: {
        x: 0.5,
        y: 0.5,
      },
      child: {
        x: 0.5,
        y:
          (getEffectiveScrollTop() + containerHeight.value / 2) /
          (scrollContainerChild.value.clientHeight * oldCssScale),
      },
    }

    if (translation) {
      // If we're using a CSS transform to emulate scrolling, we need to adjust
      // the translation so that the part of the document under the cursor remains
      // the same

      // What the new scale will be
      const newScaleAsAbsolute = typeof newScale === 'number' ? newScale : fitAsAbsolute.value
      const oldScaleAsAbsolute = typeof scale.value === 'number' ? scale.value : fitAsAbsolute.value
      const scaleDiffMultiple = newScaleAsAbsolute / oldScaleAsAbsolute
      const newCssScale = oldCssScale * scaleDiffMultiple

      // current 'scroll top', if we didn't have any scaling or transforms
      const scrollTop = getEffectiveScrollTop()

      // Convert our emulated scrolltop to a new y translate value
      const newYTranslate = -scrollTop * newCssScale

      // Adjust the translate so that the part of the document under the cursor
      // remains the same
      const trimmedPx = (1 - scaleDiffMultiple) * containerHeight.value
      const translateAdjustment = trimmedPx * scrollEventProportions.value.container.y

      translation.value = {
        x: translation.value.x,
        y: newYTranslate + translateAdjustment,
      }
    }

    scale.value = newScale
  }

  /**
   * This watcher will run after the zoom level has changed and the child element's
   * height has been updated. It will then try and scroll the container to the
   * same position as it was before the zoom level changed.
   */
  watch(childHeight, async (newChildHeight) => {
    assertIsNotNullOrUndefined(scrollContainer.value)
    assertIsNotNullOrUndefined(scrollContainerChild.value)
    if (scrollEventProportions.value === null) {
      return
    }

    const newChildY = newChildHeight * scrollEventProportions.value.child.y
    const newContainerY = containerHeight.value * scrollEventProportions.value.container.y

    const newChildX = childWidth.value * scrollEventProportions.value.child.x
    const newContainerX = containerWidth.value * scrollEventProportions.value.container.x

    scrollContainer.value.scrollTop = newChildY - newContainerY
    scrollContainer.value.scrollLeft = newChildX - newContainerX

    scrollEventProportions.value = null
  })

  return { onZoom, scrollContainer, scrollContainerChild, scale, setScale }
}
