<script setup lang="ts">
import { toast } from '@/shared/toast'
import AutosizedTextarea from '@/sharedComponents/AutosizedTextarea.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed, nextTick } from 'vue'
import CaseAttachment from './CaseAttachment.vue'
import type { CaseInputAttachment } from './types'

/**
 * Renders a form that lets a user submit a new query to a case, along with
 * attachments.
 */

const props = defineProps<{
  attachments: CaseInputAttachment[]
  isDroppingFiles?: boolean
  isEditing?: boolean
  placeholder: string
  disabled?: boolean
}>()

const emit = defineEmits<{
  submit: []
  'delete-attachment': [attachmentId: string]
  cancel: []
}>()

const modelValue = defineModel<string>('modelValue', { required: true })

const onSubmit = (e: Event) => {
  e.preventDefault()

  if (props.disabled) {
    toast.warning('Your previous question is still being answered, please hold on...')
    return
  }

  nextTick(() => emit('submit'))
}

const messageHasContent = computed(() => !!modelValue.value || props.attachments.length > 0)
</script>

<template>
  <div class="flex flex-col gap-1">
    <div
      v-if="attachments.length > 0"
      class="flex flex-wrap gap-1"
      aria-label="New message attachments"
      role="list"
    >
      <CaseAttachment
        v-for="attachment in attachments"
        :key="attachment.uploadId"
        :name="attachment.name"
        :progress="'progress' in attachment ? attachment.progress : null"
        :type="attachment.type"
        deletable
        @delete="emit('delete-attachment', attachment.uploadId)"
      />
    </div>
    <slot name="status" />
    <form
      class="relative rounded-corner-20 bg-background-gray-subtlest transition-colors hover:bg-background-gray-subtlest-hovered"
      @submit="onSubmit"
    >
      <div
        v-if="isEditing"
        class="m-1 flex flex-row items-center gap-1 rounded-b-corner-6 rounded-t-corner-16 bg-background-gray-subtle p-2 text-sm-12px-light text-text-subtle"
      >
        <IconSprite
          icon="task"
          variant="transparent"
          size="sm"
          rounded
        />
        Modifying message
        <div class="flex grow flex-row items-center justify-end">
          <ToolTip title="Cancel">
            <IconButton
              icon="close"
              variant="transparent"
              size="sm"
              rounded
              type="button"
              @click="emit('cancel')"
            />
          </ToolTip>
        </div>
      </div>
      <div
        v-if="isDroppingFiles"
        class="absolute inset-0 flex items-center justify-center rounded-corner-20 bg-background-blue-subtle text-sm-12px-default text-text-selected shadow-focus-ring-primary backdrop-blur-[2px]"
      >
        Drop your files to use them in a case
      </div>
      <div class="go-scrollbar max-h-80 overflow-y-auto">
        <AutosizedTextarea
          v-model="modelValue"
          aria-label="Your message"
          autofocus
          mode="chat"
          :placeholder="placeholder"
          class="bg-background-transparent px-4 pb-4 pt-3 text-lg-15px-light outline-none"
          @submit="onSubmit"
        />
      </div>
      <div class="flex w-full grow items-center justify-start gap-2 p-2">
        <div class="flex flex-1 flex-row items-center gap-2 self-start">
          <slot name="actions" />
        </div>
        <div class="flex grow flex-row items-center justify-end gap-2">
          <DarwinButton
            v-if="isEditing"
            variant="outline"
            size="sm"
            rounded
            @click="emit('cancel')"
          >
            Cancel
          </DarwinButton>
          <IconButton
            aria-label="Send message"
            class="w-12 self-end transition-colors"
            :variant="messageHasContent ? 'black' : 'brand'"
            size="lg"
            icon="arrow-right"
            rounded
            type="submit"
            :disabled="!messageHasContent || disabled"
          />
        </div>
      </div>
    </form>
  </div>
</template>
