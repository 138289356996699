import { get } from './client'
import type { BackendMeta } from './types'

export type GetCase = BackendMeta<'/api/workspaces/{workspace_id}/cases/{case_id}', 'get'>

export type GetCaseParams = {
  workspaceId: string
  caseId: string
}

export const getCase = async ({ caseId, workspaceId }: GetCaseParams) =>
  get<undefined, GetCase['successResponse'], GetCase['path']>(
    `/api/workspaces/${workspaceId}/cases/${caseId}`,
    undefined,
  )
