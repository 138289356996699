<script lang="ts" setup>
import type { User } from '@auth0/auth0-vue'
import { computed } from 'vue'

const props = defineProps<{ user: User | null }>()

const getPartOfDay = () => {
  const hour = new Date().getHours()
  if (hour < 12) {
    return 'Morning'
  }
  if (hour < 18) {
    return 'Afternoon'
  }
  return 'Evening'
}

const name = computed(() => {
  if (props.user?.givenName) {
    return props.user.givenName
  }

  return props.user?.firstName ?? ''
})
</script>

<template>
  <div class="flex w-full flex-col items-center justify-center">
    <h1 class="text-display-md-28px text-text">
      {{ getPartOfDay() }}{{ !!name ? ' ' : '' }}{{ name }},
    </h1>
    <p class="text-display-md-28px text-text-subtlest">What would you like to do today?</p>
  </div>
</template>
