import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import { useProject } from '@/modules/Project/useProject'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { invariant } from '@/shared/utils/typeAssertions'
import { computed, inject, provide, toValue, type InjectionKey, type MaybeRefOrGetter } from 'vue'

export type ProjectFieldContextArg = {
  field: MaybeRefOrGetter<Field>
  property: MaybeRefOrGetter<Property>
  column: MaybeRefOrGetter<number>
  row: MaybeRefOrGetter<number>
}

export type ProjectFieldContext = ProjectFieldContextArg & {
  workspaceId: MaybeRefOrGetter<string>
  projectId: MaybeRefOrGetter<string>
  getValues: () => {
    workspaceId: string
    projectId: string
    field: Field
    property: Property
    column: number
    row: number
  }
}

export const fieldContextToken = Symbol('fieldContextToken') as InjectionKey<ProjectFieldContext>

export function provideFieldContext(args: ProjectFieldContextArg) {
  const workspaceId = computed(() => {
    return useCurrentWorkspace().value.id
  })

  const projectId = computed(() => {
    const projectStore = useProject()
    invariant(projectStore.projectId)
    return projectStore.projectId
  })

  const ctx = {
    ...args,
    workspaceId,
    projectId,
    getValues: () => ({
      workspaceId: toValue(workspaceId),
      projectId: toValue(projectId),
      field: toValue(args.field),
      property: toValue(args.property),
      column: toValue(args.column),
      row: toValue(args.row),
    }),
  }

  provide(fieldContextToken, ctx)
}

export function injectFieldContext() {
  const ctx = inject(fieldContextToken)
  invariant(ctx)
  return ctx
}
