<script setup lang="ts">
import CaseFilterDropdown from '@/modules/Cases/CaseFilterDropdown.vue'
import TitleWithCount from '@/modules/Cases/Sidebar/TitleWithCount.vue'
import { useProjectCreateModal } from '@/modules/Projects/ProjectCreateModal.vue'
import { useDashboardFilterStore } from '@/sharedComposables/useDashboardFilterStore'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import SearchTextField from '@/uiKit/SearchTextField.vue'
import { onClickOutside } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import type { Ref } from 'vue'
import { computed, inject, onMounted, onUnmounted, ref, useTemplateRef } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

const dashboardFilterStore = useDashboardFilterStore()
const { filteredCases, filteredProjects, searchValue, isSearchbarActive } =
  storeToRefs(dashboardFilterStore)
const projectCreateModal = useProjectCreateModal()
const route = useRoute()

const scrollableContainer = inject<Ref<HTMLElement | null>>('scrollableContainer')
const isSticky = ref(false)
const toolbarRef = useTemplateRef<HTMLElement | null>('toolbarRef')
const sentinelRef = useTemplateRef<HTMLElement | null>('sentinelRef')
const searchbarRef = useTemplateRef<HTMLElement | null>('searchbarRef')
let observer: IntersectionObserver | null = null

const isProjectsActive = computed(() => route.name === 'WorkspaceProjectsV2')

const isCasesActive = computed(() => route.name === 'WorkspaceCasesV2')

const formatCount = (count: number) => (count > 999 ? '999+' : `${count}`)

const scrollToTop = () => {
  if (!scrollableContainer?.value) {
    return
  }

  scrollableContainer.value.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const openSearchbar = () => {
  dashboardFilterStore.setSearchbarActive(true)
}

const closeSearchbar = () => {
  dashboardFilterStore.setSearchbarActive(false)
}

onClickOutside(searchbarRef, (event) => {
  const isRouterLink = (event.target as HTMLElement).closest('a[href]')
  const isSearchbarWrapper = (event.target as HTMLElement).closest('[ref="searchbarRef"]')

  if (isRouterLink || isSearchbarWrapper) {
    return
  }

  closeSearchbar()
})

onMounted(() => {
  observer = new IntersectionObserver(([entry]) => {
    isSticky.value = !entry.isIntersecting
  })

  if (!sentinelRef.value) {
    return
  }
  observer.observe(sentinelRef.value)
})

onUnmounted(() => {
  observer?.disconnect()
})
</script>

<template>
  <div class="inset-0 contents">
    <div ref="sentinelRef" />
    <div
      ref="toolbarRef"
      class="sticky top-0 z-50 flex h-11 w-full items-center justify-center border-border-subtle bg-surface-primary"
      :class="{
        'border-b': isSticky,
        '-my-2': !isSticky,
      }"
    >
      <div class="go-column flex items-center justify-between gap-4 px-4">
        <div
          class="flex items-center justify-between"
          :class="{ 'w-full': !isSearchbarActive }"
        >
          <div class="flex w-full min-w-[160px] grow gap-2">
            <RouterLink
              :to="{
                name: 'WorkspaceProjectsV2',
              }"
              class="flex min-w-16 items-start justify-start gap-1 text-sm-12px-default text-text"
              :class="{ 'opacity-30 hover:opacity-50': !isProjectsActive }"
              aria-label="Navigate to projects"
            >
              <TitleWithCount class="gap-1">
                <template #title>Projects</template>
                <template #count>{{ formatCount(filteredProjects.length) }}</template>
              </TitleWithCount>
            </RouterLink>
            <RouterLink
              :to="{
                name: 'WorkspaceCasesV2',
              }"
              class="flex min-w-16 items-start justify-start gap-1 text-sm-12px-default text-text"
              :class="{ 'opacity-30 hover:opacity-50': !isCasesActive }"
              aria-label="Navigate to cases"
            >
              <TitleWithCount class="gap-1">
                <template #title>Cases</template>
                <template #count>{{ formatCount(filteredCases.length) }}</template>
              </TitleWithCount>
            </RouterLink>
          </div>
          <div class="flex w-full grow gap-4">
            <DarwinButton
              v-if="isSticky && !isSearchbarActive"
              class="w-12"
              variant="neutral"
              size="sm"
              rounded
              aria-label="Scroll to top"
              @click="scrollToTop"
            >
              <IconSprite icon="arrow-top" />
            </DarwinButton>
          </div>
        </div>
        <div class="flex grow items-center justify-end gap-2">
          <SearchTextField
            v-if="isSearchbarActive"
            ref="searchbarRef"
            v-model:value="searchValue"
            size="md"
            type="search"
            rounded
            aria-label="Search projects and cases"
            plaeholder="Search projects and cases"
            autofocus
            @keydown.esc="closeSearchbar"
          />
          <div
            v-else
            class="flex w-[104px] items-center justify-end gap-2 transition-none"
          >
            <div class="relative">
              <DarwinButton
                class="flex w-12"
                variant="outline"
                size="sm"
                rounded
                aria-label="Search"
                @click="openSearchbar"
              >
                <IconSprite icon="search" />
              </DarwinButton>
              <span
                v-if="!!searchValue"
                class="absolute right-0 top-0 size-2 rounded-full border border-surface-primary bg-background-primary"
              />
            </div>
            <template v-if="!isSearchbarActive">
              <DarwinButton
                v-if="isProjectsActive"
                class="flex w-12"
                variant="black"
                size="sm"
                rounded
                aria-label="Create new project"
                @click="projectCreateModal.open"
              >
                <IconSprite icon="plus" />
              </DarwinButton>
              <CaseFilterDropdown v-if="isCasesActive" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
