<script lang="ts" setup>
import { addEntity } from '@/backend/addEntity'
import ProjectName from '@/modules/Project/ProjectName.vue'
import type { Project } from '@/modules/Projects/useProjects'
import { toast } from '@/shared/toast'
import ProjectDropdownV2 from '@/sharedComponents/ProjectDropdownV2.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import ProjectCardAvatar from './ProjectCardAvatar.vue'

const props = defineProps<{ project: Project }>()

const emit = defineEmits<{
  (e: 'set-cover-image-download-error', id: string, value: boolean): void
}>()

const router = useRouter()
const isStartingCase = ref(false)

const projectRoute = computed(() => ({
  name: 'WorkspaceProject',
  params: { workspaceId: props.project.workspaceId, projectId: props.project.id },
}))

const onImageSrcError = () => {
  emit('set-cover-image-download-error', props.project.id, true)
}

async function startCase() {
  if (isStartingCase.value) {
    return
  }
  isStartingCase.value = true
  try {
    const entityResult = await addEntity({
      workspaceId: props.project.workspaceId,
      projectId: props.project.id,
    })

    if (!entityResult.ok) {
      throw Error(entityResult.error.message)
    }

    router.push({
      name: 'New Case',
      params: { workspaceId: props.project.workspaceId },
      query: { agentId: props.project.id, entityId: entityResult.data.id },
    })
  } catch {
    toast.error('Failed to start a new case')
  } finally {
    isStartingCase.value = false
  }
}
</script>

<template>
  <div
    data-test="project"
    tabindex="0"
    class="group relative flex h-[200px] min-w-[200px] max-w-[360px] flex-1 flex-col items-start justify-between gap-2 overflow-hidden rounded-corner-20 bg-background-gray-sunken p-5 hover:bg-background-gray-sunken-hovered"
    :aria-label="project.name ?? undefined"
  >
    <div class="flex w-full items-start justify-between">
      <ProjectCardAvatar
        class="transition-all duration-300 ease-in group-hover:blur-sm"
        :project="project"
        @error="onImageSrcError"
      />
      <div
        class="pointer-events-auto flex items-end opacity-0 transition-opacity group-hover:opacity-100"
      >
        <ProjectDropdownV2
          :project="project"
          size="lg"
        />
      </div>
    </div>
    <div class="z-2 flex w-full shrink flex-col justify-between gap-2">
      <div
        class="absolute inset-x-4 bottom-4 z-3 flex flex-col gap-1 transition-all duration-300 ease-in group-hover:bottom-16"
      >
        <div class="line-clamp-1 text-md-13px-default text-text">
          <ProjectName :project="project" />
        </div>
        <div class="line-clamp-2 text-xs-11px-light text-text-subtle">
          {{ project.description || 'No description' }}
        </div>
      </div>
      <div
        class="flex h-fit w-full shrink grow items-end gap-3 overflow-hidden opacity-0 transition-all duration-200 ease-in group-hover:opacity-100 group-hover:delay-100"
      >
        <DarwinButton
          class="w-full"
          rounded
          size="md"
          variant="black"
          aria-label="Start a new case"
          @click="startCase()"
        >
          Start a new case
        </DarwinButton>
        <DarwinButton
          class="w-full"
          rounded
          size="md"
          variant="neutral"
          :to="projectRoute"
        >
          Configure agent
        </DarwinButton>
      </div>
    </div>
  </div>
</template>
