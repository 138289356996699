import type { Field } from '@/modules/Project/Fields/types'
import type { EmptyObject } from '@/shared/types'
import { isEmptyObject } from '@/shared/utils/typeGuards'
import { objectEntries } from '@vueuse/core'
import {
  getFilterSubjectForProperty,
  isGroupFilter,
  type FieldValueFilter,
  type Filter,
  type FilterableProperty,
  type NumberValueFilter,
  type SimpleFilter,
  type StatusFilterMatcherValue,
  type TextLikeFilter,
} from './types'

export const getFilterComparisonValue = (
  v: Exclude<Field['manualValue'], Filter>,
  caseSensitive: boolean,
): Exclude<Field['manualValue'], Filter | number> => {
  if (v === null) {
    return v
  }

  if (caseSensitive) {
    return v
  }

  if (Array.isArray(v)) {
    return v.map((s) => s.toLowerCase())
  }

  return v.toLowerCase()
}

/** Returns the total number of filters contained within a recursive filter object */
export const countFilters = (filter: Filter | EmptyObject | undefined | null): number => {
  if (!filter || isEmptyObject(filter)) {
    return 0
  }

  // If the filter has a conjunction, then it's a group filter so we must count
  // the number of filters in all of its children.
  if (isGroupFilter(filter)) {
    return filter.filters.reduce((acc, f) => acc + countFilters(f), 0)
  }

  // If we're here, the filter is a SimpleFilter, so has one condition.
  return 1
}

/**
 * Given a property and a search term, will return a simple filter that can
 * be used to filter entities based on that search term.
 */
export const getSimpleFilterForProperty = (
  property: FilterableProperty,
  searchValue: string,
): SimpleFilter => {
  const subject = getFilterSubjectForProperty(property.type)

  switch (subject) {
    case 'field_select_option_value': {
      return {
        subject,
        matcher: {
          name: 'property_any_of',
          property_id: property.id,
          values: [searchValue],
          case_sensitive: false,
        },
      }
    }

    case 'field_metadata_field_name':
    case 'field_text_value':
    case 'field_file_name': {
      return {
        subject,
        matcher: {
          name: 'property_contains_any_of',
          property_id: property.id,
          values: [searchValue],
          case_sensitive: false,
        },
      }
    }

    case 'field_number_value': {
      return {
        subject,
        matcher: {
          name: 'property_any_of',
          property_id: property.id,
          values: [searchValue],
          case_sensitive: false,
        },
      }
    }
  }
}

export const statusFilterMap = {
  error: 'Has errors',
  complete: 'Is complete',
  waiting: 'Pending computation',
  idle: 'Idle',
}

export const statusFilterItems = {
  id: 'status',
  label: 'Status',
  type: 'status',
  options: objectEntries(statusFilterMap).map(([value, label]) => ({
    value,
    label,
  })) satisfies Array<{ value: StatusFilterMatcherValue; label: string }>,
  group: 'misc',
} as const

/**
 * The API supports filtering number fields by multiple values, but on the FE
 * we only support filtering by a single value. This function returns the
 * single value that should be used for the given filter.
 */
export const getNumberFilterValue = (filter: NumberValueFilter): string | undefined => {
  if ('value' in filter.matcher) {
    return filter.matcher.value
  }

  return filter.matcher.values[0]
}

/**
 * Returns the search value used for the given filter, either as a string or
 * an array of strings, depending on the matcher.
 */
export const getFilterValue = (filter: FieldValueFilter): string | string[] | undefined => {
  if (filter.subject === 'field_number_value') {
    return getNumberFilterValue(filter)
  }

  return filter.matcher.values
}

export const getFilterDisplayValue = (filter: TextLikeFilter | NumberValueFilter) => {
  const maxLength = 12
  const filterValue = getFilterValue(filter)
  const value = Array.isArray(filterValue) ? filterValue[0] : filterValue
  // The value will be undefined when `props.filter.values` is an empty array
  if (!value) {
    return ''
  }

  const formatter = new Intl.NumberFormat()
  // If the filter is a number, display it formatted in the user's locale
  if (filter.subject === 'field_number_value') {
    return formatter.format(value as Intl.StringNumericLiteral)
  }

  // For strings, wrap in quotes and truncate if necessary
  return value.length > maxLength
    ? `"${value.slice(0, maxLength / 2)}...${value.slice(-maxLength / 2)}"`
    : `"${value}"`
}

/**
 * Depending on the search value, a number filter could use either
 * - `values: string[]`
 * - `value: string`
 * This function builds the correct filter object based on the matcher name.
 */
export const buildNumberFilter = ({
  matcherName,
  propertyId,
  value,
}: {
  matcherName: NumberValueFilter['matcher']['name']
  value: string
  propertyId: string
}): NumberValueFilter => {
  if (matcherName === 'property_any_of' || matcherName === 'property_none_of') {
    return {
      subject: 'field_number_value',
      matcher: {
        name: matcherName,
        property_id: propertyId,
        values: [value],
      },
    }
  }

  return {
    subject: 'field_number_value',
    matcher: {
      name: matcherName,
      property_id: propertyId,
      value,
    },
  }
}
