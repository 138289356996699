<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import { useLibraryStore } from '@/modules/Library/libraryStore'
import ExportButton from '@/modules/Project/ExportButton.vue'
import { useRouteTrace } from '@/modules/Project/routing/useRouteTrace'
import { useResolveEntityRoute } from '@/modules/Project/useResolveEntityRoute'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { doExportProject } from '@/shared/utils/exports'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import SegmentedControl, { type SegmentedControlItem } from '@/uiKit/SegmentedControl.vue'
import { useProject } from './useProject'
import { useResolveProjectRoute } from './useResolveProjectRoute'

const router = useRouter()
const { captureAnalyticsEvent } = useAnalytics()
const projectStore = useProject()
const workspacesStore = useWorkspaces()
const permissionsStore = usePermissionsStore()
const isKnowledgeHubEnabled = useFeatureFlags(FeatureFlag.KNOWLEDGE_HUB)
const libraryStore = useLibraryStore()
const { parentEntityId, parentProjectId, parentViewId, projectId, viewId, workspaceId } =
  useRouteParams()
const resolveProjectTableRoute = useResolveProjectRoute()
const resolveEntityRoute = useResolveEntityRoute()
const { getSerializedTrace } = useRouteTrace()

const exporting = ref({ csv: false, xlsx: false })
const showExportProgress = ref(false)

const superView = computed(() => {
  const route = router.currentRoute.value

  if (route.name === 'WorkflowView') {
    return 'workflow'
  }

  if (
    route.name === 'WorkspaceProjectEntityView' ||
    route.name === 'WorkspaceProjectEntitySubView' ||
    route.name === 'WorkspaceProjectEntityViewEmpty' ||
    route.name === 'WorkspaceSubProjectEntityView' ||
    route.name === 'WorkspaceSubProjectEntitySubView'
  ) {
    return 'entity'
  }

  return 'main'
})

const viewOptions = computed<SegmentedControlItem<'main' | 'entity' | 'workflow'>[]>(() => {
  const options: SegmentedControlItem<'main' | 'entity' | 'workflow'>[] = [
    {
      label: 'Build',
      value: 'main',
      width: 80,
    },
    {
      label: 'Review',
      value: 'entity',
      width: 80,
    },
  ]

  if (permissionsStore.currentProjectPermissions.update_projects) {
    options.push({
      label: 'Automate',
      value: 'workflow',
      width: 80,
    })
  }

  return options
})

const onActiveViewChange = (event: 'main' | 'entity' | 'workflow' | null) => {
  if (event === 'workflow') {
    return router.push({
      name: 'WorkflowView',
      params: {
        workspaceId: workspaceId.value,
        projectId: projectStore.projectId,
      },
    })
  }

  const activeView = projectStore.activeView
  if (!activeView) {
    return
  }

  if (event === 'entity') {
    if (!(activeView.entities && activeView.entities.length > 0 && activeView.entities[0])) {
      return router.push({
        name: 'WorkspaceProjectEntityViewEmpty',
        params: {
          workspaceId: workspaceId.value,
          projectId: projectStore.projectId,
        },
        query: {
          parentViewId: parentViewId.value,
          trace: getSerializedTrace(),
        },
      })
    }

    const route = resolveEntityRoute({
      workspaceId: workspaceId.value,
      projectId: projectStore.projectId || '',
      entityId: activeView.entities[0].id,
      parentEntityId: parentEntityId.value,
      parentProjectId: parentProjectId.value,
      parentViewId: parentViewId.value,
    })

    return router.push(route)
  }

  if (event === 'main') {
    const route = resolveProjectTableRoute({
      parentEntityId: parentEntityId.value,
      parentProjectId: parentProjectId.value,
      parentViewId: parentViewId.value,
      projectId: projectId.value,
      viewId: viewId.value,
      workspaceId: workspaceId.value,
      trace: 'same',
    })
    return router.push(route)
  }
}

const isAnyExportInProgress = computed(() => exporting.value.csv || exporting.value.xlsx)

const onExport = async (format: 'csv' | 'xlsx') => {
  if (!workspacesStore.currentWorkspace?.id || !projectStore.projectId) return
  captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_EXPORTED)
  exporting.value[format] = true
  showExportProgress.value = true
  try {
    await doExportProject(
      workspacesStore.currentWorkspace?.id,
      projectStore.projectId,
      200,
      3000,
      format,
    ) // keep trying for max 200 times (sleep 3000ms = 3s) = 10m
    captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_EXPORTED)
  } finally {
    exporting.value[format] = false
    showExportProgress.value = false
  }
}
</script>

<template>
  <!--
    reduce the height and remove the bottom padding when on the table view because
    the table has a 2px top border which is used to highlight input columns, and
    this top border eats into the space used by this top bar.
  -->
  <div
    class="flex w-full items-center px-2.5"
    :class="[
      superView === 'main' ? 'h-[42px] min-h-[42px] pt-0.5' : 'h-11 min-h-11 py-0.5',
      superView === 'entity' ? 'bg-surface-secondary' : 'bg-surface-primary',
    ]"
  >
    <!-- Library -->
    <div class="flex w-1/3 items-center">
      <DarwinButton
        v-if="isKnowledgeHubEnabled"
        size="sm"
        rounded
        variant="neutral"
        :to="{ name: 'Files' }"
      >
        Knowledge Hub
      </DarwinButton>
      <DarwinButton
        v-else
        rounded
        size="sm"
        variant="neutral"
        @click="libraryStore.dialogIsOpen = true"
        >Library
      </DarwinButton>
    </div>

    <!-- Nav Segmented Control -->
    <div class="flex w-1/3 items-center justify-center">
      <div class="sticky right-0">
        <SegmentedControl
          name="viewControl"
          aria-label="View Control"
          :value="superView"
          :items="viewOptions"
          @change="onActiveViewChange"
        />
      </div>
    </div>

    <!-- Export -->
    <div class="flex w-1/3 items-center justify-end">
      <ExportButton
        v-if="
          workspacesStore.currentWorkspace?.id &&
          projectStore.projectId &&
          permissionsStore.currentProjectPermissions.manage_project_exports
        "
        :loading="isAnyExportInProgress"
        @click="onExport"
      />
      <div
        class="absolute bottom-3 z-9 transition-all duration-300 ease-in"
        :class="{
          'right-3 opacity-100': showExportProgress,
          'right-[-420px] opacity-0': !showExportProgress,
        }"
        aria-labelledby="export-in-progress-dialog-label"
        role="dialog"
      >
        <div
          class="flex h-16 w-[420px] grow items-center justify-between gap-1 rounded-corner-8 border border-icon-gray-subtle bg-surface-primary p-2 shadow-md"
        >
          <div class="flex items-center justify-center gap-2">
            <div class="flex size-7 items-center justify-center">
              <CircularProgress
                size="xs"
                class="animate-spin rounded-full"
              />
            </div>
            <div class="flex flex-col items-center justify-start gap-2">
              <p class="text-sm-12px-default text-text">Export in progress</p>
            </div>
          </div>
          <IconButton
            size="sm"
            icon="close"
            rounded
            variant="transparent"
            aria-label="Close export menu"
            name="Close export menu"
            role="button"
            @click="showExportProgress = false"
          >
            Export
          </IconButton>
        </div>
      </div>
    </div>
  </div>
</template>
