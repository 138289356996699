<script setup lang="ts">
import { FeatureFlag } from '@/modules/App/featureFlags'
import { LDEvents, useABMetrics } from '@/modules/App/useABMetrics'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import ProjectLink from '@/modules/Project/Navigation/ProjectLink.vue'
import type { Property } from '@/modules/Project/Properties/types'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
// eslint-disable-next-line no-restricted-imports
import PopupMenu, { type PopupMenuChangeOpenReason } from '@/uiKit/PopupMenu.vue'
import TagButton from '@/uiKit/TagButton.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { autoUpdate, offset, size, useFloating } from '@floating-ui/vue'
import { ref, watch } from 'vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'
import ProjectTableHeaderItemResizeHandle from './ProjectTableHeaderItemResizeHandle.vue'
import PropertyEditMenu from './PropertyEditMenu.vue'
import { TYPE_ICON } from './icons'
import { useProject } from './useProject'
import { useProperty } from './useProperty'
import { usePropertyDropdown } from './usePropertyDropdown'

const props = defineProps<{
  property: Property
  selected: boolean
  width: number
  isInput: boolean
  /** Will be true if this property hasn't been created on the backend yet. */
  isOptimistic?: boolean
}>()

const emit = defineEmits<{
  (e: 'select'): void
  (e: 'resize', delta: number): void
}>()

const isPropertyEditorOpensInSidebar = useFeatureFlags(FeatureFlag.AB_PROPERTY_EDITOR) || false

const highlighter = ref<HTMLElement>()
const container = ref<HTMLElement>()
const { floatingStyles } = useFloating(container, highlighter, {
  placement: 'bottom-start',
  middleware: [
    offset(({ rects }) => ({ mainAxis: -rects.reference.height - 1, crossAxis: -1 })),
    size({
      apply({ elements, rects }) {
        const { floating } = elements
        floating.style.width = rects.reference.width + 2 + 'px'

        if (!(props.selected || props.isInput)) {
          return
        }
        const header = container.value
        if (!header) {
          return
        }

        // To work out how tall the highlighter should be, we take the distance
        // between the bottom of the last row and the top of the header. If there
        // are no rows, we use the bottom of the header instead.
        const headerTop = header.getBoundingClientRect().top
        const lastRow = document.querySelector('[role="row"]:last-child > :first-child') ?? header
        const lastRowBottom = lastRow.getBoundingClientRect().bottom

        floating.style.height = lastRowBottom - headerTop + 2 + 'px'
      },
    }),
  ],
  whileElementsMounted: autoUpdate,
})

const {
  deleteProperty,
  deletePropertyConfirmationOpen,
  hideProperty,
  menuIsOpen,
  onClickOutside,
  onCloseMenu,
  onReprocessColumn,
  onUpdate,
  openDeleteDialog,
  triggerRef,
} = usePropertyDropdown()

const propertyStore = useProperty()
/**
 * The menu should open when the property is selected. If the user chooses
 * to open the sidebar or if the user deselects the property, the menu should close.
 */
watch(
  () => [props.selected, propertyStore.sidebarIsOpen],
  ([selected, sidebarIsOpen]) => {
    menuIsOpen.value = selected && !sidebarIsOpen
  },
  {
    immediate: true,
  },
)

const { startTrackingTimeFor, trackElapsedTimeEvent } = useABMetrics()

const permissionsStore = usePermissionsStore()

const onOpenChange = (isOpen: boolean, reason: PopupMenuChangeOpenReason) => {
  if (
    reason !== 'trigger' ||
    !permissionsStore.currentProjectPermissions.manage_properties ||
    !projectStore.projectLoaded
  )
    return

  /**
   * We don't want to let users open the edit menu while a property hasn't been created
   * on the backend. The only exception to this is reference properties, because they
   * cannot be created until the user has linked a reference project.
   */
  if (props.isOptimistic && props.property.type !== 'reference') {
    return
  }

  // AB_PROPERTY_EDITOR EXPERIMENT - Metrics
  startTrackingTimeFor(LDEvents.TIME_SPENT_ON_EDITING)

  if (!isPropertyEditorOpensInSidebar.value) {
    // AB_PROPERTY_EDITOR EXPERIMENT - A: Editor opens in Popup
    if (props.selected) {
      propertyStore.sidebarIsOpen = false
      menuIsOpen.value = isOpen
    } else {
      emit('select')
    }
  } else {
    // AB_PROPERTY_EDITOR EXPERIMENT - B: Editing opens on Sidebar, right click opens the popup
    if (reason === 'trigger') {
      // opens sidebar on click
      menuIsOpen.value = false
      propertyStore.sidebarIsOpen = true
    } else if (reason === 'triggerContext') {
      // closes sidebar on right click, opens popup
      propertyStore.sidebarIsOpen = false
      menuIsOpen.value = isOpen
    }
    if (!props.selected) {
      emit('select')
    }
  }
}

const welcomeTour = useWelcomeTour()
const projectStore = useProject()
</script>

<template>
  <PopupMenu
    :auto-placement="{ allowedPlacements: ['bottom-start', 'bottom-end'] }"
    class="group relative h-8 select-none items-center"
    v-bind="$attrs"
    :open-on-right-click="isPropertyEditorOpensInSidebar"
    :open="menuIsOpen && !!permissionsStore.currentProjectPermissions.manage_properties"
    :disable-focus-trap="welcomeTour.status === 'IN_PROGRESS'"
    :disable-close-on-click-outside="welcomeTour.status === 'IN_PROGRESS'"
    @change:open="onOpenChange"
  >
    <template #trigger>
      <div
        ref="container"
        class="relative flex size-full w-full items-center justify-start gap-1.5 px-2 py-1.5 hover:cursor-pointer focus:outline-none focus-visible:outline-none"
      >
        <LoadingSkeleton
          v-if="!projectStore.projectLoaded"
          class="absolute inset-1"
          status
        />
        <div
          ref="triggerRef"
          class="flex max-w-full shrink grow items-center justify-start gap-1.5 py-1.5 hover:cursor-pointer focus-visible:outline-none"
          :aria-label="`Edit ${property.name}`"
        >
          <IconSprite
            :icon="TYPE_ICON[property.type]"
            class="fill-icon-subtle text-icon-subtle"
          />
          <div
            class="flex grow truncate text-sm-12px-default text-text-subtle"
            :title="property.name"
            data-sentry="unmask"
          >
            <ToolTip
              v-if="property.type === 'reference'"
              body="Open related project"
              :placement="{ allowedPlacements: ['top'] }"
            >
              <ProjectLink :to="{ projectId: property.config.projectId || undefined }">
                <TagButton
                  :label="property.name"
                  size="sm"
                />
              </ProjectLink>
            </ToolTip>
            <template v-else>
              {{ property.name }}
            </template>
          </div>
        </div>
        <ProjectTableHeaderItemResizeHandle
          :width="width"
          @resize="emit('resize', $event)"
        />
      </div>
      <div
        v-if="isInput || selected"
        ref="highlighter"
        :data-test="isInput ? 'highlighter-input' : 'highlighter-selected'"
        :style="floatingStyles"
        class="pointer-events-none"
        :class="[
          selected && ['bg-background-gray-subtlest'],
          isInput && [
            'absolute inset-0 z-10 !translate-y-0.5 border-border-focused shadow-[0px_-2px_0px_var(--color-background-primary)] [background:linear-gradient(180deg,_rgba(26,_125,_255,_0.1),_rgba(26,_125,_255,_0))]',
          ],
        ]"
      />
    </template>
    <template #dropdown>
      <PropertyEditMenu
        show-table-actions
        :property-id="property.id"
        aria-label="Property Popup Menu"
        @close="onCloseMenu"
        @update="
          (args) => {
            onUpdate(args)
            // AB_PROPERTY_EDITOR EXPERIMENT - Metrics
            trackElapsedTimeEvent(LDEvents.TIME_SPENT_ON_EDITING)
          }
        "
        @delete="openDeleteDialog"
        @reprocess="onReprocessColumn"
        @click:outside="onClickOutside"
        @hide="hideProperty"
      />
    </template>
  </PopupMenu>

  <ConfirmationDialog
    id="delete-property-confirmation"
    :open="deletePropertyConfirmationOpen"
    title="Delete this property?"
    description="This property will be deleted immediately. You can't undo this action."
    @confirm="deleteProperty"
    @close="deletePropertyConfirmationOpen = false"
  />
</template>
