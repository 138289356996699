import { get } from './client'
import type { BackendMeta } from './types'

export type ListCases = BackendMeta<'/api/workspaces/{workspace_id}/cases', 'get'>

type QueryParams = ListCases['queryParams']
type OrderBy = Exclude<QueryParams['order_by'], undefined>[number]
type OrderDirection = Exclude<QueryParams['order_directions'], null | undefined>[number]

export type ListCasesParams = {
  workspaceId: string
  order?: Array<[OrderBy, OrderDirection]> | null
  first?: QueryParams['first']
  after?: QueryParams['after']
  last?: QueryParams['last']
  before?: QueryParams['before']
}

export const listCases = async ({
  workspaceId,
  after,
  before,
  first,
  last,
  order,
}: ListCasesParams) =>
  get<ListCases['queryParams'], ListCases['successResponse'], ListCases['path']>(
    `/api/workspaces/${workspaceId}/cases`,
    {
      after,
      before,
      first,
      last,
      order_by: order?.map(([orderBy]) => orderBy),
      order_directions: order?.map(([, orderDirection]) => orderDirection),
    },
  )
