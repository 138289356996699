<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import ModalDialogHeader from '@/uiKit/ModalDialogHeader.vue'
import TextField from '@/uiKit/TextField.vue'
import { nextTick, ref, useTemplateRef, watch } from 'vue'
import { FALLBACK_CASE_NAME } from './serializers'

const props = withDefaults(
  defineProps<{
    value?: string | null
    open: boolean
  }>(),
  {
    value: FALLBACK_CASE_NAME,
  },
)

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'rename', value: string): void
}>()

const newValue = ref(props.value ?? '')

const textField = useTemplateRef('textField')
const focusText = () => textField.value?.$el.focus()
const cancelButton = useTemplateRef('cancelButton')
const focusCancel = () => cancelButton.value?.$el.focus()
const confirmButton = useTemplateRef('confirmButton')
const focusConfirm = () => confirmButton.value?.$el.focus()

watch(
  () => props.open,
  async (value) => {
    if (!value) {
      newValue.value = ''
      return
    }
    await nextTick()
    focusText()
  },
)
</script>

<template>
  <ModalDialog
    :open="open"
    class="min-w-[480px]"
    @close="emit('close')"
  >
    <template #header>
      <ModalDialogHeader title="Rename case" />
    </template>
    <template #body>
      <TextField
        ref="textField"
        autofocus
        size="md"
        :value="newValue"
        label="Case name"
        @input="newValue = $event"
        @keydown.enter="emit('rename', newValue)"
        @esc="emit('close')"
      />
    </template>
    <template #footer>
      <DarwinButton
        ref="cancelButton"
        variant="neutral"
        size="md"
        tabindex="1"
        @keydown.left="focusCancel"
        @click="emit('close')"
      >
        Cancel
      </DarwinButton>
      <DarwinButton
        ref="confirmButton"
        size="md"
        data-test="confirm-btn"
        variant="black"
        @keydown.right="focusConfirm"
        @click="emit('rename', newValue)"
      >
        Save
      </DarwinButton>
    </template>
  </ModalDialog>
</template>
