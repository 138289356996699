<script setup lang="ts">
/**
 * https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/V7-Go---Design-System?type=design&node-id=1305-26078&mode=design&t=UzgWUnY8pt2zV5UC-0
 */

import { useButtonLink } from '@/sharedComposables/useButtonLink'
import { type RouterLinkProps } from 'vue-router'
import type { IconName } from './IconName'
import IconSprite from './IconSprite.vue'

const props = defineProps<{
  label: string
  leadingIcon?: IconName
  trailingIcon?: IconName
  size: 'xs' | 'sm' | 'md'
  active?: boolean
  to?: RouterLinkProps['to']
}>()

const { linkProps, tag } = useButtonLink(props)
</script>

<template>
  <component
    :is="tag"
    :to="to"
    class="group shrink-0 truncate focus:outline-none focus-visible:bg-background-transparent-hovered"
    :class="[
      size === 'xs' && ['rounded-corner-4'],
      size === 'sm' && ['rounded-corner-6'],
      size === 'md' && ['rounded-corner-8'],
      ,
    ]"
    :title="label"
    v-bind="linkProps"
  >
    <div
      class="flex shrink-0 items-center"
      :class="[
        size === 'xs' && ['rounded-corner-4 p-0 text-xs-11px-default'],
        size === 'sm' && ['rounded-corner-6 p-0.5 text-sm-12px-default'],
        size === 'md' && ['rounded-corner-8 p-0.5 text-md-13px-default'],
        active
          ? 'bg-background-transparent-pressed'
          : 'bg-background-transparent hover:bg-background-transparent-hovered',
      ]"
    >
      <slot name="leading-icon">
        <IconSprite
          v-if="leadingIcon"
          class="pl-0.5"
          :icon="leadingIcon"
          data-test="leading-icon"
          :size="size === 'md' ? 'lg' : 'xs'"
        />
      </slot>
      <span
        class="truncate text-sm-12px-default text-text-subtle decoration-border underline-offset-[3px] group-hover:decoration-background-transparent"
        :class="[
          size === 'xs' && 'px-0.5',
          size === 'sm' && 'px-1',
          size === 'md' && 'px-1',
          !active && 'underline',
        ]"
      >
        <slot>
          {{ label }}
        </slot>
      </span>
      <slot name="trailing-icon">
        <div
          v-if="trailingIcon"
          :class="[size === 'xs' && 'p-0.5', size === 'sm' && 'p-0.5', size === 'md' && 'p-0.5']"
        >
          <IconSprite
            data-test="trailing-icon"
            class="text-icon-subtle"
            :size="size === 'md' ? 'lg' : 'xs'"
            :icon="trailingIcon"
          />
        </div>
      </slot>
    </div>
  </component>
</template>
