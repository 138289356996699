import { wait } from '@/shared/utils/time'
import { ref, type Ref } from 'vue'

type State = Ref<boolean>
type ExecFn = <R, T extends (...args: unknown[]) => R>(
  callback: T,
  ...args: Parameters<T>
) => Promise<R>

/**
 * This composable helps with toggling a boolean value for a short duration.
 * It's useful to animate a component when it's toggled on/off.
 *
 * @example
 * ```ts
 * const [isAnimated, withAnimation] = useTemporaryToggle({ initialValue: false, trailingMs: 200 })
 * const onClick = withAnimation(() => scrollToPage(15))
 * <div :class="[isAnimated && 'transition-transform duration-200']" />
 * ```
 */
export function useTemporaryToggle(props: {
  /** Default state of the toggle, defaults to `false`. */
  initialValue: boolean
  /** Minimum time after which the toggle will be set back to its initial value. */
  trailingMs?: number
}): [State, ExecFn] {
  const isActive: State = ref(props.initialValue)

  const run: ExecFn = async <R, T extends (...args: unknown[]) => R>(
    callback: T,
    ...args: Parameters<T>
  ) => {
    isActive.value = !props.initialValue
    // Expected use case is to add a class to the element, so we need to wait for a DOM change
    await wait()

    const result = await callback(...args)
    await wait(props.trailingMs)
    isActive.value = props.initialValue

    return result
  }

  return [isActive, run]
}
