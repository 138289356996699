import type { LibraryItem } from '@/modules/Library/libraryStore'
import { getFileExtension } from '@/modules/Workspaces/KnowledgeHub/Files/library-item'
import type { IconName } from '@/uiKit/IconName'

type LinkInfo = {
  label: string
  icon: IconName
  link?: { url: string; target: '_self' | '_blank' }
}

/**
 * Returns information needed to consistently display buttons that open various
 * types of library items.
 */
export function getItemLinkInfo(item: LibraryItem): LinkInfo {
  const action = getLibraryItemOpeningAction(item)
  const label = action === 'download' ? 'Download' : 'Open'
  const icon = 'expand'
  const output: LinkInfo = { label, icon }

  if (item.type === 'file' && item.fileUrl) {
    output.link = {
      url: item.fileUrl,
      target: action === 'download' ? '_self' : '_blank',
    }
  }

  return output
}

/** Extensions that Chrome can open directly, rather than downloading */
const displayedInChrome = ['pdf', 'jpg', 'png', 'jpeg']

function getLibraryItemOpeningAction(item: LibraryItem) {
  if (item.type === 'text') {
    return 'open'
  }

  const ext = getFileExtension(item)
  if (ext && displayedInChrome.includes(ext)) {
    return 'open'
  }

  return 'download'
}
