<script setup lang="ts">
import { useProjectSync } from '@/modules/Project/useProjectSync'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import { computed } from 'vue'

const props = defineProps<{
  projectId: string
}>()

const workspace = useCurrentWorkspace()
const { loadingState } = useProjectSync({
  projectId: computed(() => props.projectId),
  workspaceId: computed(() => workspace.value.id),
})
</script>

<template>
  <div class="size-full">
    <template v-if="loadingState === 'loaded'">
      <slot />
    </template>
    <template v-else-if="loadingState === 'errored'">
      <div class="p-8 text-center text-text-critical">Could not load project</div>
    </template>
    <template v-else>
      <div class="flex items-center justify-center p-8">
        <CircularProgress
          class="animate-spin"
          size="md"
        />
      </div>
    </template>
  </div>
</template>
