import type { components } from '@/api'
import type { Entity } from '@/modules/Project/useProject'
import type { Project } from '@/modules/Projects/useProjects'
import type { LibraryItem } from '../Library/libraryStore'

/** The response to GET /cases/:caseId, containing all properties of a case. */
export type CaseResponse = components['schemas']['Cases.CaseResponse']
export type MessageResponse = CaseResponse['messages'][number]
export type OutputResponse = CaseResponse['outputs'][number]

/** The response to GET /cases, containing only partial case data. */
export type PaginatedCaseResponse = components['schemas']['Cases.CaseResponseInList']

/**
 * The `Message` type is a discriminated union, all members have these
 * properties.
 */
type MessageBase = {
  id: string
  createdAt: string
  updatedAt: string
}

/**
 * An in-progress response from the server. It will receive websocket updates
 * and eventually be replaced by a `MessageFullResponse`.
 */
export type MessagePartialResponse = MessageBase & {
  type: 'partial_response'
  text: string
}

/**
 * A full response from the server. This is the final state of a response message.
 */
export type MessageFullResponse = MessageBase & {
  type: 'response'
  text: string
}

/** A message sent by a user */
export type MessageQuery = MessageBase & {
  type: 'query'
  fileIds: string[]
  text: string
  userId: string
  agentId: string | null
}

/** A message that points to an output of an agent run on the case. */
export type MessageAgentRun = MessageBase & {
  type: 'agent_run'
  outputId: string | null
  agentId: string
  inProgress: boolean
}

export type MessageToolRun = MessageBase & {
  type: 'tool_run'
  toolName: ToolName
  inProgress: boolean
}

type Message =
  | MessagePartialResponse
  | MessageFullResponse
  | MessageQuery
  | MessageAgentRun
  | MessageToolRun

export type CaseOutput = {
  createdAt: string
  entity: Entity
  id: string
  project: Project
}

export type CaseSource =
  | { type: 'library-item'; libraryItem: LibraryItem }
  | { type: 'file'; file: { filename: string } }

// Completely made up type, this will change when the backend is implemented
// and we know more about what a case really is
export type PartialCase = {
  id: string
  name: string | null
  createdAt: string
  updatedAt: string
  projectIds: string[]
  userId: string
}

type CaseFileStatus = CaseResponse['files'][number]['status']

export type CaseFile = {
  id: string
  name: string
  status: CaseFileStatus
}

export type Case = PartialCase & {
  messages: Message[]
  outputs: CaseOutput[]
  sources: CaseSource[]
  files: CaseFile[]
}

export const isFullCase = (c?: PartialCase | Case | null): c is Case => !!c && 'messages' in c

export type CaseInputAttachment = {
  uploadId: string
  type: 'file' | 'library-file' | 'integration-file'
  name: string
  fileId: string | null
  progress: number | null
  status:
    | 'registering'
    | 'uploading'
    | 'confirming'
    | 'library-downloading'
    | 'processing'
    | 'error'
    | 'complete'
}

export type BubbleMessagePlaceholder = {
  id: string
  messageType: 'response-placeholder'
  text: string
  createdAt: string
  updatedAt: string
}

export type BubbleSent = { id: string; type: 'sent'; message: BubbleMessageQuery }
export type BubbleReceived = {
  id: string
  type: 'received'
  messages: (
    | BubbleMessageResponse
    | BubbleMessageAgentRun
    | BubbleMessageToolRun
    | BubbleMessagePlaceholder
  )[]
  previousQueryId: string | null
}
export type Bubble = BubbleSent | BubbleReceived

export type BubbleMessageResponse = {
  id: string
  messageType: 'partial_response' | 'response'
  attachments: CaseInputAttachment[]
  text: string
  createdAt: string
  updatedAt: string
}

export type ToolName = components['schemas']['Cases.ToolRunToolName']

export type BubbleMessageToolRun = {
  id: string
  messageType: 'tool_run'
  attachments: CaseInputAttachment[]
  toolName: ToolName
  createdAt: string
  updatedAt: string
  inProgress: boolean
}

export type BubbleMessageQuery = {
  id: string
  messageType: 'query' | 'pending_query'
  agentId: string | null
  attachments: CaseInputAttachment[]
  createdAt: string
  text: string
  updatedAt: string
  user: { id: string; fullName: string | null; email: string | null } | null
}

export type BubbleMessageAgentRun = {
  id: string
  messageType: 'agent_run'
  attachments: CaseInputAttachment[]
  outputId: string | null
  agent: { id: string; name: string; coverHighRes: string; coverLowRes: string } | null
  createdAt: string
  updatedAt: string
  inProgress: boolean
}

export type BubbleMessage = BubbleMessageResponse | BubbleMessageQuery | BubbleMessageAgentRun
