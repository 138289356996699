<script setup lang="ts">
import { clamp } from '@vueuse/core'

import type { ScaleType } from '@/sharedComposables/useNaturalZoom'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    scale: number | 'fit'
    minScale?: number
    maxScale?: number
    step?: number
    disableFit?: boolean
    /**
     * If true, the buttons will take up the full width of the container
     */
    fullWidthMode?: boolean
    downloadUrl?: string | null
  }>(),
  {
    minScale: 0.25,
    maxScale: 2,
    step: 0.25,
    disableFit: false,
    inlineMode: false,
    downloadUrl: null,
  },
)

/**
 * emits the closest step value to the current scale within the min and max scale
 */
const emit = defineEmits<{
  (e: 'update:scale', scale: ScaleType): void
}>()

const emitChange = (next: boolean) => {
  const { scale, step, minScale, maxScale } = props
  if (scale === 'fit') {
    emit('update:scale', 1)
    return
  }
  const multipliedScale = scale * 100
  const multipliedStep = step * 100
  const newScale = next
    ? Math.floor(multipliedScale / multipliedStep) * multipliedStep + multipliedStep
    : Math.ceil(multipliedScale / multipliedStep) * multipliedStep - multipliedStep

  const toEmit = clamp(newScale / 100, minScale, maxScale)
  if (toEmit !== scale) emit('update:scale', toEmit)
}

const menuOpen = ref(false)
</script>

<template>
  <div
    class="select-none items-center gap-0.5 rounded-lg p-0.5"
    :class="
      fullWidthMode
        ? 'flex bg-background-gray-subtlest'
        : 'inline-flex border border-border-subtle bg-surface-popover shadow-sm'
    "
  >
    <IconButton
      icon="minus"
      size="md"
      data-test="zoom-out-button"
      variant="transparent"
      :class="fullWidthMode && 'w-full'"
      @click="emitChange(false)"
    />
    <Menu.Root
      v-slot="{ getTriggerProps }"
      disable-teleport
      :positioning="{ placement: 'top', offset: { mainAxis: 4, crossAxis: -2 } }"
      :open="menuOpen"
      close-on-select
      @change:open="menuOpen = $event"
    >
      <DarwinButton
        v-bind="getTriggerProps()"
        size="xs"
        :class="fullWidthMode ? 'w-full' : 'w-12'"
        variant="transparent"
      >
        {{ scale === 'fit' ? 'Fit' : `${(scale * 100).toFixed(0)}%` }}
      </DarwinButton>
      <Menu.Content class="p-1 text-center">
        <Menu.Item
          v-for="s in [2, 1.75, 1.5, 1.25, 'fit', 1, 0.75, 0.5, 0.25].filter(
            (s) => s !== 'fit' || !disableFit,
          )"
          :key="s"
          :label="typeof s === 'string' ? 'Fit' : `${(s * 100).toFixed(0)}%`"
          @select="$emit('update:scale', s === 'fit' ? 'fit' : Number(s))"
        />
      </Menu.Content>
    </Menu.Root>
    <IconButton
      icon="plus"
      size="md"
      :class="fullWidthMode && 'w-full'"
      data-test="zoom-in-button"
      variant="transparent"
      @click="emitChange(true)"
    />
    <template v-if="downloadUrl && !fullWidthMode">
      <DividerLine
        direction="vertical"
        color="subtle"
      />

      <IconButton
        icon="arrow-bottom-circle"
        size="md"
        :href="downloadUrl"
        download
        target="_blank"
        title="Download file"
        class="!text-icon-subtlest"
        variant="transparent"
      />
    </template>
  </div>
</template>
