<script setup lang="ts">
import { useProfileImage } from '@/modules/WorkspaceSettings/useProfileImage'
import type { WorkspaceMember } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import IconButton from '@/uiKit/IconButton.vue'
import type { IconName } from '@/uiKit/IconName'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'

const props = defineProps<{
  connection: {
    name: string
    user: WorkspaceMember
    icon: IconName
  }
}>()

defineEmits<{ remove: [] }>()

const profileImage = useProfileImage(props.connection.user.id)
</script>

<template>
  <div class="group grid h-12 grid-cols-[64px_repeat(5,1fr)] items-center gap-3">
    <div
      class="pointer-events-none absolute -inset-2 rounded-corner-10 bg-background-transparent-hovered opacity-0 transition group-hover:opacity-100"
    />

    <IconSprite
      :icon="connection.icon"
      size="xxl"
      class="grid h-full place-items-center rounded-corner-8 bg-background-gray-subtlest"
    />

    <div class="col-span-3">
      <div class="text-md-13px-bold">{{ connection.name }}</div>
      <div class="text-sm-12px-light text-text-subtle">
        {{ connection.user?.email }}
      </div>
    </div>

    <div class="text-sm-12px-light text-text-subtle">
      <span class="flex items-center gap-1.5">
        <AvatarIcon
          size="xs"
          :full-text="connection.user?.fullName"
          shape="circle"
          :url="profileImage"
          class="inline-block"
        />
        {{ connection.user?.fullName }}
      </span>
    </div>

    <div class="flex items-center justify-between">
      <div class="flex items-center justify-between gap-1.5 text-text-subtle">
        <IconSprite icon="annotators" />
        <p class="text-sm-12px-light">Shared</p>
      </div>

      <Menu.Root
        v-slot="{ getTriggerProps }"
        close-on-select
      >
        <IconButton
          aria-label="Open integration menu"
          icon="more-dots"
          variant="transparent"
          size="md"
          v-bind="getTriggerProps()"
          @click.prevent
        />
        <Menu.Content>
          <Menu.Item
            icon="trash"
            label="Delete"
            critical
            class="min-w-28 text-left"
            @select="$emit('remove')"
          />
        </Menu.Content>
      </Menu.Root>
    </div>
  </div>
</template>
