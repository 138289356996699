<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div class="case-markdown flex min-w-0 shrink grow">
    <slot />
  </div>
  <!-- eslint-enable tailwindcss/no-custom-classname -->
</template>

<style lang="scss" scoped>
.case-markdown :deep(.ProseMirror) {
  > * {
    @apply my-5 leading-5;

    &:first-child {
      @apply mt-0;
    }

    &:last-child {
      @apply mb-0;
    }
  }

  > p,
  > blockquote > p,
  > ol > li > p,
  > ul > li > p {
    @apply text-md-13px-light;

    > strong {
      @apply font-semibold;
    }
  }

  > * > code {
    @apply text-code-sm-default;
  }

  > pre {
    @apply max-w-full overflow-x-auto;
  }

  a {
    @apply text-md-13px-light text-text-subtle decoration-text-subtle transition-colors;
  }

  a:hover {
    @apply text-text decoration-text;
  }
}
</style>
