import { ref, type Ref } from 'vue'
import { useCaseStore } from './useCaseStore'

import { invariant } from '@/shared/utils/typeAssertions'
import { isFullCase } from './types'
import { useCaseUploadStore } from './useCaseUploadStore'
import { getMessageAttachments } from './utils'

/**
 * Handles entering and leaving edit mode for a query.
 */
export const useCaseQueryEdit = (
  messageText: Ref<string>,
  selectedAgentId: Ref<string | undefined>,
) => {
  const caseStore = useCaseStore()
  const uploadStore = useCaseUploadStore()
  const editedMessageId = ref<string | null>(null)

  /**
   * A list of upload store ids, that are part of the original query and already uploaded.
   * We need to remove them when leaving edit mode. Since they are generated when entering edit mode,
   * we need to keep them in the scope of the function.
   */
  let editedUploadIds: string[] = []

  const cancelEdit = () => {
    invariant(isFullCase(caseStore.activeCase), 'Case is not ready')
    editedMessageId.value = null

    editedUploadIds.forEach((id) => uploadStore.removeUpload(id))
    editedUploadIds = []
  }

  const editQuery = (queryId: string) => {
    invariant(isFullCase(caseStore.activeCase), 'Case is not ready')

    const message = caseStore.activeCase.messages.find((m) => m.id === queryId)
    invariant(message?.type === 'query', 'Message is not a query')

    const attachments = getMessageAttachments(message, caseStore.activeCase)
    editedUploadIds = uploadStore.addDoneUploads(attachments)

    editedMessageId.value = message.id

    messageText.value = message.text
    selectedAgentId.value = message.agentId ?? undefined
  }

  return {
    editedMessageId,
    cancelEdit,
    editQuery,
  }
}
