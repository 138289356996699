<script setup lang="ts">
import { getProject } from '@/backend/getProject'
import { listEntities } from '@/backend/listEntities'
import type { Field } from '@/modules/Project/Fields/types'
import { TYPE_ICON } from '@/modules/Project/icons'
import type { CollectionProperty, Property } from '@/modules/Project/Properties/types'
import { serializeField, serializeProperty } from '@/modules/Project/useProject'
import { useResolveProjectRoute } from '@/modules/Project/useResolveProjectRoute'
import { useSerializeFieldToText } from '@/modules/Project/useSerializeFieldToText'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import IconSprite from '@/uiKit/IconSprite.vue'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import type { SidebarEmits } from '../../field-types'

const props = defineProps<{
  field: Field<'collection'>
  property: CollectionProperty
}>()

defineEmits<SidebarEmits<'collection'>>()

const MAX_ENTITY_NUMBER = 10 // Reduced from 50 to fit better in sidebar

const serializeFieldToText = useSerializeFieldToText()
const { workspaceId } = useRouteParams()

const properties = ref<Property[]>([])
const data = ref<Array<{ id: string; data: Record<string, string> }>>([])
const isLoading = ref(false)
const error = ref<string | null>(null)

// Watch for property changes to load child project properties
watch(
  () => props.property,
  async (property) => {
    isLoading.value = true
    error.value = null
    try {
      const projectRes = await getProject(
        workspaceId.value,
        property.config.subprojectConfig.child_project_id,
      )
      if (!projectRes.ok) {
        error.value = 'Failed to load project properties'
        return
      }

      properties.value = projectRes.data.properties.map(serializeProperty)
    } catch (e) {
      error.value = 'Error loading project'
      throw e
    } finally {
      isLoading.value = false
    }
  },
  { immediate: true },
)

// Watch for entity changes to load collection data
watch(
  () => props.field.entityId,
  async (parentEntityId) => {
    if (!parentEntityId) {
      return
    }

    isLoading.value = true
    error.value = null
    try {
      const entitiesResult = await listEntities(
        workspaceId.value,
        props.property.config.subprojectConfig.child_project_id,
        0,
        MAX_ENTITY_NUMBER,
        parentEntityId,
      )
      if (!entitiesResult.ok) {
        error.value = 'Failed to load collection items'
        return
      }

      data.value = entitiesResult.data.data.map((d) => ({
        id: d.id,
        data: Object.fromEntries(
          Object.values(d.fields).map((f) => {
            const field = serializeField(f)
            return [f.property_id, serializeFieldToText(field)]
          }),
        ),
      }))
    } catch (e) {
      error.value = 'Error loading collection items'
      throw e
    } finally {
      isLoading.value = false
    }
  },
  { immediate: true },
)

const projectRoute = useResolveProjectRoute()
const router = useRouter()
const openTableView = () => {
  const route = projectRoute({
    projectId: props.property.config.subprojectConfig.child_project_id,
    workspaceId: workspaceId.value,
  })

  router.push(route)
}
</script>

<template>
  <div
    v-if="isLoading"
    class="flex h-7 items-center justify-center text-sm-12px-light text-text-subtle"
  >
    Loading...
  </div>
  <div
    v-else-if="error"
    class="flex h-7 items-center justify-center text-sm-12px-light text-text-critical"
  >
    {{ error }}
  </div>
  <div
    v-else-if="data.length === 0 || properties.length === 0"
    class="flex h-7 items-center justify-center text-sm-12px-light text-text-subtle"
  >
    No items in collection
  </div>
  <div
    v-else
    class="go-scrollbar max-h-[400px] min-h-0 min-w-0 max-w-full shrink grow-0 overflow-auto rounded-corner-4 border border-border-subtle bg-surface-primary"
  >
    <table class="min-w-0 max-w-full rounded-corner-4">
      <thead
        class="cursor-pointer"
        aria-role="rowgroup"
        aria-roledescription="Table header"
        @click="openTableView"
      >
        <tr class="hover:bg-background-transparent-hovered">
          <th
            v-for="prop in properties"
            :key="prop.id"
            class="border-b border-r border-border-subtle py-1.5 pl-1 pr-1.5 last:border-r-0"
          >
            <div class="flex h-6 max-w-60 flex-row items-center gap-0.5 p-0.5">
              <div class="flex size-5 items-center justify-center">
                <IconSprite
                  size="md"
                  :icon="TYPE_ICON[prop.type]"
                  class="fill-icon-subtlest text-icon-subtlest"
                />
              </div>
              <span class="truncate text-sm-12px-light text-text-subtle">{{ prop.name }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody
        aria-role="rowgroup"
        aria-roledescription="Table body"
      >
        <tr
          v-for="item in data"
          :key="item.id"
          aria-role="row"
          class="overflow-hidden border-b border-border-subtle first:border-t last:border-b-0 hover:bg-background-transparent-hovered"
        >
          <td
            v-for="prop in properties"
            :key="`${item.id}-${prop.id}`"
            class="border-b border-r border-border-subtle last:border-r-0"
          >
            <div class="flex max-w-60 flex-row items-center py-2 pl-3 pr-0">
              <span class="truncate text-sm-12px-light text-text">{{ item.data[prop.id] }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
