<script setup lang="ts">
import FileTableHeaderCellContent from '@/modules/Workspaces/KnowledgeHub/Files/FileTableHeaderCellContent.vue'
import FileTableRow from '@/modules/Workspaces/KnowledgeHub/Files/FileTableRow.vue'
import FileTableRowGrid from '@/modules/Workspaces/KnowledgeHub/Files/FileTableRowGrid.vue'
import type {
  SortableColumn,
  TableSorting,
} from '@/modules/Workspaces/KnowledgeHub/Files/library-item'

const sort = defineModel<TableSorting>('sort', { required: true })

function getDirection(field: SortableColumn) {
  if (sort.value && sort.value[0] === field) {
    return sort.value[1]
  }
  return null
}

function toggleDirection(col: SortableColumn) {
  const nextDirection = getDirection(col) === 'asc' ? 'desc' : 'asc'
  sort.value = [col, nextDirection]
}
</script>

<template>
  <FileTableRow>
    <template #grid>
      <FileTableRowGrid>
        <template #filename>
          <FileTableHeaderCellContent
            :sort="getDirection('filename')"
            @toggle="toggleDirection('filename')"
          >
            Name
          </FileTableHeaderCellContent>
        </template>

        <template #fileType>
          <FileTableHeaderCellContent
            :sort="getDirection('fileType')"
            @toggle="toggleDirection('fileType')"
          >
            Type
          </FileTableHeaderCellContent>
        </template>

        <template #createdAt>
          <FileTableHeaderCellContent
            :sort="getDirection('createdAt')"
            @toggle="toggleDirection('createdAt')"
          >
            Added
          </FileTableHeaderCellContent>
        </template>

        <template #updatedBy>
          <FileTableHeaderCellContent
            :sort="getDirection('updatedBy')"
            @toggle="toggleDirection('updatedBy')"
          >
            Owner
          </FileTableHeaderCellContent>
        </template>
      </FileTableRowGrid>
    </template>
  </FileTableRow>
</template>
