<script setup lang="ts">
import { useProjectNavigationContext } from '@/modules/Project/Navigation/projectNavigationContext'
import { useProjects } from '@/modules/Projects/useProjects'
import { whenever } from '@vueuse/core'
import { ref } from 'vue'
/*
 * This is a component that makes it easier to debug breadcrumbs and navigation
 */

const projectId = ref<string>('')

const projectsStore = useProjects()
const navContext = useProjectNavigationContext({ required: true })

whenever(
  () => navContext.breadcrumbs.value.at(-1),
  (crumb) => {
    if (!crumb.entityId) {
      projectId.value = crumb.projectId || ''
    }
  },
)

function onSelectProject() {
  navContext.navigate({ projectId: projectId.value })
}
</script>

<template>
  <select
    v-model="projectId"
    class="rounded-lg border border-border-subtle bg-background-transparent px-2 py-1 text-sm-12px-default text-text-subtle"
    @change="onSelectProject"
  >
    <option
      disabled
      selected
      value
    >
      Choose a project
    </option>
    <option
      v-for="project in projectsStore.projects"
      :key="project.id"
      :value="project.id"
    >
      {{ project.name || 'Unnamed project' }}
    </option>
  </select>
</template>
