<script setup lang="ts">
import {
  useWorkspaceMembers,
  type WorkspaceMember,
} from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { omit } from '@/shared/utils'
import { useDashboardFilterStore } from '@/sharedComposables/useDashboardFilterStore'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import CheckBox from '@/uiKit/CheckBox.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import RadioButton from '@/uiKit/RadioButton.vue'
import { ref, useTemplateRef, type UnwrapRef } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const dashboardFilterStore = useDashboardFilterStore()
const workspaceMembersStore = useWorkspaceMembers()

const menuRef = useTemplateRef('menuRef')
const ownerSubMenuRef = useTemplateRef('ownerSubMenuRef')
const sortSubMenuRef = useTemplateRef('sortSubMenuRef')
const selectedOwners = ref<Set<WorkspaceMember['id']>>(new Set())
const sortLabel = ref<string>('Name')
const sortIdx = ref(0)

const filteredOptions = ref<UnwrapRef<typeof workspaceMembersStore.workspaceMembers>>([])

const isSearchMatch = (item: WorkspaceMember, search: string) =>
  item.fullName?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
  item.firstName?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
  item.lastName?.toLocaleLowerCase().includes(search.toLocaleLowerCase())

const onFilterOwner = (id: string) => {
  if (selectedOwners.value.has(id)) {
    selectedOwners.value.delete(id)
  } else {
    selectedOwners.value.add(id)
  }

  dashboardFilterStore.setCaseOwnerIds(Array.from(selectedOwners.value))
}
</script>

<template>
  <Menu.Root
    ref="menuRef"
    v-slot="{ getTriggerProps, getTriggerItemProps }"
  >
    <div class="relative">
      <DarwinButton
        class="flex w-12"
        variant="outline"
        size="sm"
        rounded
        aria-label="Filter cases"
        v-bind="{ ...omit(getTriggerProps(), ['onSelect', 'disabled']), ...$attrs }"
        @click.prevent
      >
        <IconSprite icon="filter" />
      </DarwinButton>
      <span
        v-if="selectedOwners.size > 0"
        class="absolute right-0 top-0 size-2 rounded-full border border-surface-primary bg-background-primary"
      />
    </div>
    <Menu.Content class="min-w-56">
      <Menu.GroupTitle>
        <div>Owners</div>
      </Menu.GroupTitle>
      <Menu.Root
        v-slot="{ menu: subMenu }"
        ref="ownerSubMenuRef"
        :positioning="{
          placement: 'right-start',
          offset: {
            mainAxis: 2,
            crossAxis: -2,
          },
        }"
      >
        <Menu.Item
          v-bind="ownerSubMenuRef ? getTriggerItemProps(ownerSubMenuRef!.menu) : {}"
          label="All owners"
          class="cursor-pointer"
          @select="subMenu.setOpen(true)"
          @mouseenter="subMenu.setOpen(true)"
        >
          <template #suffix>
            <IconSprite
              class="text-icon-subtlest"
              icon="chevron-right"
            />
          </template>
        </Menu.Item>
        <Menu.Content>
          <Menu.Search
            :items="workspaceMembersStore.workspaceMembers"
            :key-or-predicate="isSearchMatch"
            @change="filteredOptions = $event"
          />
          <Menu.Item
            v-for="user in filteredOptions"
            :key="user.id"
            @select="onFilterOwner(user.id)"
          >
            <template #prefix>
              <CheckBox :checked="selectedOwners.has(user.id)" />
            </template>
            <div class="flex w-full grow items-center justify-start gap-2">
              <AvatarIcon
                :full-text="user?.fullName || user?.email || ''"
                shape="circle"
                size="xs"
              />
              <span class="text-sm-12px-default text-text">
                {{ user.firstName }} {{ user.lastName }}
              </span>
            </div>
          </Menu.Item>
        </Menu.Content>
      </Menu.Root>
      <Menu.Divider />
      <Menu.GroupTitle>
        <div>Sort</div>
      </Menu.GroupTitle>
      <Menu.Root
        v-slot="{ menu: subMenu }"
        ref="sortSubMenuRef"
        :positioning="{
          placement: 'right-start',
          offset: {
            mainAxis: 2,
            crossAxis: -2,
          },
        }"
      >
        <Menu.Item
          v-bind="sortSubMenuRef ? getTriggerItemProps(sortSubMenuRef!.menu) : {}"
          :label="sortLabel"
          class="cursor-pointer"
          @select="subMenu.setOpen(true)"
          @mouseenter="subMenu.setOpen(true)"
        >
          <template #suffix>
            <IconSprite
              class="text-icon-subtlest"
              icon="chevron-right"
            />
          </template>
        </Menu.Item>
        <Menu.Content>
          <Menu.Item
            @select="
              () => {
                sortIdx = 0
                sortLabel = 'Name'
                dashboardFilterStore.setCaseSortValue('name')
              }
            "
          >
            <template #prefix>
              <RadioButton :checked="sortIdx === 0" />
            </template>
            <div class="flex w-full grow items-center justify-start gap-2">
              <span class="text-sm-12px-default text-text"> Name </span>
            </div>
          </Menu.Item>
          <Menu.Item
            @select="
              () => {
                sortIdx = 1
                sortLabel = 'Last interacted with'
                dashboardFilterStore.setCaseSortValue('updatedAt')
              }
            "
          >
            <template #prefix>
              <RadioButton :checked="sortIdx === 1" />
            </template>
            <div class="flex w-full grow items-center justify-start gap-2">
              <span class="text-sm-12px-default text-text"> Last interacted with </span>
            </div>
          </Menu.Item>
          <Menu.Item
            @select="
              () => {
                sortIdx = 2
                sortLabel = 'Last created'
                dashboardFilterStore.setCaseSortValue('createdAt')
              }
            "
          >
            <template #prefix>
              <RadioButton :checked="sortIdx === 2" />
            </template>
            <div class="flex w-full grow items-center justify-start gap-2">
              <span class="text-sm-12px-default text-text"> Last created </span>
            </div>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item @select="dashboardFilterStore.setCaseSortDirection('asc')">
            <template #prefix>
              <RadioButton :checked="dashboardFilterStore.caseSortDirection === 'asc'" />
            </template>
            <div class="flex w-full grow items-center justify-start gap-2">
              <span class="text-sm-12px-default text-text"> Ascending </span>
            </div>
          </Menu.Item>
          <Menu.Item @select="dashboardFilterStore.setCaseSortDirection('desc')">
            <template #prefix>
              <RadioButton :checked="dashboardFilterStore.caseSortDirection === 'desc'" />
            </template>
            <div class="flex w-full grow items-center justify-start gap-2">
              <span class="text-sm-12px-default text-text"> Descending </span>
            </div>
          </Menu.Item>
        </Menu.Content>
      </Menu.Root>
    </Menu.Content>
  </Menu.Root>
</template>
