import { cloneProject } from '@/backend/cloneProject'
import { createProject } from '@/backend/createProject'
import type { ProjectResponse } from '@/backend/types'
import { updateProject } from '@/backend/updateProject'
import { useRefreshUsage } from '@/modules/Billing/useRefreshUsage'
import { serializeProperty } from '@/modules/Project/useProject'
import { useProjects } from '@/modules/Projects/useProjects'
import { toast } from '@/shared/toast'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import * as sentry from '@sentry/vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { useTemplates } from './useTemplates'

/*
 * This composable can be used to create a new project.
 * It uses the `createProject` be api to create a new project and then adds it to the projects store.
 * It also captures an analytics event when a new project is created.
 * @param workspaceId - The id of the workspace in which the project is to be created.
 * @returns - id of the newly created project or null (when the creation failed)
 */
export function useCreateProject() {
  const projectsStore = useProjects()
  const templateStore = useTemplates()
  const { captureAnalyticsEvent } = useAnalytics()
  const { refreshUsageNTimes } = useRefreshUsage()

  const emptyTemplateUuid = useFeatureFlags(FeatureFlag.EMPTY_TEMPLATE_UUID)

  const createNewProject = async (
    workspaceId: string,
    payload: { name: string | null; description: string | null } = {
      name: null,
      description: null,
    },
  ): Promise<{ ok: true; data: ProjectResponse } | { ok: false }> => {
    // "Create new project" should usually just clone the empty template
    const result = templateStore.emptyTemplate
      ? await cloneProject({
          name: payload.name,
          workspaceId,
          templateId: templateStore.emptyTemplate.id,
        })
      : await createProject(workspaceId, payload)

    if (!templateStore.emptyTemplate) {
      // There should always be an empty template, but I've seen some session
      // replays where there isn't. Capture more information in these cases.
      sentry.captureException('Empty template not found', {
        extra: {
          emptyTemplateUuid: emptyTemplateUuid.value,
          templateUuids: templateStore.templates.map((t) => t.id),
        },
      })
    }

    if (!result.ok) {
      toast.error('There was an error when creating the project')
      return { ok: false }
    }

    if (payload.description) {
      await updateProject(workspaceId, result.data.id, {
        name: result.data.name,
        description: payload.description,
      })
    }

    captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_CREATED)

    projectsStore.addProject({
      id: result.data.id,
      name: result.data.name,
      workspaceId: workspaceId,
      updatedAt: result.data.updated_at,
      propertyCount: 0,
      coverImageUrls: result.data.cover_image_urls,
      properties: result.data.properties.map(serializeProperty),
      description: result.data.description,
    })

    refreshUsageNTimes(3)

    return result
  }

  return { createNewProject }
}
