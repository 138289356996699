import { defineStore } from 'pinia'
import { ref } from 'vue'

type PendingQuery = {
  id: string
  text: string
  agentId: string | undefined
  uploadIds: string[]
  createdAt: string
  updatedAt: string
  type: 'create' | 'edit'
}

export const useCasePendingQueryStore = defineStore('casePendingQueries', () => {
  const pendingQueries = ref<PendingQuery[]>([])

  const addPendingQuery = (
    payload:
      | {
          text: string
          agentId: string | undefined
          uploadIds: string[]
          type: 'create'
        }
      | {
          text: string
          agentId: string | undefined
          uploadIds: string[]
          type: 'edit'
          messageId: string
        },
  ) => {
    const now = new Date().toISOString()
    const { text, agentId, uploadIds, type } = payload
    const id = payload.type === 'edit' ? payload.messageId : window.crypto.randomUUID()
    pendingQueries.value.push({
      id,
      text,
      agentId,
      uploadIds,
      createdAt: now,
      updatedAt: now,
      type,
    })
  }

  return { pendingQueries, addPendingQuery }
})
