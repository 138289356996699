import { getCase } from '@/backend/getCase'
import { getEntity } from '@/backend/getEntity'
import { getProject } from '@/backend/getProject'
import { listCases } from '@/backend/listCases'
import { toast } from '@/shared/toast'
import { unique } from '@/shared/utils/array'
import { defineStore } from 'pinia'
import { computed, ref, type ComputedRef, type Ref } from 'vue'
import { serializeEntity, type Entity } from '../Project/useProject'
import { serializeProject, type Project } from '../Projects/useProjects'
import { serializeCase, serializePaginatedCase } from './serializers'
import { isFullCase, type Case, type CaseOutput, type CaseSource, type PartialCase } from './types'

// Without an explicit return type, we get TS errors in the IDE because the return type
// is longer than what the compiler will serialize.
type StoreReturn = {
  cases: Ref<(PartialCase | Case)[]>
  loadingState: Ref<'idle' | 'loading' | 'loaded' | 'errored'>
  hasMoreCases: Ref<boolean>
  activeCase: ComputedRef<PartialCase | Case | null>
  activeCaseHasOutputs: ComputedRef<boolean>
  activeCaseOutputs: ComputedRef<CaseOutput[] | null>
  activeCaseSources: ComputedRef<CaseSource[] | null>
  getCaseById: (id: string) => Case | PartialCase | undefined
  setCase: (c: Case | PartialCase) => () => void
  setActiveCase: ({ workspaceId, caseId }: { workspaceId: string; caseId: string }) => void
  addOrUpdateMessageToActiveCase: (message: Case['messages'][number]) => () => void
  loadCases: (workspaceId: string) => ReturnType<typeof listCases>
  removeCase: (id: string) => () => void
  setCases: (value: (PartialCase | Case)[]) => () => void
  addCases: (value: (PartialCase | Case)[]) => () => void
  activeCaseId: Ref<string | null>
  latestOutput: ComputedRef<CaseOutput | undefined>
  setLoadingState: (state: 'idle' | 'loading' | 'loaded' | 'errored') => void
  showSidebar: Ref<boolean>
}

/**
 * This store holds all state relating to cases, including:
 * - All cases in the current workspace
 * - The currently active case
 */
export const useCaseStore = defineStore<'case', StoreReturn>('case', () => {
  const loadingState = ref<'idle' | 'loading' | 'loaded' | 'errored'>('idle')
  const cases = ref<Array<PartialCase | Case>>([])
  const hasMoreCases = ref(false)
  const activeCaseId = ref<string | null>(null)
  const showSidebar = ref(false)

  const caseIds = computed(() => new Set(cases.value.map((c) => c.id)))

  const activeCase = computed(() => cases.value.find((c) => c.id === activeCaseId.value) || null)

  const activeCaseSources = computed(() =>
    isFullCase(activeCase.value) ? activeCase.value.sources : null,
  )

  const activeCaseOutputs = computed(() =>
    isFullCase(activeCase.value) ? activeCase.value.outputs : null,
  )

  const activeCaseHasOutputs = computed(
    () => !!activeCaseOutputs.value && activeCaseOutputs.value.length > 0,
  )

  const loadCases = async (workspaceId: string) => {
    const res = await listCases({ workspaceId, first: 100 })
    if (res.ok) {
      cases.value = res.data.data.reduce<Array<PartialCase | Case>>((acc, c) => {
        if (c.id === activeCase.value?.id) {
          /**
           * Avoid a race condition when directly loading the /cases/:caseId page.
           * Don't replace the active (full) case with the paginated case.
           */
          acc.push(activeCase.value)
        } else {
          acc.push(serializePaginatedCase(c))
        }

        return acc
      }, [])
      hasMoreCases.value = !!res.data.metadata.has_next_page
    }

    return res
  }

  const getCaseById = (id: string) => cases.value.find((c) => c.id === id)

  const setCase = (c: Case | PartialCase) => {
    const index = cases.value.findIndex(({ id }) => c.id === id)
    if (index === -1) {
      cases.value.unshift(c)
      return () => {
        cases.value = cases.value.filter(({ id }) => c.id !== id)
      }
    } else {
      const oldCase = { ...cases.value[index] }

      /**
       * Preserve any keys from the old case that are not present in the
       * new case. This is because we may have received a partial case
       * through a websocket update (no messages or outputs) for a case
       * that we currently have messages and outputs for.
       */
      cases.value[index] = { ...oldCase, ...c }
      return () => {
        cases.value[index] = oldCase
      }
    }
  }

  const setActiveCase = async ({
    workspaceId,
    caseId,
  }: {
    workspaceId: string
    caseId: string
  }) => {
    const oldActiveCaseId = activeCaseId.value
    activeCaseId.value = caseId

    const res = await getCase({ workspaceId, caseId })
    if (!res.ok) {
      activeCaseId.value = null
      toast.error('Failed to load case, contact support if this error persists.')
      activeCaseId.value = oldActiveCaseId
      return
    }

    const projectIds = unique(res.data.outputs.map((output) => output.project_id))
    const projectPromises = projectIds.map((projectId) => getProject(workspaceId, projectId))
    const entityPromises = res.data.outputs.map((output) =>
      getEntity(workspaceId, output.project_id, output.entity_id),
    )
    const projects: Project[] = []
    const entities: Entity[] = []
    const responses = await Promise.all([...projectPromises, ...entityPromises])
    responses.forEach((response) => {
      if (response.ok) {
        if ('fields' in response.data) {
          entities.push(serializeEntity(response.data))
        } else {
          projects.push(serializeProject(response.data))
        }
      }
    })

    const newCase = serializeCase({ caseResponse: res.data, projects, entities })
    setCase(newCase)
  }

  const setCases = (value: (PartialCase | Case)[]) => {
    const oldCases = [...cases.value]

    cases.value = value

    return () => {
      cases.value = oldCases
    }
  }

  const addCases = (value: (PartialCase | Case)[]) => {
    const oldCases = [...cases.value]

    value.forEach((newCase: PartialCase | Case) => {
      if (caseIds.value.has(newCase.id)) {
        return
      }

      cases.value.push(newCase)
    })

    return () => {
      cases.value = oldCases
    }
  }

  const addOrUpdateMessageToActiveCase = (message: Case['messages'][number]) => {
    const oldActiveCase = activeCase.value
    const index = cases.value.findIndex(({ id }) => activeCaseId.value === id)
    if (index === -1) return () => {}
    if (!isFullCase(cases.value[index])) return () => {}

    const existingMessageIndex = cases.value[index].messages.findIndex((m) => m.id === message.id)
    const caseWithNewMessage = {
      ...cases.value[index],
      messages:
        existingMessageIndex >= 0
          ? cases.value[index].messages.map((m, i) => (i === existingMessageIndex ? message : m))
          : [...cases.value[index].messages, message],
    }
    cases.value[index] = caseWithNewMessage

    return () => {
      if (!oldActiveCase) return
      cases.value[index] = oldActiveCase
    }
  }

  const removeCase = (id: string) => {
    const oldCaseIndex = cases.value.findIndex((c) => c.id === id)
    if (oldCaseIndex > -1) {
      cases.value.splice(oldCaseIndex, 1)

      if (activeCaseId.value === id) {
        activeCaseId.value = null
      }
    }

    return () => {
      cases.value.splice(oldCaseIndex, 0, cases.value[oldCaseIndex])
    }
  }

  const $reset = () => {
    cases.value = []
    activeCaseId.value = null
    showSidebar.value = false
  }

  /**
   * Returns the most recent output of an active case
   */
  const latestOutput = computed(() => {
    if (!activeCase.value || !isFullCase(activeCase.value)) {
      return undefined
    }

    const sorted = activeCase.value.outputs.toSorted((a, b) => {
      return b.createdAt.localeCompare(a.createdAt)
    })

    return sorted.at(0)
  })

  const setLoadingState = (state: 'idle' | 'loading' | 'loaded' | 'errored') => {
    loadingState.value = state
  }

  return {
    $reset,
    activeCase,
    activeCaseHasOutputs,
    activeCaseId,
    activeCaseOutputs,
    activeCaseSources,
    addCases,
    addOrUpdateMessageToActiveCase,
    cases,
    getCaseById,
    hasMoreCases,
    latestOutput,
    loadCases,
    loadingState,
    removeCase,
    setActiveCase,
    setCase,
    setCases,
    setLoadingState,
    showSidebar,
  }
})
