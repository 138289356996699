<script setup lang="ts">
import { deleteConnection } from '@/backend/deleteConnection'
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import { gmailIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/Gmail/gmailIntegration'
import { googleDriveIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/googleDriveIntegration'
import IntegrationCard from '@/modules/Workspaces/KnowledgeHub/Integrations/IntegrationCard.vue'
import IntegrationConnectionRow from '@/modules/Workspaces/KnowledgeHub/Integrations/IntegrationConnectionRow.vue'
import { integrations } from '@/modules/Workspaces/KnowledgeHub/Integrations/integrations'
import type {
  Integration,
  PreviewIntegration,
} from '@/modules/Workspaces/KnowledgeHub/Integrations/integrationTypes'
import { oneDriveIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/OneDrive/oneDriveIntegration'
import { outlookIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/Outlook/outlookIntegration'
import { sharepointIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/SharePoint/sharepointIntegration'
import { useLazyIntegrationDialog } from '@/modules/Workspaces/KnowledgeHub/Integrations/useLazyIntegrationDialog'
import PageContainer from '@/modules/Workspaces/KnowledgeHub/Layout/PageContainer.vue'
import PageTitle from '@/modules/Workspaces/KnowledgeHub/Layout/PageTitle.vue'
import PageTitleBar from '@/modules/Workspaces/KnowledgeHub/Layout/PageTitleBar.vue'
import { requestedIntegrations } from '@/modules/Workspaces/KnowledgeHub/preferences'
import { useIntegrationPolling } from '@/modules/Workspaces/KnowledgeHub/useIntegrationPolling'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { invariant } from '@/shared/utils/typeAssertions'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed } from 'vue'
import { useIntegrationStore } from './useIntegrationStore'

type CardData = (Integration | PreviewIntegration) & {
  isPreview?: boolean
  /**
   * For real integrations, this is true when there's a connection.
   * For previews, it's true when the integration is requested.
   */
  isActive: boolean
  onClick: () => void
}

const userStore = useUser()
const { workspaceId } = useRouteParams()
const integrationStore = useIntegrationStore()
const workspaceMembersStore = useWorkspaceMembers()
const { isDialogOpen, loadDialog, integrationDialog, onDialogMounted } = useLazyIntegrationDialog()

useIntegrationPolling()

const connections = computed(() => {
  if (workspaceMembersStore.workspaceMembers.length === 0) {
    // Prevent a race condition where the workspace members are not loaded yet
    return []
  }

  return integrationStore.connections.map((c) => {
    const user = workspaceMembersStore.getMember(c.userId)
    const { name, icon } = integrations[c.integrationId]
    invariant(user, 'User not found')
    return { ...c, user, name, icon }
  })
})

/**
 * List of integrations to be displayed on the home page.
 * Manually ordered for fine control.
 */
const integrationCardList = computed<CardData[]>(() => [
  getRealIntegrationCard(googleDriveIntegration),
  getPreviewIntegrationCard(sharepointIntegration),
  getPreviewIntegrationCard(outlookIntegration),
  getPreviewIntegrationCard(gmailIntegration),
  getPreviewIntegrationCard(oneDriveIntegration),
])

function getPreviewIntegrationCard(integration: PreviewIntegration): CardData {
  return {
    ...integration,
    isPreview: true,
    isActive: requestedIntegrations.value.includes(integration.id),
    onClick() {
      requestedIntegrations.value.push(integration.id)
      openTypeform(integration)
    },
  }
}

function openTypeform(integration: PreviewIntegration) {
  const email = userStore.user?.email
  invariant(email)
  const url = new URL('https://vseven.typeform.com/to/yJstqb22')
  url.searchParams.set('integration', integration.name)
  url.searchParams.set('email', email)
  window.open(url, '_blank')
}

function getRealIntegrationCard(integration: Integration): CardData {
  return {
    ...integration,
    isActive: !!integrationStore.findConnection(integration.id),
    onClick: () => loadDialog(integration.asyncComponent),
  }
}

async function removeConnection(c: (typeof integrationStore.connections)[number]) {
  if (!workspaceId.value) return
  await deleteConnection({
    connectionId: c.id,
    integrationId: c.integrationId,
    workspaceId: workspaceId.value,
  })
  integrationStore.load()
}
</script>

<template>
  <PageContainer>
    <PageTitleBar class="mb-10">
      <template #title>
        <PageTitle :count="connections.length">Integrations</PageTitle>
      </template>
    </PageTitleBar>

    <!-- Wait until the first load, we have no loading state yet (will be added soon) -->
    <template v-if="integrationStore.isLoaded">
      <div
        v-if="connections.length"
        class="mb-12"
      >
        <h2 class="mt-10 text-md-13px-bold">Enabled</h2>
        <p class="text-sm-12px-light text-text-subtle">Manage your connected integrations</p>

        <IntegrationConnectionRow
          v-for="connection in connections"
          :key="connection.id"
          :connection="connection"
          class="relative mt-6"
          @remove="removeConnection(connection)"
        />
      </div>

      <div>
        <template v-if="connections.length">
          <h2 class="text-md-13px-bold">Explore more</h2>
          <p class="text-sm-12px-light text-text-subtle">
            Find more integrations to connect to your account
          </p>
        </template>
        <div class="mx-auto mt-6 grid grid-cols-[repeat(auto-fill,minmax(275px,1fr))] gap-1">
          <IntegrationCard
            v-for="integration in integrationCardList"
            :key="integration.id"
            :name="integration.name"
            :description="integration.description ?? ''"
            :is-preview="integration.isPreview || false"
            :is-active="integration.isActive"
            :icon="integration.icon"
            @activate="integration.onClick"
          />
        </div>
      </div>
    </template>

    <component
      :is="integrationDialog"
      v-if="integrationDialog"
      :open="isDialogOpen"
      @close="isDialogOpen = false"
      @vue:mounted="onDialogMounted"
    />
  </PageContainer>
</template>
