import type { PropertyType } from '@/backend/types'
import type { FieldRegistryItem } from './field-types'
import { collectionField } from './registry/collection.ts'
import { fileField } from './registry/file/file.ts'
import { fileCollectionField } from './registry/filecollection/fileCollection.ts'
import { jsonField } from './registry/json.ts'
import { multiSelectField } from './registry/multiSelect.ts'
import { numberField } from './registry/number/number.ts'
import { referenceField } from './registry/reference.ts'
import { singleSelectField } from './registry/singleSelect.ts'
import { textField } from './registry/text.ts'
import { urlField } from './registry/url.ts'
import { userSelectField } from './registry/userselect/userSelect.ts'
import type { Field } from './types'

type FieldRegistry = {
  [K in PropertyType]: FieldRegistryItem<K>
}

export const fieldRegistry: FieldRegistry = {
  single_select: singleSelectField,
  multi_select: multiSelectField,
  collection: collectionField,
  reference: referenceField,
  url: urlField,
  json: jsonField,
  text: textField,
  file: fileField,
  user_select: userSelectField,
  number: numberField,
  file_collection: fileCollectionField,
}

export const getSidebarComponent = <T extends PropertyType>(
  field: Field<T>,
): FieldRegistryItem<T>['sidebar'] => fieldRegistry[field.type].sidebar
