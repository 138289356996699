import { addCaseQuery } from '@/backend/addCaseQuery'
import { editCaseQuery } from '@/backend/editCaseQuery'
import { toast } from '@/shared/toast'
import { invariant } from '@/shared/utils/typeAssertions'
import { computed, watch, type ComputedRef } from 'vue'
import { serializeFile, serializeMessage } from './serializers'
import { isFullCase } from './types'
import { useCasePendingQueryStore } from './useCasePendingQueryStore'
import { useCaseStore } from './useCaseStore'
import { useCaseUploadStore } from './useCaseUploadStore'

type NewType = ComputedRef<string>

export const useQueryDispatcher = ({
  caseId,
  workspaceId,
}: {
  caseId: NewType
  workspaceId: ComputedRef<string>
}) => {
  const pendingStore = useCasePendingQueryStore()
  const uploadStore = useCaseUploadStore()
  const caseStore = useCaseStore()

  const getAreUploadsProcessed = (uploadIds: string[]) => {
    if (!isFullCase(caseStore.activeCase)) {
      return false
    }

    const files = caseStore.activeCase.files

    return uploadIds.every((id) => {
      const file = files.find((f) => f.id === id)
      return file?.status === 'complete'
    })
  }

  const getRelevantUploadsErrors = (uploadIds: string[]) => {
    const relevantUploads = uploadStore.uploads.filter((u) => uploadIds.includes(u.id))

    if (relevantUploads.some((u) => u.status === 'error')) {
      return 'Some of your attachments failed to upload. Please remove them and try again.'
    }
  }

  const nextToSend = computed(() => {
    const nextPending = pendingStore.pendingQueries.at(0)
    if (!nextPending) {
      return null
    }

    const allFinishedUplodding = nextPending.uploadIds.every((id) => {
      const upload = uploadStore.uploads.find((u) => u.id === id)
      return (
        upload?.status === 'done' ||
        upload?.status === 'library-uploaded' ||
        upload?.status === 'integration-uploaded'
      )
    })

    if (!allFinishedUplodding) {
      return null
    }

    const fileIds = uploadStore.getFileIds(nextPending.uploadIds)

    if (!getAreUploadsProcessed(fileIds)) {
      return null
    }

    return nextPending
  })

  // automatically dispatch next pending query
  watch(
    nextToSend,
    async (pendingQuery) => {
      if (!pendingQuery) {
        return
      }

      const error = getRelevantUploadsErrors(pendingQuery.uploadIds)
      if (error) {
        toast.error(error)
        return
      }

      const fileIds = uploadStore.getFileIds(pendingQuery.uploadIds)
      const text = pendingQuery.text
      const agentId = pendingQuery.agentId

      const match = caseStore.getCaseById(caseId.value)
      invariant(match && isFullCase(match), 'Case not found')

      const res =
        pendingQuery.type === 'create'
          ? await addCaseQuery({
              workspaceId: workspaceId.value,
              caseId: caseId.value,
              fileIds,
              text,
              agentId: agentId ?? null,
            })
          : await editCaseQuery({
              workspaceId: workspaceId.value,
              caseId: caseId.value,
              fileIds,
              text,
              agentId: agentId ?? null,
              messageId: pendingQuery.id,
            })

      if (!res.ok) {
        toast.error('Failed to send message.')
      } else {
        pendingQuery.uploadIds.forEach((id) => uploadStore.removeUpload(id))
        pendingStore.pendingQueries.shift()
        caseStore.setCase({
          ...match,
          messages: res.data.messages.map(serializeMessage),
          files: res.data.files.map(serializeFile),
        })
      }
    },
    { immediate: true },
  )
}
