import { setFieldValue } from '@/backend/setFieldValue'
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import { useLibraryStore, type LibraryFile } from '@/modules/Library/libraryStore'
import { serializeEntity, type Entity } from '@/modules/Project/useProject'
import type { IntegrationId } from '@/modules/Workspaces/KnowledgeHub/Integrations/integrationTypes'
import { useIntegrationStore } from '@/modules/Workspaces/KnowledgeHub/useIntegrationStore'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { invariant } from '@/shared/utils/typeAssertions'
import { useLibraryBackend } from './useLibraryBackend'

export function useUploadLibraryUrl() {
  return { uploadIntegrationFile }
}

/**
 * Upload a file from an integration to the library
 */
async function uploadIntegrationFile(props: {
  integrationId: IntegrationId
  file: { id: string; name: string }
}): Promise<Entity> {
  const libraryBackend = useLibraryBackend()
  const libraryStore = useLibraryStore()
  const workspace = useCurrentWorkspace()
  const integrationStore = useIntegrationStore()

  const workspaceId = workspace.value.id

  const connection = integrationStore.findConnection(props.integrationId)
  invariant(connection)

  const library = libraryStore.library
  invariant(library)

  const optimisticFile = getOptimisticFile({ name: props.file.name })
  libraryStore.setItem(optimisticFile)

  try {
    const entityId = await libraryBackend.getLibraryItemToCreateId({
      libraryId: library.id,
      workspaceId,
    })

    optimisticFile.id = entityId

    const response = await setFieldValue({
      entityId,
      workspaceId,
      projectId: library.id,
      fields: {
        [library.fileProperty.id]: {
          connection_id: connection.id,
          integration_id: connection.integrationId,
          file_id: props.file.id,
          file_name: props.file.name,
        },
      },
    })

    if (!response.ok) {
      throw new Error('Failed to upload file')
    }
    return serializeEntity(response.data)
  } catch {
    libraryStore.deleteItem(optimisticFile.id)
    throw new Error('Failed to upload file')
  }
}

/** Create an optimistic file object that will be used to upload a file to the library */
function getOptimisticFile(props: { name: string }): LibraryFile {
  const userStore = useUser()
  invariant(userStore.user)

  return {
    id: crypto.randomUUID(), // can't useId() outside of setup() block
    fileUrl: null,
    filename: props.name,
    name: props.name,
    updatedAt: new Date().toISOString(),
    status: 'uploading',
    type: 'file',
    inputReference: [],
    createdAt: new Date().toISOString(),
    updatedBy: userStore.user.id || '',
  }
}
