<script setup lang="ts">
import type { OcrPage } from '@/backend/types'
import { clamp } from '@/shared/utils/number'
import ClaimIndicator from '@/sharedComponents/ClaimIndicator.ce.vue'
import { useNaturalZoom } from '@/sharedComposables/useNaturalZoom'
import { useDebounceFn, useElementSize } from '@vueuse/core'
import { computed, nextTick, ref, watch } from 'vue'
import ScaleButtons from './ScaleButtons.vue'
import { useGroundingPolygons } from './useGroundingPolygons'
import { useGroundingStore, type Source } from './useGroundingStore'

const props = defineProps<{
  sources: Source[]
  imagePath: string
  ocrPage: OcrPage
  propertyId?: string
}>()

const originalImageWidth = computed(() => props.ocrPage.width)
const storageKey = computed(
  () => `grounding-image-scale-${props.imagePath.slice(0, 300).replace(/[\s/:]/g, '-')}`,
)
const { setScale, scale, scrollContainer, scrollContainerChild, onZoom } = useNaturalZoom({
  storageKey,
  originalWidth: originalImageWidth,
})
const { height: containerHeight, width: containerWidth } = useElementSize(scrollContainer)
const { height: imageHeight, width: imageWidth } = useElementSize(scrollContainerChild)

const actualScale = computed(() => {
  if (typeof scale.value === 'number') return scale.value

  // If dimensions are missing, fallback to 100%
  if (
    !containerWidth.value ||
    !containerHeight.value ||
    !imageWidth.value ||
    !imageHeight.value ||
    !scrollContainer.value
  ) {
    return 1
  }

  const styleScale = Number(getComputedStyle(scrollContainer.value).scale)
  const currentScale = Number.isNaN(styleScale) ? 1 : styleScale

  const actualWidth = imageWidth.value / currentScale
  const actualHeight = imageHeight.value / currentScale

  return Math.min(containerWidth.value / actualWidth, containerHeight.value / actualHeight)
})

const translate = ref({ x: 0, y: 0 })
// Simulate scrolling
function onScroll(e: WheelEvent) {
  const isX = e.shiftKey
  const isPos = e.deltaY > 0
  const inc = 50 * (isPos ? -1 : 1)

  if (isX) {
    translate.value.x += inc
  } else {
    translate.value.y += inc
  }
}

/** Position of the claim indicator that appears to the top left of a source */
const getClaimIndicatorStyles = (
  bbox: Source['boundingBoxes'][number],
): Pick<CSSStyleDeclaration, 'left' | 'top'> => {
  const { height, width } = props.ocrPage

  const PADDING_PX = 2

  return {
    left: `${(bbox.xmin * imageWidth.value) / width - PADDING_PX}px`,
    top: `${(bbox.ymin * imageHeight.value) / height - PADDING_PX}px`,
  }
}

const zooming = ref(false)
const resetZoom = useDebounceFn(() => {
  zooming.value = false
}, 300)
function zoom() {
  zooming.value = true
  resetZoom()
}

const groundingStore = useGroundingStore()
const selectedSourceId = computed(() => groundingStore.selectedClaimsAndSource?.sourceId)

const scrollToSourceId = async (sourceId: number) => {
  await nextTick()
  if (!scrollContainer.value || !scrollContainerChild.value) return

  const source = props.sources.find((s) => s.id === sourceId)
  const page = source?.boundingBoxes[0].page
  const sourceEl = scrollContainer.value.querySelector(`[data-source="${sourceId}"]`)
  if (!sourceEl || !page) return

  const viewbox = getOcrPageViewbox(page)
  const [vw, vh] = viewbox.split(' ').map(Number).slice(2)
  const originalWidth = props.ocrPage.width
  const originalHeight = (originalWidth / vw) * vh
  const points = getPolygonPointsFromSource(source, page).flatMap((polygons) => {
    return polygons
      .trim()
      .split(' ')
      .flatMap((points) => {
        const [x, y] = points.split(',').map(Number)
        return { x, y }
      })
  })

  const [minX, minY, maxX, maxY] = points.reduce(
    ([minX, minY, maxX, maxY], { x, y }) => [
      Math.min(minX, x),
      Math.min(minY, y),
      Math.max(maxX, x),
      Math.max(maxY, y),
    ],
    [Infinity, Infinity, -Infinity, -Infinity],
  )

  const leftPercentage = minX / originalWidth
  const rightPercentage = maxX / originalWidth
  const topPercentage = minY / originalHeight
  const bottomPercentage = maxY / originalHeight
  const widthPercentage = rightPercentage - leftPercentage
  const heightPercentage = bottomPercentage - topPercentage

  const centerXPercentage = leftPercentage + widthPercentage / 2
  const centerYPercentage = topPercentage + heightPercentage / 2

  // Figure out appropriate zoom level
  const zoomLevel = clamp(0.25, 1 / Math.max(widthPercentage, heightPercentage), 4)

  const currentZoom = actualScale.value
  const imgRect = scrollContainerChild.value.getBoundingClientRect()
  const newWidth = (imgRect.width * zoomLevel) / currentZoom
  const newHeight = (imgRect.height * zoomLevel) / currentZoom

  zoom()
  setScale(zoomLevel)
  translate.value = {
    x: (0.5 - centerXPercentage) * newWidth,
    y: (0.5 - centerYPercentage) * newHeight,
  }
}

watch(
  () => groundingStore.selectedClaimsAndSource?.sourceId,
  async (firstSourceId) => {
    if (firstSourceId === undefined) return
    await scrollToSourceId(firstSourceId)
  },
  {
    immediate: true,
    flush: 'post',
  },
)

const { getPolygonPointsFromSource, strokeWidthPerPage, getOcrPageViewbox } = useGroundingPolygons(
  computed(() => [props.ocrPage]),
)
</script>

<template>
  <div
    class="relative flex size-full min-h-0 flex-col gap-3 overflow-hidden pl-3"
    @wheel.ctrl.exact.prevent.stop="onZoom"
    @wheel.meta.exact.prevent.stop="onZoom"
    @wheel.shift.exact.prevent.stop="onScroll"
    @wheel.exact.prevent.stop="onScroll"
  >
    <div
      ref="scrollContainer"
      class="size-full grow justify-center overflow-auto rounded-md p-3"
    >
      <div
        class="absolute left-1/2 top-1/2 origin-center"
        :class="zooming ? 'transition-all duration-200' : ''"
        :style="{
          translate: `calc(-50% + ${translate.x}px) calc(-50% + ${translate.y}px)`,
          scale: actualScale,
        }"
      >
        <img
          ref="scrollContainerChild"
          :src="imagePath"
        />
        <template
          v-for="source in sources"
          :key="source.id"
        >
          <ClaimIndicator
            v-if="source.boundingBoxes[0]"
            :data-source="source.id"
            class="absolute z-10 -translate-x-2 -translate-y-4 scroll-m-6 transition"
            :class="
              selectedSourceId !== undefined &&
              selectedSourceId !== source.id &&
              'pointer-events-none opacity-0'
            "
            :source-id="source.id"
            :property-id="propertyId"
            npm
            :is-selected="selectedSourceId === source.id"
            :style="getClaimIndicatorStyles(source.boundingBoxes[0])"
            @click="groundingStore.clickSource(source.id)"
          />
          <svg
            :viewBox="getOcrPageViewbox(1)"
            class="pointer-events-none absolute left-0 top-0 transition"
            :class="
              selectedSourceId !== undefined &&
              selectedSourceId !== source.id &&
              'pointer-events-none opacity-0'
            "
          >
            <polygon
              v-for="(points, index) in getPolygonPointsFromSource(source, 1)"
              :key="index"
              :points="points"
              :data-test="`grounding-source-${source.id}-${index}`"
              class="fill-background-stages-model-subtle stroke-background-stages-model"
              :style="{
                strokeWidth: String(strokeWidthPerPage[0]),
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              }"
            />
          </svg>
        </template>
      </div>
    </div>
    <div class="absolute bottom-2 flex w-full justify-center">
      <ScaleButtons
        class="shadow-sm"
        :scale="scale"
        @update:scale="
          (e) => {
            zoom()
            setScale(e)
            translate = { x: 0, y: 0 }
          }
        "
      />
    </div>
  </div>
</template>
