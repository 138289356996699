import type { PropertyType } from '@/backend/types'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import type { Field } from '@/modules/Project/Fields/types'
import { useProject } from '@/modules/Project/useProject'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { toast } from '@/shared/toast'
import { useSetFieldValue } from '@/shared/useSetFieldValue'
import { invariant } from '@/shared/utils/typeAssertions'

export function useFieldSaving() {
  return { saveFieldChange }
}

/**
 * Saves a manual field value to the entity.
 */
async function saveFieldChange<T extends PropertyType>(
  field: Field<T>,
  newValue: Field<T>['manualValue'],
): Promise<void> {
  const currentWorkspace = useCurrentWorkspace()
  const projectStore = useProject()
  const setFieldValue = useSetFieldValue()

  if (!hasPermissionToUpdate()) {
    toast.error('You do not have permission to update this field.')
    return
  }

  invariant(field.entityId)
  invariant(projectStore.projectId)

  const { ok: success } = await setFieldValue({
    workspaceId: currentWorkspace.value.id,
    projectId: projectStore.projectId,
    fields: { [field.propertyId]: { field, newValue } },
    entityId: field.entityId,
  })

  if (!success) {
    toast.error('Failed to save value.')
  }
}

function hasPermissionToUpdate() {
  const permissionsStore = usePermissionsStore()
  return permissionsStore.currentProjectPermissions.update_entities
}
