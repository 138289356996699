import { post } from './client'
import type { BackendMeta } from './types'

export type UpdateCase = BackendMeta<'/api/workspaces/{workspace_id}/cases/{case_id}', 'post'>

export type UpdateCaseParams = {
  workspaceId: string
  caseId: string
  name: string
}

export const updateCase = async ({ caseId, workspaceId, name }: UpdateCaseParams) =>
  post<UpdateCase['body'], UpdateCase['successResponse'], UpdateCase['path']>(
    `/api/workspaces/${workspaceId}/cases/${caseId}`,
    {
      name,
    },
  )
