<script setup lang="ts">
import { useLibraryStore } from '@/modules/Library/libraryStore'
import { useLibraryBackend } from '@/modules/Library/useLibraryBackend'
import LibraryItemActions from '@/modules/Workspaces/KnowledgeHub/Files/Actions/LibraryItemActions.vue'
import { useFileTableContext } from '@/modules/Workspaces/KnowledgeHub/Files/context'
import FilePlaceholder from '@/modules/Workspaces/KnowledgeHub/Files/FilePlaceholder.vue'
import { useRowContext } from '@/modules/Workspaces/KnowledgeHub/Files/rowContext'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import CheckBox from '@/uiKit/CheckBox.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { useActiveElement, whenever } from '@vueuse/core'
import { computed, nextTick, ref, useTemplateRef } from 'vue'

const { isRenaming, item, isHovering } = useRowContext()

const { renameItem } = useLibraryBackend()
const workspace = useCurrentWorkspace()
const selection = useFileTableContext()
const libraryStore = useLibraryStore()

const newName = ref('')
const renameInput = ref<HTMLInputElement | null>(null)

const checkbox = useTemplateRef('checkbox')
const active = useActiveElement()

const checkboxLabel = computed(() => {
  return isSelected.value ? `Deselect ${item.value.name}` : `Select ${item.value.name}`
})

whenever(
  isRenaming,
  async () => {
    newName.value = item.value.name
    await nextTick()
    renameInput.value?.focus()
    renameInput.value?.select()
  },
  { immediate: true },
)

const isIncomplete = computed(() => item.value.status !== 'complete')
const progress = computed(() => libraryStore.uploadProgressMap[item.value.id])

const isSelected = computed({
  get: () => selection.isSelected(item.value.id),
  set: (value) => selection.setSelected(item.value.id, value),
})

function submitName() {
  const nextName = newName.value.trim()
  isRenaming.value = false
  renameItem({ id: item.value.id, newName: nextName, workspaceId: workspace.value.id })
}
</script>

<template>
  <div
    class="relative flex h-full shrink grow items-center truncate"
    :class="{ 'text-text-disabled': isIncomplete }"
  >
    <!-- Checkbox, icon, throbber -->
    <div class="relative flex w-6 shrink-0 items-center">
      <!-- Has absolutely positioned children because checkbox needs to always be rendered for a11y -->
      <!-- Other components need to be layered it its place when needed -->
      <CircularProgress
        v-if="isIncomplete"
        :value="progress"
        class="absolute"
        size="xs"
      />
      <FilePlaceholder
        v-else-if="!isHovering && !isSelected && active && !checkbox?.$el.contains(active)"
        :name="item.type === 'text' ? '' : item.filename"
        class="absolute h-fit max-w-4 rounded-sm border border-border-subtle"
      />

      <CheckBox
        v-if="!isIncomplete"
        ref="checkbox"
        :disabled="isIncomplete"
        :checked="isSelected"
        :class="{ '!opacity-100': isSelected }"
        class="absolute opacity-0 focus-within:opacity-100 group-hover:opacity-100"
        :aria-label="checkboxLabel"
        @click.stop
        @change="(ev) => (isSelected = ev)"
      />
    </div>

    <template v-if="isRenaming">
      <input
        ref="renameInput"
        v-model="newName"
        :class="[isSelected ? 'bg-background-selected' : 'bg-background-gray-subtlest']"
        class="-mx-1 mr-16 w-full rounded px-1 outline-none"
        @click.stop
        @blur="submitName"
        @keydown.enter.stop.prevent="submitName"
        @keydown.esc.stop="isRenaming = false"
      />
    </template>
    <template v-else>
      <!-- File name with truncation -->
      <div
        class="mr-16 w-full truncate"
        :title="item.name"
      >
        {{ item.name }}
      </div>
    </template>
    <!-- Action buttons fixed at the right edge -->
    <LibraryItemActions>
      <template #default="{ open, rename }">
        <div
          class="absolute right-2 flex items-center gap-0.5 rounded-md bg-surface-primary p-0.5 opacity-0 shadow-xs focus-within:opacity-100 group-hover:opacity-100"
        >
          <IconButton
            :icon="open.icon"
            size="sm"
            variant="transparent"
            v-bind="open.props"
          />
          <IconButton
            :icon="rename.icon"
            size="sm"
            variant="transparent"
            v-bind="rename.props"
          />
        </div>
      </template>
    </LibraryItemActions>
  </div>
</template>
