<script setup lang="ts">
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'

const props = defineProps<{
  workspaceId: string
  caseId: string
  open: boolean
}>()

const emit = defineEmits<{
  (e: 'close' | 'delete'): void
}>()
</script>

<template>
  <ConfirmationDialog
    :open="props?.open"
    title="Delete this case?"
    description="This case will be deleted immediately. You can't undo this action."
    @confirm="emit('delete')"
    @close="emit('close')"
  />
</template>
