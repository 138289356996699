<script lang="ts">
export type SelectItem = {
  label: string
  value: string
  /** The icon to be used when the item is selected. */
  icon?: IconName
}
</script>

<script setup lang="ts" generic="Item extends SelectItem">
import { omit } from '@/shared/utils'
import { useElementBounding } from '@vueuse/core'
import * as select from '@zag-js/select'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed, useTemplateRef, watch } from 'vue'
import type { IconName } from '../IconName'
import IconSprite from '../IconSprite.vue'
import { getPopoverTeleportTarget } from '../utils/teleport'
import SelectButton from './SelectButton.vue'

/**
 * This is a styled version of a component that meets the w3c `select` role.
 * @see https://w3c.github.io/aria/#select
 *
 * Be aware that when adding functionality to this component, it should still
 * meet the w3c spec linked above. If you require richer functionality (e.g.
 * a searchbox), consider using a different component.
 */

const props = withDefaults(
  defineProps<{
    /** Trigger props */

    /**
     * If true then the width of the trigger button will be the same, regardless of the
     * selected item.
     */
    constantTriggerWidth?: boolean

    /** The text to show when no selection is made. */
    placeholder?: string
    leadingIcon?: IconName | null
    trailingIcon?: IconName | null
    /**
     * When true, the dropdown trigger will have a transparent
     * background and hover effect.
     */
    inline?: boolean
    size?: 'md' | 'lg'

    /** Content props */

    items: Item[]
    positioning?: select.PositioningOptions
    disableTeleport?: boolean

    /** General props */

    value?: Item['value']
  }>(),
  {
    placeholder: 'Add...',
    leadingIcon: null,
    trailingIcon: 'chevron-select',
    size: 'md',
    value: undefined,
    positioning: undefined,
  },
)

const emit = defineEmits<{
  (e: 'change', value: Item['value']): void
}>()

const service = useMachine(select.machine, {
  id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
  collection: select.collection({
    items: props.items,
  }),
  defaultValue: props.value ? [props.value] : undefined,
  onValueChange: ({ value }) => {
    emit('change', value[0])
  },
  positioning: props.positioning,
})

watch(
  () => props.value,
  (newValue) => {
    if (!newValue) return
    api.value.setValue([newValue])
  },
)

const api = computed(() => select.connect(service, normalizeProps))

function getTriggerProps() {
  const p = api.value.getTriggerProps()
  return {
    ...p,
    disabled: p.disabled === 'true' || p.disabled === true,
  }
}

const selectedItem = computed(() => {
  return props.items.find((i) => i.value === api.value.value[0])
})

const popoverTarget = getPopoverTeleportTarget()

/**
 * This isn't perfect, as characters have different widths, so the longest string might
 * not be the longest DOM element. However, it's good enough for most cases and we can
 * fix it later if people complain.
 */
const longestLabel = computed(() => {
  return props.items.reduce((acc, item) => (item.label.length > acc.length ? item.label : acc), '')
})
const sizer = useTemplateRef('sizer')
const sizerWidth = useElementBounding(sizer).width
</script>

<template>
  <slot
    name="trigger"
    v-bind="{
      selectedItem,
      getTriggerProps,
      isOpen: api.open,
      ariaLabel: api.valueAsString || placeholder,
      getLabelProps: api.getLabelProps,
    }"
  >
    <SelectButton
      v-if="constantTriggerWidth"
      ref="sizer"
      class="fixed left-0 top-0 opacity-0"
      inert
      aria-hidden="true"
    >
      <span class="text-text">{{ longestLabel }}</span>
    </SelectButton>
    <SelectButton
      :active="api.open"
      :inline="inline"
      :size="size"
      :leading-icon="leadingIcon ?? selectedItem?.icon"
      :trailing-icon="trailingIcon"
      :aria-label="api.valueAsString || placeholder"
      v-bind="{ ...omit(api.getTriggerProps(), ['aria-labelledby']), ...$attrs }"
      :style="{
        width: sizerWidth ? `${sizerWidth}px` : undefined,
      }"
    >
      <span class="truncate text-text">{{ api.valueAsString || placeholder }}</span>
    </SelectButton>
  </slot>
  <Teleport
    :to="popoverTarget || 'body'"
    :disabled="disableTeleport"
  >
    <div v-bind="api.getPositionerProps()">
      <ul
        class="group min-h-0 min-w-[220px] cursor-pointer flex-col items-stretch gap-0 rounded-corner-10 bg-surface-popover p-0.5 shadow-lg outline outline-1 outline-border-subtle"
        :class="api.open ? 'flex' : 'hidden'"
        v-bind="api.getContentProps()"
      >
        <li
          v-for="item in items"
          :key="item.value"
          v-bind="api.getItemProps({ item })"
          class="group/item"
        >
          <slot
            name="item"
            :item="item"
            :api="api"
            :selected="api.value[0] === item.value"
            :highlighted="api.highlightedValue === item.value"
          >
            <div
              class="flex min-h-7 w-full flex-row items-center justify-start gap-1 self-stretch rounded-corner-8 px-1.5 py-1 text-sm-12px-default text-icon outline-none transition group-data-[highlighted]/item:bg-background-transparent-hovered"
            >
              <div class="flex items-center gap-1">
                <slot
                  name="item:icon"
                  :selected="api.value[0] === item.value"
                >
                  <IconSprite
                    v-if="item.icon"
                    :icon="item.icon"
                    class="text-icon-subtle"
                  />
                </slot>
                <span class="px-1 text-sm-12px-default">{{ item.label }}</span>
              </div>
            </div>
          </slot>
        </li>
      </ul>
    </div>
  </Teleport>
</template>
