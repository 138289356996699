<script setup lang="ts">
import { type SelectOptionValueFilter } from '@/modules/Project/Filters/types'
import type {
  MultiSelectProperty,
  SingleSelectProperty,
  UserSelectProperty,
} from '@/modules/Project/Properties/types'
import SelectContent from '@/modules/Project/SelectContent.vue'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import Menu from '@/uiKit/Menu'
import type { Props as MenuContext } from '@zag-js/menu'
import { computed, ref } from 'vue'

const props = defineProps<{
  filter: SelectOptionValueFilter
  property: SingleSelectProperty | MultiSelectProperty | UserSelectProperty
  positioning?: MenuContext['positioning']
  teleportTo?: string
}>()

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'values', values: string[]): void
}>()

const onValues = (values: string[]) => {
  if (values.length === 0) {
    emit('delete')
    return
  }
  emit('values', values)
}

const workspaceMembersStore = useWorkspaceMembers()

const userValue = (option: { color?: string | null; value: string }) => {
  const member = workspaceMembersStore.workspaceMembers.find((member) => member.id === option.value)
  return member ? `${member.firstName} ${member.lastName}` : option.value
}

const label = computed(() => {
  if (props.filter.matcher.values.length > 1) {
    return `${props.filter.matcher.values.length} options`
  }

  if (props.property.type === 'user_select') {
    const userId = props.filter.matcher.values[0]
    if (!props.property.config) return userId

    const option = props.property.config.options.find((o) => o.value === userId)
    if (!option) return userId

    return userValue(option)
  }

  return props.filter.matcher.values[0]
})

const getOptionColor = (value: string) => {
  const option = props.property.config.options.find((o) => o.value === value)
  return option?.color || 'rainbow-17'
}

// Select properties in filters should always be treated as multi-select.
// This is because the filter matcher can be any of the following:
// - property_any_of
// - property_all_of
// - property_none_of
const filterProperty = computed((): MultiSelectProperty => {
  if (props.property.type === 'multi_select') return props.property

  return {
    ...props.property,
    config: {
      defaultOption: null,
      maxSelected: null,
      options: props.property.config.options,
    },
    type: 'multi_select',
  }
})

const isOpen = ref(false)
const onOpenChange = (open: boolean) => {
  isOpen.value = open
}
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    :positioning="positioning ?? { placement: 'top-start' }"
    :teleport-to="teleportTo"
    @change:open="onOpenChange"
  >
    <button
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      class="flex cursor-pointer items-center gap-0.5 px-1.5 py-1 text-sm-12px-default text-text-subtle hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
      :class="{ 'bg-background-transparent-hovered': isOpen }"
    >
      <div
        v-if="property.type !== 'user_select'"
        class="flex shrink-0"
      >
        <div
          v-for="(value, j) in (filter.matcher.values as string[]).slice(0, 3)"
          :key="j"
          class="-mr-0.5 size-3 rounded-corner-4 border border-border-subtle"
          :style="{
            backgroundColor: `var(--${getOptionColor(value)}-light)`,
          }"
        />
      </div>
      <div
        class="flex shrink-0 px-1"
        :class="label ? 'text-text' : 'text-text-disabled'"
      >
        {{ label ?? 'Select...' }}
      </div>
    </button>
    <SelectContent
      class="min-w-[242px]"
      :property="filterProperty"
      :readonly="false"
      :value="filter.matcher.values"
      :force-user-value="property.type === 'user_select'"
      @submit="onValues"
    />
  </Menu.Root>
</template>
