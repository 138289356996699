import { getEntity } from '@/backend/getEntity'
import { getProject, type GetProjectResponse } from '@/backend/getProject'
import type { APIResult } from '@/backend/types'
import { useLoadProjectPermissions } from '@/modules/Project/Permissions/useLoadProjectPermissions'
import { useRootProjectId } from '@/modules/Project/Permissions/useRootProjectId'
import { useProjectChannel } from '@/modules/Project/useProjectChannel'
import { useProjectChannelHandlers } from '@/modules/Project/useProjectChannelHandlers'
import { useDataLoader, type DataLoaderStatus } from '@/sharedComposables/useDataLoader'
import { computed, ref, shallowRef, toValue, watch, type Ref } from 'vue'
import { useRouter } from 'vue-router'
import { useParentProject } from '../Project/useParentProject'
import { serializeProject, useProjects } from '../Projects/useProjects'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import { useFileCollectionLoadingStore } from './useFileCollectionLoadingStore'
import { serializeProperty, serializeView, useProject } from './useProject'
import { useResolveProjectRoute } from './useResolveProjectRoute'
import { useTable } from './useTable'

type ProjectLoader = {
  status: Ref<DataLoaderStatus>
  load: () => Promise<APIResult<GetProjectResponse>>
}

/**
 * When the project ID changes, this composable will load the project and set it in the project Pinia
 * store. When the view ID changes, it will set the active view in the project Pinia store.
 */
export const useProjectSync = (props: {
  workspaceId: Ref<string>
  projectId: Ref<string>
  viewId?: Ref<string | undefined>
  parentEntityId?: Ref<string | undefined>
}) => {
  const projectLoader = shallowRef<ProjectLoader>()

  const currentProjectStore = useProject()
  const parentProjectStore = useParentProject()
  const allProjectsStore = useProjects()
  const relatedProjectsStore = useRelatedProjectsStore()
  const router = useRouter()
  const tableStore = useTable()
  const resolveProjectTableRoute = useResolveProjectRoute()
  /** Tracks if project that we're trying sync is already a loaded active project */
  const isAlreadyLoaded = ref(false)

  const projectChannelHandlers = useProjectChannelHandlers()
  useProjectChannel(props.projectId, projectChannelHandlers)

  const rootProjectId = useRootProjectId()
  useLoadProjectPermissions({
    workspaceId: props.workspaceId,
    /** Root project ID pulls from a route query param which is not present when using the project table in cases */
    rootProjectId: computed(() => rootProjectId.value || props.projectId.value),
  })

  const loadingState = computed<DataLoaderStatus>(() => {
    const loaderState = projectLoader.value?.status.value
    if (loaderState) return loaderState
    if (isAlreadyLoaded.value) return 'loaded'
    return 'idle'
  })

  const loadAndSetProject = async () => {
    const projectId = toValue(props.projectId)
    const workspaceId = toValue(props.workspaceId)
    const viewId = toValue(props.viewId)

    currentProjectStore.setProjectId(projectId)
    projectLoader.value = useDataLoader(() => getProject(workspaceId, projectId))

    const result = await projectLoader.value.load()
    if (result.ok) {
      allProjectsStore.addProject(serializeProject(result.data))
      currentProjectStore.setProperties(result.data.properties.map(serializeProperty))
      currentProjectStore.setViews(result.data.main_view_id, result.data.views.map(serializeView))
      currentProjectStore.resetDrafts()

      await loadAndSetParentProject()
      await loadAndSetParentEntity()
      await loadAndSetRelatedProjects()

      const hasMainView = result.data.main_view_id !== null
      if (!hasMainView && !props.viewId) {
        const firstView = result.data.views.at(0)
        if (!firstView) {
          throw new Error('User is unable to access any views in this project.')
        }
        // 1. If the response contains no main view, then the user does not have permission to access
        //    the main view
        // 2. If the user has not provided a viewId, then we redirect the user to the first view that
        //    they do have access to
        router.replace(
          resolveProjectTableRoute({
            workspaceId,
            projectId,
            viewId: firstView.id,
          }),
        )
      } else {
        currentProjectStore.setActiveViewId(viewId)
      }
      currentProjectStore.projectLoaded = true
    }
  }

  const loadAndSetParentProject = async () => {
    const workspaceId = toValue(props.workspaceId)

    const parentPropertyId = currentProjectStore.properties.at(0)?.parentPropertyId

    parentProjectStore.reset()

    if (parentPropertyId) {
      // if we have any props with parent property (eg collections), we load the parent project so we can access to their properties
      const parentProjectId = currentProjectStore.properties.at(0)?.parentProjectId
      if (!parentProjectId) throw new Error('Parent project ID is missing')

      const parentProjectLoader = useDataLoader(() => getProject(workspaceId, parentProjectId))

      const result = await parentProjectLoader.load()
      if (result.ok) {
        allProjectsStore.addProject(serializeProject(result.data))
        parentProjectStore.setParentProject(result.data)
        parentProjectStore.setParentProperty(parentPropertyId)
      } else {
        throw new Error('Unable to load parent project')
      }
    }
  }

  const loadAndSetParentEntity = async () => {
    const workspaceId = toValue(props.workspaceId)
    const parentEntityId = toValue(props.parentEntityId)

    const firstProperty = currentProjectStore.properties.at(0)
    if (!firstProperty) {
      // Prevents a bug where breadcrumb forming would read a stale parent field
      parentProjectStore.parentField = null
      return
    }

    const parentProjectId = firstProperty.parentProjectId

    if (!parentEntityId || !parentProjectId) {
      return
    }

    const parentEntityLoader = useDataLoader(() => {
      return getEntity(workspaceId, parentProjectId, parentEntityId)
    })

    const result = await parentEntityLoader.load()

    if (result.ok) {
      parentProjectStore.setParentEntity(result.data)
    } else {
      throw new Error('Unable to load parent entity')
    }
  }

  const loadAndSetRelatedProjects = async () => {
    const workspaceId = toValue(props.workspaceId)
    const referenceProperties = currentProjectStore.properties.filter(
      (property) => property.type === 'reference',
    )

    await relatedProjectsStore.loadRelatedProjects({ referenceProperties, workspaceId })
  }

  const fileCollectionLoadingStore = useFileCollectionLoadingStore()
  watch(
    () => toValue(props.projectId),
    (newId) => {
      if (newId === currentProjectStore.projectId) {
        isAlreadyLoaded.value = true
        return
      }
      isAlreadyLoaded.value = false

      currentProjectStore.projectLoaded = false
      currentProjectStore.setProperties([])
      currentProjectStore.setViews(null, [])
      tableStore.clearFocusedAndSelected()
      loadAndSetProject()
      fileCollectionLoadingStore.reset()
    },
    { immediate: true },
  )

  watch(
    () => toValue(props.viewId),
    (newViewId) => {
      if (newViewId !== currentProjectStore.activeView?.id) {
        currentProjectStore.setActiveViewId(newViewId)
      }
    },
    { immediate: true },
  )

  watch(
    () => toValue(props.parentEntityId),
    (newPid) => {
      currentProjectStore.setParentEntityId(newPid)
    },
    { immediate: true },
  )

  return { loadingState }
}
