import { listTemplates, type ListTemplatesResponse } from '@/backend/listTemplates'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'

/**
 * Stores the project templates that are used when creating a new project.
 */
export const useTemplates = defineStore('templates', () => {
  const allTemplates = ref<ListTemplatesResponse['data']>([])
  const emptyTemplateUuid = useFeatureFlags(FeatureFlag.EMPTY_TEMPLATE_UUID, '')
  const templateCategories = ref<string[]>([])

  const extractCategories = (templates: ListTemplatesResponse['data']): string[] => {
    const categories = templates
      .filter((template) => /\[.*\].*/.test(template.name || ''))
      .map((template) => template?.name?.match(/\[(.*?)\]/)?.[1])
      .filter((tag): tag is string => tag !== undefined)
      .filter((tag, index, self) => self.indexOf(tag) === index)
      .filter((tag) => tag !== 'Data Labelling') // hacky way to remove the Data Labelling category
      .sort()
    categories.push('Data Labelling') // and add it to the end of the list later (this one should be the last)
    return categories
  }

  const templates = computed(() =>
    allTemplates.value.filter((t) => t.id !== emptyTemplateUuid.value),
  )

  const emptyTemplate = computed(() => {
    return allTemplates.value.find((t) => t.id === emptyTemplateUuid.value)
  })

  const refreshTemplates = async () => {
    const res = await listTemplates()
    if (!res.ok) return
    allTemplates.value = res.data.data
    templateCategories.value = extractCategories(res.data.data)
  }

  return {
    templates,
    templateCategories,
    emptyTemplate,
    refreshTemplates,
    _allTemplates: allTemplates, // is only used in tests, so we can set the state
  }
})
