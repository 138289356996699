<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'

const emit = defineEmits<{
  (e: 'browse-library' | 'create-agent' | 'reset-filters'): void
}>()
</script>

<template>
  <div class="flex size-full h-full grow flex-col items-center justify-center">
    <div class="flex grow flex-col items-center justify-center">
      <div class="text-sm-12px-default text-text">No agent matching your search</div>
      <div class="text-sm-12px-light text-text-subtle">
        You can create a new one or browse our agents library to get started.
      </div>
    </div>
    <div class="flex grow flex-col items-center justify-center gap-2">
      <DarwinButton
        class="w-[180px]"
        variant="black"
        size="md"
        rounded
        @click="emit('create-agent')"
      >
        <template #leading-icon>
          <IconSprite icon="plus" />
        </template>
        Create new agent
      </DarwinButton>
      <DarwinButton
        class="w-[180px]"
        variant="neutral"
        size="md"
        rounded
        @click="emit('browse-library')"
      >
        <template #leading-icon>
          <IconSprite icon="templates" />
        </template>
        Browse agents library
      </DarwinButton>
    </div>
    <div class="flex flex-col items-center justify-center">
      <DarwinButton
        variant="transparent"
        size="md"
        @click="emit('reset-filters')"
      >
        <template #leading-icon>
          <IconSprite icon="close" />
        </template>
        Reset filters
      </DarwinButton>
    </div>
  </div>
</template>
