<script setup lang="ts">
/**
 * Basic textarea implementation in UI Kit
 *
 * This is not the final version of the component, but the API should remain as is
 *
 * This is mainly mimicking styling and behavior of the TextField,
 * with the difference that it's a textarea, so multiline.
 */
import { computed, ref, watch } from 'vue'

import { useResizeObserver, useStorage } from '@vueuse/core'

const props = defineProps<{
  placeholder?: string
  error?: string
  warning?: string
  info?: string
  value: string
  label?: string
  size: 'md' | 'lg'
  rounded?: boolean
  ariaLabel?: string
  inline?: boolean
  /** When provided, will persist the height to localStorage using this key */
  savedHeightKey?: string
}>()

defineEmits<{
  (e: 'change' | 'input', value: string): void
  (e: 'customCaretPosition', position: { top: number; left: number; height: number }): void
}>()

const isFocused = ref(false)

const variant = computed<'error' | 'warning' | 'info' | 'default'>(() => {
  if (props.error) {
    return 'error'
  }
  if (props.warning) {
    return 'warning'
  }
  if (props.info) {
    return 'info'
  }
  return 'default'
})

defineOptions({
  inheritAttrs: false,
})

// Persist height to localStorage
const height = useStorage(props.savedHeightKey ?? 'default-textarea-key', 150)
const textareaRef = ref<HTMLTextAreaElement | null>(null)
useResizeObserver(textareaRef, () => {
  if (!props.savedHeightKey) return
  height.value = textareaRef.value?.getBoundingClientRect().height
})
watch(textareaRef, (el) => {
  if (!el) return
  el.style.height = props.savedHeightKey ? `${height.value}px` : '150px'
})
</script>

<template>
  <div
    :class="
      label ? 'flex w-full flex-col items-stretch justify-center gap-0.5 self-stretch' : 'contents'
    "
  >
    <label
      v-if="label"
      class="text-sm-12px-default text-text transition"
      :for="typeof $attrs.id === 'string' ? $attrs.id : undefined"
    >
      {{ label }}
    </label>
    <textarea
      ref="textareaRef"
      type="text"
      class="block max-h-[600px] min-h-[50px] w-full text-md-13px-default text-text transition-colors placeholder:text-text-subtlest focus:text-text focus:outline-none disabled:cursor-not-allowed"
      :class="[
        [variant === 'error' && ['border-border-critical'], variant !== error && []],
        [size === 'md' ? 'px-2 py-1.5' : 'px-2.5 py-2'],
        [rounded ? 'rounded-corner-10' : 'rounded-corner-8'],
        [
          inline
            ? 'bg-background-transparent hover:[&:not(:disabled):not(:focus)]:bg-background-transparent-hovered'
            : 'bg-background-gray-subtlest hover:[&:not(:disabled):not(:focus)]:bg-background-gray-subtlest-hovered',
        ],
      ]"
      :placeholder="placeholder"
      :value="value"
      :ariaLabel="ariaLabel"
      v-bind="$attrs"
      @focusin="isFocused = true"
      @focusout="isFocused = false"
      @change="$event.target && $emit('change', ($event.target as HTMLTextAreaElement).value)"
      @input="$event.target && $emit('input', ($event.target as HTMLTextAreaElement).value)"
    />
    <div
      v-if="error || warning || info"
      class="flex h-5 flex-row items-center text-sm-12px-default transition duration-300"
    >
      <div
        v-if="error"
        class="text-text-critical"
      >
        {{ error }}
      </div>
      <div
        v-else-if="warning"
        class="text-text-warning"
      >
        {{ warning }}
      </div>
      <div
        v-else-if="info"
        class="text-text"
      >
        {{ info }}
      </div>
    </div>
  </div>
</template>
