<!-- eslint-disable tailwindcss/no-custom-classname -->
<script setup lang="ts" generic="T extends SegmentedControlItem">
import { isHtmlElement } from '@/shared/utils'
import { useRafFn } from '@vueuse/core'
import * as radio from '@zag-js/radio-group'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed, onMounted, ref, watch } from 'vue'
import DividerLine from './DividerLine.vue'
import type { IconName } from './IconName'
import IconSprite from './IconSprite.vue'
/**
 * Segmented Control component, basically a styled radio group.
 * @see https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/V7-Go---Design-System?type=design&node-id=290-21978&mode=design&t=zAuducvfLghFMx1e-0
 */
export type SegmentedControlItem<Value extends string = string> = {
  label: string
  value: Value
  icon?: IconName
  iconSelected?: IconName
  width?: number
}

const props = withDefaults(
  defineProps<{
    items: T[]
    value: T['value']
    /**
     * The name of the radio group.
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#defining_a_radio_group
     */
    name: string
    size?: 'xs' | 'sm' | 'md'
    transparent?: boolean
  }>(),
  {
    size: 'md',
    transparent: false,
  },
)

const emit = defineEmits<{
  (e: 'change', value: T['value'] | null): void
}>()

const service = useMachine(radio.machine, {
  // The ID is used to ensure that every part of this component has a unique identifier
  id: props.name,
  defaultValue: props.value,

  onValueChange: ({ value }) => {
    pausable.resume()
    setTimeout(() => {
      // stop the redrawing function after 300ms since it should become idle
      pausable.pause()
    }, 300)
    if (value === props.value) {
      return
    }

    emit('change', value)
  },
  orientation: 'horizontal',
  name: props.name,
})

watch(
  () => props.value,
  (newValue) => {
    api.value.setValue(newValue)
  },
)

const api = computed(() => radio.connect(service, normalizeProps))

// When there are 2 unselected items next to each other, there should be a small
// divider between them
const hasRightBorder = (item: T): boolean => {
  const thisIndex = props.items.findIndex((i) => i.value === item.value)

  const nextItem = props.items[thisIndex + 1]
  if (!nextItem) {
    return false
  }

  const thisItemIsSelected = api.value.getItemState({
    value: item.value,
  }).checked
  const nextItemIsSelected = api.value.getItemState({
    value: nextItem.value,
  }).checked
  return !thisItemIsSelected && !nextItemIsSelected
}

// Animations
const rootEl = ref<HTMLElement | null>(null)
const insetEl = ref<HTMLElement | null>(null)
const selectedEl = ref<HTMLElement | null>(null)

const rect = ref({ left: 0, right: 0, width: 0 })
const animatedRect = ref({ left: 0, right: 0, width: 0 })

const updateActiveRect = () => {
  if (!rootEl.value) return
  const checkedEl = rootEl.value.querySelector('[data-state="checked"]')
  if (!isHtmlElement(checkedEl)) return
  const checkedRect = checkedEl.getBoundingClientRect()
  const rootRect = rootEl.value.getBoundingClientRect()
  rect.value = {
    left: checkedRect.left - rootRect.left - 2,
    right: rootRect.right - checkedRect.right - 2,
    width: checkedRect.width,
  }
}

onMounted(updateActiveRect)
watch(() => props.value, updateActiveRect, { flush: 'post' })

const pausable = useRafFn(() => {
  if (!insetEl.value || !selectedEl.value) return
  if (animatedRect.value.width === 0) {
    animatedRect.value = rect.value
  } else {
    animatedRect.value = {
      left: animatedRect.value.left + (rect.value.left - animatedRect.value.left) * 0.25,
      right: animatedRect.value.right + (rect.value.right - animatedRect.value.right) * 0.25,
      width: animatedRect.value.width + (rect.value.width - animatedRect.value.width) * 0.25,
    }
  }

  insetEl.value.style.clipPath = `inset(0 ${animatedRect.value.right + 2}px 0 ${animatedRect.value.left + 2}px)`
  selectedEl.value.style.transform = `translateX(${animatedRect.value.left}px)`
  selectedEl.value.style.width = `${animatedRect.value.width}px`
})

onMounted(() => {
  setTimeout(() => {
    pausable.pause()
  }, 300)
})

function isChecked(value: T['value']): boolean {
  return api.value.getItemState({ value }).checked
}
</script>

<template>
  <div
    v-bind="api.getRootProps()"
    ref="rootEl"
    class="relative"
  >
    <div
      class="box-border inline-flex items-center gap-0.5 p-0.5 outline-background-primary focus:outline focus:outline-2"
      :class="[
        size === 'sm' && 'h-5 rounded-corner-6',
        size === 'md' && 'h-7 rounded-corner-8',
        !transparent && 'bg-surface-tertiary',
      ]"
    >
      <div
        v-for="(item, i) in items"
        :key="item.value"
        class="flex h-full"
        :style="{
          width: item.width ? `${item.width}px` : undefined,
        }"
      >
        <label
          class="flex h-full cursor-pointer items-center justify-center gap-1 text-text-subtlest outline-2 outline-border-focused transition duration-300 has-[:focus-visible]:outline"
          :class="[
            size === 'sm' && 'rounded-corner-4 px-1.5',
            size === 'md' && 'rounded-corner-6 px-2',
            !isChecked(item.value) && 'hover:bg-background-transparent-hovered',
          ]"
          :style="{
            width: item.width ? `${item.width}px` : undefined,
          }"
          v-bind="api.getItemProps({ value: item.value })"
        >
          <IconSprite
            v-if="item.icon"
            :icon="item.value === value ? item.iconSelected || item.icon : item.icon"
          />
          <span
            v-if="item.label"
            :class="[
              size === 'sm' && 'text-xs-11px-default',
              size === 'md' && 'text-sm-12px-default',
            ]"
            v-bind="api.getItemTextProps({ value: item.value })"
            >{{ item.label }}</span
          >
          <slot :name="`item:${item.value}:trailing`" />
          <input v-bind="api.getItemHiddenInputProps({ value: item.value })" />
        </label>
        <DividerLine
          v-if="i < items.length - 1"
          class="py-1 transition duration-300"
          :class="!hasRightBorder(item) && 'opacity-0'"
          color="subtle"
          :width="1"
          direction="vertical"
        />
      </div>
    </div>
    <!-- Animated elements, need duplication for the masking -->
    <div
      ref="selectedEl"
      class="pointer-events-none absolute inset-y-0.5 left-0.5 bg-surface-primary shadow-sm"
      :class="[size === 'sm' && 'rounded-corner-4', size === 'md' && 'rounded-corner-6']"
    />
    <div
      ref="insetEl"
      class="pointer-events-none absolute inset-0 box-border inline-flex items-center gap-0.5 p-0.5 text-text focus:outline focus:outline-2"
      :style="`clip-path: inset(0 ${rect.right}px 0 ${rect.left}px);`"
      aria-hidden="true"
    >
      <div
        v-for="(item, i) in items"
        :key="item.value"
        class="flex h-full"
        :style="{
          width: item.width ? `${item.width}px` : undefined,
        }"
      >
        <label
          class="flex h-full cursor-pointer items-center justify-center gap-1"
          :class="[size === 'sm' && 'px-1.5', size === 'md' && 'px-2']"
          :style="{
            width: item.width ? `${item.width}px` : undefined,
          }"
        >
          <IconSprite
            v-if="item.icon"
            :icon="item.value === value ? item.iconSelected || item.icon : item.icon"
          />
          <span
            v-if="item.label"
            :class="[
              size === 'sm' && 'text-xs-11px-default',
              size === 'md' && 'text-sm-12px-default',
            ]"
            v-bind="api.getItemTextProps({ value: item.value })"
            >{{ item.label }}</span
          >
          <slot :name="`item:${item.value}:trailing`" />
        </label>
        <DividerLine
          v-if="i < items.length - 1"
          class="py-1"
          :class="!hasRightBorder(item) && 'opacity-0'"
          color="subtle"
          :width="1"
          direction="vertical"
        />
      </div>
    </div>
  </div>
</template>
