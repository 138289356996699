import { addCase } from '@/backend/addCase'
import { updateCase as updateCaseBackend } from '@/backend/updateCase'
import { invariant } from '@/shared/utils/typeAssertions'
import { captureException } from '@sentry/vue'
import { serializeCase } from './serializers'
import { useCaseStore } from './useCaseStore'

/**
 * This composable provides functions that send HTTP requests to the backend
 * to update case data, updating the Case store as well (with optimistic UI).
 *
 * These functions are intentionally separate to the store since we also
 * receive updates via a websocket connection, and we want to make sure we
 * don't "double count" updates by sending API requests when we receive a
 * websocket update.
 */
export const useCaseBackend = () => {
  const caseStore = useCaseStore()

  const createCase = async ({ workspaceId }: { workspaceId: string }) => {
    const res = await addCase({ workspaceId })
    if (res.ok) {
      const newCase = serializeCase({ caseResponse: res.data, entities: [], projects: [] })
      caseStore.setCase(newCase)
    } else {
      captureException(new Error('Failed to create case'), {
        data: {
          code: res.error.code,
          message: res.error.message,
        },
      })
    }

    return res
  }

  const updateCaseName = async ({
    caseId,
    workspaceId,
    name,
  }: {
    workspaceId: string
    caseId: string
    name: string
  }) => {
    const match = caseStore.getCaseById(caseId)
    invariant(match, 'Case not found')
    const revert = caseStore.setCase({ ...match, name })

    const res = await updateCaseBackend({ caseId, workspaceId, name })

    if (!res.ok) {
      revert()
    }

    return res
  }

  return {
    createCase,
    updateCaseName,
  }
}
