<script setup lang="ts">
import type { SidebarEmits, SidebarProps } from '@/modules/Project/Fields/field-types'
import { provideFieldContext } from '@/modules/Project/Fields/fieldContext'
import FileFieldUpload from '@/modules/Project/FileFieldUpload.vue'
import { useFileField } from '@/modules/Project/useFileField'
import { useResolveProjectRoute } from '@/modules/Project/useResolveProjectRoute'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import TagButton from '@/uiKit/TagButton.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { useDropZone } from '@vueuse/core'
import { computed, useTemplateRef } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps<SidebarProps<'file_collection'>>()

defineEmits<SidebarEmits<'file_collection'>>()

/**
 * The component doesn't render a fragment directly,
 * but the wrapping component in the template does, so we must disable inheritAttrs and
 * bind them to the correct slot element
 */
defineOptions({ inheritAttrs: false })

provideFieldContext({ field: props.field, property: props.property, column: 0, row: 0 })

const { filename, previewButton, supportedMimeTypes, dialogFiles } = useFileField(
  computed(() => props.field),
)

const dropzone = useTemplateRef('dropzone')
const { isOverDropZone } = useDropZone(dropzone, {
  onDrop: (files) => {
    if (files && files.length > 0) {
      dialogFiles.value = files
    }
  },
  multiple: false,
})

const { workspaceId } = useRouteParams()

const projectRoute = useResolveProjectRoute()
const router = useRouter()
const openTableView = () => {
  const route = projectRoute({
    projectId: props.property.config.subprojectConfig.child_project_id,
    workspaceId: workspaceId.value,
  })

  router.push(route)
}
</script>

<template>
  <FileFieldUpload
    v-slot="{ getTriggerProps, menu }"
    library-picker-confirm-label="Upload file"
    :file-input-props="{ accept: supportedMimeTypes.join(', '), multiple: false }"
    :positioning="{ placement: 'bottom-start', offset: { mainAxis: 2, crossAxis: 1 } }"
    @import:computer="dialogFiles = $event"
  >
    <div
      v-bind="$attrs"
      ref="dropzone"
      class="group flex min-h-7 grow items-center justify-between rounded-corner-8 pl-1 focus-within:bg-background-transparent-hovered hover:bg-background-transparent-hovered focus:bg-background-transparent-hovered focus:outline-none"
      :class="[(menu?.open || isOverDropZone) && 'bg-background-transparent-hovered']"
    >
      <ToolTip title="Open table view">
        <TagButton
          v-if="filename"
          variant="neutral"
          size="sm"
          :label="filename"
          aria-label="Open table view"
          @click.stop="openTableView"
        >
          <template #leading-icon>
            <IconSprite
              class="fill-icon-subtle pl-0.5"
              icon="multi-page-file"
              size="xs"
            />
          </template>
          {{ filename }}
          <template #trailing-icon>
            <BadgeItem
              v-if="field.subprojectPreview"
              class="min-w-0 grow"
              :label="field.subprojectPreview.entityCount.toString()"
              variant="neutral"
              size="xs"
            />
          </template>
        </TagButton>
      </ToolTip>

      <div class="flex flex-row items-center gap-0">
        <DarwinButton
          size="sm"
          variant="transparent"
          class="group-hover:opacity-100 group-[:focus-within]:opacity-100 focus:opacity-100"
          :class="[
            menu?.open ? 'opacity-100' : 'opacity-0',
            previewButton ? 'rounded-none' : 'rounded-l-none',
          ]"
          v-bind="getTriggerProps()"
          aria-label="Upload new file"
        >
          Upload
        </DarwinButton>
        <DarwinButton
          v-if="previewButton"
          size="sm"
          variant="transparent"
          class="rounded-l-none opacity-0 group-hover:opacity-100 group-[:focus-within]:opacity-100 focus:opacity-100"
          v-bind="previewButton.attrs"
          :aria-label="previewButton.text"
          @click.stop="$emit('file:preview', field)"
          >{{ previewButton.text }}</DarwinButton
        >
      </div>
    </div>
  </FileFieldUpload>
</template>
