<script lang="ts" setup>
import CaseDataRow from '@/modules/Cases/Sidebar/CaseDataRow.vue'
import TitleWithCount from '@/modules/Cases/Sidebar/TitleWithCount.vue'
import { isFullCase, type CaseFile } from '@/modules/Cases/types'
import { useCaseStore } from '@/modules/Cases/useCaseStore'
import FilePlaceholder from '@/modules/Workspaces/KnowledgeHub/Files/FilePlaceholder.vue'
import { computed } from 'vue'

const caseStore = useCaseStore()

const files = computed(() => {
  const activeCase = caseStore.activeCase
  return isFullCase(activeCase) ? activeCase.files : []
})

function getDescription(file: CaseFile) {
  const ext = file.name.split('.').pop()?.toUpperCase()
  return ext ? `${ext} File` : 'File'
}
</script>

<template>
  <!-- CaseUsedAgentList -->
  <div
    v-if="files.length"
    class="flex flex-col gap-2 px-2 py-4"
  >
    <TitleWithCount class="ml-2.5">
      <template #title>Sources</template>
      <template #count>{{ files.length }}</template>
    </TitleWithCount>
    <div
      v-for="file in files"
      :key="file.id"
    >
      <CaseDataRow class="cursor-pointer">
        <template #icon>
          <FilePlaceholder
            :name="file.name"
            class="size-full rounded-md border border-border"
          />
        </template>
        <template #name>
          <div>
            {{ file.name }}
          </div>
        </template>
        <template #description>{{ getDescription(file) }} </template>
      </CaseDataRow>
    </div>
  </div>
</template>
