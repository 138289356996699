<script setup lang="ts">
import IconButton from '@/uiKit/IconButton.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import { onMounted, useTemplateRef } from 'vue'

const props = defineProps<{
  totalMatches: number
  currentMatch: number
  ariaLabel?: string
}>()

const emit = defineEmits<{
  next: []
  previous: []
}>()

const value = defineModel<string | null>({ default: '' })

const onPrevious = () => {
  if (props.currentMatch > 0) {
    emit('previous')
  }
}

const onNext = () => {
  if (props.currentMatch < props.totalMatches - 1) {
    emit('next')
  }
}

const inputRef = useTemplateRef('input')
onMounted(() => {
  inputRef.value?.focus()
})
</script>

<template>
  <form
    class="flex items-center gap-2"
    @submit.prevent="onNext"
  >
    <InlineTextField
      ref="input"
      :aria-label="ariaLabel"
      size="sm"
      class="grow"
      :value="value || undefined"
      @input="value = $event"
      @keydown.up.prevent="onPrevious"
      @keydown.down.prevent="onNext"
    />
    <template v-if="totalMatches > 0">
      <div
        v-if="value"
        class="min-w-11 text-right text-sm-12px-light tabular-nums text-text-subtle"
      >
        {{ currentMatch + 1 }} / {{ totalMatches }}
      </div>
      <IconButton
        variant="transparent"
        size="xl"
        icon="chevron-top"
        type="button"
        aria-label="Previous match"
        rounded
        :disabled="currentMatch <= 0"
        @click="onPrevious"
      />
      <IconButton
        variant="transparent"
        size="xl"
        type="button"
        icon="chevron-bottom"
        rounded
        aria-label="Next match"
        :disabled="currentMatch >= totalMatches - 1"
        @click="onNext"
      />
    </template>
  </form>
</template>
