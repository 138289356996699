import type { components } from '@/api'
import { post } from './client'
import type { BackendMeta } from './types'

export type PerformCaseAsyncFileUpload = BackendMeta<
  '/api/workspaces/{workspace_id}/cases/{case_id}/async_file_upload',
  'post'
>

export const performCaseAsyncFileUpload = (
  workspaceId: string,
  caseId: string,
  params:
    | { type: 'url'; fileName?: string; fileUrl: string }
    | {
        type: 'integration'
        connectionId: string
        fileId: string
        fileName?: string | null
        integrationId: 'google_drive'
      },
) => {
  const body =
    params.type === 'url'
      ? { file_name: params.fileName, file_url: params.fileUrl }
      : {
          connection_id: params.connectionId,
          file_id: params.fileId,
          file_name: params.fileName,
          integration_id: params.integrationId,
        }
  return post<
    | components['schemas']['FILE_UPLOAD_FROM_URL']
    | components['schemas']['FILE_UPLOAD_FROM_INTEGRATION'],
    PerformCaseAsyncFileUpload['successResponse'],
    PerformCaseAsyncFileUpload['path']
  >(`/api/workspaces/${workspaceId}/cases/${caseId}/async_file_upload`, body)
}
