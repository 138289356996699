import { listCases } from '@/backend/listCases'
import { useLoadAllPaginatedResults } from '@/sharedComposables/useLoadAllPaginatedResults'
import { ref, watch, type Ref } from 'vue'
import { useRouter } from 'vue-router'
import { serializePaginatedCase } from './serializers'
import type { Case, PartialCase } from './types'
import { useCaseStore } from './useCaseStore'

type CaseArray = (PartialCase | Case)[]

// Without an explicit return type, we get TS errors in the IDE because the return type
// is longer than what the compiler will serialize.
type StoreReturn = {
  dashboardCases: Ref<CaseArray>
}

export const useLoadAllCases = (workspaceId: Ref<string>): StoreReturn => {
  const caseStore = useCaseStore()
  const router = useRouter()

  const alphabeticalCases = ref<CaseArray>([])

  const loadAllCases = useLoadAllPaginatedResults(
    async ({ after, first }) => {
      const res = await listCases({
        workspaceId: workspaceId.value,
        after,
        first,
        order: [['name', 'asc']],
      })

      if (!res.ok && res.error.code === 'not_found') {
        router.push({
          name: 'ErrorPage',
          query: {
            title: 'Workspace not found',
            message: 'We could not find the page you’re looking for.',
          },
        })
      }

      return res
    },
    (backendCase) => backendCase,
    (cases) => {
      alphabeticalCases.value = cases.map<PartialCase | Case>((c) => serializePaginatedCase(c))
    },
    20,
  )

  // Clear the projects and load new projects when the workspace changes
  watch(
    () => workspaceId.value,
    async () => {
      caseStore.setCases([])
      caseStore.setLoadingState('loading')
      try {
        await loadAllCases()
        caseStore.setLoadingState('loaded')
      } catch {
        caseStore.setLoadingState('errored')
      }
    },
    { immediate: true },
  )

  // When we fetch new paginated projects, add them to the store
  watch(
    () => alphabeticalCases.value,
    (cases) => {
      const definedProjects = cases.filter((c: PartialCase | Case): c is PartialCase | Case => !!c)
      const caseIds = definedProjects.map((c: PartialCase | Case) => c.id)
      const storeIds = caseStore.cases.map((c: PartialCase | Case) => c.id)
      if (caseIds.every((id: string, i: number) => id === storeIds[i])) {
        return
      }
      caseStore.addCases(definedProjects)
    },
  )

  return {
    dashboardCases: alphabeticalCases,
  }
}
