import { post } from './client'
import type { BackendMeta } from './types'

type CloneProjectMeta = BackendMeta<'/api/workspaces/{workspace_id}/projects/clone', 'post'>
export type CloneProjectPath = CloneProjectMeta['path']
export type CloneProjectResponse = CloneProjectMeta['successResponse']
export type CloneProjectRequest = CloneProjectMeta['body']

export type CloneProjectParams = {
  name: string | null
  workspaceId: string
  templateId: string
  cloneEntities?: boolean
}

export const cloneProject = ({
  name,
  workspaceId,
  templateId,
  cloneEntities = true,
}: CloneProjectParams) =>
  post<CloneProjectRequest, CloneProjectResponse, CloneProjectPath>(
    `/api/workspaces/${workspaceId}/projects/clone`,
    {
      name,
      template_id: templateId,
      clone_entities: cloneEntities,
    },
  )
