<script setup lang="ts">
import CaseDataAgentList from '@/modules/Cases/Sidebar/CaseDataAgentList.vue'
import CaseDataBreadcrumbs from '@/modules/Cases/Sidebar/CaseDataBreadcrumbs.vue'
import CaseDataEntity from '@/modules/Cases/Sidebar/CaseDataEntity.vue'
import CaseDataFileList from '@/modules/Cases/Sidebar/CaseDataFileList.vue'
import CaseDataHeaderLayout from '@/modules/Cases/Sidebar/CaseDataHeaderLayout.vue'
import CaseDataLiveProject from '@/modules/Cases/Sidebar/CaseDataLiveProject.vue'
import { useCaseStore } from '@/modules/Cases/useCaseStore'
import { useEntitiesToMarkdown } from '@/modules/Cases/useEntitiesToMarkdown'
import { buildEntityIdFilter } from '@/modules/Project/Filters/filterBuilders'
import { isProjectParams } from '@/modules/Project/Navigation/navigationTypes'
import { useProjectNavigationContext } from '@/modules/Project/Navigation/projectNavigationContext'
import ProjectTable from '@/modules/Project/ProjectTable.vue'
import { useEntity } from '@/modules/Project/useEntity'
import { useFilters } from '@/modules/Project/useFilters'
import { useProject } from '@/modules/Project/useProject'
import { copyToClipboard } from '@/shared/clipboard'
import { computed, onBeforeUnmount, watch } from 'vue'
import ExportButton from './ExportButton.vue'

const entityStore = useEntity()
const projectStore = useProject()
const caseStore = useCaseStore()
const filterStore = useFilters()
const entitiesToMarkdown = useEntitiesToMarkdown()

const navContext = useProjectNavigationContext({ required: true })

const projectId = computed(() => projectStore.projectId)

const selectedProjectId = computed(() => {
  return navContext.breadcrumbs.value.findLast((item) => isProjectParams(item))?.projectId
})

/**
 * Returns maps of project IDs to entity IDs sets for the active case.
 * Whenever we want to show the entity list for a project, we can use this
 * to set a filter on the project to show only the entities in the case.
 */
const caseEntityGroups = computed(() => {
  const groups = new Map<string, Set<string>>()
  const outputs = caseStore.activeCaseOutputs || []
  for (const output of outputs) {
    const projectGroup = groups.get(output.project.id)
    if (projectGroup) {
      projectGroup.add(output.entity.id)
    } else {
      groups.set(output.project.id, new Set([output.entity.id]))
    }
  }
  return groups
})

const copyEntitiesToClipboard = () =>
  copyToClipboard('Entity markdown', entitiesToMarkdown.getParsedFields())

/**
 * Whenever entities or cases change for an active case, we need to update
 * the filter on the project to show only the entities in the case.
 */
watch(
  [caseEntityGroups, projectId],
  () => {
    if (!projectId.value) return
    const entityIds = caseEntityGroups.value.get(projectId.value) || []
    const filter = buildEntityIdFilter(entityIds)
    filterStore.setCurrentFilter(filter)
  },
  { immediate: true },
)

/** Case-based filters are temporary, so when the component is unmounted, we need to reset the filter on the project. */
onBeforeUnmount(() => filterStore.setCurrentFilter())
</script>

<template>
  <div class="flex flex-col">
    <!-- Header -->
    <CaseDataHeaderLayout class="border-b border-border-subtle">
      <template #breadcrumbs>
        <CaseDataBreadcrumbs class="max-w-full" />
      </template>
      <template #actions>
        <div class="flex items-center gap-2">
          <ExportButton @copy="copyEntitiesToClipboard" />
        </div>
      </template>
    </CaseDataHeaderLayout>

    <div class="grow overflow-auto">
      <div v-if="!selectedProjectId">
        <CaseDataAgentList />
        <CaseDataFileList />
      </div>

      <CaseDataLiveProject
        v-else-if="selectedProjectId"
        :project-id="selectedProjectId"
      >
        <CaseDataEntity
          v-if="entityStore.entityId && projectStore.projectId"
          :entity-id="entityStore.entityId"
        />
        <ProjectTable
          v-else-if="selectedProjectId"
          class="size-full overflow-auto"
        />
      </CaseDataLiveProject>
    </div>
  </div>
</template>
