import { SUPPORTED_FILE_EXTENSIONS, SUPPORTED_FILE_EXTENSIONS_HELPER } from '@/backend/types'
import type { Field } from '@/modules/Project/Fields/types'
import { toast } from '@/shared/toast'
import { invariant } from '@/shared/utils/typeAssertions'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useFileType } from '@/sharedComposables/useFileType'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed, ref, watch, type Ref } from 'vue'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { canPreviewFileField } from './Fields/utils/file'
import { resolveFilename } from './resolveFilename'
import { useFieldUpload } from './useFieldUpload'
import { fileURL, useProject } from './useProject'
import { useSupportedMimeTypes } from './useSupportedMimeTypes'

/**
 * Provides functionality that is common to:
 * - a file field as represented by a table cell
 * - a file field as represented by an entity view card
 * - any other places we render file fields
 */
export const useFileField = (field: Ref<Field<'file' | 'file_collection'>>) => {
  const filename = computed(() => resolveFilename(field.value))

  const supportedMimeTypes = useSupportedMimeTypes(field)
  const supportedFileExtensions = [...SUPPORTED_FILE_EXTENSIONS]

  const displayUrl = computed<string | null>(() => fileURL(field.value, 'display'))
  const downloadUrl = computed<string | null>(() => fileURL(field.value, 'download'))

  const fileType = useFileType(displayUrl)

  /**
   * When there is a file, we either:
   * - Let the user preview the file inline for supported file types
   * - Let the user download the file for unsupported file types
   * This computed prop returns values we can pass to our button component to
   * support either case.
   */
  const previewButton = computed<null | {
    text: string
    attrs: { to?: string; target?: string }
  }>(() => {
    if (canPreviewFileField(field.value)) {
      return {
        text: 'Preview',
        attrs: {},
      }
    }

    if (downloadUrl.value) {
      return {
        attrs: { href: downloadUrl.value, target: '_blank' },
        text: 'Download',
      }
    }

    return null
  })

  const projectStore = useProject()
  const { captureAnalyticsEvent } = useAnalytics()
  const uploadStore = useFieldUpload()
  const { parentEntityId } = useRouteParams()

  const workspaceStore = useWorkspaces()

  const readyUpload = async (filesToUpload: File[]) => {
    const workspaceId = workspaceStore.currentWorkspace?.id
    const projectId = projectStore.projectId
    /**
     * This should never really happen - something very weird is happening if a user
     * is uploading a file without being in a workspace or project.
     */
    invariant(workspaceId && projectId, 'No workspace or project ID found when uploading a file.')

    const files = Array.from(filesToUpload)
    const unsupportedFiles = files.filter((f) => {
      // Some files have no type, so we fallback to the extension
      // https://developer.mozilla.org/en-US/docs/Web/API/Blob/type
      return f.type === ''
        ? !(f.name.split('.').pop() ?? '' in SUPPORTED_FILE_EXTENSIONS_HELPER)
        : !supportedMimeTypes.value.includes(f.type)
    })
    if (unsupportedFiles.length > 0) {
      toast.warning(
        [
          'Some of the files you tried to upload are not supported, so the upload was skipped.',
          'We only support the following file extensions:',
          supportedFileExtensions.join(', ').concat('.'),
        ].join(' '),
      )
      return
    }

    const [file, ...moreFiles] = files

    if (file) {
      uploadStore.addUpload({
        workspaceId,
        projectId,
        entityId: field.value.entityId,
        propertyId: field.value.propertyId,
        file,
        parentEntityId: parentEntityId.value,
      })
    }

    if (moreFiles.length === 0) {
      return
    }

    const currentIndex = projectStore.activeView?.entityIdToIndex?.get(field.value.entityId) ?? -1
    const remainingEntities = projectStore.activeView?.entities?.slice(currentIndex + 1) ?? []

    let foundCount = 0
    const pendingUpdates: {
      workspaceId: string
      projectId: string
      entityId: string
      propertyId: string
      parentEntityId: string | null
      file: File
    }[] = []
    for (let i = 0; foundCount < moreFiles.length && i <= remainingEntities.length; i++) {
      const entity = remainingEntities[i]
      if (entity && !entity.fields.get(field.value.propertyId)?.manualValue) {
        pendingUpdates.push({
          workspaceId,
          projectId,
          entityId: entity.id,
          propertyId: field.value.propertyId,
          file: moreFiles[foundCount],
          parentEntityId: parentEntityId.value,
        })
        foundCount++
      }
    }

    pendingUpdates.forEach((u) => {
      uploadStore.addUpload({
        workspaceId: u.workspaceId,
        projectId: u.projectId,
        entityId: u.entityId,
        propertyId: u.propertyId,
        file: u.file,
        parentEntityId: u.parentEntityId,
      })

      captureAnalyticsEvent(ANALYTICS_EVENT.FILE_UPLOAD_PENDING, {
        workspaceId: u.workspaceId,
        projectId: u.projectId,
        entityId: u.entityId,
        propertyId: u.propertyId,
      })
    })

    const pendingCreates = moreFiles.slice(pendingUpdates.length)
    if (pendingCreates.length > 0) {
      uploadStore.addPendingUploads({
        workspaceId,
        projectId,
        propertyId: field.value.propertyId,
        files: pendingCreates,
        parentEntityId: parentEntityId.value,
      })
    }
  }

  const dialogFiles = ref<File[]>([])
  watch(dialogFiles, (files) => {
    readyUpload(files)
  })

  return {
    /**
     * This is a URL to a PDF version of the file, that can be previewed in the browser. This
     * is used when the user uploads a file that cannot be previewed in the browser (e.g. .docx).
     * The backend converts the file to a PDF so that we can preview it.
     */
    displayUrl,
    /** This is a signed URL to download the exact file the user uploaded. */
    downloadUrl,
    filename,
    supportedMimeTypes,
    supportedFileExtensions,
    fileType,
    previewButton,
    dialogFiles,
  }
}
