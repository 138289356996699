import { listConnections } from '@/backend/listConnections'
import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { integrations } from '@/modules/Workspaces/KnowledgeHub/Integrations/integrations'
import type {
  IntegrationConnection,
  IntegrationConnectionRaw,
  IntegrationId,
} from '@/modules/Workspaces/KnowledgeHub/Integrations/integrationTypes'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { serializeObject } from '@/shared/utils/serialization'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

export const useIntegrationStore = defineStore('integrations', () => {
  const workspace = useCurrentWorkspace()
  const workspaceId = computed(() => workspace.value.id)

  const hasAccess = ref<boolean>()
  const connections = ref<IntegrationConnection[]>([])

  /** Whether integrations had a first load. */
  const isLoaded = ref(false)

  /** All integrations that are available, including ones not connected. */
  const allIntegrations = ref(integrations)

  const startedConnections = computed(() => {
    return connections.value.filter((c) => c.status === 'started')
  })

  /** Integrations that have a connection (that might be started or errored). */
  const connectedIntegrations = computed<string[]>(() => {
    const integrationList = Object.values(allIntegrations.value).map((i) => i.id)
    return integrationList.filter((id) => connections.value.some((c) => c.integrationId === id))
  })

  const knowledgeHubEnabled = useFeatureFlags(FeatureFlag.KNOWLEDGE_HUB)

  async function load() {
    if (!workspace.value.id || !knowledgeHubEnabled.value) return
    const res = await listConnections({ workspaceId: workspace.value.id })
    // If the user is not an admin, he cannot access connections.
    // In the future, this logic will change in the backend, so the error is
    // the best way to check access for now.
    hasAccess.value = res.ok
    const rawData: IntegrationConnectionRaw[] = res.ok
      ? (res.data.data as IntegrationConnectionRaw[])
      : []

    connections.value = serializeObject(rawData)
    isLoaded.value = true
  }

  /**
   * Returns true if the integration has a corresponding connection established.
   */
  function findConnection(integrationId: IntegrationId) {
    return connections.value.find((c) => c.integrationId === integrationId)
  }

  watch(
    // Workspace ID can change at runtime, and FF can be late to come when loading the page initially.
    [workspaceId, knowledgeHubEnabled],
    ([workspaceId, isEnabled]) => {
      if (workspaceId && isEnabled) {
        load()
      }
    },
    { immediate: true },
  )

  return {
    connections,
    hasAccess,
    load,
    startedConnections,
    connectedIntegrations,
    findConnection,
    isLoaded,
  }
})
