import { computed, ref, watch, type Ref } from 'vue'
import { useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'
import type { Field } from './Fields/types'
import type { Property } from './Properties/types'
import { useFieldValue } from './useFieldValue'

type SelectType = 'single_select' | 'multi_select' | 'user_select'

/**
 * Contains controlled and derived state used when rendering a component for a select field.
 * The property type can be any kind of select (single, multi, user), and the state/logic
 * exposed here can be used in any place in the UI that one of these fields is rendered.
 */
export const useSelectField = (
  field: Ref<Field<SelectType> | undefined>,
  property: Ref<Property<SelectType>>,
) => {
  const valueToColorMap = computed(() =>
    Object.fromEntries(property.value.config.options.map((o) => [o.value, o.color]) ?? []),
  )

  const optionColours = computed(() => property.value.config.options.map((o) => o.color))

  const localValue = ref<Field<SelectType>['manualValue']>([])
  const savedValue = useFieldValue(field)
  watch(
    savedValue,
    (newValue) => {
      localValue.value = newValue
    },
    { immediate: true },
  )

  const workspaceMembersStore = useWorkspaceMembers()
  const selectOptions = ref<
    {
      id: string
      data: {
        label: string
        color?: string | null
      }
    }[]
  >([])
  watch(
    property,
    (newValue) => {
      selectOptions.value = newValue.config.options.map((option) => ({
        id: option.value,
        data: {
          label:
            newValue.type === 'user_select'
              ? workspaceMembersStore.getMemberNameFromId(option.value)
              : option.value,
          color: option.color,
        },
      }))
    },
    { immediate: true },
  )

  return { valueToColorMap, localValue, selectOptions, optionColours }
}
