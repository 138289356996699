import { exhaustiveGuard } from '@/shared/utils/typeAssertions'
import {
  isFullCase,
  type Case,
  type CaseInputAttachment,
  type CaseSource,
  type PartialCase,
} from './types'

/** Gets the human-readable label associated with a case source. */
export const getCaseSourceLabel = (source: CaseSource) => {
  switch (source.type) {
    case 'file': {
      return source.file.filename
    }
    case 'library-item': {
      return source.libraryItem.name
    }
    default: {
      return exhaustiveGuard(source, 'Unhandled case source type')
    }
  }
}

/**
 * Combines file id on a message with file data in a case record to return a list of attachments.
 * This is used to display attachments in a message and to upload new attachments.
 */
export const getMessageAttachments = (
  message: Case['messages'][number],
  activeCase: PartialCase | Case | null,
): CaseInputAttachment[] => {
  if (
    // response messages don't get attachments (yet?)
    !isFullCase(activeCase) ||
    message.type === 'agent_run' ||
    message.type === 'partial_response' ||
    message.type === 'response' ||
    message.type === 'tool_run'
  ) {
    return []
  }

  const attachments: CaseInputAttachment[] = []
  message.fileIds.forEach((fileId) => {
    const file = activeCase.files.find((file) => file.id === fileId)

    if (file) {
      attachments.push({
        uploadId: file.id,
        type: 'file',
        name: file.name,
        fileId: file.id,
        progress: null,
        status: 'complete',
      })
    }
  })

  return attachments
}
