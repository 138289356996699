import { getEffectivePlan } from '@/backend/getEffectivePlan'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { createSharedComposable, useThrottleFn } from '@vueuse/core'
import { ref } from 'vue'
import { serializePlan, useBilling } from './useBilling'

/**
 * Returns a function that can be used to refresh the usage data for the current workspace.
 */
export const useRefreshUsage = createSharedComposable(() => {
  const billingStore = useBilling()
  const currentWorkspace = useCurrentWorkspace()
  const effectivePlanLoader = useDataLoader(() => getEffectivePlan(currentWorkspace.value.id))

  const hasSkippedRefresh = ref(false)

  const POLLING_DELAY = 2000

  const refreshUsage = async () => {
    const effectivePlanResponse = await effectivePlanLoader.load()
    if (!effectivePlanResponse.ok) {
      return
    }

    billingStore.activePlan = serializePlan(effectivePlanResponse.data)
  }

  // This function will refresh the usage data for the current workspace `maxRetries` times. There is no way to use a re-try mechanism
  const refreshUsageNTimes = async (maxRetries: number) => {
    for (let i = 0; i <= maxRetries; i++) {
      await refreshUsage()
      await new Promise((r) => setTimeout(r, POLLING_DELAY))
    }
  }

  const throttledRefreshUsage = useThrottleFn(refreshUsage, POLLING_DELAY)

  return { refreshUsage: throttledRefreshUsage, refreshUsageNTimes, hasSkippedRefresh }
})
