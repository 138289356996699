import { computed } from 'vue'
import { isFullCase } from './types'
import { useCasePendingQueryStore } from './useCasePendingQueryStore'
import { useCaseStore } from './useCaseStore'

export const useIsCaseReady = () => {
  const caseStore = useCaseStore()
  const pendingStore = useCasePendingQueryStore()

  const isReady = computed(() => {
    // we already have a pending query, so we can't send another one
    if (pendingStore.pendingQueries.length > 0) {
      return false
    }

    // we don't have a case, so we can't send a query
    if (!isFullCase(caseStore.activeCase)) {
      return false
    }

    const messagesLatestFirst = caseStore.activeCase.messages

    // it's a empty case. we HAVE to send a query
    if (messagesLatestFirst.length === 0) {
      return true
    }

    const lastMessage = messagesLatestFirst[0]

    // we sent a query and haven't gotten a response yet
    if (lastMessage.type === 'query') {
      return false
    }

    // we got a response but it's not complete yet
    if (lastMessage.type === 'partial_response') {
      return false
    }

    if (lastMessage.type === 'agent_run' && lastMessage.inProgress) {
      return false
    }

    if (lastMessage.type === 'tool_run' && lastMessage.inProgress) {
      return false
    }

    return true
  })

  return { isReady }
}
