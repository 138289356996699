<script setup lang="ts">
import { useCaseStore } from '@/modules/Cases/useCaseStore'
import { copyToClipboard } from '@/shared/clipboard'
import { omit } from '@/shared/utils'
import { getWindow } from '@/shared/utils/window'
import IconButton, { type IconButtonSize } from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import DeleteCaseDialog from './DeleteCaseDialog.vue'
import RenameCaseDialog from './RenameCaseDialog.vue'

const props = withDefaults(
  defineProps<{
    workspaceId: string
    caseId: string
    size?: IconButtonSize
  }>(),
  {
    size: 'xs',
  },
)

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'rename', name: string): void
}>()

const isDeleting = ref(false)
const isRenaming = ref(false)
const menuRef = ref()

const caseStore = useCaseStore()
const { resolve } = useRouter()

const canDelete = computed(() => {
  return true // placeholder from when we will implement cases permissions
})
const canUpdate = computed(() => {
  return true // placeholder from when we will implement cases permissions
})

const link = computed(() =>
  resolve({
    name: 'Case',
    params: { workspaceId: props.workspaceId, caseId: props.caseId },
  }),
)

const targetCase = computed(() => caseStore.getCaseById(props.caseId))

const handleRename = async (value: string) => {
  if (value.trim() === '') return
  emit('rename', value.trim())

  isRenaming.value = false
}

defineOptions({
  inheritAttrs: false,
})
</script>

<template>
  <Menu.Root
    v-if="targetCase"
    ref="menuRef"
    v-slot="{ getTriggerProps }"
    close-on-select
  >
    <IconButton
      icon="more-dots"
      data-test="case-menu-trigger"
      :size="size"
      variant="transparent"
      class="focus:outline-none focus:ring-2"
      v-bind="{ ...omit(getTriggerProps(), ['onSelect', 'disabled']), ...$attrs }"
      @click.prevent
    >
      Click me
    </IconButton>
    <Menu.Content class="min-w-56">
      <Menu.Item
        icon="arrow-top-right"
        @select="() => getWindow()?.open(link.href, '_blank')"
      >
        Open in a new tab
      </Menu.Item>
      <Menu.Item
        icon="arrow-top-right"
        @select="() => getWindow()?.open(link.href, '_blank', 'toolbar=0,location=0,menubar=0')"
      >
        Open in a new window
      </Menu.Item>
      <ToolTip
        class="w-full"
        arrow
        :placement="{ allowedPlacements: ['left'] }"
        :title="caseId"
      >
        <Menu.Item
          icon="copy"
          @select="() => copyToClipboard('Case ID', caseId)"
        >
          Copy case ID
        </Menu.Item>
      </ToolTip>

      <Menu.Divider v-if="canUpdate || canDelete" />

      <Menu.Item
        v-if="canUpdate"
        icon="edit"
        @select="
          () => {
            isRenaming = true
          }
        "
      >
        Rename case
      </Menu.Item>
      <Menu.Item
        v-if="canDelete"
        icon="trash"
        critical
        @select="isDeleting = true"
      >
        Delete case
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>

  <DeleteCaseDialog
    :case-id="caseId"
    :workspace-id="workspaceId"
    :open="isDeleting"
    @delete="emit('delete')"
    @close="isDeleting = false"
  />

  <RenameCaseDialog
    :value="targetCase?.name"
    :open="isRenaming"
    @rename="handleRename"
    @close="isRenaming = false"
  />
</template>
