import { post } from './client'
import type { BackendMeta } from './types'

export type AddCaseQuery = BackendMeta<
  '/api/workspaces/{workspace_id}/cases/{case_id}/queries',
  'post'
>

export type AddCaseQueryParams = {
  workspaceId: string
  caseId: string
  fileIds: string[]
  text: string
  agentId: string | null
}

export const addCaseQuery = async ({
  caseId,
  fileIds,
  text,
  workspaceId,
  agentId,
}: AddCaseQueryParams) =>
  post<AddCaseQuery['body'], AddCaseQuery['successResponse'], AddCaseQuery['path']>(
    `/api/workspaces/${workspaceId}/cases/${caseId}/queries`,
    {
      agent_id: agentId,
      file_ids: fileIds,
      text,
    },
  )
