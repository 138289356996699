<template>
  <div class="flex size-full grow flex-col items-center justify-center">
    <div class="text-sm-12px-default">
      <slot name="title" />
    </div>

    <div class="text-sm-12px-light text-text-subtle">
      <slot name="description" />
    </div>
  </div>
</template>
