<script setup lang="ts">
import type { LibraryFile } from '@/modules/Library/libraryStore'
import FilePickerDialog from '@/modules/Workspaces/KnowledgeHub/Files/FilePickerDialog.vue'
import {
  useGoogleDriveConnection,
  type GoogleDriveFile,
} from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/useGoogleDriveConnection'
import { invariant } from '@/shared/utils/typeAssertions'
import Menu from '@/uiKit/Menu'
import { whenever } from '@vueuse/core'
import type * as zagMenu from '@zag-js/menu'
import { ref, useId, useTemplateRef, type InputHTMLAttributes } from 'vue'

/**
 * This component provides a dropdown menu for importing files from a range
 * of sources, supporting local uploads, knowledgehub files, and files
 * from integrations.
 */

defineProps<{
  libraryPickerConfirmLabel: string
  /** Attributes to pass to the hidden file input when uploading local files */
  fileInputProps?: InputHTMLAttributes
  positioning?: zagMenu.Props['positioning']
}>()

const emit = defineEmits<{
  'import:computer': [File[]]
  'import:library': [LibraryFile[]]
  'import:google': [GoogleDriveFile[]]
  'menu:close': []
  'done:library': []
}>()

const { connection: driveConnection, pick: pickDrive } = useGoogleDriveConnection()

const isFilePickerOpen = ref(false)
whenever(
  () => !isFilePickerOpen.value,
  () => emit('done:library'),
)

async function onLibrarySelect(files: LibraryFile[] = []) {
  isFilePickerOpen.value = false
  emit('import:library', files)
}

async function onDriveSelect() {
  const files = await pickDrive()
  emit('import:google', files)
}

function onOpenStateChange(open: boolean) {
  if (!open) {
    emit('menu:close')
  }
}

const fileInput = useTemplateRef('file-input')
const onAddFile = (e: Event) => {
  invariant(e.target instanceof HTMLInputElement)
  if (!e.target.files) return
  emit('import:computer', Array.from(e.target.files))
}

const fileInputId = useId()
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps, menu }"
    :positioning="positioning"
    close-on-select
    @change:open="onOpenStateChange"
  >
    <slot
      :get-trigger-props="getTriggerProps"
      :menu="menu"
    />

    <Menu.Content>
      <Menu.Item
        label="Import from computer"
        @select="fileInput?.click()"
      />
      <Menu.Divider />
      <Menu.Item
        icon="folder"
        label="Pick from Library"
        @select="isFilePickerOpen = true"
      />
      <Menu.Item
        v-if="driveConnection"
        icon="google-drive-logo"
        label="Google Drive"
        @select="onDriveSelect"
      />
    </Menu.Content>
  </Menu.Root>
  <FilePickerDialog
    v-model:open="isFilePickerOpen"
    only="file"
    :confirm-label="libraryPickerConfirmLabel"
    @select="onLibrarySelect"
  />
  <input
    :id="fileInputId"
    ref="file-input"
    name="file-input"
    class="hidden"
    data-test="file-upload-menu-file-input"
    type="file"
    v-bind="fileInputProps"
    multiple
    @change="onAddFile"
  />
</template>
