<script setup lang="ts">
import { useProjectCreateModal } from '@/modules/Projects/ProjectCreateModal.vue'
import { useTemplateModal } from '@/modules/Workspaces/TemplateModal.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useCsvImportModal } from '../Workspaces/CsvImportModal.vue'

defineProps<{ workspaceId: string; size?: 'md' | 'xs' | 'sm' | 'lg' }>()

const templateModal = useTemplateModal()
const csvModal = useCsvImportModal()
const projectCreateModal = useProjectCreateModal()

const permissionsStore = usePermissionsStore()
</script>

<template>
  <Menu.Root
    v-if="permissionsStore.workspacePermissions.create_projects"
    v-slot="{ getTriggerProps, menu }"
  >
    <DarwinButton
      variant="black"
      data-test="new-project-button-homepage"
      :size="size || 'sm'"
      rounded
      v-bind="{ ...getTriggerProps(), ...$attrs }"
    >
      <template #leading-icon><IconSprite icon="plus" /></template>
      <template #trailing-icon><IconSprite icon="chevron-bottom" /></template>
      New project
    </DarwinButton>
    <Menu.Content class="min-w-[242px]">
      <Menu.Item
        data-test="explore-templates"
        label="Explore templates"
        @select="templateModal.open"
      >
        <template #prefix>
          <div class="flex size-5 items-center justify-center text-icon-subtle">
            <IconSprite icon="templates" />
          </div>
        </template>
      </Menu.Item>
      <Menu.Item
        label="Import CSV File"
        @select="csvModal.open"
      >
        <template #prefix>
          <div class="flex size-5 items-center justify-center text-icon-subtle">
            <IconSprite icon="table" />
          </div>
        </template>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        label="Start from scratch"
        @click="
          () => {
            menu.setOpen(false)
            projectCreateModal.open()
          }
        "
      />
    </Menu.Content>
  </Menu.Root>
</template>
