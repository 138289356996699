<script setup lang="ts">
import { useNumberField } from '@/modules/Project/useNumberField'
import { normaliseLocalisedNumberString } from '@/shared/utils/string'
import { computed } from 'vue'
import type { SidebarEmits } from '../../field-types'
import { type SidebarProps } from '../../field-types'

const props = defineProps<SidebarProps<'number'>>()

defineEmits<SidebarEmits<'number'>>()

const { inputFieldValue, shouldBeRed } = useNumberField(
  computed(() => props.field),
  computed(() => props.property),
)
</script>

<template>
  <input
    v-model="inputFieldValue"
    class="h-7 w-full rounded-corner-8 bg-background-transparent px-2 py-0.5 text-sm-12px-light outline-none hover:bg-background-transparent-hovered focus-visible:bg-background-transparent-hovered"
    :class="[shouldBeRed ? 'text-text-critical' : 'text-text']"
    inputmode="numeric"
    type="text"
    @change="$emit('submit', normaliseLocalisedNumberString(inputFieldValue))"
  />
</template>
