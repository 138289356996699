<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { updateView } from '@/backend/updateView'
import { toggleArray } from '@/shared/utils'
import IconSprite from '@/uiKit/IconSprite.vue'

import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import Menu from '@/uiKit/Menu'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import ProjectConfigurationMenuItem from './ProjectConfigurationMenuItem.vue'
import { useProject } from './useProject'

const projectStore = useProject()
const workspacesStore = useWorkspaces()

const isOpen = ref(false)

const { captureAnalyticsEvent } = useAnalytics()
onMounted(() => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_PROPERTY_VISIBILITY_MENU)
})

const toggleVisibility = async (propertyItem: { id: string; label: string }) => {
  const property = projectStore.properties.find((p) => p.id === propertyItem.id)
  if (
    property &&
    projectStore.activeView &&
    workspacesStore.currentWorkspace &&
    projectStore.projectId
  ) {
    const propertyIds = toggleArray(
      projectStore.activeView.view.propertyIds ?? [],
      property.id,
      projectStore.properties.map((p) => p.id),
    )

    projectStore.upsertView({
      id: projectStore.activeView.id,
      name: projectStore.activeView.view.name,
      propertyIds,
      propertyLayouts: projectStore.activeView.view.propertyLayouts,
      filters: projectStore.activeView.view.filters,
      assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
      propertyOptions: projectStore.activeView.view.propertyOptions,
      numPinnedProperties: projectStore.activeView.view.numPinnedProperties,
    })

    await updateView({
      workspaceId: workspacesStore.currentWorkspace.id,
      projectId: projectStore.projectId,
      viewId: projectStore.activeView.id,
      name: projectStore.activeView.view.name,
      propertyIds,
      propertyLayouts: projectStore.activeView.view.propertyLayouts,
      filters: projectStore.activeView.view.filters,
      propertyOptions: projectStore.activeView.view.propertyOptions ?? [],
      assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
    })
  }
}

const items = computed(() =>
  projectStore.properties.map((property) => ({
    id: property.id,
    label: property.name,
    type: property.type,
    visibility: projectStore.activeView?.view.propertyIds?.includes(property.id) ?? false,
    readonly:
      projectStore.activeView?.view.propertyOptions?.find(
        (propertyOption) => propertyOption.property_id === property.id,
      )?.block_workers_edits ?? false,
  })),
)

const setReadWriteOption = async (propertyItem: { id: string }, readonly: boolean) => {
  const property = projectStore.properties.find((p) => p.id === propertyItem.id)
  if (
    !property ||
    !projectStore.activeView ||
    !workspacesStore.currentWorkspace ||
    !projectStore.projectId
  ) {
    return
  }

  const propertyOptions = (projectStore.activeView.view.propertyOptions || []).slice() // Copy the array
  const optionIndex = propertyOptions.findIndex((option) => option.property_id === property.id)
  if (optionIndex !== -1) {
    propertyOptions[optionIndex] = {
      ...propertyOptions[optionIndex],
      block_workers_edits: readonly,
    }
  } else {
    // Add the new property option
    propertyOptions.push({
      property_id: property.id,
      block_workers_edits: readonly,
    })
  }
  projectStore.upsertView({
    id: projectStore.activeView.id,
    name: projectStore.activeView.view.name,
    propertyIds: projectStore.activeView.view.propertyIds,
    propertyLayouts: projectStore.activeView.view.propertyLayouts,
    filters: projectStore.activeView.view.filters,
    assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
    propertyOptions,
    numPinnedProperties: projectStore.activeView.view.numPinnedProperties,
  })
  await updateView({
    workspaceId: workspacesStore.currentWorkspace.id,
    projectId: projectStore.projectId,
    viewId: projectStore.activeView.id,
    name: projectStore.activeView.view.name,
    propertyIds: projectStore.activeView.view.propertyIds || [],
    propertyLayouts: projectStore.activeView.view.propertyLayouts,
    filters: projectStore.activeView.view.filters,
    propertyOptions,
    assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
  })
}

const canAssignWork = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)

const filteredItems = ref<(typeof items)['value']>([])

const visibleProperties = computed(() => filteredItems.value.filter((item) => item.visibility))
const hiddenProperties = computed(() => filteredItems.value.filter((item) => !item.visibility))
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    :positioning="{ offset: { mainAxis: 0 } }"
    @change:open="isOpen = $event"
  >
    <button
      id="project-configuration"
      class="flex w-full select-none items-center border-b border-l border-border-subtle p-2.5 text-icon-subtle transition-colors hover:bg-background-transparent-hovered focus-visible:outline-none active:bg-background-transparent-pressed"
      :class="isOpen ? 'bg-background-transparent-hovered' : 'bg-background-transparent'"
      v-bind="getTriggerProps()"
      aria-label="Configure project"
      data-test="project-configuration"
    >
      <IconSprite
        icon="more-dots"
        size="sm"
      />
    </button>
    <Menu.Content
      class="go-scrollbar max-h-[500px] overflow-y-auto overflow-x-hidden"
      :class="canAssignWork && 'w-[320px]'"
    >
      <Menu.Search
        :items="items"
        key-or-predicate="label"
        @change="filteredItems = $event"
      />
      <template v-if="visibleProperties.length > 0">
        <Menu.GroupTitle label="Visible" />
        <ProjectConfigurationMenuItem
          v-for="item in visibleProperties"
          :key="item.id"
          :checked="projectStore.activeView?.view.propertyIds?.includes(item.id) ?? false"
          :readonly="item.readonly"
          :property-id="item.id"
          :type="item.type"
          :label="item.label"
          @toggle:visibility="toggleVisibility(item)"
          @set:readonly="setReadWriteOption(item, $event)"
        />
      </template>
      <template v-if="hiddenProperties.length > 0">
        <Menu.GroupTitle label="Hidden" />
        <ProjectConfigurationMenuItem
          v-for="item in hiddenProperties"
          :key="item.id"
          :checked="projectStore.activeView?.view.propertyIds?.includes(item.id) ?? false"
          :readonly="item.readonly"
          :property-id="item.id"
          :type="item.type"
          :label="item.label"
          @toggle:visibility="toggleVisibility(item)"
          @set:readonly="setReadWriteOption(item, $event)"
        />
      </template>
    </Menu.Content>
  </Menu.Root>
</template>
