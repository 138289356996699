<script setup lang="ts">
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import Menu from '@/uiKit/Menu'
import ToolTip from '@/uiKit/ToolTip.vue'

defineProps<{
  loading?: boolean
}>()

const emit = defineEmits<{
  (e: 'click', format: 'csv' | 'xlsx'): void
}>()

const onExportViaZapier = () => {
  window.open('https://zapier.com/apps/v7-go/integrations', '_blank')
}

const onSendViaApi = () => {
  window.open('https://docs.go.v7labs.com/reference/generate-an-api-key', '_blank')
}
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    close-on-select
  >
    <ToolTip
      arrow
      :placement="{ allowedPlacements: ['left'] }"
      title="Export table"
    >
      <DarwinButton
        size="sm"
        icon="upload"
        rounded
        variant="neutral"
        name="Open export menu"
        aria-label="Open export menu"
        :loading="loading"
        v-bind="getTriggerProps()"
        :disabled="loading"
      >
        Export
      </DarwinButton>
    </ToolTip>
    <Menu.Content class="go-scrollbar max-h-[340px] w-80 overflow-y-auto overflow-x-hidden">
      <Menu.Item
        name="Export as CSV"
        class="flex w-full items-center justify-start gap-2 !p-2"
        role="menutem"
        aria-label="Export as CSV"
        @select="emit('click', 'csv')"
        @keydown.enter="emit('click', 'csv')"
      >
        <AvatarIcon
          icon-name="table"
          size="lg"
        />
        <div class="flex grow flex-col items-start justify-start">
          <p class="text-sm-12px-default leading-4 text-text">Export as CSV</p>
          <p class="text-sm-12px-light text-text-subtle">Broadly compatible format</p>
        </div>
      </Menu.Item>
      <Menu.Item
        class="flex w-full items-center justify-start gap-2 !p-2"
        role="menuitem"
        name="Export as Excel"
        aria-label="Export as Excel"
        @select="emit('click', 'xlsx')"
        @keydown.enter="emit('click', 'xlsx')"
      >
        <AvatarIcon
          icon-name="excel-logo"
          size="lg"
        />
        <div class="flex grow flex-col items-start justify-start">
          <p class="text-sm-12px-default leading-4 text-text">Export as XLSX</p>
          <p class="text-sm-12px-light text-text-subtle">Supports views and formatting</p>
        </div>
      </Menu.Item>
      <Menu.Item
        class="flex size-full items-center justify-start gap-2 !p-2"
        role="menuitem"
        name="Send to Zapier"
        aria-label="Send to Zapier"
        @select="onExportViaZapier"
        @keydown.enter="onExportViaZapier"
      >
        <AvatarIcon
          icon-name="company-zapier"
          size="lg"
        />
        <div class="flex grow flex-col items-start justify-start">
          <p class="text-sm-12px-default leading-4 text-text">Send to Zapier</p>
        </div>
      </Menu.Item>
      <Menu.Item
        class="flex size-full items-center justify-start gap-2 !p-2"
        role="menuitem"
        name="Send via the API"
        aria-label="Send via the API"
        @select="onSendViaApi"
        @keydown.enter="onSendViaApi"
      >
        <AvatarIcon
          icon-name="json"
          size="lg"
        />
        <div class="flex grow flex-col items-start justify-start">
          <p class="text-sm-12px-default leading-4 text-text">Send via the API</p>
        </div>
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
