import { post } from './client'
import type { BackendMeta } from './types'

export type ConfirmCaseFilesUploaded = BackendMeta<
  '/api/workspaces/{workspace_id}/cases/{case_id}/confirm_files_uploaded',
  'post'
>

/**
 * Fires a network request to he backend to provide a file url to upload a file to
 * The backend action will generate a presigned url for the file and return it,
 * and also save it onto the manual_value of the given field.
 */
export const confirmCaseFilesUploaded = (workspaceId: string, caseId: string, fileIds: string[]) =>
  post<
    ConfirmCaseFilesUploaded['body'],
    ConfirmCaseFilesUploaded['successResponse'],
    ConfirmCaseFilesUploaded['path']
  >(`/api/workspaces/${workspaceId}/cases/${caseId}/confirm_files_uploaded`, { file_ids: fileIds })
