<script setup lang="ts">
import { isEntityParams, type NavigationParams } from '@/modules/Project/Navigation/navigationTypes'
import { useProjectNavigationContext } from '@/modules/Project/Navigation/projectNavigationContext'
import { useProject } from '@/modules/Project/useProject'
import { useResolveEntityRoute } from '@/modules/Project/useResolveEntityRoute'
import { useResolveProjectRoute } from '@/modules/Project/useResolveProjectRoute'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { invariant } from '@/shared/utils/typeAssertions'
import { computed } from 'vue'
import { RouterLink, type RouteLocationResolved } from 'vue-router'

const props = defineProps<{ to: NavigationParams }>()

const project = useProject()
const workspace = useCurrentWorkspace()

const resolveEntityRoute = useResolveEntityRoute()
const resolveProjectRoute = useResolveProjectRoute()
const navigationContext = useProjectNavigationContext()

const workspaceId = computed(() => workspace.value.id)

const projectId = computed<string>(() => {
  if (props.to.projectId) {
    return props.to.projectId
  }

  invariant(project.projectId)
  return project.projectId
})

const routeParams = computed(() => ({
  workspaceId: workspaceId.value,
  projectId: projectId.value,
  ...props.to,
}))

/**
 * Route that will be used to navigate to the project or entity when the link is clicked
 */
const route = computed<RouteLocationResolved>(() => {
  const params = routeParams.value
  return isEntityParams(params) ? resolveEntityRoute(params) : resolveProjectRoute(params)
})

/**
 * Callback that will be invoked when a custom link handler button is clicked
 */
const clickHandler = computed(() => {
  const params = routeParams.value
  if (navigationContext) {
    return () => navigationContext.navigate(params)
  }

  return undefined
})
</script>

<template>
  <button
    v-if="clickHandler"
    @click.stop="clickHandler"
  >
    <slot />
  </button>
  <RouterLink
    v-else
    :to="route"
  >
    <slot />
  </RouterLink>
</template>
