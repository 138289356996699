<script setup lang="ts">
import type { Field } from '@/modules/Project/Fields/types'
import FileFieldUpload from '@/modules/Project/FileFieldUpload.vue'
import type { Property } from '@/modules/Project/Properties/types'
import { useFieldDeleteFile } from '@/modules/Project/useFieldDeleteFile'
import { useFileField } from '@/modules/Project/useFileField'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { useDropZone } from '@vueuse/core'
import { computed, useTemplateRef } from 'vue'
import type { SidebarEmits } from '../../field-types'
import { provideFieldContext } from '../../fieldContext'

const props = defineProps<{
  field: Field<'file'>
  property: Property<'file'>
}>()

defineEmits<SidebarEmits<'file'>>()

provideFieldContext({ field: props.field, property: props.property, column: 0, row: 0 })

const { filename, previewButton, supportedMimeTypes, dialogFiles } = useFileField(
  computed(() => props.field),
)

const dropzone = useTemplateRef('dropzone')
const { isOverDropZone } = useDropZone(dropzone, {
  onDrop: (files) => {
    if (files && files.length > 0) {
      dialogFiles.value = files
    }
  },
  multiple: false,
})

defineOptions({ inheritAttrs: false })

const { deleteFile } = useFieldDeleteFile(computed(() => props.field))

const isDeletable = computed(() => filename.value && !!props.field.manualValue)
const isOverriden = computed(() => props.field.toolValue && props.field.manualValue)
</script>

<template>
  <FileFieldUpload
    v-slot="{ getTriggerProps, menu }"
    library-picker-confirm-label="Upload file"
    :file-input-props="{
      accept: supportedMimeTypes.join(', '),
      multiple: false,
    }"
    :positioning="{ placement: 'bottom-start', offset: { mainAxis: 2, crossAxis: 1 } }"
    @import:computer="dialogFiles = $event"
  >
    <button
      ref="dropzone"
      class="group flex min-h-7 grow items-center justify-between rounded-corner-8 pl-1 hover:bg-background-transparent-hovered focus:bg-background-transparent-hovered focus:outline-none"
      :class="[(menu?.open || isOverDropZone) && 'bg-background-transparent-hovered']"
      v-bind="{ ...getTriggerProps(), ...$attrs }"
    >
      <BadgeItem
        v-if="filename"
        variant="neutral"
        size="sm"
        leading-icon="attachment"
        :label="filename"
      />
      <BadgeItem
        v-else
        variant="neutral"
        size="sm"
        class="group-hover:opacity-100 group-focus:opacity-100"
        :class="menu?.open || isOverDropZone ? 'opacity-100' : 'opacity-0'"
        leading-icon="plus"
        label="Add file or drag and drop"
      />
      <div class="flex items-center gap-0">
        <ToolTip
          v-if="isDeletable"
          :title="isOverriden ? 'Reset value' : 'Delete file'"
        >
          <DarwinButton
            size="sm"
            variant="transparent"
            class="rounded-r-none opacity-0 group-hover:opacity-100 focus:opacity-100"
            :class="previewButton && 'rounded-l-none'"
            @click.stop="deleteFile"
            >Delete</DarwinButton
          >
        </ToolTip>
        <DarwinButton
          v-if="previewButton"
          size="sm"
          variant="transparent"
          class="rounded-l-none opacity-0 group-hover:opacity-100 focus:opacity-100"
          v-bind="previewButton.attrs"
          @click.stop="$emit('file:preview', field)"
          >{{ previewButton.text }}</DarwinButton
        >
      </div>
    </button>
  </FileFieldUpload>
</template>
