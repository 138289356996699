<script setup lang="ts">
import ClaimIndicator from '@/sharedComponents/ClaimIndicator.ce.vue'
import { useFileType } from '@/sharedComposables/useFileType'
import { useNaturalZoom } from '@/sharedComposables/useNaturalZoom'
import { usePdfSearch } from '@/sharedComposables/usePdfSearch'
import IconButton from '@/uiKit/IconButton.vue'
import { getBackgroundRainbowColor, getTextRainbowColor } from '@/uiKit/RainbowColor'
import { VuePDF, usePDF } from '@tato30/vue-pdf'
import '@tato30/vue-pdf/style.css'
import { useDebounceFn, useElementSize } from '@vueuse/core'
import { computed, nextTick, onMounted, reactive, ref, toRef, watch } from 'vue'
import CaseFileViewerSearchField from '../Cases/CaseFileViewerSearchField.vue'
import { PDF_SEARCH_HIGHLIGHT_OPTIONS } from './constants'
import type { Field } from './Fields/types'
import { isGroundableField } from './Fields/utils/grounding'
import ScaleButtons from './ScaleButtons.vue'
import { useGroundingPolygons } from './useGroundingPolygons'
import { useGroundingStore, type Source as GroundingSource } from './useGroundingStore'
import { fileURL } from './useProject'

const props = defineProps<{
  field: Field & {
    ocrPages?: Array<{
      number: number
      width: number
      height: number
      offset: number
      unit: 'inch' | 'pixel'
    }> | null
  }
  groundingField?: Field
}>()
defineEmits<{
  (e: 'close'): void
}>()
// Constants
const SCROLL_VISIBILITY_RATIO = 0.1
const MAX_VISIBILITY_OFFSET_PX = 30
const PDF_PAGE_GAP_PX = 16

const displayUrl = computed(() => fileURL(props.field, 'display'))
const isPdf = computed(() => useFileType(displayUrl).value === 'pdf')

// Create a unique storage key based on the field ID
const storageKey = computed(
  () => `entity-sidebar-file-${props.field.entityId}-${props.field.propertyId}`,
)
// Zoom functionality
const { scale, setScale, onZoom, scrollContainer, scrollContainerChild } = useNaturalZoom({
  storageKey,
})

// PDF viewer state
const maxScale = 2
const { width: containerWidth } = useElementSize(scrollContainer)
const { pdf, pages } = usePDF(displayUrl)

const zooming = ref(false)
const debouncedResetZoom = useDebounceFn(() => {
  zooming.value = false
}, 300)

function zoom() {
  zooming.value = true
  debouncedResetZoom()
}

function onScroll(e: WheelEvent) {
  if (!scrollContainer.value) return

  if (e.shiftKey) {
    // Handle zooming with shift+scroll
    onZoom(e)
  } else {
    // Use native scrolling
    scrollContainer.value.scrollBy({
      top: e.deltaY,
      behavior: 'auto',
    })
  }
}

// Track PDF page info for proper scaling
const pdfInfo = reactive<
  Array<{
    scale: number
    rawDims: {
      pageWidth: number
      pageHeight: number
    }
  } | null>
>([])

const actualScale = computed(() => {
  if (typeof scale.value === 'number') return scale.value
  if (!containerWidth.value || !scrollContainer.value) return 1

  const styleScale = Number(getComputedStyle(scrollContainer.value).scale)
  return Number.isNaN(styleScale) ? 1 : styleScale
})

// Create a shared scroll helper function
function scrollToPosition(
  pageNumber: number,
  yPosition: number,
  height: number,
  shouldInvertY = false,
) {
  if (!scrollContainer.value || !scrollContainerChild.value) return

  const pageElement = scrollContainerChild.value.querySelector(`[data-page="${pageNumber}"]`)
  if (!pageElement) return

  // Get the page dimensions and scale
  const pageInfo = pdfInfo[pageNumber - 1]
  if (!pageInfo?.rawDims) return

  // Get PDF's internal scale for this page
  const pdfScale = pageInfo.scale || 1
  const pageHeight = pageInfo.rawDims.pageHeight

  // Handle coordinate system differences and scaling
  // For text search (shouldInvertY=true), yPosition is in PDF units (needs to be inverted and scaled)
  // For grounding boxes (shouldInvertY=false), yPosition is already in PDF units
  const adjustedY = shouldInvertY ? (pageHeight - yPosition) * pdfScale : yPosition * pdfScale

  // Scale height appropriately - both cases need pdfScale since we're in PDF units
  const scaledHeight = height * pdfScale

  // Get all pages before the target page to calculate total offset
  const previousPages = Array.from(
    scrollContainerChild.value.querySelectorAll('[data-page]'),
  ).filter((el) => Number(el.getAttribute('data-page')) < pageNumber)

  // Calculate total height of previous pages plus gaps
  const totalPreviousHeight = previousPages.reduce((sum, el) => {
    const height = el.getBoundingClientRect().height
    return sum + height + PDF_PAGE_GAP_PX // Gap between pages
  }, 0)

  // Get the height of the current page
  const currentPageHeight = pageElement.getBoundingClientRect().height

  // Calculate the position within the current page
  const positionInPage = (currentPageHeight * adjustedY) / (pageHeight * pdfScale)

  // Calculate the final scroll position
  const targetScroll = totalPreviousHeight + positionInPage

  // Get the container's viewport height
  const containerHeight = scrollContainer.value.clientHeight

  // Calculate the final scroll position
  const scaledElementHeight = (scaledHeight / (pageHeight * pdfScale)) * currentPageHeight

  // Add a small offset to ensure the element is fully visible
  const visibilityOffset = Math.min(
    containerHeight * SCROLL_VISIBILITY_RATIO,
    MAX_VISIBILITY_OFFSET_PX,
  )
  const finalScrollPosition =
    targetScroll - containerHeight / 2 + scaledElementHeight / 2 - visibilityOffset

  scrollContainer.value.scrollTo({
    top: Math.max(0, finalScrollPosition),
    behavior: 'smooth',
  })
}

const scrollToSourceId = async (sourceId: number) => {
  await nextTick()
  if (!scrollContainer.value || !scrollContainerChild.value) return

  const source = sourcesWithCoords.value[sourceId]
  if (!source || !source.boxes[0]) return

  const box = source.boxes[0]
  if (!box?.page) return

  // Get the page info and OCR data
  const pageInfo = pdfInfo[box.page - 1]
  if (!pageInfo?.rawDims) return

  const ocrPage = props.field.ocrPages?.find((p) => p.number === box.page)
  if (!ocrPage) return

  // Calculate scaling factors between OCR and PDF coordinates
  const scaleY = pageInfo.rawDims.pageHeight / ocrPage.height

  // Convert OCR coordinates to PDF coordinates
  const centerY = ((box.ymin + box.ymax) / 2) * scaleY
  const boxHeight = (box.ymax - box.ymin) * scaleY

  // Use shouldInvertY=false since we're already in PDF coordinate space
  scrollToPosition(box.page, centerY, boxHeight, false)
}

const groundingStore = useGroundingStore()
const selectedSourceId = computed(() => groundingStore.selectedClaimsAndSource?.sourceId)

watch(
  () => groundingStore.selectedClaimsAndSource?.sourceId,
  async (firstSourceId) => {
    if (firstSourceId === undefined) return
    await scrollToSourceId(firstSourceId)
  },
  {
    immediate: true,
    flush: 'post',
  },
)

// Get sources and coordinates for highlighting
const sourcesWithCoords = computed(() => {
  if (
    !props.field ||
    !props.groundingField ||
    !isGroundableField(props.groundingField) ||
    !props.groundingField.grounding
  ) {
    return []
  }

  // Get all referenced source indices and their associated claims
  const sourceToClaimMap = new Map<number, number>()
  const claims = props.groundingField.grounding.claims
  claims.forEach((claim, claimIndex) => {
    // For each source index in this claim, store both the claim index and the source index
    claim.source_indices.forEach((sourceIndex) => {
      sourceToClaimMap.set(sourceIndex, claimIndex)
    })
  })

  // Filter and sort sources
  const relevantSources = props.groundingField.grounding.sources
    .filter(
      (source) =>
        source.property_id === props.field.propertyId &&
        source.bounding_boxes.length > 0 &&
        sourceToClaimMap.has(source.index),
    )
    .toSorted((a, b) => a.property_id.localeCompare(b.property_id))

  const mappedSources = relevantSources.map((source) => {
    const boxes = source.bounding_boxes
      .map((box) => {
        if (!pdfInfo[box.page - 1]?.rawDims) return null

        const pageInfo = pdfInfo[box.page - 1]
        if (!pageInfo) return null

        return {
          ...box,
          page: box.page,
        }
      })
      .filter(Boolean)

    const claimIndex = sourceToClaimMap.get(source.index) ?? 0

    return {
      id: source.index,
      originalIndex: source.index,
      claimId: claimIndex,
      boxes,
      boundingBoxes: source.bounding_boxes,
    }
  })

  return mappedSources
})

// Initialize the useGroundingPolygons composable with the OCR pages
const { getPolygonPointsFromSource, strokeWidthPerPage, getOcrPageViewbox } = useGroundingPolygons(
  toRef(() => props.field?.ocrPages ?? []),
)

// Modified version of getPolygonPointsFromSource to handle our source structure
const getSourcePolygonPoints = (source: (typeof sourcesWithCoords.value)[number], page: number) => {
  // Gather bounding boxes for this page
  const boxes = source.boxes.filter(
    (box): box is NonNullable<typeof box> => box !== null && box.page === page,
  )

  // If no boxes on this page or no boxes at all, return empty array
  if (boxes.length === 0 || source.boundingBoxes.length === 0) return []

  // Filter bounding boxes for this page
  const filteredBoxes = source.boundingBoxes.filter((box) => box.page === page)

  // If no bounding boxes on this page after filtering, return empty array
  if (filteredBoxes.length === 0) return []

  // Adapt to the structure expected by useGroundingPolygons
  const groundingSource: GroundingSource = {
    id: source.id,
    boundingBoxes: filteredBoxes as typeof filteredBoxes & { 0: (typeof filteredBoxes)[0] },
    propertyId: props.field.propertyId,
  }

  return getPolygonPointsFromSource(groundingSource, page)
}

// Get sources for a specific page
const getPageSources = (pageNum: number) => {
  return sourcesWithCoords.value.filter((s) => s.boxes.some((b) => b?.page === pageNum))
}

// Define the type for the loaded event
type LoadedEvent = {
  scale: number
  rawDims: {
    pageWidth: number
    pageHeight: number
  }
}

// Add onMounted hook after zoom functions
onMounted(() => {
  setScale('fit')
})

// Search functionality
const { search, onHighlight, matchingElements, activeMatchIndex, onTextLayerLoaded } = usePdfSearch(
  computed(() => props.field),
)
</script>

<template>
  <div class="relative h-full pl-3">
    <div
      class="z-0 mb-2.5 mr-3 flex h-full flex-col overflow-hidden rounded-corner-12 border border-border-subtle bg-surface-primary"
    >
      <div
        class="relative z-10 flex items-center justify-between border-b border-border-subtle bg-surface-primary p-2"
      >
        <div class="flex items-center gap-2">
          <ScaleButtons
            :scale="scale"
            @update:scale="
              (e: 'fit' | number) => {
                zoom()
                if (e === 'fit') {
                  setScale('fit')
                } else if (typeof e === 'number') {
                  setScale(e)
                }
              }
            "
          />
          <!-- Search bar -->
          <div
            v-if="isPdf"
            class="ml-4 flex items-center gap-2"
          >
            <div class="relative flex grow items-center">
              <CaseFileViewerSearchField
                v-model="search"
                class="grow"
                :total-matches="matchingElements.length"
                :current-match="activeMatchIndex"
                @next="activeMatchIndex++"
                @previous="activeMatchIndex--"
              />
            </div>
          </div>
        </div>
        <IconButton
          icon="close"
          size="md"
          variant="transparent"
          aria-label="Close file viewer"
          @click="$emit('close')"
        />
      </div>

      <div
        ref="scrollContainer"
        class="flex-1 overflow-auto p-4 scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
        @wheel.ctrl.exact.prevent.stop="onZoom"
        @wheel.meta.exact.prevent.stop="onZoom"
        @wheel.exact.prevent.stop="onScroll"
      >
        <div
          v-if="isPdf"
          ref="scrollContainerChild"
          class="flex flex-col items-center gap-4"
          :class="zooming ? 'transition-all duration-200' : ''"
          :style="{
            transformOrigin: 'top center',
            scale: actualScale / (scale === 'fit' ? 1 : maxScale),
          }"
        >
          <div
            v-for="page in pages"
            :key="page"
            class="relative w-fit"
            :data-page="page"
          >
            <VuePDF
              :page="page"
              :pdf="pdf"
              text-layer
              :source="displayUrl"
              class="relative w-fit overscroll-none transition"
              :width="containerWidth"
              :scale="maxScale"
              :highlight-text="search || undefined"
              :highlight-options="PDF_SEARCH_HIGHLIGHT_OPTIONS"
              @loaded="
                ((e: LoadedEvent) => {
                  const index = page - 1
                  const newInfo = {
                    scale: e.scale,
                    rawDims: {
                      pageWidth: e.rawDims.pageWidth,
                      pageHeight: e.rawDims.pageHeight,
                    },
                  }

                  // Ensure array has enough space
                  while (pdfInfo.length <= index) {
                    pdfInfo.push(null)
                  }

                  pdfInfo[index] = newInfo

                  if (selectedSourceId !== undefined) {
                    scrollToSourceId(selectedSourceId)
                  }
                }) as any
              "
              @highlight="onHighlight"
              @text-loaded="onTextLayerLoaded"
            />
            <!-- Grounding highlights -->
            <template
              v-for="source in getPageSources(Number(page))"
              :key="source.id"
            >
              <div
                :data-source="source.id"
                class="pointer-events-none absolute inset-0 transition"
                :class="{
                  'opacity-50': selectedSourceId && selectedSourceId !== source.id,
                }"
              >
                <ClaimIndicator
                  v-if="source.boxes[0]"
                  :data-source="source.id - 1"
                  class="absolute -translate-x-2 -translate-y-4 cursor-pointer transition"
                  :source-id="source.id - 1"
                  :property-id="props.groundingField?.propertyId"
                  :is-selected="selectedSourceId === source.id"
                  :style="{
                    ...(() => {
                      const box = source.boxes[0]
                      if (!box) return {}

                      const pageInfo = pdfInfo[box.page - 1]
                      if (!pageInfo?.rawDims) return {}

                      const pageWidth = pageInfo.rawDims.pageWidth
                      const pageHeight = pageInfo.rawDims.pageHeight
                      const ocrPage = props.field.ocrPages?.find((p) => p.number === box.page)
                      if (!pageWidth || !pageHeight || !ocrPage) return {}

                      const scaleX = pageWidth / ocrPage.width
                      const scaleY = pageHeight / ocrPage.height

                      return {
                        left: `${((box.xmin * scaleX) / pageWidth) * 100}%`,
                        top: `${((box.ymin * scaleY) / pageHeight) * 100}%`,
                      }
                    })(),
                  }"
                  @click="groundingStore.clickSource(source.id)"
                />
                <svg
                  :viewBox="getOcrPageViewbox(Number(page))"
                  class="absolute left-0 top-0 size-full"
                >
                  <polygon
                    v-for="(points, index) in getSourcePolygonPoints(source, Number(page))"
                    :key="index"
                    :points="points"
                    class="transition-opacity"
                    :style="{
                      strokeWidth:
                        selectedSourceId === source.id
                          ? String(strokeWidthPerPage[Number(page) - 1] || 1)
                          : '0',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      fill: getBackgroundRainbowColor(
                        `${(
                          props.groundingField?.propertyId ||
                          props.groundingField?.propertyId ||
                          ''
                        ).toString()}-${source.id * 8}`,
                      ),
                      stroke: getTextRainbowColor(
                        `${(
                          props.groundingField?.propertyId ||
                          props.groundingField?.propertyId ||
                          ''
                        ).toString()}-${source.id * 8}`,
                      ),
                      opacity: 1,
                      pointerEvents: 'none',
                    }"
                  />
                </svg>
              </div>
            </template>
          </div>
        </div>
        <div
          v-else
          ref="scrollContainerChild"
          class="flex h-full items-center justify-center"
          :class="zooming ? 'transition-all duration-200' : ''"
          :style="{
            transformOrigin: 'top center',
            scale: actualScale / (scale === 'fit' ? 1 : maxScale),
          }"
        >
          <img
            :src="displayUrl || ''"
            class="max-h-full max-w-full object-contain"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
