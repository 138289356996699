<script setup lang="ts">
import { PropertyType } from '@/backend/types'
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import { tools } from '@/modules/Project/Tools/tool-registry'
import { toast } from '@/shared/toast'
import { useSetFieldValue } from '@/shared/useSetFieldValue'
import {
  isFileField,
  isFileProperty,
  isSelectField,
  isSelectProperty,
} from '@/shared/utils/typeGuards'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import DotIndicator from '@/uiKit/DotIndicator.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
// eslint-disable-next-line no-restricted-imports
import PopupMenu from '@/uiKit/PopupMenu.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed, nextTick, ref, watch } from 'vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import CollectionContent from './CollectionContent.vue'
import EntityCardContent from './EntityCardContent.vue'
import FieldTooltip from './FieldTooltip.vue'
import FileContent from './FileContent.vue'
import { TOOL_ICON, TYPE_ICON } from './icons'
import NumberContent from './NumberContent.vue'
import PropertyEditMenu from './PropertyEditMenu.vue'
import ReferenceContent from './ReferenceContent.vue'
import { resolveFilename } from './resolveFilename'
import SelectContent from './SelectContent.vue'
import StatusContent from './StatusContent.vue'
import TextContent from './TextContent.vue'
import UrlContent from './UrlContent.vue'
import { useDotIndicator } from './useDotIndicator'
import { useFieldStatusIndicator } from './useFieldStatusIndicator'
import { useGroundingStore } from './useGroundingStore'
import { useOpenGrounding } from './useOpenGrounding'
import { fileURL, useProject, type Entity } from './useProject'
import { useProjectTooltip } from './useProjectTooltip'
import { useProperty } from './useProperty'
import { usePropertyDropdown } from './usePropertyDropdown'

export type LayoutItem = {
  x: number
  y: number
  w: number
  h: number
  i: string
  field: Field
  readonly: boolean
  property: Property
}

const props = defineProps<{
  workspaceId: string
  projectId: string
  item: LayoutItem
  isLayoutLocked: boolean
  isHovered: boolean
  entity: Entity
  readonlyEntity?: boolean
}>()

const card = ref<HTMLDivElement>()

const textInHeader = computed(() => {
  if (props.isHovered && props.item.field.type === 'file') {
    const fileName = resolveFilename(props.item.field)
    if (fileName) {
      return fileName
    }
  }
  return props.item.property?.name || ''
})

watch(
  () => [card.value?.parentNode, props.isLayoutLocked, props.isHovered],
  async () => {
    await nextTick()

    const dragHandle = card.value?.parentNode?.querySelector('.vue-resizable-handle')
    if (!dragHandle) return

    if (props.isLayoutLocked || !props.isHovered) {
      dragHandle.innerHTML = ''
      return
    }

    dragHandle.innerHTML = `<svg width="10" height="10" fill="currentColor">
    <path fill-rule="evenodd" d="M9.857.01a.77.77 0 0 1 .632.886c-.756 4.539-4.554 8.337-9.093 9.093a.77.77 0 1 1-.253-1.517c3.89-.649 7.18-3.938 7.829-7.83a.77.77 0 0 1 .885-.631Z" clip-rule="evenodd"/>
    </svg>`
  },
)

const projectStore = useProject()

const setFieldValue = useSetFieldValue()
const saveValue = async (newValue: string | string[] | null) => {
  if (!props.item.property) {
    return
  }

  card.value?.focus()
  const { ok: success } = await setFieldValue({
    entityId: props.item.field.entityId,
    fields: {
      [props.item.field.propertyId]: {
        field: props.item.field,
        newValue,
      },
    },
    projectId: props.projectId,
    workspaceId: props.workspaceId,
  })

  if (!success) {
    toast.error('Failed to save value, please try again.')
  }
}

const { staleness, showDotIndicator } = useDotIndicator(
  computed(() => props.item.field),
  computed(() => props.item.property),
)

const { isModelOutput } = useFieldStatusIndicator(computed(() => props.item.field))

const {
  deleteProperty,
  deletePropertyConfirmationOpen,
  hideProperty,
  menuIsOpen,
  onClickOutside,
  onCloseMenu,
  onUpdate,
  openDeleteDialog,
  onReprocessColumn,
  triggerRef,
} = usePropertyDropdown()

const onToggleMenu = () => {
  if (!props.item.property) {
    return
  }
  projectStore.setSelectedProperty(props.item.property.id)
  menuIsOpen.value = !menuIsOpen.value
}

const propertyStore = useProperty()
watch(
  () => [propertyStore.savedProperty, propertyStore.sidebarIsOpen],
  ([property, sidebarIsOpen]) => {
    if (sidebarIsOpen || !property) {
      menuIsOpen.value = false
    }
  },
  {
    immediate: true,
  },
)

const isWaitingForConfiguration = computed(() => {
  return props.item.property?.tool !== 'manual' && props.item.property?.inputs.length === 0
})

const isPdfDowloadEnabled = useFeatureFlags(FeatureFlag.PDF_DOWNLOAD)

const isLocked = computed(() => props.item.field.groundTruth)

const showPropertyConfiguration = computed(() => props.isHovered || menuIsOpen.value)

const fileContentRef = ref<typeof FileContent | null>(null)

const fileName = computed(
  () => (isFileField(props.item.field) && resolveFilename(props.item.field)) || null,
)
const isPdf = computed(() => fileName.value?.endsWith('.pdf') ?? false)

const isFile = computed(() => props.item.field.type === 'file')

const downloadAsFile = async () => {
  if (props.item.field.type !== 'file') {
    return
  }
  fileContentRef.value?.download(fileName)
}

const fileDownloadUrl = computed<string | null>(() => fileURL(props.item.field, 'download'))

const property = computed(() => props.item.property)
const propertyId = computed(() => property.value?.id)
const field = computed(() => props.item.field)

const { isCorrectedModelOutput, lastCorrectedBy, onLockField } = useProjectTooltip({
  field,
  entity: computed(() => props.entity),
  propertyId,
})

const { hasGroundingClaim } = useGroundingStore()
const { openGrounding } = useOpenGrounding()

const onTooltipReset = () => {
  saveValue(null)
}

const permissionsStore = usePermissionsStore()

const readonly = computed(
  () =>
    !!props.readonlyEntity ||
    !permissionsStore.currentProjectPermissions.update_entities ||
    props.item.readonly,
)
</script>

<template>
  <div
    ref="card"
    data-test="entity-card"
    class="flex h-full flex-col items-start justify-start overflow-hidden rounded-corner-10 bg-surface-primary shadow-entity-card transition-colors hover:outline hover:outline-1 hover:outline-border-subtle focus-visible:shadow-focus-ring-primary focus-visible:outline-none"
    tabindex="0"
    @keydown.enter.prevent="($event.target as HTMLDivElement)?.querySelector('textarea')?.focus()"
  >
    <!-- header -->
    <div
      class="flex h-8 flex-row items-center justify-center gap-1.5 self-stretch p-1"
      data-test="card-header"
    >
      <div class="flex shrink grow basis-0 items-center justify-start gap-1 px-1.5 py-1">
        <IconSprite
          v-if="item.property?.type"
          :icon="TYPE_ICON[item.property.type]"
          :class="
            item.field.status === 'error'
              ? 'text-text-critical'
              : isModelOutput
                ? 'text-background-stages-model'
                : isLocked
                  ? 'text-text-stages-model'
                  : 'text-text-subtle'
          "
        />
        <div
          class="line-clamp-1 min-w-0 shrink text-center text-sm-12px-default font-medium"
          :class="
            item.field.status === 'error'
              ? 'text-text-critical'
              : isModelOutput
                ? 'text-background-stages-model'
                : isLocked
                  ? 'text-text-stages-model'
                  : 'text-text-subtle'
          "
          :title="textInHeader"
        >
          {{ textInHeader }}
        </div>
        <BadgeItem
          v-if="item.readonly"
          leading-icon="lock-fill"
          size="sm"
          variant="neutral"
          label="Readonly"
        />
      </div>

      <div class="flex h-full items-center justify-center">
        <div class="flex flex-row items-center gap-1">
          <template v-if="isHovered || menuIsOpen">
            <ToolTip
              v-if="isPdf && isPdfDowloadEnabled"
              title="Download PDF"
              :placement="{ allowedPlacements: ['top-end'] }"
            >
              <IconButton
                ref="triggerRef"
                icon="arrow-bottom-circle"
                size="md"
                aria-label="Download PDF"
                variant="transparent"
                class="self-end"
                tabindex="-1"
                @click="downloadAsFile"
              />
            </ToolTip>
            <ToolTip
              v-else-if="isFile"
              title="Open file in new tab"
              :placement="{ allowedPlacements: ['top-end'] }"
              class="contents"
            >
              <a
                :href="fileDownloadUrl ?? undefined"
                target="_blank"
                :aria-label="`Download ${fileName || 'file'}`"
              >
                <IconButton
                  role="presentation"
                  tabindex="-1"
                  size="md"
                  variant="transparent"
                  icon="arrow-top-right"
                  :aria-label="`Download ${fileName || 'file'}`"
                />
              </a>
            </ToolTip>
          </template>

          <PopupMenu
            v-if="
              item.property &&
              !item.readonly &&
              !readonlyEntity &&
              permissionsStore.currentProjectPermissions.manage_properties &&
              showPropertyConfiguration
            "
            :auto-placement="{
              allowedPlacements: ['bottom-end', 'bottom-start', 'top-end', 'top-start'],
            }"
            :open="menuIsOpen"
            trigger-element="div"
          >
            <template #trigger>
              <IconButton
                ref="triggerRef"
                icon="more-dots"
                :active="menuIsOpen"
                size="md"
                :aria-label="`Edit ${item.property.name}`"
                variant="transparent"
                class="self-end"
                tabindex="-1"
                @click="onToggleMenu"
              />
            </template>
            <template #dropdown>
              <PropertyEditMenu
                @close="onCloseMenu"
                @update="onUpdate"
                @delete="openDeleteDialog"
                @click:outside="onClickOutside"
                @hide="hideProperty"
                @reprocess="onReprocessColumn"
              />
            </template>
          </PopupMenu>
          <ToolTip
            v-if="hasGroundingClaim(item.field)"
            title="View citations"
            :placement="{ allowedPlacements: ['top'] }"
          >
            <IconButton
              icon="idea"
              size="md"
              aria-label="View citations"
              variant="transparent"
              :class="isHovered ? 'visible' : 'invisible'"
              @click="openGrounding({ propertyId: item.property?.id, entityId: entity.id })"
            />
          </ToolTip>
          <FieldTooltip
            v-if="
              item.field?.toolValue &&
              item.field.toolValueUpdatedBy &&
              item.property?.id &&
              entity?.id
            "
            :last-corrected-by="isCorrectedModelOutput ? lastCorrectedBy : undefined"
            :tool="
              isCorrectedModelOutput
                ? undefined
                : {
                    name: tools[item.field.toolValueUpdatedBy].label,
                    icon: tools[item.field.toolValueUpdatedBy].icon,
                  }
            "
            :field-has-ground-truth="item.field.groundTruth"
            :is-corrected-model-output="isCorrectedModelOutput"
            :offset="2"
            :class="isHovered || menuIsOpen ? 'visible' : 'invisible'"
            :property-type="field.type"
            :status="field.status"
            @lock="onLockField"
            @reset="onTooltipReset"
          >
            <IconButton
              size="md"
              variant="transparent"
              aria-label="AI tooltip"
              class="hover:cursor-default"
              role="presentation"
            >
              <AvatarIcon
                :icon-name="
                  isCorrectedModelOutput ? undefined : TOOL_ICON[item.field.toolValueUpdatedBy]
                "
                :url="isCorrectedModelOutput ? lastCorrectedBy?.avatarUrl : undefined"
                size="xs"
                no-background
                class="bg-background-black text-text-inverted"
                shape="circle"
              />
            </IconButton>
          </FieldTooltip>

          <DotIndicator
            v-if="showDotIndicator"
            class="pr-0.5"
            :field="item.field"
            :staleness="staleness"
          />
        </div>
      </div>
    </div>
    <!-- end header -->

    <EntityCardContent
      class="relative bg-surface-primary"
      data-test="entity-card-content"
    >
      <UrlContent
        v-if="item.property?.type === PropertyType.url"
        :item="item"
        :readonly="readonly"
        @submit="saveValue"
      />

      <StatusContent
        v-else-if="
          item.field.type !== 'file' &&
          (item.field.status === 'computing' ||
            item.field.status === 'waiting' ||
            isWaitingForConfiguration)
        "
        :is-waiting-for-configuration="isWaitingForConfiguration"
        :status="item.field.status"
        :type="item.property?.type"
      />

      <TextContent
        v-else-if="[PropertyType.text, PropertyType.json].some((pt) => pt === item.property?.type)"
        :item="item"
        :json="item.property?.type === PropertyType.json"
        :readonly="readonly"
        :show-actions="isHovered"
        @submit="saveValue"
      />

      <FileContent
        v-else-if="isFileField(item.field) && isFileProperty(item.property)"
        ref="fileContentRef"
        :field="item.field"
        :property="item.property"
        :transcription="
          'transcription' in item.field && item.field.transcription
            ? item.field.transcription
            : null
        "
        :workspace-id="workspaceId"
        :project-id="projectId"
        :readonly="readonly"
        @file-clear="saveValue(null)"
      />

      <Menu.Root
        v-else-if="isSelectProperty(item.property) && isSelectField(item.field)"
        disable-teleport
        open
        disable-close-on-click-outside
        static
      >
        <div class="size-full">
          <SelectContent
            :value="item.field.manualValue || item.field.toolValue"
            :property="item.property"
            :readonly="readonly"
            is-entity-view
            class="!rounded-none border-t border-t-border-subtle !bg-[transparent] shadow-none"
            @submit="saveValue"
          />
        </div>
      </Menu.Root>

      <CollectionContent
        v-else-if="item.property?.type === 'collection'"
        :workspace-id="workspaceId"
        :project-id="projectId"
        :entity-id="item.field.entityId"
        :property="item.property"
        class="h-full"
        @submit="saveValue"
      />
      <ReferenceContent
        v-else-if="item.property?.type === 'reference' && item.field.type === 'reference'"
        :field="item.field"
        :property="item.property"
        :project-id="projectId"
      />
      <NumberContent
        v-else-if="item.field.type === 'number' && item.property?.type === 'number'"
        :field="item.field"
        :property="item.property"
      />
    </EntityCardContent>

    <ConfirmationDialog
      id="delete-property-confirmation"
      :open="deletePropertyConfirmationOpen"
      title="Delete this property?"
      description="This property will be deleted immediately. You can't undo this action."
      @confirm="deleteProperty"
      @close="deletePropertyConfirmationOpen = false"
    />
  </div>
</template>
