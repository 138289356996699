<script setup lang="ts">
import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import CaseDropdown from '@/modules/Cases/CaseDropdown.vue'
import { useCaseStore } from '@/modules/Cases/useCaseStore'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import ProjectName from '@/modules/Project/ProjectName.vue'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import ProjectDropdown from '@/sharedComponents/ProjectDropdown.vue'
import ProjectDropdownV2 from '@/sharedComponents/ProjectDropdownV2.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import ExpandableNavigationList from '@/uiKit/ExpandableNavigationList.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import NavigationListItem from '@/uiKit/NavigationListItem.vue'
import SearchTextField from '@/uiKit/SearchTextField.vue'
import { useScroll } from '@vueuse/core'
import { computed, ref, toRef, useTemplateRef } from 'vue'
import ObjectURLImage from './ObjectURLImage.vue'
import { useLoadAllProjects } from './useLoadAllProjects'
import { useProjects, type Project } from './useProjects'
import { useRecentProjects } from './useRecentProjects'

const props = defineProps<{
  workspaceId: string
  someDialogIsOpen: boolean
}>()

const emit = defineEmits<{
  (e: 'create-project'): void
  (e: 'remove-case', id: string): void
  (e: 'rename-case', id: string, name: string): void
}>()

const searchValue = ref<string | undefined>()

const casesStore = useCaseStore()
const permissionsStore = usePermissionsStore()
const projectsStore = useProjects()
const recentProjectsStore = useRecentProjects()
const { sidebarProjects } = useLoadAllProjects(toRef(props, 'workspaceId'))
const areCasesEnabled = useFeatureFlags(FeatureFlag.CASES)

/**
 * Track the scroll position of the container so that we can add
 * a fade-out effect to the bottom of the chat messages when there
 * is content behind the chat input.
 */
const scrollContainerRef = useTemplateRef('scroll-container')
const { arrivedState } = useScroll(scrollContainerRef)
const idHovered = ref(-1)

const filteredCases = computed(() => {
  if (searchValue.value === '') return casesStore.cases
  return casesStore.cases.filter(({ name }) =>
    name?.toLowerCase()?.includes(searchValue.value?.toLowerCase() ?? ''),
  )
})

const filteredProjects = computed(() => {
  if (searchValue.value === '') return sidebarProjects.value
  return sidebarProjects.value.filter(({ name }) => name?.includes(searchValue.value ?? ''))
})

const hasCases = computed(() => filteredCases.value.length > 0)

const hasWorkspaceProjects = computed(() => filteredProjects.value.length > 0)

const onImageSrcError = (project: Project) => {
  projectsStore.setCoverImageDownloadError(project.id, true)
  recentProjectsStore.setCoverImageDownloadError(project.id, true)
}

const projectIsDisabled = (project: Project) =>
  project.propertyCount === 0 && permissionsStore.workspaceRole === 'worker'
</script>

<template>
  <div class="relative flex w-full flex-col gap-2">
    <div class="bg-surface-tertiary p-2 pb-0">
      <SearchTextField
        v-model:value="searchValue"
        size="md"
        aria-label="Search for cases or projects"
      />
    </div>
    <div
      class="relative flex w-full grow overflow-hidden before:pointer-events-none before:absolute before:top-0 before:z-2 before:block before:h-[40px] before:w-[calc(100%-1px)] before:bg-gradient-to-b before:from-surface-tertiary before:to-background-transparent before:transition before:content-[''] after:pointer-events-none after:absolute after:bottom-0 after:block after:h-[40px] after:w-[calc(100%-1px)] after:bg-gradient-to-t after:from-surface-tertiary after:to-background-transparent after:transition after:content-['']"
      :class="{
        [arrivedState.bottom ? 'after:opacity-0' : 'after:opacity-100']: true,
        [arrivedState.top ? 'before:opacity-0' : 'before:opacity-100']: true,
      }"
    >
      <div
        ref="scroll-container"
        class="relative w-full overflow-hidden overflow-y-auto scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
      >
        <div
          v-if="areCasesEnabled"
          class="relative overflow-hidden"
          data-test="all-cases-container"
        >
          <div
            class="h-full"
            data-test="cases-sidebar-list"
          >
            <ExpandableNavigationList>
              <template #header>{{ hasCases ? 'All cases' : 'No case found' }}</template>
              <div
                v-for="({ id, name }, idx) in filteredCases"
                :key="`${id}-${idx}`"
                class="h-7"
              >
                <NavigationListItem
                  v-if="id"
                  class="group/item"
                  data-test="case-sidebar-item"
                  :force-active-state="someDialogIsOpen ? false : undefined"
                  :to="{
                    name: 'Case',
                    params: { caseId: id },
                  }"
                  leading-icon="case-bubble"
                  leading-icon-active="case-bubble-fill"
                  @mouseenter="idHovered = idx"
                  @mouseleave="idHovered = -1"
                >
                  <div class="flex w-full max-w-[90%] items-center justify-between gap-2">
                    <p class="truncate">
                      {{ name }}
                    </p>
                  </div>
                  <CaseDropdown
                    class="ml-auto opacity-0 group-focus-within/item:opacity-100 group-hover/item:opacity-100 data-[state=open]:opacity-100"
                    :workspace-id="workspaceId"
                    :case-id="id"
                    :tabindex="idHovered === idx ? 0 : -1"
                    @delete="emit('remove-case', id)"
                    @rename="emit('rename-case', id, $event)"
                  />
                </NavigationListItem>
                <LoadingSkeleton
                  v-else
                  status
                  class="box-border h-5 p-1"
                />
              </div>

              <NavigationListItem
                v-if="!hasCases"
                leading-icon="plus"
                aria-label="Create a new case"
                :to="{ name: 'New Case', params: { workspaceId } }"
              >
                Create a new case
              </NavigationListItem>
            </ExpandableNavigationList>
          </div>
        </div>
        <div
          class="relative overflow-hidden pb-2"
          data-test="all-projects-container"
        >
          <div
            class="h-full"
            data-test="project-sidebar-list"
          >
            <ExpandableNavigationList>
              <template #header>{{
                hasWorkspaceProjects ? 'All projects' : 'No project found'
              }}</template>
              <div
                v-for="(project, idx) in filteredProjects"
                :key="`${project?.id}-${idx}`"
                class="h-7"
              >
                <NavigationListItem
                  v-if="project"
                  :disabled="projectIsDisabled(project)"
                  class="group/item"
                  data-test="project-sidebar-item"
                  :force-active-state="someDialogIsOpen ? false : undefined"
                  :to="{
                    name: 'WorkspaceProject',
                    params: { projectId: project.id },
                  }"
                >
                  <div class="flex w-full items-center justify-between gap-2">
                    <IconSprite
                      v-if="project.coverImageDownloadError"
                      class="w-4"
                      icon="file"
                      size="sm"
                    />
                    <CircularProgress
                      v-else-if="project.coverImageUploading"
                      size="xs"
                      class="animate-spin"
                    />
                    <ObjectURLImage
                      v-else
                      class="size-4 min-w-4 rounded-corner-4 object-cover"
                      :class="projectIsDisabled(project) ? 'opacity-50' : 'opacity-100'"
                      :url="project.coverImageUrls.low"
                      :loading="project.coverImageUploading ?? false"
                      @error="onImageSrcError(project)"
                    >
                      <template #fallback>
                        <IconSprite
                          class="w-4"
                          icon="file"
                          size="sm"
                        />
                      </template>
                    </ObjectURLImage>

                    <p class="truncate">
                      <ProjectName :project="project" />
                    </p>

                    <ProjectDropdownV2
                      v-if="areCasesEnabled"
                      class="ml-auto opacity-0 group-hover/item:opacity-100 data-[state=open]:opacity-100"
                      :project="project"
                    />
                    <ProjectDropdown
                      v-else
                      class="ml-auto opacity-0 group-hover/item:opacity-100 data-[state=open]:opacity-100"
                      :workspace-id="workspaceId"
                      :project-id="project.id"
                    />
                  </div>
                </NavigationListItem>
                <LoadingSkeleton
                  v-else
                  status
                  class="box-border h-5 p-1"
                />
              </div>

              <NavigationListItem
                v-if="!hasWorkspaceProjects"
                leading-icon="plus"
                aria-label="Start a new project"
                @click="$emit('create-project')"
              >
                Start a new project
              </NavigationListItem>
            </ExpandableNavigationList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
