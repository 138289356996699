<script setup lang="ts">
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import { autoPlacement, autoUpdate, offset, useFloating } from '@floating-ui/vue'
import { computed, ref, useTemplateRef } from 'vue'
import type { SidebarEmits, SidebarProps } from '../../field-types'

const props = defineProps<SidebarProps<'url'>>()
const emit = defineEmits<SidebarEmits<'url'>>()

defineOptions({
  inheritAttrs: false,
})

const isHovered = ref(false)
const previewAnchorEl = useTemplateRef<HTMLDivElement>('previewAnchor')
const previewEl = useTemplateRef<HTMLDivElement>('preview')

const httpUrl = computed(() => {
  const value = props.field.manualValue ?? props.field.toolValue
  if (!value || typeof value !== 'string') {
    return undefined
  }
  const startsWithHttpOrS = value.match(/^https?:\/\//)
  return startsWithHttpOrS ? value : `https://${value}`
})

const input = useTemplateRef('input')

// we use the input as reference as using the full container causes flicker in some scenarios
// we use offset so the floating element is still aligned with the full container
const { floatingStyles } = useFloating(previewAnchorEl, previewEl, {
  placement: 'bottom-start',
  middleware: [
    autoPlacement({ allowedPlacements: ['bottom-start', 'top-start'] }),
    offset({ mainAxis: 8 }),
  ],
  whileElementsMounted: autoUpdate,
})

const getInputEventValue = (event: Event) => (event.target as HTMLInputElement).value
</script>

<template>
  <div
    ref="previewAnchor"
    class="group flex min-h-7 grow items-center justify-between rounded-corner-8 pl-2 focus-within:bg-background-transparent-hovered hover:bg-background-transparent-hovered focus:bg-background-transparent-hovered focus:outline-none"
    v-bind="$attrs"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <CircularProgress
      v-if="field.status === 'computing'"
      size="xs"
      color="neutral"
    />
    <ObjectURLImage
      v-else-if="field.metadata?.favicon?.url"
      class="size-4 shrink-0 overflow-hidden rounded-corner-4"
      :url="field.metadata.favicon.url"
      :loading="false"
    >
      <template #fallback>
        <LoadingSkeleton
          :status="true"
          class="size-4"
        />
      </template>
    </ObjectURLImage>
    <IconSprite
      v-else
      icon="link"
      size="xs"
      color="error"
    />
    <InlineTextField
      ref="input"
      :value="httpUrl"
      size="xs"
      class="flex grow hover:!bg-background-transparent"
      placeholder="Enter URL"
      :aria-label="property.name"
      @focus="emit('focus', $event)"
      @submit="emit('submit', $event)"
      @blur="emit('submit', getInputEventValue($event))"
      @esc="input?.reset"
    />

    <DarwinButton
      :href="httpUrl"
      rel="noopener noreferrer"
      size="sm"
      target="_blank"
      variant="transparent"
      class="rounded-l-none opacity-0 group-hover:opacity-100 group-focus:opacity-100"
    >
      Open
    </DarwinButton>
    <Teleport to="body">
      <div
        v-if="field.metadata?.screenshot?.url"
        v-show="isHovered"
        ref="preview"
        :style="floatingStyles"
        class="pointer-events-none absolute z-[1000]"
      >
        <div class="w-[400px] overflow-hidden rounded-corner-4 bg-surface-primary p-2 shadow-lg">
          <img
            class="w-full"
            :src="field.metadata.screenshot.url"
            alt="URL preview"
          />
        </div>
      </div>
    </Teleport>
  </div>

  <!-- z-[1000] is needed to ensure the preview is above the input for JSON higlighted field -->
</template>
