import { fileURL } from '../../useProject'
import type { Field } from '../types'

/** All types of file that we can render within Go */
export type FileType = 'image' | 'pdf' | 'audio' | 'csv' | undefined

/**
 * For a given filename, returns the 'Go Type' of the file, which will correspond
 * to a component that can render it. Returns undefined if we can't render the file
 * inline.
 */
export const getFiletype = (filename: string): FileType => {
  if (!filename) {
    return undefined
  }

  // The filename might be a signed URL. Remove the query parameters to get the extension
  const filenameWithoutQuery = filename.replace(/\?.*$/, '')
  const extension = filenameWithoutQuery.split('.').pop()?.toLowerCase() ?? ''

  if (extension === 'pdf') {
    return 'pdf'
  }

  const imageExtensions = ['png', 'jpg', 'jpeg']
  if (imageExtensions.includes(extension)) {
    return 'image'
  }

  const audioExtensions = ['mp3', 'wav', 'ogg']
  if (audioExtensions.includes(extension)) {
    return 'audio'
  }

  const sheetExtensions = ['csv']
  if (sheetExtensions.includes(extension)) {
    return 'csv'
  }

  // It's a different type of file, one that we don't support
  // inline rendering for.
  return undefined
}

/**
 * Returns true if any only if the file associated with the given file field can
 * be previewed within Go.
 */
export const canPreviewFileField = (field: Field<'file' | 'file_collection'>): boolean => {
  const url = fileURL(field, 'display')
  return Boolean(url && getFiletype(url))
}
