<script setup lang="ts">
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import type { Project } from '@/modules/Projects/useProjects'
import IconSprite from '@/uiKit/IconSprite.vue'
import { twMerge } from 'tailwind-merge'
import { computed, ref, useAttrs } from 'vue'

const props = defineProps<{
  project: Project
}>()

const hasError = ref(false)
const url = computed(() => props.project.coverImageUrls.low)
const attrs = useAttrs()

const classnames = twMerge(
  'size-8 rounded-full',
  typeof attrs.class === 'string' ? attrs.class : '',
)
</script>

<template>
  <ObjectURLImage
    v-if="project.coverImageUrls.low && !hasError"
    :loading="false"
    :url="url"
    :class="classnames"
    @error="hasError = true"
  >
    <template #fallback>
      <slot />
    </template>
  </ObjectURLImage>
  <IconSprite
    v-else-if="hasError"
    icon="file"
    :class="classnames"
  />
</template>
