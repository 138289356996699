<script setup lang="ts">
import { useTheme } from '@/modules/App/useTheme'
import { getTextWithClaims } from '@/modules/Project/getTextWithClaims'
import {
  useGroundingStore,
  type Claim,
  type ClaimAndSource,
} from '@/modules/Project/useGroundingStore'
import { invariant } from '@/shared/utils/typeAssertions'
import { useShikiHighlighter } from '@/sharedComposables/shikiHighlighter'
import { useElementBounding, useScroll, useTextareaAutosize } from '@vueuse/core'
import { computed, nextTick, ref, useTemplateRef, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    value: string
    // if claims are provided, the value will be displayed with claims
    claims?: Claim[]
    variant: 'transparent' | 'gray'
    error?: boolean
    size?: 'md' | 'lg'
    placeholder?: string
    ariaLabel?: string
    ariaLabelledby?: string
    lang?: 'python' | 'json'
    readonly?: boolean
  }>(),
  {
    claims: undefined,
    size: 'md',
    lang: 'python',
    error: false,
    placeholder: undefined,
    ariaLabel: undefined,
    ariaLabelledby: undefined,
    readonly: false,
  },
)

const emit = defineEmits<{
  (e: 'change' | 'input', payload: string): void
  (e: 'customCaretPosition', position: { top: number; left: number; height: number }): void
  (e: 'show-source', payload: ClaimAndSource): void
}>()

defineExpose({
  focus: () => {
    textAreaRef.value?.focus()
  },
})

const formattedValue = computed<string>(() => {
  if (props.lang === 'json' && props.claims === undefined) {
    try {
      return JSON.stringify(JSON.parse(props.value), null, 2)
    } catch {
      return props.value
    }
  }
  return props.value
})

const textAreaRef = useTemplateRef('textAreaRef')
useTextareaAutosize({
  element: computed(() => textAreaRef.value || undefined),
  input: formattedValue,
})

const currentSingleCaretSelection = ref<number>(0)
const highlightContainerRef = useTemplateRef('highlightContainerRef')
const output = ref<string>('<pre></pre>')
const { actualTheme } = useTheme()
const shikiStore = useShikiHighlighter()

const textWithClaims = computed(() => {
  if (props.claims === undefined) {
    return formattedValue.value
  }
  // add claim start and end like this:
  // {{CLAIM_START:1}}
  // {{CLAIM:1:2:3:4}}
  return getTextWithClaims(props.claims, formattedValue.value, '{{', '}}')
})

const runHighlight = () => {
  if (!shikiStore.highlighter) return

  let html = shikiStore.highlighter.codeToHtml(textWithClaims.value, {
    lang: props.lang,
    theme: actualTheme.value === 'light' ? 'min-light' : 'nord',
    transformers: [
      {
        postprocess(html) {
          if (props.claims === undefined) {
            return html
          }
          // remove claim start
          html = html.replaceAll(/{{CLAIM_START:(\d+)}}/g, '')
          // replace claim end with a button
          // this is a hacky copy-paste from `~/ClaimIndicator.vue`
          // TODO: make this a dynamic render (render a ClaimIndicator component in js -> convert to html)
          html = html.replaceAll(
            /{{CLAIM:(\d+):(\d+):(\d+):(\d+)}}/g,
            (match, claimId, sourceId, start, end) => {
              if (!showClaims.value) {
                return ''
              }
              return `<claim-indicator class="pointer-events-auto" data-claim-pill-claim="${claimId}" data-claim-pill-source="${sourceId}" data-from="${start}" data-to="${end}" source-id="${sourceId}"></claim-indicator>`
            },
          )
          return html
        },
      },
    ],
  })

  output.value = html
}

const showClaims = ref(true)
watch(() => [formattedValue.value, actualTheme.value, showClaims.value], runHighlight, {
  immediate: true,
})

const onKeydown = (e: KeyboardEvent) => {
  const el = textAreaRef.value
  invariant(el, 'no textarea element')

  // Tab key and shift-tab key indenting support
  if (e.key === 'Tab') {
    e.preventDefault()
    const text = el.value
    // selection?
    if (el.selectionStart == el.selectionEnd) {
      // These single character operations are undoable
      if (!e.shiftKey) {
        document.execCommand('insertText', false, '\t')
      } else {
        if (el.selectionStart > 0 && text[el.selectionStart - 1] == '\t') {
          document.execCommand('delete')
        }
      }
    } else {
      // Block indent/unindent trashes undo stack.
      // Select whole lines
      let selStart = el.selectionStart
      let selEnd = el.selectionEnd
      while (selStart > 0 && text[selStart - 1] != '\n') selStart--
      while (selEnd > 0 && text[selEnd - 1] != '\n' && selEnd < text.length) selEnd++

      // Get selected text
      var lines = text.substr(selStart, selEnd - selStart).split('\n')

      // Insert tabs
      for (var i = 0; i < lines.length; i++) {
        // Don't indent last line if cursor at start of line
        if (i == lines.length - 1 && lines[i].length == 0) continue

        // Tab or Shift+Tab?
        if (e.shiftKey) {
          if (lines[i].startsWith('\t')) lines[i] = lines[i].substr(1)
          else if (lines[i].startsWith('    ')) lines[i] = lines[i].substr(4)
        } else lines[i] = '\t' + lines[i]
      }
      const linesresult = lines.join('\n')

      // Update the text area
      el.value = text.substr(0, selStart) + linesresult + text.substr(selEnd)
      el.selectionStart = selStart
      el.selectionEnd = selStart + linesresult.length
    }

    if (e.target) {
      emit('input', (e.target as HTMLTextAreaElement).value)
    }
  }
}

const getLineAndColumnInsidePreElement = (caretPosition: number) => {
  const preEl = highlightContainerRef.value as HTMLElement
  const text = preEl.innerText
  if (!preEl || text === undefined) return { line: 0, column: 0 }

  const lines = text.split('\n')
  let line = 0
  let column = 0
  for (let i = 0; i < lines.length; i++) {
    if (caretPosition <= lines[i].length) {
      line = i
      column = caretPosition
      break
    }
    caretPosition -= lines[i].length + 1
  }

  return { line, column }
}

const updateCaretPosition = async () => {
  // for some reason it's a bit flaky with one nextTick()
  await nextTick()
  await nextTick()
  currentSingleCaretSelection.value = textAreaRef.value?.selectionStart || 0

  const lineAndColumnInsidePreElement = getLineAndColumnInsidePreElement(
    currentSingleCaretSelection.value,
  )
  const emitPayload = {
    top: lineAndColumnInsidePreElement.line * 18 - (highlightContainerRef.value?.scrollTop || 0),
    left: lineAndColumnInsidePreElement.column * 7 - (highlightContainerRef.value?.scrollLeft || 0),
    height: 18,
  }
  emit('customCaretPosition', emitPayload)
}

const onChange = (event: Event) => {
  emit('change', (event.target as HTMLTextAreaElement).value)
}

const onTextareaInput = (event: Event) => {
  updateCaretPosition()
  if (event.target) {
    emit('input', (event.target as HTMLTextAreaElement).value)
  }
}

const groundingStore = useGroundingStore()
watch(
  () => groundingStore.selectedClaimsAndSource?.sourceId,
  (sourceId) => {
    if (sourceId === undefined) {
      return
    }
    const claim = document.querySelector(`[data-claim-pill-source="${sourceId}"]`)
    invariant(claim)

    claim.scrollIntoView({ behavior: 'smooth' })
  },
)

const onButtonClick = (e: MouseEvent) => {
  // click handler for entire highlightContainerRef
  // because we're using v-html, we need to handle the click event for the HTML citation buttons manually
  if (!(e.target instanceof HTMLElement)) {
    return
  }

  if ((e.target as HTMLElement).tagName === 'CLAIM-INDICATOR') {
    e.preventDefault()
    e.stopPropagation()
    const claimIndicator = e.target.closest('[data-claim-pill-claim]')
    const claimId = claimIndicator?.getAttribute('data-claim-pill-claim')
    const sourceId = claimIndicator?.getAttribute('data-claim-pill-source')

    if (!claimId || !sourceId) {
      return
    }

    emit('show-source', { claimId: parseInt(claimId), sourceId: parseInt(sourceId) })
  }
}

const { y: scrollY, x: scrollX } = useScroll(textAreaRef)
const { width } = useElementBounding(highlightContainerRef)

const onClickTextarea = () => {
  if (props.readonly) {
    return
  }
  showClaims.value = false
}
</script>

<template>
  <div
    class="go-scrollbar relative overflow-auto rounded-corner-8"
    :class="[
      variant === 'transparent' && 'bg-background-transparent',
      variant === 'gray' && 'bg-background-gray-subtlest',
    ]"
  >
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <textarea
      ref="textAreaRef"
      type="text"
      class="invisible-textarea relative block resize-none overflow-hidden bg-background-transparent px-2 text-md-13px-default caret-text transition-colors placeholder:text-text-subtlest focus:outline-none disabled:cursor-not-allowed"
      :class="[
        [error && ['border-border-critical']],
        [size === 'md' ? 'px-2 py-1.5' : 'px-2.5 py-2'],
      ]"
      :style="{ width: `${width}px` }"
      :placeholder="placeholder"
      :value="formattedValue"
      :aria-label="ariaLabel"
      :aria-labelledby="ariaLabelledby"
      :readonly="readonly"
      @contextmenu.stop
      @paste.stop
      @change="onChange"
      @input="onTextareaInput"
      @keydown="onKeydown"
      @click="onClickTextarea"
      @blur="showClaims = true"
    />
    <!-- eslint-enable tailwindcss/no-custom-classname -->

    <!-- eslint-disable vue/no-v-html -->
    <div
      ref="highlightContainerRef"
      class="pointer-events-none absolute left-0 top-0 min-h-full min-w-full whitespace-pre-line bg-background-transparent text-[red] [&>pre]:!bg-background-transparent"
      :class="[size === 'md' ? 'px-2 py-1.5' : 'px-2.5 py-2']"
      :style="{ transform: `translate(-${scrollX}px, ${-scrollY}px)` }"
      @click="onButtonClick"
      v-html="output"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<style type="text/css" scoped>
.invisible-textarea {
  font-family:
    ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
  line-height: 18px;
  color: transparent;
  font-size: 12px;
  scrollbar-color: transparent transparent;
  white-space: pre;
  overscroll-behavior: none;
}
</style>
