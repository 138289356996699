import type { Ref } from 'vue'
import { computed, onBeforeUnmount, unref, watch } from 'vue'

import type { Workspace } from '@/modules/Workspaces/useWorkspaces'

import type { Case, CaseResponse, MessageResponse } from '@/modules/Cases/types'
import { useCaseStore } from '@/modules/Cases/useCaseStore'
import { useWebSocketChannel } from '@/sharedComposables/useWebSocketChannel'
import { serializeMessage } from './serializers'

export const useCaseChannel = (workspaceId: Workspace['id'], caseId: Ref<Case['id']>) => {
  const casesStore = useCaseStore()

  const topic = computed<`case:${string}:${string}`>(() => `case:${workspaceId}:${caseId.value}`)

  const { channel, channelState, leaveChannel } = useWebSocketChannel(topic)

  onBeforeUnmount(async () => {
    await leaveChannel()
  })

  watch(
    channel,
    () => {
      const wid = unref(workspaceId)
      if (!wid) return

      channel.value?.on('case:updated', async (data: { case: CaseResponse }) => {
        casesStore.setActiveCase({ workspaceId, caseId: data.case.id })
      })

      channel.value?.on('case:deleted', ({ case_id: caseId }: { case_id: string }) => {
        casesStore.removeCase(caseId)
      })

      channel.value?.on('case:message_stream', ({ message }: { message: MessageResponse }) => {
        const serializedMessage = serializeMessage(message)
        casesStore.addOrUpdateMessageToActiveCase(serializedMessage)
      })
    },
    { immediate: true },
  )

  return {
    channel,
    channelState,
    leaveChannel,
  }
}
