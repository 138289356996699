<script setup lang="ts">
import type { NavigationParams } from '@/modules/Project/Navigation/navigationTypes'
import ProjectLink from '@/modules/Project/Navigation/ProjectLink.vue'
import { getSingleParam } from '@/shared/utils/routing'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { computed, ref } from 'vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { useRoutes } from '../App/useRoutes'
import { useEntitySidebar } from './useEntitySidebar'
import { usePrevOrNextEntity } from './usePrevOrNextEntity'

const props = defineProps<{
  entityId: string
  colIndex: number
  workspaceId: string
  projectId: string
  propertyId: string
  viewId?: string
  filename: string | null
}>()

defineEmits<{
  (e: 'delete'): void
}>()

const entitySidebarStore = useEntitySidebar()
const deleteFile = ref(false)
const showDelete = computed(() => props.filename !== null)
const showExpand = computed(() => props.colIndex === 0)

const { currentlyInSubProject, route } = useRoutes()
const { parentEntityId, parentViewId } = useRouteParams()

const navigationData = computed<NavigationParams>(() => {
  const parentProjectId = currentlyInSubProject.value
    ? getSingleParam(route.params.parentProjectId)
    : undefined

  return {
    parentProjectId,
    parentEntityId: getSingleParam(parentEntityId.value),
    viewId: props.viewId,
    entityId: props.entityId,
    parentViewId: getSingleParam(parentViewId.value),
  }
})

const isEntitySidebarEnabled = useFeatureFlags(FeatureFlag.ENTITY_SIDEBAR)
const { loadAndSetEntity } = usePrevOrNextEntity()

const onOpenEntitySidebar = async (e: Event) => {
  e.preventDefault()
  e.stopPropagation()

  // Set the entity ID in both stores
  entitySidebarStore.setSelectedEntityId(props.entityId)

  // Load the entity data
  await loadAndSetEntity(props.entityId)

  // Then open the sidebar
  entitySidebarStore.sidebarIsOpen = true
}
</script>

<template>
  <div>
    <div
      v-if="showDelete || showExpand"
      class="flex items-center justify-end gap-0.5 rounded-md bg-surface-primary p-0.5 text-text-subtle shadow-xs"
    >
      <template v-if="isEntitySidebarEnabled">
        <IconButton
          v-if="showExpand"
          title="Expand entity"
          aria-label="Expand entity"
          icon="expand"
          size="sm"
          variant="transparent"
          @click="onOpenEntitySidebar"
        />

        <ProjectLink
          v-if="showExpand"
          title="Open in entity view"
          :to="navigationData"
          data-test="entity-view-link"
          @click.stop
        >
          <IconButton
            icon="arrow-top-right"
            size="sm"
            variant="transparent"
          />
        </ProjectLink>
      </template>
      <template v-else>
        <ProjectLink
          v-if="showExpand"
          title="Open in entity view"
          :to="navigationData"
          data-test="entity-view-link"
          @click.stop
        >
          <IconButton
            icon="expand"
            size="sm"
            variant="transparent"
          />
        </ProjectLink>
      </template>

      <IconButton
        v-if="showDelete"
        class="hidden group-hover/cell:flex"
        title="Delete file"
        aria-label="Delete file"
        icon="trash"
        size="sm"
        variant="transparent"
        @click.stop.prevent="deleteFile = true"
        @mousedown.prevent
        @dblclick.stop
      />
    </div>

    <ConfirmationDialog
      :open="deleteFile"
      :title="`Delete ${filename}?`"
      data-test="delete-file-confirmation-dialog"
      description="This file will be deleted immediately. You can't undo this action."
      @close="deleteFile = false"
      @confirm="($emit('delete'), (deleteFile = false))"
    />
  </div>
</template>
