import { computed, type Reactive } from 'vue'
import { RouterLink, type RouterLinkProps } from 'vue-router'

/**
 * A common pattern we have is UI elements that visually resemble buttons,
 * but semantically and functionally are links. This composable abstracts
 * the logic for determining whether the element should be a button, an anchor,
 * or a Vue RouterLink, and provides an object that can be bound to the
 * element in a Vue template.
 */
export const useButtonLink = (
  props: Reactive<{
    href?: string
    to?: RouterLinkProps['to']
  }>,
) => {
  const tag = computed<'button' | 'a' | typeof RouterLink>(() => {
    if (props.to) {
      return RouterLink
    }

    if (props.href) {
      return 'a'
    }

    return 'button'
  })

  /*
   * We need to bind:
   * - the `href` attribute if the tag is an anchor
   * - the `to` prop if the tag is a router-link
   * If we don't do it like this, then we break the RouterLink implementation
   * by setting href as undefined.
   */
  const linkProps = computed(() => {
    if (props.href) {
      return { href: props.href }
    }
    if (props.to) {
      return { to: props.to }
    }
    return {}
  })

  return {
    tag,
    linkProps,
  }
}
