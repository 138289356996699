import { useElementBounding, useElementSize, useEventListener, useScroll } from '@vueuse/core'
import { computed, ref, watch, type Ref } from 'vue'

/**
 * In charge of handling scroll behavior for case chat UI
 *
 * - computes padding that needs to be added to the chat scroll container so the last query sent can be scrolled to the top of the page
 * - computes padding for the bottom of the chat scroll container so the chat content is never obscured by the input field
 * - implements autoscrolling behavior for the chat, where if the user scrolls to bottom and the content grows, we keep snapped to the bottom
 */
export const useCaseScroll = ({
  containerRef,
  inputRef,
  lastQueryRef,
  lastResponseRef,
}: {
  containerRef: Ref<HTMLElement | null>
  inputRef: Ref<HTMLElement | null>
  lastQueryRef: Ref<HTMLElement | null>
  lastResponseRef: Ref<HTMLElement | null>
}) => {
  const { y: containerScrollY } = useScroll(containerRef, { behavior: 'smooth' })

  /** Scrolls to the bottom of the chat */
  const scrollToBottom = () => {
    const bottom = containerRef.value?.scrollHeight
    if (bottom === undefined) return
    containerScrollY.value = bottom
  }

  const { bottom: containerBottom, height: containerHeight } = useElementBounding(containerRef)
  const { height: lastQueryHeight } = useElementSize(lastQueryRef)
  const { height: lastResponseHeight } = useElementSize(lastResponseRef)

  // we could compute these from DOM, but they are unlikely to change often

  // gap between query and response bubbles (gap-2)
  const BUBBLE_GAP = 8
  // padding at the top of the chat container (pt-8)
  const CONTAINER_PADDING_TOP = 32

  /**
   * Padding given to the chat container so that any query can be scrolled to the top of the page
   */
  const chatPadding = computed(() => {
    const queryResponseHeight = lastResponseRef.value
      ? lastQueryHeight.value + lastResponseHeight.value + BUBBLE_GAP
      : lastQueryHeight.value

    const result =
      containerHeight.value - inputPadding.value - queryResponseHeight - CONTAINER_PADDING_TOP

    return Math.max(0, result)
  })

  const { top: inputTop } = useElementBounding(inputRef)

  /**
   * Padding given to the chat container so that the chat content is never obscured by the input field
   */
  const inputPadding = computed(() => containerBottom.value - inputTop.value)

  const autoScrollToBottom = ref(true)
  const setAutoScrollToBottom = (value: boolean) => {
    autoScrollToBottom.value = value
  }

  // set autoscrolling on wheel, not scroll event,
  // scroll events trigger during autoscrolling, which could accidentally turn off autoscrolling
  const SNAP_BUFFER = 16
  useEventListener(containerRef, 'wheel', () => {
    if (!containerRef.value) return

    const { scrollTop, scrollHeight, clientHeight } = containerRef.value
    const isScrolledToBottom = scrollTop + clientHeight + SNAP_BUFFER >= scrollHeight

    autoScrollToBottom.value = isScrolledToBottom
  })

  // autoscroll to bottom when the last response or input padding changes
  watch(
    () => [lastResponseHeight.value, inputPadding.value],
    () => {
      if (!autoScrollToBottom.value) return
      scrollToBottom()
    },
  )

  return {
    setAutoScrollToBottom,
    scrollToBottom,
    chatPadding,
    inputPadding,
  }
}
